import React from 'react'

import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = '#F54C5A' } = props
  const defaultStyle = { width: 14, height: 16 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.33109 4.66662L4.33394 4.66663L4.33679 4.66662H9.66442L9.66727 4.66663L9.67012 4.66662H11.0006V13.3333C11.0006 13.7015 10.7021 14 10.3339 14H3.66727C3.29908 14 3.0006 13.7015 3.0006 13.3333V4.66662H4.33109ZM10.3339 3.33329H11.6673H13.0007C13.3688 3.33329 13.6673 3.63176 13.6673 3.99995C13.6673 4.36814 13.3688 4.66662 13.0007 4.66662H12.3339V13.3333C12.3339 14.4379 11.4385 15.3333 10.3339 15.3333H3.66727C2.5627 15.3333 1.66727 14.4379 1.66727 13.3333V4.66662H1.00065C0.632461 4.66662 0.333984 4.36814 0.333984 3.99995C0.333984 3.63176 0.632461 3.33329 1.00065 3.33329H2.33394H3.66727V2.66663C3.66727 1.56206 4.5627 0.666626 5.66727 0.666626H8.33394C9.43851 0.666626 10.3339 1.56206 10.3339 2.66663V3.33329ZM9.0006 3.33329V2.66663C9.0006 2.29844 8.70213 1.99996 8.33394 1.99996H5.66727C5.29908 1.99996 5.0006 2.29844 5.0006 2.66663V3.33329H9.0006ZM5.0007 7.33329V11.3333C5.0007 11.7015 5.29917 12 5.66736 12C6.03555 12 6.33403 11.7015 6.33403 11.3333V7.33329C6.33403 6.9651 6.03555 6.66663 5.66736 6.66663C5.29917 6.66663 5.0007 6.9651 5.0007 7.33329ZM7.66727 11.3333V7.33329C7.66727 6.9651 7.96575 6.66663 8.33394 6.66663C8.70213 6.66663 9.0006 6.9651 9.0006 7.33329V11.3333C9.0006 11.7015 8.70213 12 8.33394 12C7.96575 12 7.66727 11.7015 7.66727 11.3333Z"
        fill={fill}
      />
    </svg>
  )
}
