import React from 'react'

import { IIcons } from './types'

export default (props: IIcons) => {
  const { style } = props
  const defaultStyle = { width: 20, height: 20 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.375 16.8755H10.625C10.28 16.8755 10 16.5955 10 16.2505C10 15.9055 10 4.09549 10 3.75049C10 3.40549 10.28 3.12549 10.625 3.12549H19.375C19.72 3.12549 20 3.40549 20 3.75049V16.2505C20 16.5955 19.72 16.8755 19.375 16.8755Z"
        fill="white"
      />
      <path
        d="M13.125 6.87549H10.625C10.28 6.87549 10 6.59549 10 6.25049C10 5.90549 10.28 5.62549 10.625 5.62549H13.125C13.47 5.62549 13.75 5.90549 13.75 6.25049C13.75 6.59549 13.47 6.87549 13.125 6.87549Z"
        fill="#128F30"
      />
      <path
        d="M13.125 9.37549H10.625C10.28 9.37549 10 9.09549 10 8.75049C10 8.40549 10.28 8.12549 10.625 8.12549H13.125C13.47 8.12549 13.75 8.40549 13.75 8.75049C13.75 9.09549 13.47 9.37549 13.125 9.37549Z"
        fill="#128F30"
      />
      <path
        d="M13.125 11.8755H10.625C10.28 11.8755 10 11.5955 10 11.2505C10 10.9055 10.28 10.6255 10.625 10.6255H13.125C13.47 10.6255 13.75 10.9055 13.75 11.2505C13.75 11.5955 13.47 11.8755 13.125 11.8755Z"
        fill="#128F30"
      />
      <path
        d="M13.125 14.3755H10.625C10.28 14.3755 10 14.0955 10 13.7505C10 13.4055 10.28 13.1255 10.625 13.1255H13.125C13.47 13.1255 13.75 13.4055 13.75 13.7505C13.75 14.0955 13.47 14.3755 13.125 14.3755Z"
        fill="#128F30"
      />
      <path
        d="M16.875 6.87549H15.625C15.28 6.87549 15 6.59549 15 6.25049C15 5.90549 15.28 5.62549 15.625 5.62549H16.875C17.22 5.62549 17.5 5.90549 17.5 6.25049C17.5 6.59549 17.22 6.87549 16.875 6.87549Z"
        fill="#128F30"
      />
      <path
        d="M16.875 9.37549H15.625C15.28 9.37549 15 9.09549 15 8.75049C15 8.40549 15.28 8.12549 15.625 8.12549H16.875C17.22 8.12549 17.5 8.40549 17.5 8.75049C17.5 9.09549 17.22 9.37549 16.875 9.37549Z"
        fill="#128F30"
      />
      <path
        d="M16.875 11.8755H15.625C15.28 11.8755 15 11.5955 15 11.2505C15 10.9055 15.28 10.6255 15.625 10.6255H16.875C17.22 10.6255 17.5 10.9055 17.5 11.2505C17.5 11.5955 17.22 11.8755 16.875 11.8755Z"
        fill="#128F30"
      />
      <path
        d="M16.875 14.3755H15.625C15.28 14.3755 15 14.0955 15 13.7505C15 13.4055 15.28 13.1255 15.625 13.1255H16.875C17.22 13.1255 17.5 13.4055 17.5 13.7505C17.5 14.0955 17.22 14.3755 16.875 14.3755Z"
        fill="#128F30"
      />
      <path
        d="M11.0238 0.769104C10.8813 0.650354 10.69 0.599104 10.51 0.636604L0.51 2.5116C0.21375 2.5666 0 2.8241 0 3.12535V16.8754C0 17.1754 0.21375 17.4341 0.51 17.4891L10.51 19.3641C10.5475 19.3716 10.5863 19.3754 10.625 19.3754C10.77 19.3754 10.9113 19.3254 11.0238 19.2316C11.1675 19.1129 11.25 18.9354 11.25 18.7504V1.25035C11.25 1.0641 11.1675 0.887854 11.0238 0.769104Z"
        fill="#128F30"
      />
      <path
        d="M8.59467 12.0891L6.61842 9.83037L8.61717 7.26037C8.82967 6.98787 8.77967 6.59537 8.50842 6.38287C8.23717 6.17037 7.84467 6.22037 7.63092 6.49162L5.77967 8.87162L4.21967 7.08912C3.99092 6.82662 3.59592 6.80287 3.33842 7.03037C3.07842 7.25787 3.05217 7.65287 3.27967 7.91162L4.99842 9.87662L3.25592 12.1166C3.04342 12.3891 3.09342 12.7816 3.36467 12.9941C3.47967 13.0829 3.61592 13.1254 3.74967 13.1254C3.93592 13.1254 4.11967 13.0429 4.24342 12.8841L5.83717 10.8341L7.65467 12.9104C7.77842 13.0529 7.95092 13.1254 8.12467 13.1254C8.27092 13.1254 8.41717 13.0741 8.53592 12.9704C8.79592 12.7429 8.82217 12.3479 8.59467 12.0891Z"
        fill="#FAFAFA"
      />
    </svg>
  )
}
