import React, { PureComponent, SyntheticEvent } from 'react'
import { FormattedMessage } from 'react-intl'
import ClickOutHandler from 'react-onclickout'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'

import { Mixpanel } from '../../analytics'
import { AttentionIcon } from '../../components/Icons'
import BurgerIcon from '../../components/Icons/BurgerIcon'
import CapIcon from '../../components/Icons/CapIcon'
import InviteFriendIcon from '../../components/Icons/InviteFriendIcon'
import UserIcon from '../../components/Icons/UserIcon'
import { SuggestCompanyTypes } from '../../const'
import { BackgroundWhite, RedBase, White } from '../../const/colors'
import { Routes } from '../../const/routes'
import { toggleMenu } from '../../store/app'
import { MyThunkDispatch } from '../../store/store'
import IApplicationState from '../../types/state'
import PopupMenu from './components/PopupMenu'
import {
  AttentionIconWrapper,
  BurgerButton,
  BurgerButtonWrapper,
  ButtonBlock,
  ButtonGroup,
  ClientLabel,
  ClientName,
  ClientNameBlock,
  Container,
  HeaderButton,
  HeaderIconButton,
  IconButton,
  LogoWrapper,
  NavBlock,
  StyledHeader,
  StyledLogo,
  Wrapper,
} from './styled'
import { IHeaderProps, IHeaderState } from './types'

const BLOG_URL = 'https://www.onespot.one/blog'
const HELP_URL = 'https://www.onespot.one/help'

/**
 * Header line with partners badges in desktop and logo in mobile versions.
 */
export class Header extends PureComponent<IHeaderProps, IHeaderState> {
  constructor(props: IHeaderProps) {
    super(props)

    this.state = {
      isOpen: false,
    }
  }

  public render() {
    const { user, isDesktop } = this.props
    const { name, inn, profile_type } = this.props.profile

    const showIndividualAttention = profile_type === SuggestCompanyTypes.SE && (!inn || !name)

    return (
      <Wrapper {...this.props}>
        <StyledHeader>
          <Container>
            <NavBlock>
              {!!user.has_profile && (
                <BurgerButtonWrapper>
                  <BurgerButton onClick={this.onMenuToggle}>
                    <BurgerIcon />
                  </BurgerButton>
                </BurgerButtonWrapper>
              )}
              {user.has_profile ? (
                <NavLink exact={true} to={Routes.ACCOUNTS} onClick={this.onLogoClick}>
                  <StyledLogo color={BackgroundWhite} fillBg={BackgroundWhite} fill="rgba(255, 255, 255, 0.3)" />
                </NavLink>
              ) : (
                <LogoWrapper>
                  <StyledLogo color={BackgroundWhite} />
                </LogoWrapper>
              )}
            </NavBlock>

            {isDesktop &&
              (user.profile.is_profile_client ? (
                <ClientNameBlock>
                  <ClientLabel>
                    <FormattedMessage id="header.youAreClient" />
                  </ClientLabel>
                  <ClientName>{user.profile.agency}</ClientName>
                </ClientNameBlock>
              ) : (
                <div />
              ))}

            <ButtonBlock>
              {isDesktop ? (
                <ButtonGroup>
                  {!!user.has_profile && (
                    <HeaderIconButton
                      theme="default"
                      bType="link"
                      to={Routes.REFERRAL_WELCOME}
                      onClick={this.onInviteFriendClick}
                    >
                      <InviteFriendIcon fill={White} />
                    </HeaderIconButton>
                  )}
                  <HeaderButton
                    target="_blank"
                    theme="default"
                    bType="external_link"
                    size="small"
                    href={BLOG_URL}
                    onClick={this.onBlogClick}
                  >
                    <FormattedMessage id="header.blog" />
                  </HeaderButton>
                  <HeaderButton
                    target="_blank"
                    theme="default"
                    bType="external_link"
                    size="small"
                    href={HELP_URL}
                    onClick={this.onSupportClick}
                  >
                    <FormattedMessage id="header.help" />
                  </HeaderButton>
                </ButtonGroup>
              ) : (
                <IconButton target="_blank" bType="external_link" href={BLOG_URL} onClick={this.onBlogClick}>
                  <CapIcon />
                </IconButton>
              )}
              <IconButton onClick={this.toggleMenu} data-testid="user-profile">
                <UserIcon />
                {showIndividualAttention && (
                  <AttentionIconWrapper>
                    <AttentionIcon fillBg={RedBase} style={{ width: 14, height: 14 }} />
                  </AttentionIconWrapper>
                )}
              </IconButton>
              {this.state.isOpen && (
                <ClickOutHandler onClickOut={this.onClickOut}>
                  <PopupMenu email={user.email} onClick={this.onClickClose} hasProfile={Boolean(user.has_profile)} />
                </ClickOutHandler>
              )}
            </ButtonBlock>
          </Container>
        </StyledHeader>
      </Wrapper>
    )
  }

  private onMenuToggle = () => {
    this.props.openMenu(!this.props.isMenuOpened)

    Mixpanel.track('ToggleMenuBurger')
  }

  private onClickOut = (e: SyntheticEvent) => {
    e.preventDefault()

    this.setState({
      isOpen: false,
    })
  }

  private toggleMenu = (e: SyntheticEvent) => {
    e.preventDefault()

    Mixpanel.track('TogglePopupMenu')

    this.setState((prevState: IHeaderState) => ({
      isOpen: !prevState.isOpen,
    }))
  }

  private onSupportClick = () => {
    Mixpanel.track('GoToHelp')
  }

  private onInviteFriendClick = () => {
    Mixpanel.track('ReferralVisit', { place: 'header' })
  }

  private onBlogClick = () => {
    Mixpanel.track('GoToBlog')
  }

  private onLogoClick = () => {
    Mixpanel.track('LogoClick')
  }

  private onClickClose = () => {
    this.setState({
      isOpen: false,
    })
  }
}

const mapStateToProps = (state: IApplicationState) => ({
  isDesktop: state.browser.greaterThan.medium,
  isMenuOpened: state.app.isMenuOpened,
  profile: state.user.profile,
  user: state.user,
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  openMenu: (open: boolean) => dispatch(toggleMenu(open)),
})

const HeaderClass = connect(mapStateToProps, mapDispatchToProps)(Header)

export default HeaderClass
