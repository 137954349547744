export enum AntibanActionTypes {
  SET_TASKS = 'SET_TASKS',
  SET_TASK = 'SET_TASK',
}

export interface IAntibanState {
  tasks: AntibanTaskSlugs[]
}

export type AntibanTaskSlugs =
  | 'real-profile'
  | 'fake-profile'
  | 'vpn'
  | 'two-factor-auth'
  | 'fb-auth'
  | 'company-info'
  | 'page-content'
  | 'remove-posts'
  | 'direct'
  | 'followers'
  | 'another-account'
  | 'adsets'
  | 'industry'
  | 'website'
  | 'many-ads'
  | 're-moderation'
  | 'remove-creatives'
  | 'two-factor-auth-bm'
  | 'admin'
  | 'company-approve'
  | 'domain'
  | 're-role'
  | 'remove-ex-users'
  | 'unblock-accounts'
  | 'reject-access'
  | 'remove-ex-colleagues'
  | 'giveaways'
