import { AxiosError, AxiosPromise, AxiosResponse } from 'axios'
import { AnyAction, Dispatch } from 'redux'

import { API_URL } from '../../const'
import { customAxios } from '../../utils'
import { ThunkResult } from '../store'
import { IInvoiceObject, IInvoiceUpdateResponse, InvoicesActionTypes } from './types'

export function reqInvoiceGeneration(amount: number, clientId?: number | null): ThunkResult<AxiosPromise<any>> {
  return (): AxiosPromise<any> => {
    const url = clientId ? `${API_URL}/invoices/clients/${clientId}/` : `${API_URL}/invoices/`
    return customAxios(url, 'POST', { amount }, true)
      .then((response: AxiosResponse<IInvoiceObject>) => response)
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function reqEmailInvoice(id: number, email: string): ThunkResult<AxiosPromise<any>> {
  return (): AxiosPromise<any> => {
    return customAxios(`${API_URL}/invoices/${id}/`, 'PUT', { email }, true)
      .then((response: AxiosResponse<IInvoiceUpdateResponse>) => response)
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function getInvoicesPage(page?: number): ThunkResult<AxiosPromise<any>> {
  return (dispatch: Dispatch<AnyAction>): AxiosPromise<any> => {
    const url = page ? `${API_URL}/invoices/?page=${page}` : `${API_URL}/invoices/`

    return customAxios(url, 'GET', null, true)
      .then((resp: AxiosResponse<IInvoiceObject[]>) => {
        dispatch({ type: InvoicesActionTypes.SET_INVOICES, data: resp.data })

        return resp
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function deleteInvoice(id: number): ThunkResult<AxiosPromise<any>> {
  return (): AxiosPromise<any> => {
    return customAxios(`${API_URL}/invoices/${id}/`, 'DELETE', null, true)
      .then((response: AxiosResponse) => response)
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function getInvoiceSum(): ThunkResult<AxiosPromise<any>> {
  return (): AxiosPromise<any> => {
    return customAxios(`${API_URL}/invoices/recommendation_sum/`, 'GET', null, true)
      .then((response: AxiosResponse) => response)
      .catch((error: AxiosError) => Promise.reject(error))
  }
}
