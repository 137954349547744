import { AxiosError, AxiosResponse } from 'axios'
import { AnyAction, Dispatch } from 'redux'

import { API_URL } from '../../const'
import { PlatformValues } from '../../pages/MyCashback/consts'
import { ExtraPlatformTypes } from '../../pages/MyCashback/types'
import { customAxios } from '../../utils'
import { ThunkResult } from '../store'
import {
  IMyCashbackHistory,
  IMyCashbackPlatforms,
  IMyCashbackProgress,
  IMyCashbackSpends,
  MyCashbackActionTypes,
  MyCashbackProgressType,
} from './types'

export function getSpends(): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/finance/total_spends/`, 'GET', null, true)
      .then((response: AxiosResponse<IMyCashbackSpends>) => {
        dispatch({ type: MyCashbackActionTypes.SET_SPENDS, data: response.data })
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function getProgress(platform: MyCashbackProgressType): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/finance/progress/${platform}/`, 'GET', null, true)
      .then((response: AxiosResponse<IMyCashbackProgress>) => {
        dispatch({ type: MyCashbackActionTypes.SET_PROGRESS, data: response.data })
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function getHistory(platform: ExtraPlatformTypes, page?: number): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    let url =
      platform === PlatformValues.all
        ? `${API_URL}/finance/history_cashback/`
        : `${API_URL}/finance/history_cashback/${platform}/`

    if (page) {
      url = `${url}?page=${page}`
    }

    return customAxios(url, 'GET', null, true)
      .then((response: AxiosResponse<IMyCashbackHistory>) => {
        dispatch({ type: MyCashbackActionTypes.SET_HISTORY, data: response.data })
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function getPlatforms(): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/finance/platforms/`, 'GET', null, true)
      .then((response: AxiosResponse<IMyCashbackPlatforms>) => {
        dispatch({ type: MyCashbackActionTypes.SET_PLATFORMS, data: response.data })
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}
