import { AxiosError, AxiosPromise, AxiosResponse } from 'axios'
import { AnyAction, Dispatch } from 'redux'

import { API_URL } from '../../const'
import { customAxios } from '../../utils'
import { AccountingActionTypes, IActs, IDocuments } from './types'

export function getAccounting(page?: number) {
  return (dispatch: Dispatch<AnyAction>): AxiosPromise<any> => {
    const url = page ? `${API_URL}/accounting/?page=${page}` : `${API_URL}/accounting/`

    return customAxios(url, 'GET', false)
      .then((response: AxiosResponse<IDocuments>) => {
        if (response.status === 200) {
          dispatch({ type: AccountingActionTypes.SET_ACCOUNTING, data: response.data })
        }

        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function getActs(page?: number) {
  return (dispatch: Dispatch<AnyAction>): AxiosPromise<any> => {
    const url = page
      ? `${API_URL}/accounting/acts_of_reconciliation/?page=${page}`
      : `${API_URL}/accounting/acts_of_reconciliation/`

    return customAxios(url, 'GET', false)
      .then((response: AxiosResponse<IActs>) => {
        if (response.status === 200) {
          dispatch({ type: AccountingActionTypes.SET_ACTS, data: response.data })
        }

        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}
