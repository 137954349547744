import React from 'react'

import { Black } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = Black } = props
  const defaultStyle = { width: 18, height: 18 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.5903 7C13.2009 6.10823 12.5598 5.34942 11.7456 4.81655C10.9313 4.28369 9.97936 3.99991 9.00627 4C6.76762 4 4.87197 5.47154 4.23523 7.5"
        stroke={fill}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5062 7H13.7062C13.7456 7 13.7846 6.99224 13.821 6.97716C13.8574 6.96209 13.8905 6.93999 13.9184 6.91213C13.9462 6.88427 13.9683 6.8512 13.9834 6.81481C13.9985 6.77841 14.0062 6.7394 14.0062 6.7V4.5"
        stroke={fill}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.44678 11C5.21878 12.766 6.98078 14 9.03078 14C11.1721 14 12.999 12.6542 13.7116 10.7626"
        stroke={fill}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.53125 11H4.33125C4.25169 11 4.17538 11.0316 4.11912 11.0879C4.06286 11.1441 4.03125 11.2204 4.03125 11.3V13.5"
        stroke={fill}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
