import { AxiosError, AxiosResponse } from 'axios'
import { AnyAction, Dispatch } from 'redux'

import { API_URL } from '../../const'
import { customAxios } from '../../utils'
import { ThunkResult } from '../store'
import { IStory, IStoryCard, StoriesActionTypes } from './types'

export function getStories(): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/stories/`, 'GET', null, true)
      .then((response: AxiosResponse<IStory[]>) => {
        dispatch({ type: StoriesActionTypes.SET_STORIES, data: response.data })
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function setStoryWatched(
  storyCardId: number,
  storyId: number,
  isLastStoryCard = false
): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/stories/user_story/${storyCardId}/`, 'POST', { is_watched: true }, true)
      .then((response: AxiosResponse<IStoryCard>) => {
        dispatch({
          data: { data: { id: storyCardId, is_watched: true }, storyId },
          type: StoriesActionTypes.SET_STORY_CARD_WATCHED,
        })

        if (isLastStoryCard) {
          dispatch({ type: StoriesActionTypes.SET_STORY_WATCHED, data: { id: storyId, is_watched: true } })
        }

        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}
