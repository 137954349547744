import React from 'react'

import { SlateGreyLighten22 } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = SlateGreyLighten22 } = props
  const defaultStyle = { width: 22, height: 22 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.323 4.3999C18.7925 4.3999 19.173 4.78046 19.173 5.2499V6.8999H20.823C21.2925 6.8999 21.673 7.28046 21.673 7.7499C21.673 8.21934 21.2925 8.5999 20.823 8.5999H19.173V10.2499C19.173 10.7193 18.7925 11.0999 18.323 11.0999C17.8536 11.0999 17.473 10.7193 17.473 10.2499V8.5999H15.823C15.3536 8.5999 14.973 8.21934 14.973 7.7499C14.973 7.28046 15.3536 6.8999 15.823 6.8999H17.473V5.2499C17.473 4.78046 17.8536 4.3999 18.323 4.3999Z"
        fill={fill}
      />
      <path
        d="M9.19473 11.0999C10.1205 11.0999 11.0157 10.6982 11.7163 9.96938C12.3976 9.25845 12.8137 8.31038 12.8871 7.30065C12.9654 6.22353 12.6273 5.23301 11.9352 4.51114C11.2431 3.78928 10.2754 3.3999 9.19473 3.3999C8.1217 3.3999 7.15134 3.79628 6.4615 4.5164C5.76491 5.24351 5.42585 6.23228 5.5042 7.30021C5.57939 8.31126 5.995 9.25889 6.67493 9.96895C7.37378 10.6982 8.26849 11.0999 9.19473 11.0999Z"
        fill={fill}
      />
      <path
        d="M3.25868 18.7999H15.1305C15.3213 18.8009 15.5099 18.7593 15.6816 18.6784C15.8534 18.5975 16.0037 18.4794 16.1211 18.3331C16.2456 18.1746 16.3316 17.9907 16.3726 17.7953C16.4136 17.5999 16.4086 17.3981 16.3579 17.2048C15.9788 15.7256 15.0395 14.4989 13.6418 13.6576C12.4013 12.9112 10.8221 12.5 9.19479 12.5C7.53549 12.5 5.99776 12.8937 4.75002 13.6397C3.34918 14.4766 2.40854 15.7103 2.0312 17.2074C1.98115 17.4008 1.9767 17.6027 2.01818 17.798C2.05966 17.9933 2.146 18.177 2.27075 18.3353C2.388 18.4809 2.538 18.5984 2.70924 18.679C2.88048 18.7595 3.06843 18.8009 3.25868 18.7999Z"
        fill={fill}
      />
    </svg>
  )
}
