import { createUseStyles } from 'react-jss'

import { White } from '@/const/colors'
import { FontH3 } from '@/const/fontStyles'

export default createUseStyles(
  {
    wrapper: {
      position: 'relative',

      '& .slick-dots li button:before': {
        height: 7,
        width: 7,
        padding: 0,
      },

      '& .slick-dots li.slick-active button:before': {
        height: 7,
        width: 21,
      },

      '& .slick-dots li.slick-active': {
        width: 21,
        height: 7,
      },

      '& .slick-dots li': {
        width: 7,
      },

      '& .slick-dots li button::before': {
        opacity: 0,
      },

      '& .slick-dots li button': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 7,
        height: 7,
        borderRadius: '50%',
        backgroundColor: '#966BE1',
        padding: 0,
      },

      '& .slick-dots li.slick-active button': {
        width: 21,
        borderRadius: 22,
        height: 7,
        backgroundColor: White,
      },

      '& .slick-dots': {
        position: 'absolute',
        width: 'initial',
        top: '-18px',
        left: 30,
        zIndex: 2,
      },
    },
    card: {
      width: '100%',
      height: 'calc(100vh - 263px)',
      position: 'relative',
      display: 'flex !important',
      alignItems: 'center',
      justifyContent: 'center',
    },
    imageWrapper: {
      position: 'relative',
      height: 472,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    firstCardImage: {
      width: 250,
      height: 317,
    },
    secondCardImage: {
      width: 384,
      height: 472,
    },
    thirdCardImage: {
      width: 384,
      height: 472,
    },
    animationWrapper: {
      position: 'absolute',
    },
    firstAnimation: {
      width: 169,
      height: 84,
      left: '-33px',
      bottom: 26,
    },
    secondAnimation: {
      width: 112,
      height: 87,
      top: 104,
      left: 142,
    },
    thirdAnimation: {
      width: 384,
      height: 179,
      top: 184,
      left: '-2px',
    },
    shiftRight: {
      left: 10,
    },
    title: {
      margin: '0 0 47px 0',
      padding: '0 30px',
      ...FontH3,
      color: White,
      textAlign: 'start',
    },
    notActive: {
      transition: 'opacity 0.2s ease-in-out',
      opacity: 0,
    },
  },
  { name: 'Carousel' }
)
