import { AxiosError, AxiosPromise, AxiosResponse } from 'axios'
import { AnyAction, Dispatch } from 'redux'

import { API_URL } from '../../const'
import { AdAccountsActionTypes, IAdAccountsState } from '../../store/adaccounts/types'
import { ThunkResult } from '../../store/store'
import { Platforms } from '../../types'
import { customAxios } from '../../utils'
import { ISubject, ISuggestOkveds, ITelegramRequestBody } from './types'

export enum AddTelegramActionTypes {
  SET_TELEGRAM_SUBJECTS = 'SET_TELEGRAM_SUBJECTS',
  SET_TELEGRAM_LOADING = 'SET_TELEGRAM_LOADING',
  SET_TELEGRAM_ERROR = 'SET_TELEGRAM_ERROR',
  SET_TELEGRAM_REDIRECT = 'SET_TELEGRAM_REDIRECT',
  UPDATE_TELEGRAM_ERROR = 'UPDATE_TELEGRAM_ERROR',
}

export function getSubjects(): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/telegram_accounts/subjects/`, 'GET', null, true)
      .then((response: AxiosResponse<ISubject[]>) => {
        dispatch({
          type: AddTelegramActionTypes.SET_TELEGRAM_SUBJECTS,
          data: response.data,
        })
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function setLoading(isLoading: boolean): AnyAction {
  return { type: AddTelegramActionTypes.SET_TELEGRAM_LOADING, data: isLoading }
}

export function createTelegramAdAccount(body: ITelegramRequestBody): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/telegram_accounts/accounts/`, 'POST', body, true)
      .then((response: AxiosResponse<IAdAccountsState>) => {
        dispatch({ type: AdAccountsActionTypes.SET_AD_ACCOUNT, data: response.data, network: Platforms.TELEGRAM })
        return response
      })
      .catch((error: AxiosError) => {
        dispatch({
          type: AddTelegramActionTypes.SET_TELEGRAM_ERROR,
          data: error.response?.data,
        })
        dispatch({
          type: AddTelegramActionTypes.SET_TELEGRAM_LOADING,
          data: false,
        })
        return Promise.reject(error)
      })
  }
}

export function setRedirect(route: string): AnyAction {
  return { type: AddTelegramActionTypes.SET_TELEGRAM_REDIRECT, data: route }
}

export function updateError(error: { [field: string]: string }): AnyAction {
  return { type: AddTelegramActionTypes.UPDATE_TELEGRAM_ERROR, data: error }
}

export function getSearchOkvedsRequest(query: string): AxiosPromise<ISuggestOkveds> {
  return customAxios(`${API_URL}/ad_mark/common/okveds/?code=${query}`, 'GET', false, true)
    .then((response: AxiosResponse<ISuggestOkveds[]>): any => {
      if (response.status === 200) {
        return { suggestions: [{ data: response.data }] }
      }

      return response
    })
    .catch((error: AxiosError) => Promise.reject(error))
}
