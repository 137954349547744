import { Dispatch } from 'react'
import { AxiosError, AxiosPromise, AxiosResponse } from 'axios'
import { AnyAction } from 'redux'

import { AccountsActionTypes, IUpdateAccounts } from './types'

import { API_URL } from '@/const'
import { MyThunkDispatch } from '@/store/store'
import { getUserPlugins, removeUserPlugin } from '@/store/user'
import { customAxios } from '@/utils'

export function getAccounts(page?: number, query?: string) {
  return (dispatch: Dispatch<AnyAction>): AxiosPromise<any> => {
    dispatch({ type: AccountsActionTypes.SET_ACCOUNTS_LOADING })

    let url = `${API_URL}/ad_mark/accounts/`

    if (page && !query) {
      url = `${url}?page=${page}`
    }

    if (page && query) {
      url = `${url}?page=${page}&${query}`
    }

    return customAxios(url, 'GET', null, true)
      .then((response: AxiosResponse<any>) => {
        dispatch({ type: AccountsActionTypes.SET_ACCOUNTS, data: response.data })
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export const patchAdMarkAccounts = (data: IUpdateAccounts) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch({ type: AccountsActionTypes.SET_ACCOUNTS_LOADING })
      const url = `${API_URL}/ad_mark/accounts/bulk/`
      const response = await customAxios(url, 'POST', data, true)
      return response
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export const getAccountsFilled = () => {
  return async (dispatch: MyThunkDispatch): Promise<AxiosResponse<any, any>> => {
    try {
      const url = `${API_URL}/ad_mark/accounts_filled/`
      const response = await customAxios(url, 'GET', null, true)
      dispatch({ type: AccountsActionTypes.SET_ACCOUNTS_FILLED, data: response.data })

      if (response.data?.account_filled !== response.data?.account_total) {
        const pluginsResponse = await dispatch(getUserPlugins())
        const adsMarkingSuccessPlugin = pluginsResponse.data.find(
          (plugin: any) => plugin.plugin === 'ads_marking_success'
        )
        if (adsMarkingSuccessPlugin?.id) {
          await dispatch(removeUserPlugin(adsMarkingSuccessPlugin))
        }
      }
      return response
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export function getAccountsUpdate(query: string) {
  return (dispatch: Dispatch<AnyAction>): AxiosPromise<any> => {
    dispatch({ type: AccountsActionTypes.SET_ACCOUNTS_LOADING })
    dispatch({ type: AccountsActionTypes.CLEAR_ACCOUNTS })

    const url = `${API_URL}/ad_mark/accounts/?${query}`

    return customAxios(url, 'GET', null, true)
      .then((response: AxiosResponse<any>) => {
        dispatch({ type: AccountsActionTypes.UPDATE_ACCOUNTS, data: response.data })

        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export const getProfileAdmarkInfo = () => {
  return async (dispatch: MyThunkDispatch): Promise<AxiosResponse<any, any>> => {
    try {
      const url = `${API_URL}/ad_mark/common/profile_admark_info/`
      const response = await customAxios(url, 'GET', null, true)
      dispatch({ type: AccountsActionTypes.SET_ACCOUNTS_PROFILE_ADMARK_INFO, data: response.data })

      if (response.data?.has_errors) {
        const pluginsResponse = await dispatch(getUserPlugins())
        const adsMarkingSuccessPlugin = pluginsResponse.data.find(
          (plugin: any) => plugin.plugin === 'ads_marking_success'
        )
        if (adsMarkingSuccessPlugin?.id) {
          await dispatch(removeUserPlugin(adsMarkingSuccessPlugin))
        }
      }
      return response
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
