import React from 'react'

import { BackgroundWhite } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = BackgroundWhite } = props
  const defaultStyle = { width: 40, height: 40 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM8 13C8.1913 22.3477 13.1118 27.973 21.2197 27.973H21.69V22.6253C24.6415 22.9245 26.845 25.128 27.744 27.973H32C30.845 23.717 27.8504 21.3639 25.9892 20.465C27.8504 19.3531 30.4811 16.6577 31.101 13H27.2305C26.4164 15.973 24 18.6685 21.69 18.9245V13H17.7533V23.3747C15.3585 22.7807 12.2344 19.8666 12.1064 13H8Z"
        fill={fill}
      />
    </svg>
  )
}
