import styled from 'styled-components'

import { ILogoProps } from './Logo'

export const StyledLogo = styled.figure`
  width: 100%;
  height: auto;

  & svg {
    display: block;
    fill: ${(props: ILogoProps) => (props.color ? props.color : null)};
  }
`
