import React from 'react'

import { IIcons } from './types'

export default (props: IIcons) => {
  const { style } = props
  const defaultStyle = { width: 8, height: 9 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.63832 4.30418C5.40514 3.07678 4.19955 1.87663 3.00379 0.686662C3.24439 0.447425 3.47327 0.219573 3.69403 -1.21348e-07C5.11452 1.41455 6.55536 2.84945 8 4.28797C7.98155 4.30763 7.96309 4.32868 7.94343 4.34834C6.55209 5.7415 5.16057 7.13466 3.76923 8.52782C3.68645 8.61078 3.68662 8.61044 3.60314 8.52765C3.40152 8.32739 3.19973 8.12731 3 7.92947C4.21404 6.71983 5.42015 5.51796 6.63832 4.30418Z" />
      <path d="M3.63832 4.30418C2.40514 3.07678 1.19955 1.87663 0.00379453 0.686662C0.244395 0.447425 0.473267 0.219573 0.694032 -1.21348e-07C2.11452 1.41455 3.55536 2.84945 5 4.28797C4.98155 4.30763 4.96309 4.32868 4.94343 4.34834C3.55209 5.7415 2.16057 7.13466 0.769232 8.52782C0.686445 8.61078 0.686618 8.61044 0.603141 8.52765C0.401519 8.32739 0.199725 8.12731 1.38643e-06 7.92947C1.21404 6.71983 2.42015 5.51796 3.63832 4.30418Z" />
    </svg>
  )
}
