import React from 'react'

import { PurpleBase } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = PurpleBase } = props
  const defaultStyle = { width: 18, height: 18 }

  return (
    <svg
      width="20"
      height="20"
      style={{ ...defaultStyle, ...style }}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.2846 2.28919C13.3834 2.34435 13.4599 2.43015 13.5016 2.53256C13.5432 2.63498 13.5476 2.74796 13.5139 2.85305L11.6947 8.54217H15.0512C15.1498 8.54213 15.2463 8.5699 15.3287 8.62204C15.4112 8.67417 15.4759 8.7484 15.515 8.83556C15.5541 8.92272 15.5659 9.01899 15.5488 9.1125C15.5317 9.206 15.4865 9.29263 15.4189 9.36171L7.33834 17.6251C7.26015 17.7051 7.15611 17.7573 7.04317 17.7731C6.93023 17.789 6.81505 17.7675 6.71641 17.7123C6.61777 17.657 6.54148 17.5712 6.49998 17.4689C6.45847 17.3666 6.4542 17.2537 6.48786 17.1488L8.30699 11.4587H4.95055C4.85191 11.4587 4.75542 11.4309 4.67299 11.3788C4.59055 11.3267 4.52579 11.2524 4.48669 11.1653C4.44759 11.0781 4.43586 10.9818 4.45295 10.8883C4.47005 10.7948 4.51522 10.7082 4.58288 10.6391L12.6634 2.37572C12.7415 2.2958 12.8454 2.24367 12.9581 2.22778C13.0709 2.21189 13.186 2.2332 13.2846 2.28822V2.28919Z"
        stroke={fill}
        strokeWidth="1.6"
      />
    </svg>
  )
}
