import { UseFormSetError } from 'react-hook-form'
import { IntlShape } from 'react-intl'
import * as Sentry from '@sentry/browser'
import axios, { AxiosRequestConfig, Method } from 'axios'
import qs from 'qs'

import { jwt } from './index'

import { FORM_ERROR_TYPE, SENTRY_MESSAGE } from '@/const'

const HAS_NOT_PROFILE = 'Отсутствует профиль пользователя'

let isAlreadyFetchingAccessToken = false
let subscribers: any = []
let refreshCounts = 0

function onAccessTokenFetched(accessToken: string) {
  subscribers = subscribers.filter((callback: any) => callback(accessToken))
}

function addSubscriber(callback: any) {
  subscribers.push(callback)
}

axios.interceptors.response.use(
  (response) => {
    if (response.config && response.config.url && !response.config.url.includes('token-refresh')) {
      refreshCounts = 0
    }

    return response
  },
  (error) => {
    const { config, response } = error
    const originalRequest = config

    // logging when expired token loops
    if (refreshCounts > 1) {
      Sentry.withScope(() => {
        Sentry.setExtra('response', response)

        Sentry.captureMessage(SENTRY_MESSAGE.ERROR_EXPIRED_TOKEN_QUERY_LOOP, Sentry.Severity.Info)
      })
    }

    if (
      (originalRequest.url.includes('token-refresh') && response && response.status === 401) ||
      // experiment with expired token loops
      refreshCounts > 1 ||
      (response &&
        response.status === 403 &&
        response.data &&
        response.data.detail &&
        response.data.detail === HAS_NOT_PROFILE)
    ) {
      jwt.clear()
      document.location.reload()
    }

    if (
      response &&
      response.status === 401 &&
      ((response.data && response.data.code && response.data.code === 'token_not_valid') ||
        (response.config && response.config.responseType === 'arraybuffer'))
    ) {
      if (!isAlreadyFetchingAccessToken) {
        isAlreadyFetchingAccessToken = true
        jwt
          .refreshToken()
          .then((resp: any) => {
            if (resp.status === 200) {
              refreshCounts++
              isAlreadyFetchingAccessToken = false
              onAccessTokenFetched(jwt.get().accessToken)

              return resp
            }

            return Promise.reject(resp)
          })
          .catch((refreshError: any) => {
            Sentry.configureScope((scope: Sentry.Scope) => {
              scope.setExtra('response', refreshError && refreshError.response)
            })

            Promise.reject(refreshError)
          })
      }

      const retryOriginalRequest = new Promise((resolve) => {
        addSubscriber(() => {
          originalRequest.headers.Authorization = 'Bearer ' + jwt.get().accessToken
          resolve(axios(originalRequest))
        })
      })

      return retryOriginalRequest
    }

    return Promise.reject(error)
  }
)

export default function customAxios(
  url: string,
  method: Method,
  data: any,
  needAuth = true,
  headers: any = {},
  responseType = ''
) {
  if (!headers['Content-type']) {
    headers['Content-type'] = 'application/json'
  }

  const tokens = jwt.get()

  if (needAuth) {
    headers.Authorization = `Bearer ${tokens.accessToken}`
  }

  const config: any = {
    data,
    headers,
    method,
    url,
  }

  if (responseType) {
    config.responseType = responseType
  }

  return axios(config)
}

export async function GET(
  url: string,
  params: Record<any, any> = {},
  config: AxiosRequestConfig = {}
): Promise<unknown> {
  const response = await customAxios(url, 'get', {
    params,
    paramsSerializer: qs.stringify,
    ...config,
  })

  return response.data
}

export const generateQueryString = (data: string[], paramsName?: string) => {
  if (data.length === 0) {
    return ''
  }
  let queryParamsString = ''
  if (data.length > 0) {
    if (queryParamsString !== '') {
      queryParamsString += '&'
    }

    queryParamsString += data.map((s) => `${paramsName ? paramsName + '=' : ''}${s}`).join('&')
  }
  return queryParamsString
}

interface ICatchErrorHandler {
  error: any
  intl: IntlShape
  setError: UseFormSetError<any>
  requestBody: any
  sentryMessage: string
}

export const catchErrorHandler = (props: ICatchErrorHandler) => {
  const { error, intl, setError, requestBody, sentryMessage } = props

  const resp = error.response
  let errorMessage = ''

  if (resp && resp.status === 403) {
    errorMessage = intl.formatMessage({ id: 'auth.form.requestErrorMessage.admin' })
  } else if ((resp && resp.status >= 500) || resp === undefined) {
    errorMessage = intl.formatMessage({ id: 'auth.form.requestErrorMessage.server' })
  } else {
    if (resp && resp.data) {
      Object.keys(resp.data).forEach((field: string) => {
        const key = field as any
        if (resp.data[field]) {
          setError(key, {
            type: FORM_ERROR_TYPE.MANUAL,
            message: resp.data[field].join(' '),
          })
        }
      })
    }

    if (resp && resp.status === 400) {
      const email = requestBody?.email.toLocaleLowerCase()

      email &&
        Sentry.withScope(() => {
          Sentry.configureScope((scope: Sentry.Scope) => [scope.setUser({ email })])
          Sentry.setExtra('response', resp)

          Sentry.captureMessage(`${sentryMessage}`, Sentry.Severity.Info)
        })
    }
  }

  Sentry.configureScope((scope: Sentry.Scope) => {
    scope.setExtra('response', error.response)
  })

  setError(FORM_ERROR_TYPE.REQUEST_ERROR, {
    type: FORM_ERROR_TYPE.MANUAL,
    message: errorMessage,
  })

  return Promise.reject(error)
}
