import React, { useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import Slider from 'react-slick'
import cn from 'classnames'

import first_card_animation from './media/animation-1.json'
import second_card_animation from './media/animation-2.json'
import third_card_animation from './media/animation-3.json'
import firstCardImage from './media/image-1.svg'
import secondCardImage from './media/image-2.png'
import thirdCardImage from './media/image-3.svg'
import useStyles from './styles'

import LottieRenderer from '@/components/LottieRenderer'

const cardsData = [
  {
    image: firstCardImage,
    animation: first_card_animation,
    animationClass: 'firstAnimation',
  },
  {
    image: secondCardImage,
    animation: second_card_animation,
    animationClass: 'secondAnimation',
  },
  {
    image: thirdCardImage,
    animation: third_card_animation,
    animationClass: 'thirdAnimation',
  },
]

const Carousel = () => {
  const classes = useStyles()

  const [currentSlide, setCurrentSlide] = useState(0)

  const sliderRef = useRef<Slider | null>(null)

  const changeSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext()
    }
  }

  const sliderSettings = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 700,
    initialSlide: 0,
    beforeChange: (current: number, next: number) => {
      setCurrentSlide(next)
    },
  }

  return (
    <div className={classes.wrapper}>
      <h3 className={classes.title}>
        <FormattedMessage id={`auth.title_${currentSlide}`} />
      </h3>
      <Slider ref={sliderRef} {...sliderSettings}>
        {cardsData.map((card, index) => (
          <div className={classes.card} key={index}>
            <div className={cn(classes.imageWrapper, { [classes.notActive]: currentSlide !== index })}>
              <img
                src={card.image}
                alt={`Card ${index + 1}`}
                className={cn({
                  [classes.firstCardImage]: index === 0,
                  [classes.secondCardImage]: index === 1,
                  [classes.thirdCardImage]: index === 2,
                })}
              />
              <div
                className={cn(classes.animationWrapper, {
                  [classes.shiftRight]: currentSlide === 1 && index === 2,
                  [classes.firstAnimation]: index === 0,
                  [classes.secondAnimation]: index === 1,
                  [classes.thirdAnimation]: index === 2,
                })}
              >
                <LottieRenderer
                  onComplete={changeSlide}
                  key={`${currentSlide}-${index}`}
                  play={currentSlide === index}
                  source={card.animation}
                  loop={false}
                />
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default Carousel
