import React, { useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { CountryData } from 'react-phone-input-2'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import debounce from 'lodash.debounce'

import { MIXPANEL_KEYS_INDIVIDUAL_MODAL, MIXPANEL_PARAMS_CLOSE_TYPE } from '../consts'
import { IModalProps } from '../types'
import selector from './selector'
import useStyles from './styles'
import { IIndividualDataErrorForm, INDIVIDUAL_DATA_ERROR_FORM_FIELDS } from './types'

import { onSendMixpanelEvent } from '@/analytics'
import Alert from '@/components/Alert'
import { Button, BUTTON_SIZE, BUTTON_VARIANT } from '@/components/ButtonSMB'
import { ErrorMessage } from '@/components/ErrorMessage'
import { Asterisk } from '@/components/Icons'
import { Countries, InputPhone } from '@/components/InputPhone'
import { Input, INPUT_SIZE, INPUT_VARIANT } from '@/components/InputSMB'
import { Modal } from '@/components/ModalSMB'
import { RedBase } from '@/const/colors'
import { PurpleBase } from '@/const/colors'
import { INN_REGEX, MAX_INN_LENGTH } from '@/const/consts'
import { FORM_ERROR_TYPE } from '@/const/form'
import { updateClientProfile } from '@/store/clients'
import { MyThunkDispatch } from '@/store/store'
import { updateProfile } from '@/store/user'
import { IUpdateProfileData } from '@/store/user/types'
import { CLOSE_MODAL_STATUS } from '@/types'
import { isValidINN, REGULAR_EXPRESSION_CYRILLIC_NAME } from '@/utils/form'

const AdsMarkingDataErrorIndividualModal = (props: IModalProps) => {
  const { onClose, data } = props

  const { formatMessage } = useIntl()
  const classes = useStyles()
  const dispatch: MyThunkDispatch = useDispatch()
  const { name, inn, personalPhone, clientProfile } = useSelector(selector)
  const [isLoading, setIsLoading] = useState(false)

  const clientId = data?.clientId
  const callBack = data?.callBack

  const closeHandler = useCallback(
    (status: CLOSE_MODAL_STATUS) => {
      if (data?.onCloseModal) {
        data.onCloseModal(status)
      }

      if (status === CLOSE_MODAL_STATUS.SUCCESS) {
        onSendMixpanelEvent(MIXPANEL_KEYS_INDIVIDUAL_MODAL.AD_MARK_SE_OR_FIZ_POPUP_TO_CHANGE_DATA_SAVE)
      }

      onClose(status)
    },
    [onClose]
  )

  const {
    handleSubmit,
    control,
    trigger,
    setError,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm<IIndividualDataErrorForm>({
    shouldFocusError: false,
    defaultValues: {
      [INDIVIDUAL_DATA_ERROR_FORM_FIELDS.NAME]: clientId ? clientProfile?.name : name || '',
      [INDIVIDUAL_DATA_ERROR_FORM_FIELDS.INN]: clientId ? clientProfile?.inn : inn || '',
      [INDIVIDUAL_DATA_ERROR_FORM_FIELDS.PERSONAL_PHONE]: clientId
        ? clientProfile?.personal_phone
        : personalPhone || '',
      [INDIVIDUAL_DATA_ERROR_FORM_FIELDS.REQUEST_ERROR]: '',
    },
  })

  const hasErrors = Object.values(errors).length > 0

  const isPhoneNumberValid = (phone: string, country: CountryData) => {
    if (errors.request_error) {
      clearErrors(INDIVIDUAL_DATA_ERROR_FORM_FIELDS.REQUEST_ERROR)
    }
    const minPhoneNumberLength = country.format.replace(/[^.]/g, '').length
    const dialCode = country.dialCode

    return !!phone && !!dialCode && phone.length >= minPhoneNumberLength && phone.startsWith(dialCode)
  }

  const onPhoneInputChange = (value: string, country: CountryData): void => {
    if (!isPhoneNumberValid(value, country)) {
      setError(INDIVIDUAL_DATA_ERROR_FORM_FIELDS.PERSONAL_PHONE, {
        type: FORM_ERROR_TYPE.MANUAL,
        message: formatMessage({ id: 'personalPhone.errorMessage.length' }),
      })
    } else {
      clearErrors(INDIVIDUAL_DATA_ERROR_FORM_FIELDS.PERSONAL_PHONE)
      setValue(INDIVIDUAL_DATA_ERROR_FORM_FIELDS.PERSONAL_PHONE, value)
    }
  }

  const debouncePhoneChangeHandler = debounce(onPhoneInputChange, 100)

  const handleInnChange = (value: string, onChange: (value: string) => void) => {
    if (value.length > MAX_INN_LENGTH) {
      return
    }
    if (!new RegExp(INN_REGEX).test(value)) {
      setError(INDIVIDUAL_DATA_ERROR_FORM_FIELDS.INN, {
        type: FORM_ERROR_TYPE.MANUAL,
        message: formatMessage({ id: 'budget.adsMarkingDataErrorModal.innLength.errorsMessage' }),
      })
    } else if (!isValidINN(value)) {
      setError(INDIVIDUAL_DATA_ERROR_FORM_FIELDS.INN, {
        type: FORM_ERROR_TYPE.MANUAL,
        message: formatMessage({ id: 'budget.adsMarkingDataErrorModal.innNotValid.errorsMessage' }),
      })
    } else {
      clearErrors(INDIVIDUAL_DATA_ERROR_FORM_FIELDS.INN)
    }
    onChange(value)
  }

  const handleNameChange = (value: string, onChange: (value: string) => void) => {
    if (!REGULAR_EXPRESSION_CYRILLIC_NAME.test(value)) {
      setError(INDIVIDUAL_DATA_ERROR_FORM_FIELDS.NAME, {
        type: FORM_ERROR_TYPE.MANUAL,
        message: formatMessage({ id: 'budget.adsMarkingDataErrorModal.name.errorsMessage' }),
      })
    } else {
      clearErrors(INDIVIDUAL_DATA_ERROR_FORM_FIELDS.NAME)
    }
    onChange(value)
  }

  useEffect(() => {
    const validateAndSubmit = async () => {
      const isValid = await trigger()
      if (isValid) {
        handleSubmit(onSubmit)()
      }
    }

    validateAndSubmit()
  }, [trigger, handleSubmit])

  const closeModalHandler = (status: CLOSE_MODAL_STATUS) => () => {
    onSendMixpanelEvent(MIXPANEL_KEYS_INDIVIDUAL_MODAL.AD_MARK_SE_POPUP_TO_CHANGE_DATA_CANCEL, {
      type: status === CLOSE_MODAL_STATUS.CROSS ? MIXPANEL_PARAMS_CLOSE_TYPE.CROSS : MIXPANEL_PARAMS_CLOSE_TYPE.CANCEL,
    })
    return closeHandler(status)
  }

  const onSubmit = async (data: IIndividualDataErrorForm) => {
    setIsLoading(true)

    const profileRequestBody: IUpdateProfileData = {
      [INDIVIDUAL_DATA_ERROR_FORM_FIELDS.NAME]: data.name,
      [INDIVIDUAL_DATA_ERROR_FORM_FIELDS.INN]: data.inn,
      [INDIVIDUAL_DATA_ERROR_FORM_FIELDS.PERSONAL_PHONE]: data.personal_phone.startsWith('+')
        ? data.personal_phone
        : `+${data.personal_phone}`,
    }

    try {
      clientId
        ? await updateClientProfile(Number(clientId), profileRequestBody)
        : await dispatch(updateProfile(profileRequestBody))

      if (callBack) {
        const result = callBack()

        if (result instanceof Promise) {
          await result
        }
      }

      closeHandler(CLOSE_MODAL_STATUS.SUCCESS)
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        const resp = error.response
        if (resp?.data) {
          Object.keys(resp.data).forEach((field) => {
            const key = field as INDIVIDUAL_DATA_ERROR_FORM_FIELDS
            if (resp.data[field]) {
              setError(key, {
                type: FORM_ERROR_TYPE.MANUAL,
                message: resp.data[field].join(' '),
              })
            }
          })
        }
      }
      return
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      isOpen
      closeCallback={closeModalHandler(CLOSE_MODAL_STATUS.CROSS)}
      bodyContent={
        <div className={classes.root}>
          <h4 className={classes.title}>
            <FormattedMessage id="budget.adsMarkingDataErrorModal.title" />
          </h4>
          <Alert text={formatMessage({ id: 'budget.adsMarkingDataErrorModal.alertDesription' })} />
          <form className={classes.form}>
            <section>
              <label className={classes.label}>
                <FormattedMessage id="common.fio" />
                <Asterisk style={{ width: 16, height: 16 }} fill={PurpleBase} />
              </label>
              <Controller
                name={INDIVIDUAL_DATA_ERROR_FORM_FIELDS.NAME}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: formatMessage({ id: 'form.errorMessage.required' }),
                  },
                  pattern: {
                    value: REGULAR_EXPRESSION_CYRILLIC_NAME,
                    message: formatMessage({ id: 'budget.adsMarkingDataErrorModal.name.errorsMessage' }),
                  },
                }}
                render={({ field: { ref, onChange }, field, fieldState }) => (
                  <Input
                    {...field}
                    placeholder={formatMessage({ id: 'budget.adsMarkingDataErrorModal.name.placeholder' })}
                    inputRef={ref}
                    id={INDIVIDUAL_DATA_ERROR_FORM_FIELDS.NAME}
                    value={field.value}
                    errorMsg={fieldState.error?.message}
                    variant={INPUT_VARIANT.default}
                    size={INPUT_SIZE.big}
                    fullWidth
                    error={Boolean(errors[INDIVIDUAL_DATA_ERROR_FORM_FIELDS.NAME])}
                    disabled={isLoading}
                    onChange={(e) => handleNameChange(e.target.value, onChange)}
                  />
                )}
              />
            </section>
            <div className={classes.sectionWrapper}>
              <section>
                <div className={classes.label}>
                  <FormattedMessage id="budget.adsMarkingDataErrorModal.inn.label" />{' '}
                  <Asterisk style={{ width: 16, height: 16 }} fill={PurpleBase} />
                </div>
                <Controller
                  name={INDIVIDUAL_DATA_ERROR_FORM_FIELDS.INN}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: formatMessage({ id: 'form.errorMessage.required' }),
                    },
                    pattern: {
                      value: new RegExp(INN_REGEX),
                      message: formatMessage({ id: 'budget.adsMarkingDataErrorModal.innLength.errorsMessage' }),
                    },
                    validate: {
                      isValidINN: (value) =>
                        isValidINN(value) ||
                        formatMessage({ id: 'budget.adsMarkingDataErrorModal.innNotValid.errorsMessage' }),
                    },
                  }}
                  render={({ field: { ref, onChange }, field, fieldState }) => (
                    <Input
                      {...field}
                      placeholder={formatMessage({ id: 'budget.adsMarkingDataErrorModal.inn.placeholder' })}
                      inputRef={ref}
                      id={INDIVIDUAL_DATA_ERROR_FORM_FIELDS.INN}
                      value={field.value}
                      size={INPUT_SIZE.big}
                      errorMsg={fieldState.error?.message}
                      variant={INPUT_VARIANT.default}
                      fullWidth
                      error={Boolean(errors[INDIVIDUAL_DATA_ERROR_FORM_FIELDS.INN])}
                      disabled={isLoading}
                      onChange={(e) => handleInnChange(e.target.value, onChange)}
                    />
                  )}
                />
              </section>
              <section>
                <label htmlFor={INDIVIDUAL_DATA_ERROR_FORM_FIELDS.PERSONAL_PHONE} className={classes.label}>
                  <FormattedMessage id="personalPhone.phoneLabel" />{' '}
                  <Asterisk style={{ width: 16, height: 16 }} fill={PurpleBase} />
                </label>
                <Controller
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: formatMessage({ id: 'personalPhone.errorMessage.required' }),
                    },
                  }}
                  name={INDIVIDUAL_DATA_ERROR_FORM_FIELDS.PERSONAL_PHONE}
                  render={({ field }) => (
                    <InputPhone
                      onChange={debouncePhoneChangeHandler}
                      onBlur={field.onBlur}
                      value={field.value}
                      defaultCountry={Countries.RUSSIA}
                      disabled={isLoading}
                      inputStyle={{
                        borderColor: errors[INDIVIDUAL_DATA_ERROR_FORM_FIELDS.PERSONAL_PHONE]?.message ? RedBase : '',
                      }}
                    />
                  )}
                />
                {Boolean(errors[INDIVIDUAL_DATA_ERROR_FORM_FIELDS.PERSONAL_PHONE]?.message) && (
                  <ErrorMessage>{errors[INDIVIDUAL_DATA_ERROR_FORM_FIELDS.PERSONAL_PHONE]?.message}</ErrorMessage>
                )}
              </section>
            </div>
          </form>
        </div>
      }
      footerContent={
        <div className={classes.footer}>
          <Button
            label={formatMessage({ id: 'adsMarking.adsMarkingDataErrorModal.cancel' })}
            size={BUTTON_SIZE.size40}
            variant={BUTTON_VARIANT.secondaryGray}
            disabled={isLoading}
            onClick={closeModalHandler(CLOSE_MODAL_STATUS.CANCEL)}
          />
          <Button
            size={BUTTON_SIZE.size40}
            variant={BUTTON_VARIANT.primary}
            label={formatMessage({ id: 'common.save' })}
            disabled={isLoading || hasErrors}
            isLoading={isLoading}
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      }
      maxWidth="684px"
    />
  )
}

export default AdsMarkingDataErrorIndividualModal
