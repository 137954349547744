import React from 'react'

import { IIcons } from './types'

export default (props: IIcons) => {
  const { style } = props
  const defaultStyle = { width: 20, height: 20 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" fill="#A8D87B" stroke="white" />
      <g clipPath="url(#clip0)">
        <path
          d="M14.6802 6.14249C14.3289 5.90493 13.8342 5.97329 13.5752 6.2963L9.38903 11.5046L8.93063 12.0746L8.28403 11.6584L6.26223 10.2912C5.91097 10.0536 5.41624 10.122 5.15722 10.445C4.89821 10.7671 4.97275 11.2209 5.32493 11.4584L9.00424 13.9459C9.1412 14.0391 9.33499 14.0117 9.43562 13.8861L14.8488 7.15595C15.1069 6.83379 15.0314 6.38004 14.6802 6.14249Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="10" height="8" fill="white" transform="translate(5 6)" />
        </clipPath>
      </defs>
    </svg>
  )
}
