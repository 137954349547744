import { CONTRACTOR_TYPE, COUNTRY_SELECT, NetworkAccountsTypes } from '../types'
import { IContractsCardProps } from './components/Cards/types'
import { MODALS } from './Modals/types'

import { AGENCY_STATUS } from '@/types/types'

export interface IModal {
  name: MODALS
  data?: any
}
interface IContractListType {
  count: number
  current: number
  next: number | null
  page_count: number
  previous: number | null
  loading: boolean
  results: IContractsCardProps[] | []
}
interface IContractsSelectedList {
  results: IContractsCardProps[] | []
  loading: boolean
}

export interface IContractorsItemType {
  contractor_legal_status: AGENCY_STATUS
  contractor_origin?: COUNTRY_SELECT
  contractor_type: CONTRACTOR_TYPE
  id: string
  is_you: boolean
  other_country: string
  other_payment_number: string
  other_registration_number: string
  phone_number: string
  ru_ceo: string
  ru_kpp: string
  ru_legal_address: string
  ru_name: string
  tax_number: string
  ad_platforms: NetworkAccountsTypes[]
}

export interface IContractorsSelectedList {
  results: IContractorsItemType[] | []
  loading: boolean
}

export interface IContractsState {
  modals: IModal | null
  contractList: IContractListType
  constractsSelectedList: IContractsSelectedList
  contractorsSelectedList: IContractorsSelectedList
  hasContracts: boolean
  hasFilters: boolean
}

export enum ContractsActionTypes {
  SET_CONTRACTS = 'ADS_MARKING_SET_CONTRACTS',
  UPDATE_CONTRACTS = 'ADS_MARKING_UPDATE_CONTRACTS',
  SET_CONTRACTS_LOADING = 'ADS_MARKING_SET_CONTRACTS_LOADING',
  CLEAR_CONTRACTS = 'ADS_MARKING_CLEAR_CONTRACTS',
}

export enum ContractsFormsActionTypes {
  SET_CONTRACTS_SELECTED = 'ADS_MARKING_SET_CONTRACTS_SELECTED',
  SET_CONTRACTS_SELECTED_LOADING = 'ADS_MARKING_SET_CONTRACTS_SELECTED_LOADING',
}

export enum ContractorsFormsActionTypes {
  SET_CONTRACTORS_SELECTED = 'ADS_MARKING_SET_CONTRACTORS_SELECTED',
  SET_CONTRACTORS_SELECTED_LOADING = 'ADS_MARKING_SET_CONTRACTORS_SELECTED_LOADING',
}
