import React from 'react'

import { PurpleBase } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = PurpleBase, fillBg = 'none' } = props
  const defaultStyle = { width: 20, height: 20 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 20 20" fill={fillBg} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.72656 5.90927L2.47252 5.2036L1.97656 5.38215V5.90927H2.72656ZM12.7515 2.3003L12.4974 1.59463L12.7515 2.3003ZM15.272 16.0684H3.22656V17.5684H15.272V16.0684ZM3.47656 16.3184V5.90927H1.97656V16.3184H3.47656ZM16.522 7.90927V10.0002H18.022V7.90927H16.522ZM17.272 9.25018H15.6811V10.7502H17.272V9.25018ZM16.522 10.0002V13.182H18.022V10.0002H16.522ZM16.522 13.182V14.8184H18.022V13.182H16.522ZM15.6811 13.932H17.272V12.432H15.6811V13.932ZM2.9806 6.61493L13.0055 3.00596L12.4974 1.59463L2.47252 5.2036L2.9806 6.61493ZM2.72656 6.65927H14.0902V5.15927H2.72656V6.65927ZM14.0902 6.65927H15.272V5.15927H14.0902V6.65927ZM13.3402 3.24119V5.90927H14.8402V3.24119H13.3402ZM13.0055 3.00596C13.1684 2.94731 13.3402 3.06804 13.3402 3.24119H14.8402C14.8402 2.02913 13.6378 1.18409 12.4974 1.59463L13.0055 3.00596ZM15.6811 9.25018C14.3883 9.25018 13.3402 10.2982 13.3402 11.5911H14.8402C14.8402 11.1267 15.2167 10.7502 15.6811 10.7502V9.25018ZM3.22656 16.0684C3.36463 16.0684 3.47656 16.1803 3.47656 16.3184H1.97656C1.97656 17.0087 2.53621 17.5684 3.22656 17.5684V16.0684ZM15.272 17.5684C16.7908 17.5684 18.022 16.3371 18.022 14.8184H16.522C16.522 15.5087 15.9624 16.0684 15.272 16.0684V17.5684ZM13.3402 11.5911C13.3402 12.8839 14.3883 13.932 15.6811 13.932V12.432C15.2167 12.432 14.8402 12.0555 14.8402 11.5911H13.3402ZM18.022 7.90927C18.022 6.39049 16.7908 5.15927 15.272 5.15927V6.65927C15.9624 6.65927 16.522 7.21891 16.522 7.90927H18.022Z"
        fill={fill}
      />
    </svg>
  )
}
