import { createUseStyles } from 'react-jss'

import bgFirst from './media/bg-1.png'
import bgSecond from './media/bg-2.png'
import bgThird from './media/bg-3.png'

import { greyClr } from '@/const/colors'
import { asideWidth, WidthBPs } from '@/const/commonStyles'

export default createUseStyles(
  {
    wrapper: {
      position: 'relative',

      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        background: `url(${bgFirst}) no-repeat left / contain`,
        top: 0,
        left: 0,
        width: 300,
        height: 300,
        zIndex: 1,
      },
    },
    asideBlock: {
      position: 'relative',
      paddingTop: 50,
      paddingBottom: 20,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: asideWidth,
      height: '100%',
      backgroundColor: '#25134D',
      backgroundPosition: 'center',
      backgroundSize: '240px auto',
      backgroundRepeat: 'no-repeat',
      textAlign: 'center',
      color: greyClr,

      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        background: `url(${bgSecond}) no-repeat center / contain`,
        top: '10%',
        right: '-80%',
        width: 400,
        height: 400,
      },

      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        background: `url(${bgThird}) no-repeat bottom / contain`,
        bottom: 0,
        right: '-150px',
        width: 300,
        height: 300,
      },

      [`@media (min-width: ${WidthBPs.LARGE})`]: {
        backgroundSize: '290px auto',
        paddingBottom: 80,
      },
    },
    carousel: {
      width: 384,
      position: 'relative',
    },
  },
  { name: 'Aside' }
)
