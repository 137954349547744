import React from 'react'

import { TextGreyLighten39 } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = TextGreyLighten39 } = props
  const defaultStyle = { width: 5, height: 9 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 5 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.77988 5.99805C1.78639 5.61068 1.83034 5.30469 1.91172 5.08008C1.9931 4.85547 2.15911 4.60645 2.40977 4.33301L3.04941 3.67383C3.32285 3.36458 3.45957 3.03255 3.45957 2.67773C3.45957 2.33594 3.37005 2.06901 3.19102 1.87695C3.01198 1.68164 2.75156 1.58398 2.40977 1.58398C2.07773 1.58398 1.81081 1.67188 1.60898 1.84766C1.40716 2.02344 1.30625 2.25944 1.30625 2.55566H0.40293C0.40944 2.02832 0.596615 1.60352 0.964453 1.28125C1.33555 0.955729 1.81732 0.792969 2.40977 0.792969C3.025 0.792969 3.50352 0.958984 3.84531 1.29102C4.19036 1.61979 4.36289 2.07227 4.36289 2.64844C4.36289 3.2181 4.09922 3.77962 3.57188 4.33301L3.03965 4.86035C2.80202 5.12402 2.6832 5.50326 2.6832 5.99805H1.77988ZM1.74082 7.5459C1.74082 7.39941 1.78477 7.27734 1.87266 7.17969C1.9638 7.07878 2.09727 7.02832 2.27305 7.02832C2.44883 7.02832 2.58229 7.07878 2.67344 7.17969C2.76458 7.27734 2.81016 7.39941 2.81016 7.5459C2.81016 7.69238 2.76458 7.81445 2.67344 7.91211C2.58229 8.00651 2.44883 8.05371 2.27305 8.05371C2.09727 8.05371 1.9638 8.00651 1.87266 7.91211C1.78477 7.81445 1.74082 7.69238 1.74082 7.5459Z"
        fill={fill}
      />
    </svg>
  )
}
