import { AxiosError, AxiosResponse } from 'axios'
import { AnyAction, Dispatch } from 'redux'

import { API_URL } from '../../const'
import { customAxios } from '../../utils'
import { ThunkResult } from '../store'
import { DocumentsActionTypes, IDocument, IUploadDocument } from './types'

export function getDocuments(): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/documents/`, 'GET', null, true)
      .then((response: AxiosResponse<IDocument[]>) => {
        dispatch({ type: DocumentsActionTypes.SET_DOCUMENTS, data: response.data })
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function removeDocument(id: number): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/documents/${id}/`, 'DELETE', null, true)
      .then((resp: AxiosResponse) => {
        if (resp.status === 204) {
          dispatch({ type: DocumentsActionTypes.REMOVE_DOCUMENT, data: id })
        }
        return resp
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function setDocument(data: IUploadDocument): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    const formData = new FormData()
    formData.append('file', data.file)
    formData.append('document_type', data.document_type)

    return customAxios(`${API_URL}/documents/`, 'POST', formData, true)
      .then((response: AxiosResponse<IDocument>) => {
        dispatch({ type: DocumentsActionTypes.SET_DOCUMENT, data: response.data })
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}
