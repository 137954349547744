import { Reducer } from 'redux'

import { reducerName } from './modals/actions'
import { AddContractTypes, IAddContractState } from './types'

export const initialState: IAddContractState = {
  modals: null,
  contract_id: '',
  contract_details: null,
  contract_details_id: null,
  executor: null,
  executer_id: null,
  advertiser: null,
  advertiser_id: null,
  myAggency: null,
  accounts: [],
  accountsAttached: 0,
  loading: false,
  createLoading: false,
  newMarkingDetails: {
    contract_details_id: null,
    advertiser_id: null,
    executer_id: null,
  },
}

const AdsMarkingAddContractReducer: Reducer<IAddContractState> = (state = initialState, action: any) => {
  switch (action.type) {
    case AddContractTypes.SET_CONTRACT_DETAILS: {
      return { ...state, contract_details: action.data }
    }
    case AddContractTypes.SET_CONTRACT_DETAILS_ID: {
      return {
        ...state,
        contract_details_id: action.id,
      }
    }
    case AddContractTypes.SET_CONTRACT_EXECUTOR: {
      return { ...state, executor: action.data }
    }
    case AddContractTypes.SET_CONTRACT_EXECUTOR_ID: {
      return {
        ...state,
        executer_id: action.id,
      }
    }
    case AddContractTypes.SET_CONTRACT_ADVERTISER: {
      return { ...state, advertiser: action.data }
    }
    case AddContractTypes.SET_CONTRACT_ADVERTISER_ID: {
      return {
        ...state,
        advertiser_id: action.id,
      }
    }
    case AddContractTypes.SET_MY_AGGENCY: {
      return { ...state, myAggency: action.data }
    }
    case AddContractTypes.SET_CONTRACT_ACCOUNTS: {
      if (!action.data) {
        return { ...state }
      }

      const accounts = new Set([...state.accounts, ...action.data])

      return {
        ...state,
        // @ts-ignore
        accounts: [...accounts],
      }
    }
    case AddContractTypes.SET_ACCOUNTS_ATTACHED: {
      return {
        ...state,
        accountsAttached: action.data,
      }
    }
    case AddContractTypes.SET_CONTRACT_ID: {
      return {
        ...state,
        contract_id: action.data,
      }
    }
    case AddContractTypes.CLEAR_CONTRACT: {
      return {
        ...state,
        contract_id: '',
        contract_details: null,
        contract_details_id: null,
        executor: null,
        executer_id: null,
        advertiser: null,
        advertiser_id: null,
        accounts: [],
      }
    }

    case AddContractTypes.SET_CREATE_LOADING: {
      return { ...state, createLoading: action.loading }
    }
    case AddContractTypes.SET_LOADING: {
      return { ...state, loading: action.loading }
    }
    case AddContractTypes.SET_NEW_MARKING_DETAILS: {
      return { ...state, newMarkingDetails: { ...state.newMarkingDetails, ...action.data } }
    }
    case `${reducerName}.OPEN_MODAL`: {
      return {
        ...state,
        modals: action.payload,
      }
    }
    case `${reducerName}.CLOSE_MODAL`: {
      return {
        ...state,
        modals: null,
      }
    }
    default: {
      return state
    }
  }
}

export default AdsMarkingAddContractReducer
