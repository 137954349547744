import { CONTRACTOR_TYPE, COUNTRY_SELECT } from '../types'
import { MODALS } from './modals/types'

import { AGENCY_STATUS } from '@/types/types'

export interface IModal {
  name: MODALS
  data?: any
}

export enum EXECUTOR_TYPE {
  MY_AGENCY = 'my_agency',
  OTHET_AGENCY = 'other_agency',
}

export enum CONTRACT_TYPE {
  PROVISION_SERVICES = 'provision_of_services',
  MEDIATION = 'mediation',
  ADDITIONAL_AGREEMENT = 'additional_agreement',
}

export enum CONTRACT_SUBJECT_TYPE {
  REPRESENTATION = 'representation',
  MEDIATION = 'mediation',
  ORG_DISTRIBUTION = 'org_distribution',
  DISTRIBUTION = 'distribution',
  OTHER = 'other',
}

export enum CONTRACT_ACTION_TYPE {
  DISTRIBUTION = 'distribution',
  CONCLUDE = 'conclude',
  COMMERCIAL = 'commercial',
  OTHER = 'other',
}

export interface IContractDetails {
  contract_type: CONTRACT_TYPE | ''
  contract_date: string
  contract_number: string
  contract_amount: string
  tax_included: boolean
  contract_subject_type: CONTRACT_SUBJECT_TYPE | ''
  contract_action_type: CONTRACT_ACTION_TYPE | ''
  id?: string
}

export interface IContractExecutor {
  contractor_origin?: COUNTRY_SELECT
  contractor_legal_status?: string
  phone_number?: string
  ru_name?: string
  ru_inn?: string
  ru_kpp?: string
  ru_ceo?: string
  ru_legal_address?: string
  other_country?: string
  other_registration_number?: string
  other_payment_number?: string
  tax_number?: string
  contractor_type: CONTRACTOR_TYPE
  id?: string
  is_you?: boolean
  ord_errors: string[] | []
}
export interface IContractAdvertiser {
  contractor_type: CONTRACTOR_TYPE
  contractor_origin: COUNTRY_SELECT
  contractor_legal_status: AGENCY_STATUS
  phone_number?: string
  ru_name?: string
  ru_inn?: string
  ru_kpp?: string
  ru_ceo?: string
  ru_legal_address?: string
  other_country?: string
  other_registration_number?: string
  other_payment_number?: string
  tax_number?: string
  id?: string
  ord_errors: string[] | []
}

export interface IContractDetailsRequest {
  contract_type: CONTRACT_TYPE | ''
  contract_date: string
  contract_number: string
  contract_amount: string
  tax_included: boolean
  id?: string
}

export interface INewMarkingDetailsIds {
  contract_details_id: null | string
  advertiser_id: null | string
  executer_id: null | string
}

export interface IAddContractState {
  modals: IModal | null
  contract_id?: string
  contract_details: IContractDetails | null
  contract_details_id: string | null
  executor: IContractExecutor | null
  executer_id: string | null
  myAggency: null | IContractExecutor
  advertiser: IContractAdvertiser | null
  advertiser_id: string | null
  newMarkingDetails: INewMarkingDetailsIds
  accounts: string[] | []
  accountsAttached: number
  loading: boolean
  createLoading: boolean
}

export interface IOkved {
  code: string
  name: string
}

export interface ISuggestOkveds {
  data: IOkved[]
}

export interface ICreateContractRequest {
  executor_id?: string | null
  contract_details_id?: string | null
  advertiser_id?: string | null
  accounts?: string[]
}

export const MixpanelCreateButtonType = {
  [MODALS.DETAILS]: 'MarkingAccountAddcontractRequisitesClick',
  [MODALS.ADVERTISER]: 'MarkingAccountAddcontractAdvertiserClick',
  [MODALS.EXECUTOR]: 'MarkingAccountAddcontractContractorClick',
  [MODALS.SAVE]: 'MarkingAccountAddcontractContractorClick',
  [MODALS.WARNING]: 'MarkingAccountAddcontractContractorClick',
}

export enum AddContractTypes {
  SET_CONTRACT_DETAILS = 'ADS_MARKING_SET_CONTRACT_DETAILS',
  SET_CONTRACT_DETAILS_ID = 'ADS_MARKING_SET_CONTRACT_DETAILS_ID',
  SET_CONTRACT_EXECUTOR = 'ADS_MARKING_SET_CONTRACT_EXECUTOR',
  SET_CONTRACT_EXECUTOR_ID = 'ADS_MARKING_SET_CONTRACT_EXECUTOR_ID',
  SET_CONTRACT_ADVERTISER = 'ADS_MARKING_SET_CONTRACT_ADVERTISER',
  SET_CONTRACT_ADVERTISER_ID = 'ADS_MARKING_SET_CONTRACT_ADVERTISER_ID',
  SET_MY_AGGENCY = 'ADS_MARKING_SET_MY_AGGENCY',
  SET_CONTRACT_ACCOUNTS = 'ADS_MARKING_SET_CONTRACT_ACCOUNTS',
  SET_ACCOUNTS_ATTACHED = 'ADS_MARKING_SET_ACCOUNTS_ATTACHED',
  SET_CONTRACT_ID = 'ADS_MARKING_SET_CONTRACT_ID',
  CLEAR_CONTRACT = 'ADS_MARKING_CLEAR_CONTRACT',
  SET_LOADING = 'ADS_MARKING_CONTRACT_LOADING',
  SET_CREATE_LOADING = 'ADS_MARKING_SET_CREATE_LOADING',
  SET_NEW_MARKING_DETAILS = 'ADS_MARKING_SET_NEW_MARKING_DETAILS',
}
