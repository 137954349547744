import React from 'react'

import { OrangeBase } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = OrangeBase } = props
  const defaultStyle = { width: 16, height: 16 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 7.5293V9.17636V11.7646" stroke={fill} strokeWidth="1.3" strokeLinecap="round" />
      <path d="M8 4.23535V5.17653" stroke={fill} strokeWidth="1.3" strokeLinecap="round" />
      <circle cx="8" cy="8" r="7.35" stroke={fill} strokeWidth="1.3" />
    </svg>
  )
}
