export const enum MIXPANEL_KEYS_LEGAL_MODAL {
  AD_MARK_I_POR_OOO_POPUP_TO_CHANGE_DATA_CANCEL = 'AdMarkIPorOOOPopupToChangeDataCancel',
  AD_MARK_I_POR_OOO_POPUP_TO_CHANGE_DATA_SAVE = 'AdMarkIPorOOOPopupToChangeDataSave',
}

export const enum MIXPANEL_KEYS_INDIVIDUAL_MODAL {
  AD_MARK_SE_OR_FIZ_POPUP_TO_CHANGE_DATA_SAVE = 'AdMarkSEorFIZPopupToChangeDataSave',
  AD_MARK_SE_POPUP_TO_CHANGE_DATA_CANCEL = 'AdMarkSEPopupToChangeDataCancel',
}

export const enum MIXPANEL_PARAMS_CLOSE_TYPE {
  CANCEL = 'cancel',
  CROSS = 'x',
}
