import { Reducer } from 'redux'

import { AccountingActionTypes, IAccountingState } from './types'

export const initialState: IAccountingState = {
  acts: {
    count: 0,
    current: 0,
    next: null,
    page_count: 0,
    previous: null,
    results: [],
  },
  documents: {
    count: 0,
    current: 0,
    next: null,
    page_count: 0,
    previous: null,
    results: [],
  },
}

const reducer: Reducer<IAccountingState> = (state = initialState, action: any) => {
  switch (action.type) {
    case AccountingActionTypes.SET_ACCOUNTING: {
      return {
        acts: state.acts,
        documents: { ...action.data },
      }
    }
    case AccountingActionTypes.SET_ACTS: {
      return {
        acts: { ...action.data },
        documents: state.documents,
      }
    }
    default: {
      return state
    }
  }
}

export { reducer as accountingReducer }
