import { createUseStyles } from 'react-jss'

import {
  getColorLighten,
  RedBase,
  SlateGreyLighten34,
  SlateGreyLighten43,
  TextGreyBase,
  White,
} from '../../const/colors'
import { FontBody1 } from '../../const/fontStyles'
import { CHECKBOX_POSITION, CHECKBOX_SIZE } from './types'

export default createUseStyles(
  {
    root: {
      display: 'inline-flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      cursor: 'pointer',
      userSelect: 'none',
    },
    tooltipHover: {
      '&:hover $tooltip': {
        visibility: 'visible',
      },
    },

    input: (props: { colorBase: string }) => ({
      margin: 0,
      visibility: 'hidden',
      opacity: 0,
      position: 'absolute',
      zIndex: -1,

      '&:checked + $checkbox': {
        backgroundColor: getColorLighten(props.colorBase, 6),
        borderColor: getColorLighten(props.colorBase, 6),

        '& $checkedIcon': {
          display: 'flex',
        },

        '&:hover': {
          borderColor: props.colorBase,
          backgroundColor: props.colorBase,
        },
      },
    }),
    inputWrapper: {
      display: 'flex',
    },
    tooltip: {
      marginRight: 11,
      visibility: 'hidden',
    },
    [CHECKBOX_SIZE.size18]: {
      '& $checkbox': {
        width: 18,
        height: 18,

        '& svg': {
          width: 18,
          height: 18,
        },
      },
    },
    [CHECKBOX_SIZE.size15]: {
      '& $checkbox': {
        width: 15,
        height: 15,

        '& svg': {
          width: 15,
          height: 15,
        },
      },
    },
    checkbox: (props: { colorBase: string; hasError: boolean }) => ({
      flexShrink: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 18,
      height: 18,
      borderRadius: 4,
      border: `1px solid ${props.hasError ? RedBase : SlateGreyLighten34}`,
      boxSizing: 'border-box',

      '&:hover': {
        borderColor: props.colorBase,
      },
    }),
    [CHECKBOX_POSITION.left]: {
      flexDirection: 'row-reverse',

      '& > div:last-child': {
        marginRight: 13,
      },
    },
    [CHECKBOX_POSITION.right]: {
      flexDirection: 'row',

      '& > div:last-child': {
        marginLeft: 13,
      },
    },
    checked: (props: { colorBase: string }) => ({
      backgroundColor: getColorLighten(props.colorBase, 6),
      borderColor: getColorLighten(props.colorBase, 6),

      '&:hover': {
        backgroundColor: props.colorBase,
      },

      '& $checkedIcon': {
        display: 'flex',
      },
    }),
    checkedIcon: {
      display: 'none',
      alignItems: 'center',
      justifyContent: 'center',

      '& path': {
        stroke: White,
      },
    },
    disabledRoot: (props: { colorBase: string }) => ({
      cursor: 'default',

      '& $checkbox': {
        borderColor: SlateGreyLighten43,
      },

      '& $checkbox$checked': {
        borderColor: getColorLighten(props.colorBase, 12),
        backgroundColor: getColorLighten(props.colorBase, 12),
      },

      '&:hover': {
        borderColor: SlateGreyLighten43,

        '& $checkbox$checked': {
          borderColor: getColorLighten(props.colorBase, 12),
          backgroundColor: getColorLighten(props.colorBase, 12),
        },
      },
    }),
    description: (props: { labelWidth?: number; fullWidth?: boolean; colorBase: string; hasError: boolean }) => ({
      wordBreak: 'break-word',
      ...FontBody1,
      textAlign: 'left',
      color: TextGreyBase,
      width: props.fullWidth ? '100%' : props.labelWidth ?? 'initial',
    }),
    fullWidth: {
      display: 'flex',
      width: '100%',
    },
  },
  { name: 'CheckboxSMB' }
)
