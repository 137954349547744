import React from 'react'

import { RedBase } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = RedBase, fillBg = RedBase } = props
  const defaultStyle = { width: 16, height: 16 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 8.4707V6.82364V4.23541" stroke={fill} strokeWidth="1.3" strokeLinecap="round" />
      <path d="M8 11.7646V10.8235" stroke={fill} strokeWidth="1.3" strokeLinecap="round" />
      <circle cx="8" cy="8" r="7.35" stroke={fillBg} strokeWidth="1.3" />
    </svg>
  )
}
