export type Types = 'GG' | 'FB'

export interface IFeedDataReq {
  types: Types[]
  name: string
  website: string
  source_feed_link: string
}

export interface IFeed {
  uuid: string
  name: string
  website: string
  type: Types
  source_feed_link: string
  feed_file: string
  items_count: number
  status: 'ready' | 'error' | 'in progress'
  created_at: string
  updated_at: string
}

export enum FeedActionTypes {
  SET_FEEDS = 'SET_FEEDS',
}

export type IFeedState = IFeed[]
