import React from 'react'

import { GreyBase } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = GreyBase } = props
  const defaultStyle = { width: 18, height: 18 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.8401 7.28797L9.38591 11.8372C9.28299 11.9423 9.14575 12 8.99624 12C8.84974 12 8.71242 11.9423 8.60966 11.8372L4.15959 7.29228C4.05667 7.18708 4 7.04683 4 6.8972C4 6.74757 4.05659 6.60724 4.15959 6.50212L4.48732 6.1674C4.59024 6.06228 4.72764 6.00423 4.87414 6.00423C5.02065 6.00423 5.15796 6.06228 5.26097 6.1674L8.99778 9.98397L12.7387 6.163C12.8417 6.05796 12.979 6 13.1256 6C13.2721 6 13.4093 6.05796 13.5123 6.163L13.8401 6.49788C14.0533 6.71569 14.0533 7.07016 13.8401 7.28797Z"
        fill={fill}
      />
    </svg>
  )
}
