import React from 'react'

import { IIcons } from './types'

export default (props: IIcons) => {
  const { style } = props
  const defaultStyle = { width: 40, height: 40 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3311_438386)">
        <circle cx="20" cy="20" r="20" fill="#2E77F6" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.898 31.1802C26.0726 31.1802 31.0781 26.1746 31.0781 20C31.0781 13.8254 26.0726 8.81982 19.898 8.81982C13.7233 8.81982 8.71777 13.8254 8.71777 20C8.71777 26.1746 13.7233 31.1802 19.898 31.1802Z"
          fill="#2E77F6"
        />
        <path
          d="M31.0781 20C31.0781 26.1746 26.0726 31.1802 19.898 31.1802C13.7233 31.1802 8.71777 26.1746 8.71777 20C8.71777 13.8254 13.7233 8.81982 19.898 8.81982C26.0726 8.81982 31.0781 13.8254 31.0781 20Z"
          fill="white"
        />
        <path
          d="M24.0842 20.1787C24.0842 22.4202 22.2671 24.2373 20.0256 24.2373C17.7841 24.2373 15.967 22.4202 15.967 20.1787C15.967 17.9372 17.7841 16.1202 20.0256 16.1202C20.4152 16.1202 20.792 16.1751 21.1487 16.2776C22.3209 16.6144 23.2756 17.4653 23.7531 18.5706C23.9661 19.0637 24.0842 19.6074 24.0842 20.1787Z"
          fill="#313131"
        />
        <path
          d="M23.7531 18.5706L26.6622 15.5331H29.5977C29.7168 15.4905 29.9754 15.3952 30.0571 15.3544C30.1592 15.3033 33.2478 12.0871 33.3244 11.9595C33.401 11.8319 33.6052 11.5256 33.5541 11.1171C33.5031 10.7087 33.2733 10.1727 32.7884 9.99403C32.4004 9.85108 32.2183 9.84938 32.1757 9.8664H30.1337V7.74777C30.1337 7.41594 29.8785 6.72675 29.419 6.52254C28.9595 6.31834 28.6277 6.34387 28.3724 6.39492C28.1172 6.44597 27.964 6.5736 27.8619 6.6757C27.7598 6.7778 24.7733 9.58561 24.6968 9.66218C24.6202 9.73876 24.4415 10.0196 24.4415 10.1982V12.7763L21.1487 16.2776C22.3209 16.6144 23.2756 17.4653 23.7531 18.5706Z"
          fill="#313131"
        />
      </g>
      <defs>
        <clipPath id="clip0_3311_438386">
          <rect width="40" height="40" rx="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
