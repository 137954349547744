import { createUseStyles } from 'react-jss'

import { IContentBlockProps } from './ContentBlock'

import { White } from '@/const/colors'
import { WidthBPs } from '@/const/commonStyles'

export default createUseStyles(
  {
    contentBlock: {
      width: '100%',
      gridArea: 'content',
      justifySelf: 'center',
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column',
      zIndex: 1,
      backgroundColor: (props: IContentBlockProps) =>
        !props.isAuthorized ? White : props.hasProfile && !props.isWhite ? 'transparent' : White,
      padding: (props: IContentBlockProps) =>
        props.isSmallContainerPadding ? '20px 0' : props.hasProfile ? `28px 0 40px` : '0 0',
    },
    contentBlockDesktop: {
      [`@media (min-width: ${WidthBPs.MEDIUM})`]: {
        margin: '10px 10px 10px 0',
        borderRadius: 30,
      },
    },
    container: {
      padding: 30,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      height: '100%',
    },
    mobileLogo: {
      display: 'flex',

      [`@media (min-width: ${WidthBPs.MEDIUM})`]: {
        padding: '30px 30px 40px',
        display: 'none',
      },
    },
  },
  { name: 'ContentBlock' }
)
