import React from 'react'

import { SlateGreyLighten22, SlateGreyLighten49 } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = SlateGreyLighten22, fillBg = SlateGreyLighten49 } = props
  const defaultStyle = { width: 18, height: 15 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.58337 14C6.58337 15.5188 5.35216 16.75 3.83337 16.75C2.31459 16.75 1.08337 15.5188 1.08337 14C1.08337 12.4812 2.31459 11.25 3.83337 11.25C5.35216 11.25 6.58337 12.4812 6.58337 14Z"
        fill={fillBg}
        stroke={fill}
        strokeWidth="1.5"
      />
      <path
        d="M17.0834 14C17.0834 15.5188 15.8522 16.75 14.3334 16.75C12.8146 16.75 11.5834 15.5188 11.5834 14C11.5834 12.4812 12.8146 11.25 14.3334 11.25C15.8522 11.25 17.0834 12.4812 17.0834 14Z"
        fill={fillBg}
        stroke={fill}
        strokeWidth="1.5"
      />
      <path
        d="M27.5834 14C27.5834 15.5188 26.3522 16.75 24.8334 16.75C23.3146 16.75 22.0834 15.5188 22.0834 14C22.0834 12.4812 23.3146 11.25 24.8334 11.25C26.3522 11.25 27.5834 12.4812 27.5834 14Z"
        fill={fillBg}
        stroke={fill}
        strokeWidth="1.5"
      />
    </svg>
  )
}
