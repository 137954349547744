import React from 'react'

import { PurpleBase, SlateGreyBase } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = SlateGreyBase, fillBg = PurpleBase } = props
  const defaultStyle = { width: 20, height: 20 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="0.600195"
        y="9.95566"
        width="4.55294"
        height="7.86667"
        rx="1.6"
        stroke={fill}
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <rect
        x="7.30039"
        y="6.62266"
        width="4.55294"
        height="11.2"
        rx="1.6"
        stroke={fill}
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M9.71149 0.957678L7.04588 0.68515M9.71149 0.957678L2.71971 6.29085L9.71149 0.957678ZM9.71149 0.957678L9.26965 3.60051L9.71149 0.957678Z"
        stroke={fillBg}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="13.9996"
        y="1.06602"
        width="4.55294"
        height="16.7556"
        rx="1.6"
        stroke={fill}
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
    </svg>
  )
}
