import React from 'react'

import { GreenBase } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = GreenBase } = props
  const defaultStyle = { width: 16, height: 16 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.92847 10.8822L4.11247 8.26721C3.96251 8.11011 3.96251 7.85539 4.11247 7.69827L4.65551 7.12933C4.80547 6.97221 5.04862 6.97221 5.19857 7.12933L7.2 8.89087L10.8014 5.11783C10.9514 4.96072 11.1945 4.96072 11.3445 5.11783L11.8875 5.68677C12.0375 5.84387 12.0375 6.09859 11.8875 6.25571L7.47153 10.8822C7.32156 11.0393 7.07842 11.0393 6.92847 10.8822Z"
        fill={fill}
      />
      <circle cx="8" cy="8" r="7.35" stroke={fill} strokeWidth="1.3" />
    </svg>
  )
}
