import React from 'react'

import { SlateGreyLighten39 } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = SlateGreyLighten39 } = props
  const defaultStyle = { width: 12, height: 12 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.46132 7.19993C7.42349 8.00122 5.31471 9.62906 4.74086 10.072C4.16701 10.515 2.99915 10.714 2.99915 8.87183L2.99915 3.12763C2.99915 1.28544 3.90282 1.28052 4.74086 1.92742L8.4617 4.79952C9.49914 5.49973 9.49915 6.39864 8.46132 7.19993Z"
        fill={fill}
      />
    </svg>
  )
}
