import React from 'react'

import { GreyBase } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = GreyBase } = props
  const defaultStyle = { width: 18, height: 18 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0293 7.97064C9.48322 7.4248 8.74272 7.11816 7.97062 7.11816C7.19852 7.11816 6.45803 7.4248 5.91195 7.97064L3.85262 10.0293C3.30654 10.5754 2.99976 11.316 2.99976 12.0883C2.99976 12.8606 3.30654 13.6012 3.85262 14.1473C4.3987 14.6934 5.13935 15.0002 5.91162 15.0002C6.6839 15.0002 7.42454 14.6934 7.97062 14.1473L8.99995 13.118"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.97058 10.0295C8.51665 10.5754 9.25715 10.882 10.0292 10.882C10.8013 10.882 11.5418 10.5754 12.0879 10.0295L14.1472 7.97087C14.6933 7.42479 15.0001 6.68414 15.0001 5.91187C15.0001 5.13959 14.6933 4.39895 14.1472 3.85287C13.6012 3.30678 12.8605 3 12.0882 3C11.316 3 10.5753 3.30678 10.0292 3.85287L8.99991 4.8822"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
