import { createUseStyles } from 'react-jss'

import { SlateGreyLighten50 } from '../../const/colors'
import { TextSlateGreyDarken12 } from './../../const/colors'
import { FontBody2 } from './../../const/fontStyles'

export default createUseStyles(
  {
    container: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      padding: '8px 12px 8px 41px',
      borderRadius: 6,
      backgroundColor: SlateGreyLighten50,
      maxWidth: 'max-content',
      marginTop: 12,

      '& > svg': {
        position: 'absolute',
        top: 10,
        left: 14,
      },
    },

    text: {
      ...FontBody2,
      color: TextSlateGreyDarken12,
    },
  },
  { name: 'Alert' }
)
