import { createUseStyles } from 'react-jss'

export default createUseStyles(
  {
    pageWrapper: {
      display: 'grid',
      gridTemplateRows: 'auto 1fr',
      gridTemplateColumns: 'auto',
      gridTemplateAreas: `"header" "content"`,
      minHeight: '100%',
    },
  },
  { name: 'PageWrapper' }
)
