import React from 'react'

import { SlateGreyLighten13 } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = SlateGreyLighten13 } = props
  const defaultStyle = { width: 16, height: 16 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_23386_40806)">
        <circle cx="7.99997" cy="8" r="7.4" stroke={fill} strokeWidth="1.2" />
        <path
          d="M5.49997 5.5L7.99997 8M10.5 10.5L7.99997 8M5.49997 10.5L7.99997 8M7.99997 8L10.5 5.5"
          stroke={fill}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_23386_40806">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
