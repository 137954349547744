import { PARTNERS } from '../../types/types'

export enum BudgetActionTypes {
  SET_ALL_BALANCES = 'SET_ALL_BALANCES',
}

export interface IAllBalancesState {
  allBalances: IBalance[]
  hasProfileErrors: boolean
}

export interface IBalance {
  readonly id: string
  readonly amount?: number
  readonly name: string
  readonly subject: string
  readonly partner: PARTNERS
  readonly is_ord_valid: boolean
}

export interface IChargeInfo {
  commission_percent: ICommissionPercent[]
  commission_text: ICommissionText[]
  amount_helptext: string
  amount_helptext_extended: string
}

export interface ICommissionText {
  text: string
  title: string
}

export const enum CommissionType {
  COMMISSION = 'commission',
  TAX = 'tax',
  EURO_RATE = 'euro_rate',
}

export interface ICommissionPercent {
  type: CommissionType
  value: number
}

export enum PlatformStatusChecks {
  facebook = 'facebook',
  mytarget = 'mytarget',
  yandex = 'yandex',
  tiktok = 'tiktok',
}

export enum WithdrawalPlatformsType {
  vk = 'vk',
  vkads = 'vkads',
  mytarget = 'mytarget',
}

export interface IStatusCheck {
  status: {
    is_active: boolean
    status_name: string
  }
}
