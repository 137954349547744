import React from 'react'

import { White } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = White } = props
  const defaultStyle = { width: 22, height: 22 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.27246 4H16.7271" stroke={fill} strokeWidth="1.5" strokeLinecap="round" />
      <path
        d="M7.18164 4V6.18033C7.18164 7.02125 7.53458 7.82357 8.15447 8.3918L13.8453 13.6084C14.4652 14.1766 14.8181 14.9789 14.8181 15.8198V18.0002"
        stroke={fill}
        strokeWidth="1.5"
      />
      <path
        d="M14.8184 4V6.18033C14.8184 7.02125 14.4654 7.82357 13.8455 8.3918L8.15473 13.6084C7.53485 14.1766 7.18191 14.9789 7.18191 15.8198V18.0002"
        stroke={fill}
        strokeWidth="1.5"
      />
      <path d="M5.27246 18H16.7271" stroke={fill} strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  )
}
