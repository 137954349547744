import { createUseStyles } from 'react-jss'
import hexToRgba from 'hex-to-rgba'

import { SlateGreyLighten22, SlateGreyLighten34, SlateGreyLighten43, SlateGreyLighten46 } from './colors'

export default createUseStyles(
  {
    scrollbar: {
      overflow: 'overlay',
      scrollbarWidth: 'none',
      '-ms-overflow-style': 'none',

      '&::-webkit-scrollbar': {
        width: 5,
      },

      '&::-webkit-scrollbar-track': {
        background: 'transparent',
      },

      '&::-webkit-scrollbar-thumb': {
        background: hexToRgba(SlateGreyLighten34, 0.5),
        borderRadius: 8,
      },
    },
    dividerSmallHorizontalLight: {
      borderBottom: `1px solid ${SlateGreyLighten46}`,
    },
    dividerSmallHorizontalMediumLight: {
      borderBottom: `1px solid ${SlateGreyLighten43}`,
    },
    dividerSmallHorizontalDark: {
      borderBottom: `1px solid ${SlateGreyLighten22}`,
    },
    dividerMediumHorizontalLight: {
      borderBottom: `2px solid ${SlateGreyLighten46}`,
    },
    dividerMediumHorizontalMediumLight: {
      borderBottom: `2px solid ${SlateGreyLighten43}`,
    },
    dividerMediumHorizontalDark: {
      borderBottom: `2px solid ${SlateGreyLighten22}`,
    },
    blockBoxShadow: {
      boxShadow: '0 0 6px rgba(92, 117, 149, 0.08)',
    },
  },
  {
    name: 'GlobalStyles',
  }
)
