import { AxiosError, AxiosPromise, AxiosResponse } from 'axios'
import { AnyAction, Dispatch } from 'redux'

import { API_URL } from '../../const'
import { customAxios } from '../../utils'
import { ThunkResult } from '../store'
import { IPromoState, PromoActionTypes } from './types'

export function getPromo(slug: string): ThunkResult<AxiosPromise<any>> {
  return (dispatch: Dispatch<AnyAction>): AxiosPromise<any> => {
    dispatch({ type: PromoActionTypes.SET_LOADING, loading: true })
    return customAxios(`${API_URL}/promo/${slug}/`, 'GET', null, false)
      .then((response: AxiosResponse<IPromoState>): Promise<any> | AxiosResponse<IPromoState> => {
        if (response.status === 200) {
          dispatch({ type: PromoActionTypes.SET_PROMO, data: response.data })
        } else {
          return Promise.reject(response)
        }

        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
      .finally(() => dispatch({ type: PromoActionTypes.SET_LOADING, loading: false }))
  }
}
