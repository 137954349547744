import React from 'react'

import { GreyBase } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = GreyBase } = props
  const defaultStyle = { width: 18, height: 18 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.27727 10.4443L2.05874 4.64387C1.90011 4.42574 1.80489 4.16799 1.78361 3.89913C1.76233 3.63026 1.81581 3.36074 1.93815 3.12037C2.06048 2.88 2.2469 2.67814 2.4768 2.53711C2.7067 2.39608 2.97112 2.32136 3.24083 2.32123H13.2584C13.5281 2.32126 13.7926 2.3959 14.0226 2.53691C14.2526 2.67791 14.4391 2.87977 14.5615 3.12018C14.6839 3.36058 14.7374 3.63015 14.7161 3.89908C14.6949 4.168 14.5996 4.42581 14.4409 4.64397L14.1982 4.97771C12.9237 6.81916 11.5573 8.64406 10.2224 10.4443M6.27727 10.4443V13.8522C6.27773 14.1538 6.37124 14.4479 6.54504 14.6944C6.93298 15.2446 7.67119 15.3805 8.2691 15.5941C9.37495 15.9892 10.2226 15.6139 10.2224 14.2162V10.4443M6.27727 10.4443H10.2224"
        stroke={fill}
        strokeWidth="1.4"
      />
      <path
        d="M15 10V12M15 14V12M13 12H15M15 12H17"
        stroke={fill}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
