import React, { useCallback, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'

import { sendMixpanelEvent } from '../../../analytics'
import { Button, BUTTON_SIZE, BUTTON_VARIANT } from '../../../components/ButtonSMB'
import { Checkbox } from '../../../components/CheckboxSMB'
import { Modal } from '../../../components/ModalSMB'
import NewOffer from '../../../components/NewOffer'
import { closeAppModal } from '../../../store/app'
import { MODALS } from '../../../store/app/types'
import { updateProfile } from '../../../store/user'
import { IModalProps } from '../AppModals'
import useStyles from './styles'

const OFFER_LINK = 'https://files.onespot.one/smb/oferta_101.pdf'

const NewOfferModal = (props: IModalProps) => {
  const { onClose } = props

  const classes = useStyles()
  const intl = useIntl()
  const dispatch = useDispatch()

  const [isConfirmed, confirm] = useState(false)

  const cancelHandler = useCallback(
    (place: string) => {
      onClose()
      sendMixpanelEvent('TransferClose', {
        modest: place,
      })
    },
    [onClose]
  )

  const confirmOfferHandler = useCallback(() => {
    confirm(!isConfirmed)
  }, [isConfirmed])

  const confirmButtonHandler = useCallback(() => {
    dispatch(updateProfile({ new_aitarget_offer: isConfirmed }))
    dispatch(closeAppModal({ name: MODALS.NEW_OFFER }))

    sendMixpanelEvent('TransferAgree')
  }, [isConfirmed])

  return (
    <Modal
      isOpen={true}
      closeCallback={cancelHandler.bind(this, 'cross')}
      headerContent={intl.formatMessage({ id: 'app.modal.newOffer.title' })}
      bodyContent={<NewOffer />}
      footerContent={
        <div className={classes.modalFooterWrapper}>
          <Checkbox onChange={confirmOfferHandler} fullWidth={true} checked={isConfirmed}>
            <FormattedMessage id="app.modal.newOffer.confirm" />
            <a href={OFFER_LINK} rel="noopener noreferrer" target="_blank" className={classes.link}>
              <FormattedMessage id="app.modal.newOffer.offerLink" />
            </a>
          </Checkbox>

          <Button
            size={BUTTON_SIZE.size40}
            variant={BUTTON_VARIANT.primary}
            label={intl.formatMessage({ id: 'app.modal.newOffer.button' })}
            disabled={!isConfirmed}
            className={classes.button}
            onClick={confirmButtonHandler}
          />
        </div>
      }
      maxWidth={'740px'}
      footerClassName={classes.footer}
      bodyClassName={classes.body}
      hasCloseButton={false}
    />
  )
}

export default NewOfferModal
