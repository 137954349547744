import React from 'react'

import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = '#F54C5A' } = props
  const defaultStyle = { width: 10, height: 10 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.8703 4.01895C0.891868 4.01895 0.0986938 3.24367 0.0986938 2.28731C0.0986938 1.33095 0.891868 0.555664 1.8703 0.555664C2.84873 0.555664 3.6419 1.33095 3.6419 2.28731C3.6419 3.24367 2.84873 4.01895 1.8703 4.01895ZM7.83954 1.19535L0.753121 8.12192C0.555449 8.31514 0.555449 8.6284 0.753121 8.82161C0.950794 9.01483 1.27129 9.01483 1.46896 8.82161L8.55538 1.89504C8.75305 1.70182 8.75305 1.38856 8.55538 1.19535C8.35771 1.00213 8.03721 1.00213 7.83954 1.19535ZM2.62956 2.2873C2.62956 2.69717 2.28963 3.02944 1.8703 3.02944C1.45097 3.02944 1.11104 2.69717 1.11104 2.2873C1.11104 1.87744 1.45097 1.54517 1.8703 1.54517C2.28963 1.54517 2.62956 1.87744 2.62956 2.2873ZM7.4382 9.46128C6.45977 9.46128 5.66659 8.68599 5.66659 7.72963C5.66659 6.77327 6.45977 5.99799 7.4382 5.99799C8.41663 5.99799 9.20981 6.77327 9.20981 7.72963C9.20981 8.68599 8.41663 9.46128 7.4382 9.46128ZM8.19746 7.72963C8.19746 8.1395 7.85753 8.47176 7.4382 8.47176C7.01887 8.47176 6.67894 8.1395 6.67894 7.72963C6.67894 7.31976 7.01887 6.98749 7.4382 6.98749C7.85753 6.98749 8.19746 7.31976 8.19746 7.72963Z"
        fill={fill}
      />
    </svg>
  )
}
