import React from 'react'

import { GreyLighten47 } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = GreyLighten47 } = props
  const defaultStyle = { width: 22, height: 22 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_17558:166393)">
        <path
          d="M19.9787 3.34374C19.9397 2.62873 19.371 2.06015 18.656 2.02112C17.2133 1.9423 14.3901 2.01898 11.6905 3.41528C10.3143 4.12712 8.85885 5.334 7.69733 6.72647C7.68302 6.74363 7.6691 6.76089 7.65489 6.77808L5.01888 6.98171C4.58498 7.01525 4.19851 7.24267 3.95853 7.60563L2.16015 10.3257C1.97814 10.601 1.94921 10.9455 2.0827 11.2472C2.21622 11.549 2.49058 11.7593 2.81666 11.8099C2.81666 11.8099 4.20202 11.8851 5.03751 12.1538C6.33521 12.5712 7.13956 13.1394 8 13.9999C8.84393 14.8438 9.42863 15.6646 9.84597 16.9623C10.1147 17.7978 10.1899 19.1831 10.1899 19.1831C10.2405 19.5092 10.4508 19.7836 10.7525 19.9171C10.878 19.9726 11.0109 20.0001 11.1431 20.0001C11.3289 20 11.5133 19.946 11.674 19.8397L14.3941 18.0413C14.7571 17.8013 14.9845 17.4148 15.018 16.9809L15.2216 14.3449C15.2388 14.3307 15.2561 14.3168 15.2733 14.3025C16.6658 13.141 17.8727 11.6855 18.5845 10.3093C19.9809 7.6096 20.0574 4.78638 19.9787 3.34374ZM13.8125 17.1614L11.2105 18.8817L10.8753 16.7174C11.975 16.394 13.0628 15.8762 14.0992 15.1824L13.9665 16.8996C13.9583 17.0068 13.9021 17.1022 13.8125 17.1614ZM5.10012 8.03327L6.8173 7.90062C6.12352 8.93699 5.60567 10.0248 5.28234 11.1245L3.11798 10.7893L4.83832 8.18732C4.89759 8.09767 4.99304 8.04153 5.10012 8.03327ZM14.5978 13.4925C13.7458 14.2032 12.9188 14.7171 12.1566 15.0891L6.91053 9.843C7.39773 8.84794 7.99232 8.01934 8.50722 7.40207C9.58212 6.11342 10.919 5.00174 12.1751 4.35205C14.6527 3.0705 17.2631 3.00149 18.5986 3.0742C18.7754 3.08383 18.916 3.22442 18.9256 3.40122C18.9984 4.73673 18.9293 7.34709 17.6477 9.82472C16.9981 11.0808 15.8864 12.4176 14.5978 13.4925Z"
          fill={fill}
        />
        <path
          d="M13.8125 17.1614L11.2105 18.8817L10.8753 16.7174C11.975 16.394 13.0628 15.8762 14.0992 15.1824L13.9665 16.8996C13.9583 17.0068 13.9021 17.1022 13.8125 17.1614Z"
          fill={fill}
        />
        <path
          d="M5.10012 8.03327L6.8173 7.90062C6.12352 8.93699 5.60567 10.0248 5.28234 11.1245L3.11798 10.7893L4.83832 8.18732C4.89759 8.09767 4.99304 8.04153 5.10012 8.03327Z"
          fill={fill}
        />
        <path
          d="M14.3299 10.3054C15.005 10.3053 15.6804 10.0483 16.1943 9.53436C16.6923 9.03637 16.9666 8.37424 16.9666 7.66999C16.9666 6.96574 16.6923 6.3036 16.1943 5.80562C15.1663 4.77754 13.4935 4.77761 12.4656 5.80562C11.9675 6.3036 11.6933 6.96574 11.6933 7.66999C11.6933 8.37424 11.9676 9.03637 12.4656 9.53436C12.9796 10.0484 13.6546 10.3054 14.3299 10.3054ZM13.2113 6.55135C13.5197 6.24292 13.9248 6.08873 14.3299 6.08873C14.735 6.08873 15.1401 6.24292 15.4485 6.55135C15.7473 6.85014 15.9118 7.24741 15.9118 7.66995C15.9118 8.0925 15.7473 8.4898 15.4485 8.78859C14.8317 9.40541 13.8281 9.40537 13.2112 8.78859C12.9124 8.4898 12.7479 8.09253 12.7479 7.66999C12.7479 7.24744 12.9125 6.85014 13.2113 6.55135Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.5978 13.4925C13.7458 14.2032 12.9188 14.7171 12.1566 15.0891L6.91053 9.843C7.39773 8.84794 7.99232 8.01934 8.50722 7.40207C9.58212 6.11342 10.919 5.00174 12.1751 4.35205C14.6527 3.0705 17.2631 3.00149 18.5986 3.0742C18.7754 3.08383 18.916 3.22442 18.9256 3.40122C18.9984 4.73673 18.9293 7.34709 17.6477 9.82472C16.9981 11.0808 15.8864 12.4176 14.5978 13.4925ZM16.1943 9.53436C15.6804 10.0483 15.005 10.3053 14.3299 10.3054C13.6546 10.3054 12.9796 10.0484 12.4656 9.53436C11.9676 9.03637 11.6933 8.37424 11.6933 7.66999C11.6933 6.96574 11.9675 6.3036 12.4656 5.80562C13.4935 4.77761 15.1663 4.77754 16.1943 5.80562C16.6923 6.3036 16.9666 6.96574 16.9666 7.66999C16.9666 8.37424 16.6923 9.03637 16.1943 9.53436Z"
          fill={fill}
        />
        <path
          d="M4.50012 14.5C4.50012 14.5 3.41805 16.1435 2.90003 17.3C2.47659 18.2454 1.94856 19.6396 2.15451 19.8456C2.36045 20.0515 3.75464 19.5234 4.69998 19.1C5.85653 18.582 7.50012 17.5 7.50012 17.5C7.50012 17.5 7.33913 16.339 6.50012 15.5C5.66111 14.661 4.50012 14.5 4.50012 14.5Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_17558:166393">
          <rect width="18" height="18" fill="white" transform="translate(2 2)" />
        </clipPath>
      </defs>
    </svg>
  )
}
