import React from 'react'

import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = '#E0D9F4', fillBg = '#603AC7' } = props

  return (
    <svg style={{ ...style }} viewBox="0 0 222 81" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75003 58.8631C2.37968 54.6601 1.63574 50.1502 1.63574 45.4571C1.63574 22.6872 19.1478 4.22852 40.75 4.22852C52.0087 4.22852 62.1563 9.24243 69.2929 17.2674L69.2828 17.277C70.4716 18.5899 71.1958 20.3315 71.1958 22.2422C71.1958 26.3291 67.8827 29.6422 63.7958 29.6422C61.7828 29.6422 59.9575 28.8385 58.6234 27.5343L58.5699 27.4815L58.5287 27.4401L58.011 26.8575C53.4894 21.9215 47.2861 19.0285 40.75 19.0285C28.0461 19.0285 16.4357 30.1172 16.4357 45.4571C16.4357 48.863 17.0081 52.0594 18.0377 54.9714L3.75003 58.8631Z"
        fill={fill}
      />
      <path
        d="M11.15 64.6962C15.2369 64.6962 18.55 61.3831 18.55 57.2962C18.55 53.2093 15.2369 49.8962 11.15 49.8962C7.06309 49.8962 3.75 53.2093 3.75 57.2962C3.75 61.3831 7.06309 64.6962 11.15 64.6962Z"
        fill={fillBg}
      />
      <path d="M40.9589 64.5418C30.0361 64.5418 22.7784 54.2115 22.7783 44.5216C22.7782 34.8317 29.9076 24.7445 40.9588 24.7445C52.0397 24.7445 59.1393 34.816 59.1394 44.5216C59.1395 54.2272 51.8833 64.5418 40.9589 64.5418ZM40.9589 58.1428C48.3497 58.1428 52.8221 50.7896 52.822 44.5216C52.822 38.2536 48.3243 31.1435 40.9588 31.1435C33.5933 31.1435 29.0956 38.2536 29.0957 44.5216C29.0958 50.7896 33.568 58.1428 40.9589 58.1428Z" />
      <path d="M76.5162 33.8566C84.002 33.8566 87.2702 40.2522 87.2702 46.9914V64.1676H81.2422V47.8895C81.2422 43.9493 79.6119 39.975 75.1177 39.975C70.0418 39.975 67.9323 44.5109 67.9323 49.0121V64.1676H61.9042V36.3077L67.6429 34.1934V38.0665C69.6362 35.2973 72.7226 33.8566 76.5162 33.8566Z" />
      <path d="M104.137 58.6106C106.934 58.6106 109.265 57.5254 111.129 55.355L114.312 59.6771C113.187 61.2862 111.74 62.5024 109.972 63.3256C108.204 64.1489 106.21 64.5605 103.992 64.5605C101.163 64.5605 98.6713 63.9057 96.5173 62.5959C94.3633 61.2862 92.6915 59.4713 91.502 57.1512C90.3446 54.7937 89.7659 52.1368 89.7659 49.1805C89.7659 46.2617 90.3285 43.6422 91.4538 41.3221C92.6112 38.9646 94.1865 37.131 96.1798 35.8212C98.2052 34.5115 100.488 33.8566 103.028 33.8566C105.439 33.8566 107.625 34.4741 109.586 35.709C111.579 36.9064 113.155 38.6465 114.312 40.9292C115.469 43.1744 116.048 45.8126 116.048 48.8437L96.3244 53.3342C96.9353 55.0556 97.9158 56.3653 99.2661 57.2635C100.616 58.1616 102.24 58.6106 104.137 58.6106ZM103.028 39.4698C100.809 39.4698 99.0089 40.3117 97.6265 41.9957C96.2762 43.6796 95.6011 45.9436 95.6011 48.7876V48.8437L110.165 45.5881C109.747 43.7545 108.895 42.2763 107.609 41.1537C106.355 40.0311 104.828 39.4698 103.028 39.4698Z" />
      <path d="M130.954 64.7289C128.35 64.7289 125.826 64.2986 123.383 63.4379C120.972 62.5772 119.059 61.4359 117.644 60.0139L119.814 54.3446C121.197 55.6169 122.884 56.6647 124.878 57.488C126.903 58.2738 128.929 58.6667 130.954 58.6667C133.462 58.6667 135.326 58.199 136.548 57.2635C137.802 56.3279 138.429 55.093 138.429 53.5588C138.429 52.4361 138.075 51.5193 137.368 50.8083C136.693 50.0599 135.825 49.4799 134.764 49.0683C133.703 48.6566 132.256 48.1889 130.424 47.665C127.852 46.954 125.762 46.243 124.154 45.532C122.579 44.821 121.213 43.7171 120.055 42.2202C118.93 40.686 118.367 38.6278 118.367 36.0457C118.367 33.8753 118.866 31.9107 119.862 30.1519C120.891 28.3557 122.418 26.9337 124.444 25.886C126.501 24.8382 129.009 24.3143 131.967 24.3143C134.024 24.3143 136.05 24.6136 138.043 25.2124C140.036 25.8111 141.756 26.6718 143.203 27.7944L141.226 33.4637C139.747 32.4533 138.204 31.6862 136.596 31.1623C134.989 30.6384 133.429 30.3765 131.918 30.3765C129.443 30.3765 127.594 30.8629 126.373 31.8359C125.183 32.8088 124.588 34.0999 124.588 35.709C124.588 36.8316 124.926 37.7484 125.601 38.4594C126.308 39.1704 127.192 39.7317 128.253 40.1433C129.314 40.555 130.761 41.0227 132.594 41.5466C135.101 42.2202 137.159 42.9312 138.766 43.6796C140.374 44.3906 141.74 45.4945 142.865 46.9914C144.023 48.4882 144.601 50.509 144.601 53.0536C144.601 55.224 144.087 57.1886 143.058 58.9474C142.062 60.7062 140.535 62.1095 138.477 63.1573C136.419 64.205 133.912 64.7289 130.954 64.7289Z" />
      <path d="M161.825 33.8566C170.217 33.8566 174.894 41.4759 174.894 49.1805C174.894 56.9026 170.252 64.5605 161.825 64.5605C158.353 64.5605 155.604 63.2134 153.579 60.5191L153.482 72.9428L147.551 75.0571V36.3077L153.289 34.1934V38.1226C155.265 35.3038 158.415 33.8566 161.825 33.8566ZM161.15 58.5545C166.207 58.5545 168.769 53.7079 168.769 49.1805C168.769 44.6532 166.207 39.8066 161.15 39.8066C156.104 39.8066 153.482 44.6574 153.482 49.1805C153.482 53.7354 156.088 58.5545 161.15 58.5545Z" />
      <path d="M190.255 64.5605C181.768 64.5605 176.56 57.1437 176.56 49.1805C176.56 41.2362 181.804 33.8566 190.255 33.8566C198.7 33.8566 203.999 41.2114 203.999 49.1805C203.999 57.1687 198.736 64.5605 190.255 64.5605ZM190.255 58.5545C195.331 58.5545 197.923 53.7368 197.923 49.1805C197.923 44.6242 195.331 39.8066 190.255 39.8066C185.16 39.8066 182.636 44.6226 182.636 49.1805C182.636 53.7385 185.16 58.5545 190.255 58.5545Z" />
      <path d="M221.137 62.5398C220.429 63.2134 219.561 63.7186 218.533 64.0554C217.536 64.3921 216.475 64.5605 215.35 64.5605C212.521 64.5605 210.335 63.6999 208.791 61.9785C207.248 60.2571 206.477 57.7499 206.477 54.4569V29.6842L212.505 27.5699V34.4179H219.401V40.0311H212.505V54.2885C212.505 55.7479 212.81 56.8705 213.421 57.6564C214.064 58.4048 214.948 58.779 216.073 58.779C217.423 58.779 218.549 58.3674 219.449 57.5441L221.137 62.5398Z" />
    </svg>
  )
}
