import React from 'react'

import { GreyBase } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = GreyBase } = props
  const defaultStyle = { width: 18, height: 18 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.57727 2.03221C4.4862 2.03221 12.3009 2.00001 12.3009 2.00001C12.5013 1.99919 12.8083 2.06795 12.987 2.15358C12.987 2.15358 12.8706 2.02849 13.0339 2.19758C13.1802 2.34907 16.2607 5.5276 16.3209 5.59165C16.3812 5.65578 16.3428 5.62176 16.3428 5.62176C16.4266 5.80382 16.4824 6.11886 16.4824 6.31943V14.9926C16.4824 15.6351 15.7133 16.5 14.9788 16.5C14.2442 16.5 4.22631 16.47 3.49272 16.47C2.75914 16.47 2.04134 15.9012 2.00904 15.0095C1.97675 14.1176 2.04134 4.28168 2.04134 3.50727C2.04134 2.73296 2.66834 2.03221 3.57727 2.03221ZM3.41054 3.65169L3.42414 14.6278C3.42442 14.8272 3.58743 14.9909 3.78808 14.992L14.7245 15.0499C14.7721 15.0502 14.8193 15.0411 14.8633 15.0231C14.9074 15.0051 14.9475 14.9786 14.9812 14.945C15.0149 14.9114 15.0417 14.8715 15.0599 14.8275C15.0781 14.7835 15.0874 14.7364 15.0873 14.6888L15.0743 6.49169C15.0743 6.39209 15.0155 6.25439 14.9433 6.18436L12.2914 3.61567C12.2187 3.54528 12.1605 3.57005 12.1605 3.67019V6.85798C12.1605 7.16087 11.7401 7.68709 11.3592 7.68709H5.88044C5.46316 7.68709 5.01323 7.25947 5.01323 6.85798C5.01323 6.45649 5.01196 3.67037 5.01196 3.67037C5.01196 3.57023 4.93105 3.48804 4.83126 3.48686L3.59087 3.4728C3.56725 3.47256 3.54381 3.47701 3.52191 3.4859C3.50002 3.49479 3.48011 3.50793 3.46334 3.52457C3.44656 3.54121 3.43326 3.56102 3.42419 3.58284C3.41513 3.60466 3.41049 3.62806 3.41054 3.65169ZM6.36248 3.6916V6.21384C6.36248 6.31362 6.44376 6.39535 6.5439 6.39535H10.5988C10.6998 6.39535 10.7802 6.31407 10.7802 6.21384V3.69169C10.7802 3.64357 10.7611 3.59743 10.727 3.56339C10.693 3.52935 10.6469 3.51018 10.5988 3.51009H6.54399C6.44294 3.51009 6.36248 3.59127 6.36248 3.6916Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.23282 8.51881C10.7002 8.54194 12.1205 9.8276 12.1205 11.3969C12.1205 12.9662 10.7947 14.2799 9.30693 14.2565C7.81917 14.233 6.33812 12.9172 6.33812 11.3951C6.33812 9.87295 7.76556 8.49568 9.23282 8.51881ZM7.7671 11.359C7.7671 12.2367 8.46422 12.8904 9.23826 12.8868C10.0124 12.8833 10.6687 12.2403 10.6726 11.3729C10.6765 10.5054 9.97339 9.88965 9.22257 9.88965C8.47184 9.88965 7.7671 10.4812 7.7671 11.359Z"
        fill={fill}
      />
    </svg>
  )
}
