import React from 'react'

import { BackgroundWhite } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = BackgroundWhite } = props
  const defaultStyle = { width: 40, height: 40 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 39.9012C32.1066 38.8978 40 30.3707 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 29.6758 6.87104 37.7468 16 39.5999V39.4999V25.5H11V19.9999H16V14.9999C16 14.4999 16 9.99993 19.5 8.49993C22.3 7.29993 26.3333 7.6666 28 7.99993V13H25C22.5 13 22 14.5 22 16V20H27.5L26.5 25.5H22V39.5V39.9012Z"
        fill={fill}
      />
    </svg>
  )
}
