import React from 'react'

import { Black } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = Black } = props
  const defaultStyle = { width: 18, height: 18 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.00002 4C9.22546 4 9.44166 4.08955 9.60106 4.24896C9.76047 4.40837 9.85002 4.62457 9.85002 4.85V10.9729C9.85002 11.1983 9.76047 11.4145 9.60106 11.5739C9.44166 11.7333 9.22546 11.8229 9.00002 11.8229C8.77459 11.8229 8.55839 11.7333 8.39898 11.5739C8.23958 11.4145 8.15002 11.1983 8.15002 10.9729V4.85C8.15002 4.62457 8.23958 4.40837 8.39898 4.24896C8.55839 4.08955 8.77459 4 9.00002 4Z"
        fill={fill}
      />
      <path
        d="M9 15C9.26522 15 9.51957 14.8946 9.70711 14.7071C9.89464 14.5196 10 14.2652 10 14C10 13.7348 9.89464 13.4804 9.70711 13.2929C9.51957 13.1054 9.26522 13 9 13C8.73478 13 8.48043 13.1054 8.29289 13.2929C8.10536 13.4804 8 13.7348 8 14C8 14.2652 8.10536 14.5196 8.29289 14.7071C8.48043 14.8946 8.73478 15 9 15Z"
        fill={fill}
      />
    </svg>
  )
}
