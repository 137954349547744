import { Reducer } from 'redux'

import { ITransactionsState, TransactionsActionTypes } from './types'

export const initialState: ITransactionsState = {
  count: 0,
  current: 0,
  next: null,
  page_count: 0,
  previous: null,
  results: [],
}

const reducer: Reducer<ITransactionsState> = (state = initialState, action: any) => {
  switch (action.type) {
    case TransactionsActionTypes.SET_TRANSACTIONS: {
      return { ...action.data }
    }
    default: {
      return state
    }
  }
}

export { reducer as transactionReducer }
