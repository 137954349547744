import { AxiosError, AxiosResponse } from 'axios'
import { AnyAction, Dispatch } from 'redux'

import { API_URL } from '../../const'
import { customAxios } from '../../utils'
import { ThunkResult } from '../store'
import { AntibanActionTypes, AntibanTaskSlugs } from './types'

export function getAntibanTasks(): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/antiban/`, 'GET', null, true)
      .then((response: AxiosResponse<AntibanTaskSlugs[]>) => {
        dispatch({ type: AntibanActionTypes.SET_TASKS, data: response.data })
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function setAntibanTask(slug: AntibanTaskSlugs): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/antiban/`, 'POST', { slug }, true)
      .then((response: AxiosResponse<AntibanTaskSlugs>) => {
        dispatch({ type: AntibanActionTypes.SET_TASK, data: slug })
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}
