import React from 'react'
import ReactDOM from 'react-dom'
import { DefaultTheme } from 'react-jss'
import ClickOutHandler from 'react-onclickout'
import cn from 'classnames'

import { Button, BUTTON_FILL_TYPE, BUTTON_SIZE, BUTTON_VARIANT } from '../ButtonSMB'
import PopupCloseIcon from '../Icons/PopupCloseIcon'
import useStyles from './styles'
import { IModalSMBProps } from './types'

const Modal = (props: IModalSMBProps & { theme?: DefaultTheme }) => {
  const {
    isOpen,
    closeCallback,
    shouldOutClickClose = false,
    headerContent,
    bodyContent,
    footerContent,
    className,
    headerClassName,
    footerClassName,
    bodyClassName,
    hasCloseButton = true,
    mask = true,
  } = props

  const classes = useStyles({ ...props, mask })

  const closeHandler = () => {
    if (closeCallback) {
      closeCallback()
    }
  }

  if (!isOpen) {
    return null
  }

  return ReactDOM.createPortal(
    <div
      className={cn(
        classes.root,
        {
          [classes.isOpen]: isOpen,
        },
        className
      )}
    >
      <ClickOutHandler onClickOut={shouldOutClickClose ? closeHandler : undefined}>
        <div className={classes.modal}>
          {hasCloseButton && (
            <Button
              onClick={closeHandler}
              variant={BUTTON_VARIANT.tertiary}
              size={BUTTON_SIZE.icon32}
              fillIconType={BUTTON_FILL_TYPE.stroke}
              iconLeft={<PopupCloseIcon />}
              className={classes.closeButton}
              data-testid="modal-close-btn"
            />
          )}
          {headerContent && <div className={cn(classes.header, headerClassName)}>{headerContent}</div>}
          {bodyContent && <div className={cn(classes.body, bodyClassName)}>{bodyContent}</div>}
          {footerContent && <div className={cn(classes.footer, footerClassName)}>{footerContent}</div>}
        </div>
      </ClickOutHandler>
    </div>,
    document.body
  )
}

export { Modal }
