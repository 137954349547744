import React from 'react'

import { GreyLighten47 } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = GreyLighten47 } = props
  const defaultStyle = { width: 22, height: 22 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_17575:168822)">
        <path
          d="M8.68326 13.3167L7.70743 12.3408C6.16409 10.7975 6.16409 8.28583 7.70743 6.7425L10.9491 3.5L9.51576 2.06667C9.33743 1.88833 9.28326 1.61917 9.37993 1.38583C9.47659 1.1525 9.70576 1 9.95826 1H15.3749C15.7199 1 15.9999 1.28 15.9999 1.625V7.04167C15.9999 7.29417 15.8474 7.5225 15.6141 7.61917C15.3816 7.71583 15.1124 7.66333 14.9333 7.48333L13.4999 6.05083L9.56659 9.98417C8.89076 10.66 8.89076 11.7583 9.56659 12.4333C9.81076 12.6775 9.81076 13.0733 9.56659 13.3175C9.32243 13.5617 8.92743 13.5608 8.68326 13.3167Z"
          fill={fill}
        />
        <path
          d="M12.0417 21.0002H6.625C6.28 21.0002 6 20.7202 6 20.3752V14.9585C6 14.706 6.1525 14.4776 6.38583 14.381C6.62 14.2843 6.88917 14.3376 7.06667 14.5168L8.5 15.9493L12.4333 12.016C13.1092 11.3401 13.1092 10.2418 12.4333 9.5668C12.1892 9.32264 12.1892 8.9268 12.4333 8.68264C12.6775 8.43847 13.0733 8.43847 13.3175 8.68264L14.2933 9.65847C15.8367 11.2018 15.8367 13.7135 14.2933 15.2568L11.0508 18.5001L12.4842 19.9335C12.6625 20.1118 12.7167 20.381 12.62 20.6143C12.5233 20.8477 12.2942 21.0002 12.0417 21.0002Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_17575:168822">
          <rect width="20" height="20" fill="white" transform="translate(1 1)" />
        </clipPath>
      </defs>
    </svg>
  )
}
