import React from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { IntlProvider } from 'react-intl'
import { useSelector } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import App from '../App'
import Startup from '../Startup'

import { getLanguage } from '@/common/selectors'
import { ToastContainer } from '@/components/ToastSMB'
import { LOCALE_DATA } from '@/locales'

const Router = () => {
  const language = useSelector(getLanguage)
  const helmetContext = {}

  return (
    <BrowserRouter>
      <IntlProvider locale={language} messages={LOCALE_DATA[language]}>
        <Startup>
          <HelmetProvider context={helmetContext}>
            <App />
          </HelmetProvider>
          <ToastContainer />
        </Startup>
      </IntlProvider>
    </BrowserRouter>
  )
}

export default Router
