import React from 'react'

import { GreyLighten47 } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = GreyLighten47 } = props
  const defaultStyle = { width: 24, height: 24 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.743 12.3165L12.743 3.3165C12.364 2.8945 11.636 2.8945 11.257 3.3165L2.25698 12.3165C2.12744 12.4601 2.04241 12.6382 2.01223 12.8293C1.98205 13.0203 2.00802 13.216 2.08698 13.3925C2.24698 13.7535 2.60498 13.9855 2.99998 13.9855H4.99998V20.9855C4.99998 21.2507 5.10534 21.5051 5.29288 21.6926C5.48041 21.8801 5.73477 21.9855 5.99998 21.9855H8.99998C9.2652 21.9855 9.51955 21.8801 9.70709 21.6926C9.89463 21.5051 9.99998 21.2507 9.99998 20.9855V16.9855H14V20.9855C14 21.2507 14.1053 21.5051 14.2929 21.6926C14.4804 21.8801 14.7348 21.9855 15 21.9855H18C18.2652 21.9855 18.5196 21.8801 18.7071 21.6926C18.8946 21.5051 19 21.2507 19 20.9855V13.9855H21C21.1937 13.9863 21.3834 13.9308 21.546 13.8256C21.7087 13.7205 21.8372 13.5703 21.916 13.3934C21.9947 13.2165 22.0203 13.0204 21.9896 12.8292C21.9589 12.638 21.8732 12.4599 21.743 12.3165Z"
        fill={fill}
      />
    </svg>
  )
}
