import React, { Component } from 'react'
import * as Sentry from '@sentry/browser'

import { ErrorBlock } from './styled'
import { IErrorBoundaryProps, IErrorBoundaryState } from './types'

/**
 * Wrapper for components to catch their errors and show red error block.
 */
class ErrorBoundary extends Component<IErrorBoundaryProps, IErrorBoundaryState> {
  constructor(props: IErrorBoundaryProps) {
    super(props)
    this.state = { error: null }
  }

  public componentDidCatch(error: any) {
    this.setState({ error })
    Sentry.captureException(error)
  }

  public onClick = () => {
    return Sentry.lastEventId() && Sentry.showReportDialog()
  }

  public render() {
    if (this.state.error) {
      return (
        <ErrorBlock onClick={this.onClick}>
          <p>Что-то пошло не так!</p>
          <p>Наша команда была оповещена, но нажмите здесь чтобы оставить сообщение.</p>
        </ErrorBlock>
      )
    } else {
      return this.props.children
    }
  }
}

export default ErrorBoundary
