import React from 'react'

import { IIcons } from './types'

export default (props: IIcons) => {
  const { style } = props
  const defaultStyle = { width: 9, height: 11 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 9 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="trashClip">
        <path d="M0.569391 3.31201C3.19377 3.31201 5.80563 3.31201 8.43115 3.31201C8.43371 3.37392 8.43827 3.42978 8.43827 3.48535C8.43855 5.77392 8.43855 8.06221 8.43855 10.3508C8.43855 10.7749 8.21791 10.9998 7.80025 10.9998C5.59438 10.9995 3.38851 10.9995 1.18264 10.9989C0.792882 10.9989 0.563412 10.7818 0.562843 10.3925C0.559996 8.06077 0.56085 5.72929 0.561135 3.39753C0.561419 3.37421 0.56569 3.3506 0.569391 3.31201ZM4.15777 7.08378C4.15777 7.25654 4.15777 7.42931 4.15777 7.60179C4.15777 8.11029 4.15721 8.6188 4.15806 9.12731C4.15834 9.28049 4.22525 9.39798 4.36504 9.45902C4.48632 9.512 4.62383 9.51027 4.7081 9.40777C4.77757 9.3234 4.83423 9.19929 4.83594 9.0916C4.84704 8.39133 4.84163 7.69076 4.84163 6.99048C4.84163 6.338 4.84163 5.68553 4.84163 5.03305C4.84163 4.88188 4.78583 4.76065 4.64746 4.69241C4.53273 4.63597 4.41714 4.6449 4.31009 4.71775C4.19222 4.7978 4.15664 4.91672 4.15692 5.05435C4.15834 5.73073 4.15777 6.4074 4.15777 7.08378ZM2.76758 7.07543C2.76758 6.38494 2.769 5.69416 2.76672 5.00368C2.76615 4.80874 2.6349 4.67024 2.45497 4.66045C2.26308 4.65008 2.11845 4.77188 2.09454 4.96912C2.08714 5.03074 2.0897 5.09352 2.0897 5.15571C2.08942 6.00946 2.08942 6.86293 2.08942 7.71668C2.08942 8.1771 2.08742 8.63752 2.09027 9.09794C2.09198 9.36371 2.25853 9.51891 2.49084 9.47975C2.66224 9.45096 2.76701 9.3185 2.76729 9.11838C2.76871 8.4374 2.76758 7.75641 2.76758 7.07543ZM6.20393 7.06996C6.20421 7.06996 6.20421 7.06996 6.2045 7.06996C6.2045 7.75094 6.20336 8.43221 6.20507 9.1132C6.20564 9.34327 6.34059 9.48753 6.54557 9.48609C6.74145 9.48493 6.87924 9.34067 6.87981 9.11781C6.88209 7.75584 6.88209 6.39358 6.8781 5.03161C6.87782 4.95444 6.85248 4.86892 6.81319 4.80269C6.73831 4.676 6.57575 4.62791 6.4371 4.67139C6.29361 4.71631 6.2045 4.84243 6.20421 5.01232C6.20336 5.6982 6.20393 6.38408 6.20393 7.06996Z" />
        <path d="M3.36922 1.13355C3.36922 0.950415 3.36865 0.788303 3.36922 0.626191C3.37065 0.235164 3.60439 -8.51724e-05 3.98959 0.000202771C4.33578 0.000490714 4.68227 -0.000373115 5.02847 0.000202771C5.38662 0.000490714 5.62349 0.237756 5.6289 0.602868C5.63146 0.77045 5.62919 0.938321 5.62919 1.13383C5.69581 1.13383 5.75474 1.13383 5.81367 1.13383C6.6581 1.13383 7.50252 1.13383 8.34695 1.13383C8.78112 1.13383 9.00148 1.35872 8.9992 1.79898C8.99778 2.09643 8.99749 2.39387 8.99636 2.69132C8.99636 2.70024 8.99123 2.70888 8.98582 2.72731C5.99788 2.72731 3.00765 2.72731 0.00689119 2.72731C0.00689119 2.3478 -0.0136073 1.96916 0.013724 1.59397C0.0339379 1.31466 0.298995 1.1347 0.613591 1.13412C1.46286 1.13297 2.31184 1.13383 3.1611 1.13383C3.2226 1.13355 3.28353 1.13355 3.36922 1.13355Z" />
      </g>
      <defs>
        <clipPath id="trashClip">
          <rect width="9" height="11" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
