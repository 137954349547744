import { AxiosError, AxiosResponse } from 'axios'
import { AnyAction, Dispatch } from 'redux'

import { customAxios } from '../../utils'
import { IVKAdsRequestBody } from './types'

import { API_URL } from '@/const'
import { AdAccountsActionTypes, IAdAccountsState } from '@/store/adaccounts/types'
import { ThunkResult } from '@/store/store'
import { Platforms } from '@/types'

export enum AddVKAdsActionTypes {
  SET_ADD_VK_ADS_LOADING = 'SET_ADD_VK_ADS_LOADING',
  SET_ADD_VK_ADS_ERROR = 'SET_ADD_VK_ADS_ERROR',
  UPDATE_ADD_VK_ADS_ERROR = 'UPDATE_ADD_VK_ADS_ERROR',
}

export function setLoading(isLoading: boolean): AnyAction {
  return { type: AddVKAdsActionTypes.SET_ADD_VK_ADS_LOADING, data: isLoading }
}

export function createVKAdsAdAccount(body: IVKAdsRequestBody): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/vkads/accounts/`, 'POST', body, true)
      .then((response: AxiosResponse<IAdAccountsState>) => {
        dispatch({ type: AdAccountsActionTypes.SET_AD_ACCOUNT, data: response.data, network: Platforms.VK_ADS })
        return response
      })
      .catch((error: AxiosError) => {
        dispatch({
          type: AddVKAdsActionTypes.SET_ADD_VK_ADS_ERROR,
          data: error.response?.data,
        })
        return Promise.reject(error)
      })
      .finally(() => {
        dispatch({
          type: AddVKAdsActionTypes.SET_ADD_VK_ADS_LOADING,
          data: false,
        })
      })
  }
}

export function createClientVKAdsAdAccount(body: IVKAdsRequestBody): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/profile_agency/vkads_accounts/`, 'POST', body, true)
      .then((response: AxiosResponse<IAdAccountsState>) => response)
      .catch((error: AxiosError) => {
        dispatch({
          type: AddVKAdsActionTypes.SET_ADD_VK_ADS_ERROR,
          data: error.response?.data,
        })
        return Promise.reject(error)
      })
      .finally(() => {
        dispatch({
          type: AddVKAdsActionTypes.SET_ADD_VK_ADS_LOADING,
          data: false,
        })
      })
  }
}

export function updateError(error: { [field: string]: string }): AnyAction {
  return { type: AddVKAdsActionTypes.UPDATE_ADD_VK_ADS_ERROR, data: error }
}
