export enum MODALS {
  CREATE_COMPANY = 'createCompany',
  CREATE_GROUP = 'createGroup',
  REMOVE_COMPANY = 'removeCompany',
  REMOVE_GROUP = 'removeGroup',
  RENAME_COMPANY = 'renameCompany',
  RENAME_GROUP = 'renameGroup',
  RENAME_ADS = 'renameAds',
  MOVE_ADS = 'moveAds',
  SETTING_TABLE = 'settingModal',
  SAVE_ASIDE = 'saveAside',
  REMOVE_ASIDE = 'removeAside',
  ATTENTION_ASIDE = 'attentionAside',
  WARNING_ASIDE = 'warningAside',
  ERROR_ASIDE = 'errorAside',
}

export type RemoveModalDataType = {
  count: number
  ids: string[]
  clearCheckedCallback: () => void
  id: string
  isAll: boolean
  queryAll: string
}

export type RenameModalDataType = {
  name: string
}
export interface CreateGroupFormData {
  name: string
  campaign: number
}

type AdsetField = { name: string }
export interface CreateCompanyFormData {
  name: string
  adsets: AdsetField[]
}
