import { Reducer } from 'redux'

import { INotificationsState, NotificationsActionTypes } from './types'

const initialState: INotificationsState = {
  code: '',
  subscriptions: [],
}

const reducer: Reducer<INotificationsState> = (state = initialState, action: any) => {
  switch (action.type) {
    case NotificationsActionTypes.SET_SUBSCRIPTIONS: {
      return {
        ...state,
        subscriptions: [...action.data],
      }
    }
    case NotificationsActionTypes.UPDATE_SUBSCRIPTION: {
      const newSubscriptions = state.subscriptions.map((subscription) => {
        if (subscription.id === action.data.id) {
          return {
            ...subscription,
            ...action.data,
          }
        }

        return subscription
      })

      return {
        ...state,
        subscriptions: [...newSubscriptions],
      }
    }
    case NotificationsActionTypes.SET_TELEGRAM_CODE: {
      if (action.data && action.data.code) {
        return {
          ...state,
          code: action.data.code,
        }
      }

      return state
    }
    default: {
      return state
    }
  }
}

export { reducer as notificationsReducer }
