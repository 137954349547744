import { createUseStyles } from 'react-jss'

import { PurpleBase } from '../../../const/colors'
import { WidthBPs } from '../../../const/commonStyles'
import { FontBody1Accent } from '../../../const/fontStyles'

export default createUseStyles(
  {
    modalFooterWrapper: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',

      [`@media (min-width: ${WidthBPs.SMALL})`]: {
        flexDirection: 'row',
        alignItems: 'center',
      },
    },
    link: {
      textDecoration: 'none',
      color: PurpleBase,
      ...FontBody1Accent,
    },
    footer: {
      boxShadow: '0 -2px 6px rgba(16, 23, 41, 0.04), 0px 0px 1px rgba(16, 23, 41, 0.04)',
      padding: 24,
    },
    body: {
      padding: '24px 24px 0',
    },
    button: {
      margin: '24px 0 0',

      [`@media (min-width: ${WidthBPs.SMALL})`]: {
        margin: 0,
      },
    },
  },
  { name: 'NewOfferModal' }
)
