import React from 'react'
import { useSelector } from 'react-redux'

import WalletFilledIcon from '../../../../components/Icons/WalletFilledIcon'
import { Routes } from '../../../../const/routes'
import selector from './selector'
import { LinkStyled, Wrapper } from './styled'

import { Mixpanel } from '@/analytics'
import { getNumWithDelimiter } from '@/common/utils'
import { Delimiters } from '@/const'

const Balance = () => {
  const { balance, isProfileClient } = useSelector(selector)

  const onBudgetClick = () => {
    Mixpanel.track('HeaderGoToBudget')
  }

  const balanceContent = () => (
    <>
      <WalletFilledIcon />
      {getNumWithDelimiter(balance, Delimiters.SPACE)}&nbsp;&#8381;
    </>
  )

  return (
    <Wrapper>
      {isProfileClient ? (
        <>{balanceContent()}</>
      ) : (
        <LinkStyled to={Routes.BUDGET} onClick={onBudgetClick}>
          {balanceContent()}
        </LinkStyled>
      )}
    </Wrapper>
  )
}

export default Balance
