import { ReactNode } from 'react'

export enum INPUT_VARIANT {
  default = 'default',
  grey = 'grey',
  password = 'password',
}

export enum INPUT_SIZE {
  small = 'small',
  big = 'big',
}

export interface IInputSMBProps {
  variant?: INPUT_VARIANT
  size?: INPUT_SIZE
  defaultValue?: string | number
  value?: string | number
  name: string
  placeholder?: string
  mask?: string | Array<string | RegExp>
  onChange?: (value: any) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onFocus?: (e: React.FocusEvent<HTMLElement>) => void
  onClick?: (e: React.SyntheticEvent<HTMLElement>) => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  inputRef?: React.Ref<HTMLInputElement>
  disabled?: boolean
  type?: string
  error?: boolean
  errorMsg?: string
  width?: string
  fullWidth?: boolean
  rows?: number
  min?: string | number
  max?: string | number
  className?: string
  request?: (value: string) => Promise<unknown>
  suggestionList?: (response: IResponseProps) => ReactNode
  alwaysShowMask?: boolean
  leftIcon?: ReactNode
  dataTestId?: string
  maxLength?: number
  id?: string
}

export interface ISuggestionsProps {
  data: any | any[]
  unrestricted_value: string
  value: string
}

export interface IResponseProps {
  suggestions: ISuggestionsProps[]
}
