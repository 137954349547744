import React from 'react'

import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = '#6F48D9' } = props
  const defaultStyle = { width: 21, height: 15 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.8984 9.26015C17.3515 9.26015 17.7188 9.61732 17.7188 10.058V11.0155C17.7188 11.4562 17.3515 11.8133 16.8984 11.8133H15.0117C14.5586 11.8133 14.1914 11.4562 14.1914 11.0155V10.058C14.1914 9.61732 14.5586 9.26015 15.0117 9.26015H16.8984ZM19.3594 7.82398V5.43036H1.64062V11.8085C1.64062 12.6884 2.37666 13.4043 3.28125 13.4043H17.7188C18.6233 13.4043 19.3594 12.6884 19.3594 11.8085C19.3594 11.3678 19.7266 11.0106 20.1797 11.0106C20.6328 11.0106 21 11.3678 21 11.8085C21 13.5683 19.5281 15 17.7188 15H3.28125C1.47192 15 0 13.5683 0 11.8085V3.19149C0 1.43165 1.47192 0 3.28125 0H17.7188C19.5281 0 21 1.43165 21 3.19149V7.82398C21 8.26468 20.6328 8.62185 20.1797 8.62185C19.7266 8.62185 19.3594 8.26468 19.3594 7.82398ZM19.3594 3.83462V3.19149C19.3594 2.31165 18.6233 1.59574 17.7188 1.59574H3.28125C2.37666 1.59574 1.64062 2.31165 1.64062 3.19149V3.83462H19.3594ZM4.10156 7.02611C3.64847 7.02611 3.28125 7.38328 3.28125 7.82398C3.28125 8.26468 3.64847 8.62185 4.10156 8.62185H10.8691C11.3222 8.62185 11.6895 8.26468 11.6895 7.82398C11.6895 7.38328 11.3222 7.02611 10.8691 7.02611H4.10156Z"
        fill={fill}
      />
    </svg>
  )
}
