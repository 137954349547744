import React from 'react'

import { IIcons } from './types'

export default (props: IIcons) => {
  const { style } = props
  const defaultStyle = { width: 149, height: 42 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 149 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1520_424450)">
        <path
          d="M30.1228 41.0118C36.2809 41.0118 41.273 36.0453 41.273 29.9188C41.273 23.7924 36.2809 18.8259 30.1228 18.8259C23.9648 18.8259 18.9727 23.7924 18.9727 29.9188C18.9727 36.0453 23.9648 41.0118 30.1228 41.0118Z"
          fill="#97CF26"
        />
        <path
          d="M9.16356 35.0824C12.03 35.0824 14.3537 32.7706 14.3537 29.9188C14.3537 27.0671 12.03 24.7553 9.16356 24.7553C6.29711 24.7553 3.97339 27.0671 3.97339 29.9188C3.97339 32.7706 6.29711 35.0824 9.16356 35.0824Z"
          fill="#A169F7"
        />
        <path
          d="M30.1229 16.2071C34.0865 16.2071 37.2997 13.0104 37.2997 9.06706C37.2997 5.12375 34.0865 1.92706 30.1229 1.92706C26.1592 1.92706 22.946 5.12375 22.946 9.06706C22.946 13.0104 26.1592 16.2071 30.1229 16.2071Z"
          fill="#FF6163"
        />
        <path
          d="M9.1635 18.1835C14.2244 18.1835 18.327 14.102 18.327 9.06706C18.327 4.03218 14.2244 -0.049408 9.1635 -0.049408C4.10264 -0.049408 0 4.03218 0 9.06706C0 14.102 4.10264 18.1835 9.1635 18.1835Z"
          fill="#00AAFF"
        />
        <path
          d="M70.2614 35.0221H76.0496L65.33 5.98376H61.1317L50.4102 35.0231H56.1988L58.2461 29.48H68.2142L70.2614 35.0231L59.8582 25.1101L63.2306 15.9788L66.601 25.1101H59.8582L70.2614 35.0221ZM90.3983 15.1971L85.0715 29.6224L79.7473 15.1971H74.3088L81.6306 35.0221H88.5134L95.8343 15.1971H90.3973H90.3983ZM98.1105 15.1971H103.549V35.0221H98.1105V15.1971Z"
          fill="black"
        />
        <path
          d="M100.848 12.9459C102.782 12.9459 104.35 11.3862 104.35 9.46235C104.35 7.53845 102.782 5.97882 100.848 5.97882C98.9144 5.97882 97.3467 7.53845 97.3467 9.46235C97.3467 11.3862 98.9144 12.9459 100.848 12.9459Z"
          fill="black"
        />
        <path
          d="M122.751 19.5661V15.1971H116.236V9.7282H110.801V15.1971H106.5V19.5661H110.801V28.0955C110.801 32.4952 113.013 35.4317 118.659 35.4317C120.839 35.4317 122.751 34.6352 122.751 34.6352L122.091 30.3546C122.091 30.3546 121.02 30.6729 119.704 30.6729C118.091 30.6729 116.236 29.9811 116.236 27.7625V19.5656H122.751V19.5661ZM135.222 30.4905C132.19 30.4905 129.733 28.099 129.733 25.1491C129.733 22.2017 132.19 19.8097 135.221 19.8097C138.255 19.8097 140.711 22.2022 140.711 25.1496C140.711 28.0995 138.255 30.491 135.222 30.491V30.4905ZM135.222 14.6911C129.328 14.6911 124.548 19.3432 124.548 25.0775C124.548 30.8142 129.328 35.4638 135.222 35.4638C141.113 35.4638 145.894 30.8142 145.894 25.0775C145.894 19.3432 141.113 14.6911 135.221 14.6911"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1520_424450">
          <rect width="149" height="42" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
