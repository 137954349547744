import React from 'react'

import { GreyBase } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = GreyBase } = props
  const defaultStyle = { width: 18, height: 18 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 5.38307C3.05674 6.88652 2 9 2 9C2 9 4.54545 14.0909 9 14.0909C10.3471 14.0909 11.5197 13.6253 12.5 12.9757M6.81394 4.33255C7.48023 4.06917 8.2101 3.90909 9 3.90909C13.4545 3.90909 16 9 16 9C16 9 15.3356 10.3288 14.0917 11.6286"
        stroke={fill}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.3034 10.3949C9.96223 10.7139 9.50392 10.9091 9 10.9091C7.94564 10.9091 7.09091 10.0544 7.09091 9C7.09091 8.586 7.22269 8.20278 7.4466 7.88999M10.8429 8.5C10.6482 7.78046 10.0433 7.2295 9.29467 7.11351"
        stroke={fill}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="2.91924"
        y1="3"
        x2="15"
        y2="15.0808"
        stroke={fill}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
