import { createUseStyles } from 'react-jss'

import { RedBase } from '../../const/colors'
import { FontBody2 } from '../../const/fontStyles'

export default createUseStyles(
  {
    errorMessage: {
      paddingTop: 5,
      color: RedBase,
      ...FontBody2,
    },
  },
  { name: 'ErrorMessage' }
)
