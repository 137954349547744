import React from 'react'

import { ButtonBlock, ButtonLink, ExternalLink } from './styled'
import { IButtonProps } from './types'

const componentsDict: any = {
  default: (props: any) => <ButtonBlock {...props}>{props.children}</ButtonBlock>,
  external_link: (props: any) => (
    <ExternalLink href={props.href || '/'} {...props} target="_blank" rel="noopener noreferrer">
      {props.children}
    </ExternalLink>
  ),
  link: (props: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { bType, ...otherProps } = props
    return (
      <ButtonLink to={props.to || '/'} theme={props.theme} onClick={props.onClick} size={props.size} {...otherProps}>
        {props.children}
      </ButtonLink>
    )
  },
}

/**
 * Component for `<button>`, `<a>` or `<Link>`, which are look like button block.
 */
const Button = React.forwardRef((props: IButtonProps, ref: any) =>
  componentsDict[props.bType || 'default']({ ...props, ref })
)

Button.defaultProps = {
  size: 'default',
  theme: 'default',
}

export default Button
