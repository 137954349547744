import React from 'react'

import { GreyBase } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = GreyBase } = props
  const defaultStyle = { width: 18, height: 18 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.28797 13.8401L11.8372 9.38591C11.9423 9.28299 12 9.14575 12 8.99624C12 8.84974 11.9423 8.71242 11.8372 8.60966L7.29228 4.15959C7.18708 4.05667 7.04683 4 6.8972 4C6.74757 4 6.60724 4.05659 6.50212 4.15959L6.1674 4.48732C6.06228 4.59024 6.00423 4.72764 6.00423 4.87414C6.00423 5.02065 6.06228 5.15796 6.1674 5.26097L9.98397 8.99778L6.163 12.7387C6.05796 12.8417 6 12.979 6 13.1256C6 13.2721 6.05796 13.4093 6.163 13.5123L6.49788 13.8401C6.71569 14.0533 7.07017 14.0533 7.28797 13.8401Z"
        fill={fill}
      />
    </svg>
  )
}
