import React from 'react'

import { PurpleBase } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = PurpleBase } = props
  const defaultStyle = { width: 18, height: 18 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.5 6.5V11.5" stroke={fill} strokeWidth="1.3" strokeLinecap="round" />
      <path d="M10.5 6.5V11.5" stroke={fill} strokeWidth="1.3" strokeLinecap="round" />
      <path
        d="M1.5 9C1.5 5.4645 1.5 3.69675 2.598 2.598C3.6975 1.5 5.4645 1.5 9 1.5C12.5355 1.5 14.3032 1.5 15.4012 2.598C16.5 3.6975 16.5 5.4645 16.5 9C16.5 12.5355 16.5 14.3032 15.4012 15.4012C14.304 16.5 12.5355 16.5 9 16.5C5.4645 16.5 3.69675 16.5 2.598 15.4012C1.5 14.304 1.5 12.5355 1.5 9Z"
        stroke={fill}
        strokeWidth="1.3"
        strokeLinecap="round"
      />
    </svg>
  )
}
