import React from 'react'

import { GreyBase, PurpleLighten6 } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style } = props
  const defaultStyle = { width: 16, height: 16 }

  return (
    <svg style={{ ...defaultStyle, ...style }} fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5187 9.26041C11.9685 9.26041 12.3461 9.63163 12.2773 10.0758C11.8738 12.6892 9.63661 14.6295 6.93836 14.6295C3.9531 14.6295 1.53345 12.2099 1.53345 9.22532C1.53345 6.76637 3.40152 4.47584 5.50468 3.95795C5.95661 3.84637 6.41976 4.16427 6.41976 4.62953C6.41976 7.78181 6.52573 8.59725 7.12432 9.04076C7.72292 9.48427 8.42678 9.26041 11.5187 9.26041Z"
        stroke={GreyBase}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4619 6.63479C14.4977 4.60953 12.01 1.34496 8.97841 1.40111C8.74262 1.40532 8.55385 1.60181 8.54332 1.83689C8.46683 3.50216 8.56999 5.66005 8.62753 6.6383C8.64508 6.94286 8.88437 7.18216 9.18823 7.1997C10.1938 7.25725 12.4303 7.33584 14.0717 7.08742C14.2949 7.05374 14.4584 6.86005 14.4619 6.63479Z"
        stroke={PurpleLighten6}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
