import React from 'react'

import { SlateGreyLighten34 } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = SlateGreyLighten34 } = props
  const defaultStyle = { width: 14, height: 14 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="M7 0.890625C3.61758 0.890625 0.875 3.6332 0.875 7.01562C0.875 10.398 3.61758 13.1406 7 13.1406C10.3824 13.1406 13.125 10.398 13.125 7.01562C13.125 3.6332 10.3824 0.890625 7 0.890625ZM7 12.1016C4.1918 12.1016 1.91406 9.82383 1.91406 7.01562C1.91406 4.20742 4.1918 1.92969 7 1.92969C9.8082 1.92969 12.0859 4.20742 12.0859 7.01562C12.0859 9.82383 9.8082 12.1016 7 12.1016Z"
          fill={fill}
        />
        <path
          d="M6.34375 4.60938C6.34375 4.78342 6.41289 4.95034 6.53596 5.07341C6.65903 5.19648 6.82595 5.26562 7 5.26562C7.17405 5.26562 7.34097 5.19648 7.46404 5.07341C7.58711 4.95034 7.65625 4.78342 7.65625 4.60938C7.65625 4.43533 7.58711 4.26841 7.46404 4.14534C7.34097 4.02227 7.17405 3.95313 7 3.95312C6.82595 3.95313 6.65903 4.02227 6.53596 4.14534C6.41289 4.26841 6.34375 4.43533 6.34375 4.60938ZM7.32812 6.14062H6.67188C6.61172 6.14062 6.5625 6.18984 6.5625 6.25V9.96875C6.5625 10.0289 6.61172 10.0781 6.67188 10.0781H7.32812C7.38828 10.0781 7.4375 10.0289 7.4375 9.96875V6.25C7.4375 6.18984 7.38828 6.14062 7.32812 6.14062Z"
          fill={fill}
        />
      </g>
      <defs>
        <rect width="14" height="14" fill="white" transform="translate(0 0.015625)" />
      </defs>
    </svg>
  )
}
