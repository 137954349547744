import React from 'react'

import { GreyLighten26 } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = GreyLighten26 } = props
  const defaultStyle = { width: 22, height: 22 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.8401 12.712L11.3859 8.16283C11.283 8.05771 11.1458 8 10.9962 8C10.8497 8 10.7124 8.05771 10.6097 8.16283L6.15959 12.7077C6.05667 12.8129 6 12.9532 6 13.1028C6 13.2524 6.05659 13.3928 6.15959 13.4979L6.48732 13.8326C6.59024 13.9377 6.72764 13.9958 6.87414 13.9958C7.02065 13.9958 7.15796 13.9377 7.26097 13.8326L10.9978 10.016L14.7387 13.837C14.8417 13.942 14.979 14 15.1256 14C15.2721 14 15.4093 13.942 15.5123 13.837L15.8401 13.5021C16.0533 13.2843 16.0533 12.9298 15.8401 12.712Z"
        fill={fill}
      />
    </svg>
  )
}
