import React from 'react'
import { Toaster } from 'react-hot-toast'

// documentation
// https://react-hot-toast.com/docs

const ToastContainer = () => (
  <Toaster
    position="bottom-left"
    reverseOrder={true}
    containerStyle={{
      bottom: 28,
      left: 28,
    }}
    toastOptions={{
      duration: 5000,
      style: {},
    }}
  />
)

export { ToastContainer }

// example
//
// <Button
//   onClick={() => {
//     toast.custom(t => (
//       <Toast
//         content={
//           <div style={{ minWidth: 370 }}>
//             Sit alias voluptatibus quia consequatur.
//           </div>
//         }
//         onClose={() => toast.dismiss(t.id)}
//         extraButton={<>Попробовать снова</>}
//       />
//     ))
//   }}
//   size={BUTTON_SIZE.size40}
//   variant={BUTTON_VARIANT.green}
//   label="Call toast"
// />
