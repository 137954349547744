import React from 'react'

import { GreyLighten47 } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = GreyLighten47 } = props
  const defaultStyle = { width: 22, height: 22 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.2663 7.32767C11.935 7.32767 14.6763 5.87736 14.6763 4.58055C14.6763 3.28387 11.935 1.8335 8.2663 1.8335C4.59764 1.8335 1.85632 3.28387 1.85632 4.58055C1.85632 5.87736 4.59758 7.32767 8.2663 7.32767Z"
        fill={fill}
      />
      <path
        d="M8.26228 18.3163C8.79775 18.3163 9.31314 18.2852 9.80338 18.2285C9.39014 17.6974 8.65248 16.5966 8.44587 15.6088C8.26997 15.6148 8.09345 15.6189 7.91571 15.6189C5.81941 15.6189 4.18068 15.2035 2.67236 14.4493C2.37538 14.3008 2.10208 14.0546 1.85229 13.8857V15.5693C1.85229 16.8659 4.59355 18.3163 8.26228 18.3163Z"
        fill={fill}
      />
      <path
        d="M8.26221 14.6536C8.28102 14.6536 8.29952 14.6533 8.31827 14.6533C8.28071 14.3531 8.21932 14.0847 8.21932 13.6083C8.21932 13.1751 8.26264 12.6987 8.40489 11.9189C8.38615 11.9189 8.28096 11.9196 8.26221 11.9196C6.16591 11.9196 4.18074 11.5042 2.67236 10.75C2.37532 10.6015 2.10196 10.3553 1.85217 10.1865V11.9065C1.85223 13.2033 4.59349 14.6536 8.26221 14.6536Z"
        fill={fill}
      />
      <path
        d="M8.26221 10.9906C8.44392 10.9906 8.53656 10.9869 8.71357 10.98C8.99785 10.2825 9.58367 8.78961 11.9876 7.58767C10.7748 8.19406 8.83463 8.19406 8.26221 8.19406C6.16591 8.19406 4.16194 7.6813 2.65356 6.92712C2.35652 6.77856 2.08316 6.61908 1.83337 6.4502L1.85217 8.24362C1.85223 9.54036 4.59349 10.9906 8.26221 10.9906Z"
        fill={fill}
      />
      <path
        d="M10.4438 10.5319C10.0252 11.1611 9.7262 11.8762 9.5791 12.6447C9.5112 12.9989 9.47534 13.3644 9.47534 13.7381C9.47534 14.019 9.49585 14.2953 9.53461 14.5657C9.62986 15.23 9.83796 15.8581 10.1389 16.4303C10.4546 17.0308 10.8723 17.5696 11.3691 18.0233C12.4014 18.9662 13.7744 19.542 15.2793 19.542C18.4796 19.542 21.0833 16.9384 21.0833 13.7381C21.0833 10.5377 18.4796 7.93408 15.2793 7.93408C14.9486 7.93408 14.6242 7.96233 14.3084 8.01572C12.6995 8.2878 11.3138 9.22411 10.4438 10.5319Z"
        fill={fill}
      />
    </svg>
  )
}
