import React from 'react'

import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = '#11AD37' } = props
  const defaultStyle = { width: 25, height: 23 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.65635 7.83797C5.14253 3.84466 8.98911 1 13.5001 1C19.2991 1 24.0001 5.70101 24.0001 11.5C24.0001 17.299 19.2991 22 13.5001 22C10.6633 22 8.08927 20.875 6.19977 19.0469C5.15181 18.0329 4.34768 16.8178 3.80921 15.5M3.65635 7.83797H1L3.44348 11.5L6.19977 7.83797H3.65635Z"
        stroke="#11AD37"
        strokeWidth="1.65"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8989 14.2593H13.1694V15.5H11.8159V14.2593H10.7148V13.1689H11.8159V12.5942H10.7148V11.5039H11.8159V7.67969H14.8076C15.6706 7.67969 16.3599 7.90527 16.8755 8.35645C17.3911 8.80404 17.6489 9.40023 17.6489 10.145C17.6489 10.9041 17.4036 11.5003 16.9131 11.9336C16.4225 12.3633 15.7404 12.5835 14.8667 12.5942H13.1694V13.1689H14.8989V14.2593ZM13.1694 11.5039H14.7861C15.2731 11.5039 15.6455 11.3911 15.9033 11.1655C16.1611 10.9399 16.29 10.6034 16.29 10.1558C16.29 9.75472 16.1629 9.42708 15.9087 9.17285C15.658 8.91862 15.3107 8.78613 14.8667 8.77539H13.1694V11.5039Z"
        fill={fill}
      />
    </svg>
  )
}
