import React from 'react'

import { IIcons } from './types'

export default (props: IIcons) => {
  const { style } = props
  const defaultStyle = { width: 40, height: 41 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M40 20.5C40 31.5457 31.0457 40.5 20 40.5C8.9543 40.5 0 31.5457 0 20.5C0 13.2876 3.81776 6.96683 9.54267 3.44839C12.5853 1.57841 16.1667 0.5 20 0.5C31.0457 0.5 40 9.4543 40 20.5Z"
        fill="url(#paint0_linear_5992_18408)"
      />
      <path
        d="M26.6942 16.4941C26.0177 16.4941 25.5012 17.1053 25.5353 17.7809C25.5803 18.6711 25.5447 19.5774 25.5447 20.4998C25.4276 28.1561 21.6612 35.6561 15.5 39.992C16.9453 40.3258 18.451 40.4998 19.9993 40.4998C31.0433 40.4998 39.9993 31.5439 39.9993 20.4998C39.9993 19.1291 39.8608 17.7868 39.598 16.4941H26.6942Z"
        fill="url(#paint1_linear_5992_18408)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.39048 37.4576C13.744 33.2444 16.6364 27.251 16.6364 20.4991C16.6364 13.7056 13.8542 7.67997 9.5199 3.46289C3.80764 6.98408 0 13.2976 0 20.5005C0 27.6488 3.75021 33.9212 9.39048 37.4576Z"
        fill="url(#paint2_linear_5992_18408)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5992_18408"
          x1="13.6364"
          y1="4.13636"
          x2="33.6364"
          y2="33.2273"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AF78FF" />
          <stop offset="1" stopColor="#811CFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5992_18408"
          x1="25.4538"
          y1="18.6816"
          x2="30.9084"
          y2="35.9544"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.117116" stopColor="#00D7DC" />
          <stop offset="1" stopColor="#2883EF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_5992_18408"
          x1="4.54545"
          y1="8.6823"
          x2="13.6364"
          y2="33.2278"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3321A4" />
          <stop offset="1" stopColor="#3321A4" />
        </linearGradient>
      </defs>
    </svg>
  )
}
