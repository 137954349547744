import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { GreyBase, greySky3Clr } from '../../../../const/colors'
import { baseFontFamily, smallFont } from '../../../../const/commonStyles'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  letter-spacing: -0.2px;
  color: ${GreyBase};
  font: bold ${smallFont} ${baseFontFamily};
  text-decoration: none;

  & svg {
    flex-shrink: 0;
    margin-right: 18px;

    & path {
      fill: ${greySky3Clr};
    }
  }
`

export const LinkStyled = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
`
