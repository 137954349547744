import React from 'react'

import { IIcons } from './types'

export default (props: IIcons) => {
  const { style } = props
  const defaultStyle = { width: 40, height: 40 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
        fill="#FAFAFA"
      />
      <path
        d="M28.3335 12.709H11.6668C10.5162 12.709 9.5835 13.6417 9.5835 14.7923V25.209C9.5835 26.3596 10.5162 27.2923 11.6668 27.2923H28.3335C29.4841 27.2923 30.4168 26.3596 30.4168 25.209V14.7923C30.4168 13.6417 29.4841 12.709 28.3335 12.709Z"
        stroke="#2E2E2E"
        strokeWidth="2"
        strokeLinecap="square"
      />
      <path d="M9.5835 22.084H30.4168" stroke="#2E2E2E" strokeWidth="2" strokeLinecap="square" />
    </svg>
  )
}
