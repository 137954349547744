import { useIntl } from 'react-intl'

import { FormErrorMessages } from '../const/form'

export const getErrorMessageByType = (type: FormErrorMessages) => {
  const { formatMessage } = useIntl()
  switch (type) {
    case FormErrorMessages.REQUIRED:
      return formatMessage({ id: 'form.errorMessage.required' })
  }
}

export const isValidINN = (inn: string): boolean => {
  // Проверка, что ИНН состоит ровно из 12 цифр
  if (inn.length !== 12 || /\D/.test(inn)) {
    return false
  }

  // Преобразуем ИНН в массив чисел
  const digits = inn.split('').map(Number)

  // Вычисляем первую контрольную цифру (11-я цифра)
  const control1 =
    ((7 * digits[0] +
      2 * digits[1] +
      4 * digits[2] +
      10 * digits[3] +
      3 * digits[4] +
      5 * digits[5] +
      9 * digits[6] +
      4 * digits[7] +
      6 * digits[8] +
      8 * digits[9]) %
      11) %
    10

  // Вычисляем вторую контрольную цифру (12-я цифра)
  const control2 =
    ((3 * digits[0] +
      7 * digits[1] +
      2 * digits[2] +
      4 * digits[3] +
      10 * digits[4] +
      3 * digits[5] +
      5 * digits[6] +
      9 * digits[7] +
      4 * digits[8] +
      6 * digits[9] +
      8 * digits[10]) %
      11) %
    10

  // Проверка контрольных цифр
  return digits[10] === control1 && digits[11] === control2
}

export const REGULAR_EXPRESSION_CYRILLIC_NAME = new RegExp(/^[А-ЯЁа-яё-]+( [А-ЯЁа-яё-]+)+$/)
