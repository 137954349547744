import { CountryData } from 'react-phone-input-2'
import getNumWithSpaces from 'prettify-numbers'

import { Delimiters } from '../const/consts'
import { Currencies, DelimitersType } from '../types'

export const getNumWithDelimiter = (value: string | number, delimiter?: DelimitersType) => {
  return delimiter ? getNumWithSpaces(value, ...delimiter) : getNumWithSpaces(value)
}

export const formatNumber = (value: number, fractionDigits?: number) =>
  getNumWithDelimiter(parseFloat(value.toFixed(fractionDigits || 0)), Delimiters.SPACE)

export const formatNumberToMillion = (value: number, fractionDigits?: number) =>
  `${formatNumber(value / 1000000, fractionDigits)}M`

export const formatNumberToThousand = (value: number, fractionDigits?: number) =>
  `${formatNumber(value / 1000, fractionDigits)}К`

export const declOfNum = (value: number, words: string[]) =>
  words[value % 100 > 4 && value % 100 < 20 ? 2 : [2, 0, 1, 1, 1, 2][value % 10 < 5 ? Math.abs(value) % 10 : 5]]

export const cleanObject = (obj: any) => {
  for (const propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined || obj[propName]?.length === 0) {
      delete obj[propName]
    }
  }
  return obj
}

export const omitBy = (obj: any, fieldName: string) => {
  return Object.keys(obj).reduce((acc: any, key) => {
    if (key !== fieldName) {
      acc[key] = obj[key]
    }
    return acc
  }, {})
}

export const omitGroupBy = (obj: any, fieldNames: string[]) => {
  return Object.keys(obj).reduce((acc: any, key) => {
    if (!fieldNames.includes(key)) {
      acc[key] = obj[key]
    }
    return acc
  }, {})
}

export const historyReplaceState = (url: string) => {
  window.history.replaceState(null, '', url)
}

export const getQueryParameter = (name: string) => {
  const match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search)

  return match && decodeURIComponent(match[1].replace(/\+/g, ' '))
}

export const getAmountWithCurrency = (amount: string, currency: Currencies) => {
  const CurrencyNodes: { [key in Currencies]: string } = {
    RUB: '₽',
    EUR: '€',
  }

  return currency === Currencies.RUB ? `${amount} ${CurrencyNodes[currency]}` : `${CurrencyNodes[currency]} ${amount}`
}

export interface IExchangeAmountForEUR {
  extraEuroRate: number
  amount: number
  rate: number | null | undefined
  reverse?: boolean
}

export const exchangeAmountForEUR = (props: IExchangeAmountForEUR) => {
  const { extraEuroRate, amount, rate, reverse = false } = props

  return rate ? (reverse ? amount * (rate + extraEuroRate) : amount / (rate + extraEuroRate)) : amount
}

// Ссылка на ресурс с алгоритмом проверки ИНН: http://u4ilka.kcbux.ru/Script/script-005_INN.html.

export const isValidINN = (inn: string): boolean => {
  if (inn.length !== 12 || /\D/.test(inn)) {
    return false
  }

  const digits = inn.split('').map(Number)

  // Вычисляем первую контрольную цифру (11-я цифра)
  const control1 =
    ((7 * digits[0] +
      2 * digits[1] +
      4 * digits[2] +
      10 * digits[3] +
      3 * digits[4] +
      5 * digits[5] +
      9 * digits[6] +
      4 * digits[7] +
      6 * digits[8] +
      8 * digits[9]) %
      11) %
    10

  // Вычисляем вторую контрольную цифру (12-я цифра)
  const control2 =
    ((3 * digits[0] +
      7 * digits[1] +
      2 * digits[2] +
      4 * digits[3] +
      10 * digits[4] +
      3 * digits[5] +
      5 * digits[6] +
      9 * digits[7] +
      4 * digits[8] +
      6 * digits[9] +
      8 * digits[10]) %
      11) %
    10

  // Проверка контрольных цифр
  return digits[10] === control1 && digits[11] === control2
}

export const isPhoneNumberValid = (phone: string, country: CountryData) => {
  const minPhoneNumberLength = country.format.replace(/[^.]/g, '').length
  const dialCode = country.dialCode

  return !!phone && !!dialCode && phone.length >= minPhoneNumberLength && phone.startsWith(dialCode)
}
