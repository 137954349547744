import { AxiosError, AxiosResponse } from 'axios'
import { AnyAction, Dispatch } from 'redux'

import { API_URL } from '../../const'
import { AdAccountsActionTypes, IAdAccountsState } from '../../store/adaccounts/types'
import { ThunkResult } from '../../store/store'
import { Platforms } from '../../types'
import { customAxios } from '../../utils'
import { IYandexPromoAccountBody } from './types'

export enum AddYandexPromoActionTypes {
  SET_YANDEX_PROMO_PAGE_SUBJECTS = 'SET_YANDEX_PROMO_PAGE_SUBJECTS',
  SET_YANDEX_PROMO_PAGE_LOADING = 'SET_YANDEX_PROMO_PAGE_LOADING',
  SET_YANDEX_PROMO_PAGE_ERROR = 'SET_YANDEX_PROMO_PAGE_ERROR',
  SET_YANDEX_PROMO_PAGE_REDIRECT = 'SET_YANDEX_PROMO_PAGE_REDIRECT',
  REMOVE_YANDEX_PROMO_PAGE_ERROR = 'REMOVE_YANDEX_PROMO_PAGE_ERROR',
}

export function setRedirect(route: string): AnyAction {
  return { type: AddYandexPromoActionTypes.SET_YANDEX_PROMO_PAGE_REDIRECT, data: route }
}

export function setLoading(isLoading: boolean): AnyAction {
  return { type: AddYandexPromoActionTypes.SET_YANDEX_PROMO_PAGE_LOADING, data: isLoading }
}

export function createYandexPromoAdAccount(body: IYandexPromoAccountBody): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/yandex/promo_page_accounts/`, 'POST', body, true)
      .then((response: AxiosResponse<IAdAccountsState>) => {
        dispatch({
          type: AdAccountsActionTypes.SET_AD_ACCOUNT,
          data: response.data,
          network: Platforms.YANDEX_PROMO_PAGE,
        })
        return response
      })
      .catch((error: AxiosError) => {
        dispatch({
          type: AddYandexPromoActionTypes.SET_YANDEX_PROMO_PAGE_ERROR,
          data: error.response?.data,
        })
        dispatch({
          type: AddYandexPromoActionTypes.SET_YANDEX_PROMO_PAGE_LOADING,
          data: false,
        })
        return Promise.reject(error)
      })
  }
}
