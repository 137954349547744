import { AxiosError, AxiosPromise, AxiosResponse } from 'axios'
import { AnyAction, Dispatch } from 'redux'

import { API_URL } from '../../const'
import { customAxios } from '../../utils'
import { ThunkResult } from '../store'
import { IPromocodeState, PromocodeActionTypes } from './types'

export function setPromocode(code: string): AxiosPromise<any> {
  return customAxios(
    `${API_URL}/promocodes/`,
    'PATCH',
    {
      code,
    },
    true
  )
    .then((response: AxiosResponse<any>) => response)
    .catch((error: AxiosError) => Promise.reject(error))
}

export function getUsedPromocode(): ThunkResult<AxiosPromise<any>> {
  return (dispatch: Dispatch<AnyAction>): AxiosPromise<any> => {
    return customAxios(`${API_URL}/promocodes/used/`, 'GET', null, true)
      .then((response: AxiosResponse<IPromocodeState>): Promise<any> | AxiosResponse<IPromocodeState> => {
        if (response.status === 200) {
          dispatch({ type: PromocodeActionTypes.SET_PROMOCODE, data: response.data })
        } else {
          return Promise.reject(response)
        }

        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}
