import React from 'react'

import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = '#B09DE3', fillBg = '#EAE5F8' } = props
  const defaultStyle = { width: 24, height: 24 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.39325 17.4541C3.13521 17.4541 2.88129 17.5188 2.65476 17.6424V17.6424C1.95344 18.025 2.22514 19.0905 3.02401 19.0905H20.976C21.7749 19.0905 22.0466 18.025 21.3452 17.6424V17.6424C21.1187 17.5188 20.8648 17.4541 20.6067 17.4541H3.39325Z"
        fill={fill}
      />
      <path
        d="M7.36353 5.11328H16.6363C17.8789 5.11328 18.8863 6.12064 18.8863 7.36328V14.2724C18.8863 14.4104 18.7743 14.5224 18.6363 14.5224H5.36353C5.22545 14.5224 5.11353 14.4104 5.11353 14.2724V7.36328C5.11353 6.12064 6.12089 5.11328 7.36353 5.11328Z"
        fill={fillBg}
        stroke={fill}
        strokeWidth="1.5"
      />
    </svg>
  )
}
