import { AxiosError, AxiosPromise, AxiosResponse } from 'axios'
import { AnyAction, Dispatch } from 'redux'

import { API_URL } from '../../const'
import { Pages, Platforms } from '../../types'
import { customAxios } from '../../utils'
import { ThunkResult } from '../store'
import {
  AdAccountExtraTypes,
  AdAccountsActionTypes,
  AdAccountsPageType,
  AdAccountTypes,
  IAccountBody,
  IAccountOnboardingBody,
  IAdAccount,
  IAdAccountObject,
  IAdAccountsState,
  IAdAccountUpdateBody,
  IAutoPaymentBody,
  IAvitoRequestBody,
  IIndustry,
  IYandexCredentials,
} from './types'

export function getAdAccounts(
  network: AdAccountExtraTypes,
  page: AdAccountsPageType,
  isAgency = false,
  term?: string
): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    const routes: { [key in AdAccountExtraTypes]: string } = {
      archived_accounts: isAgency ? 'profile_agency/archived' : 'archive_accounts',
      telegram: 'telegram_accounts/accounts',
      avito: 'avito/accounts',
      facebook: isAgency ? 'profile_agency/facebook_accounts' : 'facebook_accounts',
      google: isAgency ? 'profile_agency/google_accounts' : 'google_accounts',
      mytarget: isAgency ? 'profile_agency/mytarget_accounts' : 'mytarget/accounts',
      tiktok: isAgency ? 'profile_agency/tiktok_accounts' : 'tiktok/accounts',
      vk: isAgency ? 'profile_agency/vk_accounts' : 'vk_accounts',
      vkads: 'vkads/accounts',
      vk_adsmarket: isAgency ? 'profile_agency/vk_adsmarket/accounts' : 'vk_adsmarket/accounts',
      yandex_business: isAgency ? 'profile_agency/yandex_business_accounts' : 'yandex_business/accounts',
      yandex: isAgency ? 'profile_agency/yandex_accounts' : 'yandex/accounts',
      yandex_promo_page: isAgency ? 'profile_agency/yandex/promo_page_accounts' : 'yandex/promo_page_accounts',
    }

    let url = `${API_URL}/${routes[network]}/`

    if (page) {
      url = `${url}?page=${page}`
    }

    if (term && network !== 'archived_accounts') {
      url = `${url}&q=${encodeURIComponent(term)}`
    }

    return customAxios(url, 'GET', null, true)
      .then((response: AxiosResponse<IAdAccountObject>) => {
        const hasPagination = network !== 'archived_accounts' && page !== Pages.ALL

        dispatch({
          data: hasPagination ? response.data.results : response.data,
          hasPagination,
          network,
          page,
          type: AdAccountsActionTypes.SET_AD_ACCOUNTS,
        })

        if (hasPagination) {
          dispatch({ type: AdAccountsActionTypes.SET_PAGINATION_OBJECT, data: response.data })
        }

        return response
      })
      .catch((error: AxiosError) => {
        Promise.reject(error)
      })
  }
}

export function updateAdAccountName(id: number, name: string, network: AdAccountTypes): ThunkResult<Promise<any>> {
  const url =
    network === 'vk'
      ? `${API_URL}/vk_account/${id}/rename/`
      : network === 'mytarget'
      ? `${API_URL}/mytarget/account/${id}/`
      : network === 'yandex'
      ? `${API_URL}/yandex/accounts/${id}`
      : network === Platforms.VK_ADS
      ? `${API_URL}/vkads/accounts/${id}/`
      : network === 'yandex_promo_page'
      ? `${API_URL}/yandex/promo_page_accounts/${id}`
      : network === 'vk_adsmarket'
      ? `${API_URL}/vk_adsmarket/accounts/${id}`
      : `${API_URL}/adaccount/${id}`
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(
      url,
      'PATCH',
      {
        name,
      },
      true
    )
      .then((response: AxiosResponse<IAdAccount>) => {
        if (response.status === 200) {
          dispatch({
            data: response.data,
            network,
            type: AdAccountsActionTypes.UPDATE_AD_ACCOUNT,
          })
        }

        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function getIndustries(): AxiosPromise<IIndustry[]> {
  return customAxios(`${API_URL}/industries/`, 'GET', null, true)
    .then((response: AxiosResponse<IIndustry[]>): any => {
      if (response.status === 200) {
        return response.data
      }

      return Promise.reject(response)
    })
    .catch((error: AxiosError) => Promise.reject(error))
}

export function createFBAdAccount(body?: IAccountBody): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/facebook_accounts/`, 'POST', body ? body : null, true)
      .then((response: AxiosResponse<IAdAccount>) => {
        dispatch({ type: AdAccountsActionTypes.SET_AD_ACCOUNT, data: response.data, network: 'facebook' })
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function createGoogleAdAccount(): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/google_accounts/`, 'POST', {}, true)
      .then((response: AxiosResponse<IAdAccountsState>) => {
        dispatch({ type: AdAccountsActionTypes.SET_AD_ACCOUNT, data: response.data, network: 'google' })
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function createAdditionalAdAccount(body: IAccountBody): ThunkResult<Promise<any>> {
  return () => {
    return customAxios(`${API_URL}/additional_accounts_requests/`, 'POST', body, true)
      .then((response: AxiosResponse<IAdAccountsState>) => {
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function createVKAdAccount(body: IAccountBody): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/vk_accounts/`, 'POST', body, true)
      .then((response: AxiosResponse<IAdAccountsState>) => {
        dispatch({ type: AdAccountsActionTypes.SET_AD_ACCOUNT, data: response.data, network: 'vk' })
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function createClientVKAdAccount(body: IAccountBody): AxiosPromise<IAdAccount> {
  return customAxios(`${API_URL}/profile_agency/vk_accounts/`, 'POST', body, true)
    .then((response: AxiosResponse<IAdAccount>) => {
      return response
    })
    .catch((error: AxiosError) => Promise.reject(error))
}

export function createClientMTAdAccount(body: IAccountBody): AxiosPromise<IAdAccount> {
  return customAxios(`${API_URL}/profile_agency/mytarget_accounts/`, 'POST', body, true)
    .then((response: AxiosResponse<IAdAccount>) => {
      return response
    })
    .catch((error: AxiosError) => Promise.reject(error))
}

export function updateAdAccount(
  id: number,
  body: IAdAccountUpdateBody,
  network: AdAccountTypes
): ThunkResult<Promise<any>> {
  const routes = {
    facebook: `adaccount/${id}`,
    google: `google_account/${id}`,
    mytarget: `mytarget/account/${id}/`,
    tiktok: `tiktok/account/${id}`,
    vk: `vk_account/${id}/rename/`,
    vkads: `vkads/accounts/${id}/`,
    vk_adsmarket: `vk_adsmarket/accounts/${id}`,
    yandex: `yandex/accounts/${id}`,
    avito: `avito/accounts/${id}`,
    telegram: `telegram_accounts/accounts/${id}`,
    yandex_promo_page: `yandex/promo_page_accounts/${id}`,
  }

  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/${routes[network]}`, 'PATCH', body, true)
      .then((response: AxiosResponse<IAdAccount>) => {
        if (response.status === 200) {
          dispatch({
            data: response.data,
            network,
            type: AdAccountsActionTypes.UPDATE_AD_ACCOUNT,
          })
        }

        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function createMyTargetAdAccount(body: IAccountBody): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/mytarget/accounts/`, 'POST', body, true)
      .then((response: AxiosResponse<IAdAccountsState>) => {
        dispatch({ type: AdAccountsActionTypes.SET_AD_ACCOUNT, data: response.data, network: 'mytarget' })
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function createTikTokAdAccount(body: IAccountBody): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/tiktok/accounts/`, 'POST', body, true)
      .then((response: AxiosResponse<IAdAccountsState>) => {
        dispatch({ type: AdAccountsActionTypes.SET_AD_ACCOUNT, data: response.data, network: 'tiktok' })
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function createAvitoAdAccount(body: IAvitoRequestBody): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch({ type: AdAccountsActionTypes.SET_ACCOUNTS_LOADING, data: true, network: Platforms.AVITO })

    return customAxios(`${API_URL}/avito/accounts/`, 'POST', body, true)
      .then((response: AxiosResponse<IAdAccountsState>) => {
        dispatch({ type: AdAccountsActionTypes.SET_AD_ACCOUNT, data: response.data, network: Platforms.AVITO })
        return response
      })
      .catch((error: AxiosError) => {
        dispatch({
          type: AdAccountsActionTypes.SET_ACCOUNTS_ERROR,
          data: error.response?.data,
          network: Platforms.AVITO,
        })
        return Promise.reject(error)
      })
  }
}

export function createYandexAdAccount(body: IAccountBody): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/yandex/accounts/`, 'POST', body, true)
      .then((response: AxiosResponse<IAdAccountsState>) => {
        dispatch({ type: AdAccountsActionTypes.SET_AD_ACCOUNT, data: response.data, network: 'yandex' })
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function updateAccountOnboarding(
  accountId: number,
  network: AdAccountTypes,
  body: IAccountOnboardingBody
): ThunkResult<Promise<any>> {
  const networkTypes = {
    facebook: 'facebook_account',
    google: 'google_ads_account',
    mytarget: 'mytarget_account',
    tiktok: 'tiktok_account',
    vk: 'vk_account',
    vkads: 'vkads_account',
    vk_adsmarket: 'vk_adsmarket_account',
    yandex: 'yandex_account',
    yandex_promo_page: 'yandex_promo_page_account',
  }

  const requestBody: IAccountOnboardingBody = {
    ...body,
    [networkTypes[network]]: accountId,
  }

  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/onboarding/setup_hints/account/`, 'POST', requestBody, true)
      .then((response: AxiosResponse<IAccountOnboardingBody>) => {
        dispatch({
          data: { accountId, setupHint: requestBody.setup_hint },
          network,
          type: AdAccountsActionTypes.UPDATE_AD_ACCOUNT_ONBOARDING,
        })
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function setAccountPayment(
  accountId: number,
  network: AdAccountTypes,
  body: IAutoPaymentBody,
  isUpdating?: boolean,
  autopaymentId?: number
): ThunkResult<Promise<any>> {
  const networkTypes = {
    facebook: 'facebook_account',
    google: 'google_ads_account',
    mytarget: 'mytarget_account',
    tiktok: 'tiktok_account',
    vk: 'vk_account',
    vkads: 'vkads_account',
    vk_adsmarket: 'vk_adsmarket_account',
    yandex: 'yandex_account',
    yandex_promo_page: 'yandex_promo_page_account',
  }

  const requestBody: IAutoPaymentBody = {
    ...body,
    [networkTypes[network]]: accountId,
  }

  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(
      `${API_URL}/autopayment/${isUpdating ? autopaymentId : 'create'}/`,
      isUpdating ? 'PATCH' : 'POST',
      requestBody,
      true
    )
      .then((response: AxiosResponse<IAutoPaymentBody>) => {
        dispatch({
          data: { accountId, autopayment: { id: response.data.id, is_enabled: response.data.is_enabled } },
          network,
          type: AdAccountsActionTypes.SET_AD_ACCOUNT_AUTOPAYMENT,
        })
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function getAdAccountAutopayment(autopaymentId: number): AxiosPromise<IIndustry[]> {
  return customAxios(`${API_URL}/autopayment/${autopaymentId}/`, 'GET', null, true)
    .then((response: AxiosResponse<IIndustry[]>): any => {
      if (response.status === 200) {
        return response.data
      }

      return Promise.reject(response)
    })
    .catch((error: AxiosError) => Promise.reject(error))
}

export function getYandexCredentials(accountId: number): AxiosPromise<IYandexCredentials> {
  return customAxios(`${API_URL}/yandex/accounts/${accountId}/credentials/`, 'GET', null, true)
    .then((response: AxiosResponse<IYandexCredentials>): any => {
      return response.data
    })
    .catch((error: AxiosError) => Promise.reject(error))
}

export function setAvitoAccountsLoading(isLoading: boolean): AnyAction {
  return { type: AdAccountsActionTypes.SET_ACCOUNTS_LOADING, data: isLoading, network: Platforms.AVITO }
}
