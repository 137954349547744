import React from 'react'

import { IIcons } from './types'

export default (props: IIcons) => {
  const { style } = props
  const defaultStyle = { width: 40, height: 40 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="1" fill="#FAFAFA" />
      <path d="M14.791 21.0417L17.916 24.1667L25.2077 16.875" stroke="black" strokeWidth="2" strokeLinecap="square" />
      <path
        d="M20.0007 30.4173C25.7536 30.4173 30.4173 25.7536 30.4173 20.0007C30.4173 14.2477 25.7536 9.58398 20.0007 9.58398C14.2477 9.58398 9.58398 14.2477 9.58398 20.0007C9.58398 25.7536 14.2477 30.4173 20.0007 30.4173Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="square"
      />
    </svg>
  )
}
