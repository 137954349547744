export const enum INDIVIDUAL_INFO_FORM_FIELDS {
  NAME = 'name',
  INN = 'inn',
  REQUEST_ERROR = 'request_error',
}

export interface IndividualInfoFormData {
  [INDIVIDUAL_INFO_FORM_FIELDS.NAME]: string
  [INDIVIDUAL_INFO_FORM_FIELDS.INN]: string
  [INDIVIDUAL_INFO_FORM_FIELDS.REQUEST_ERROR]: string
}
