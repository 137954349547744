import React from 'react'

import { IIcons } from './types'

export default (props: IIcons) => {
  const { style } = props
  const defaultStyle = { width: 40, height: 40 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28.3235 38.1819C33.7423 38.1819 38.135 33.7836 38.135 28.3581C38.135 22.9325 33.7423 18.5343 28.3235 18.5343C22.9047 18.5343 18.512 22.9325 18.512 28.3581C18.512 33.7836 22.9047 38.1819 28.3235 38.1819Z"
        fill="#97CF26"
      />
      <path
        d="M9.88127 32.931C12.4036 32.931 14.4483 30.8837 14.4483 28.3583C14.4483 25.8328 12.4036 23.7855 9.88127 23.7855C7.35895 23.7855 5.31421 25.8328 5.31421 28.3583C5.31421 30.8837 7.35895 32.931 9.88127 32.931Z"
        fill="#A169F7"
      />
      <path
        d="M28.3233 16.2125C31.8111 16.2125 34.6385 13.3815 34.6385 9.88935C34.6385 6.39718 31.8111 3.56622 28.3233 3.56622C24.8355 3.56622 22.0081 6.39718 22.0081 9.88935C22.0081 13.3815 24.8355 16.2125 28.3233 16.2125Z"
        fill="#FF6163"
      />
      <path
        d="M9.88149 17.9651C14.3348 17.9651 17.9449 14.3505 17.9449 9.89165C17.9449 5.43279 14.3348 1.81818 9.88149 1.81818C5.42821 1.81818 1.81812 5.43279 1.81812 9.89165C1.81812 14.3505 5.42821 17.9651 9.88149 17.9651Z"
        fill="#00AAFF"
      />
    </svg>
  )
}
