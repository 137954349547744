import styled from 'styled-components'

import { Black, greyClrLoader } from '../../const/colors'

export const LoaderBlock = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 10000;

  background: ${greyClrLoader};
`

export const LoaderIconWrapper = styled.figure`
  position: absolute;

  top: 50%;
  left: 50%;

  width: 10%;
  margin: 0;
  height: auto;

  transform: translate(-50%, -50%);

  & svg {
    width: 100%;
    height: auto;
    fill: ${Black};
  }
`
