export enum OnboardingActionTypes {
  SET_ACCOUNTS_ONBOARDING = 'SET_ACCOUNTS_ONBOARDING',
}

export interface IOnboardingObject {
  account_type: 'facebook' | 'tiktok' | 'mytarget' | 'google' | 'vk' | 'yandex' | 'vk_adsmarket'
  description: string
  slug: string
  title: string
  urls: {
    title: string
    url: string
  }
}

export interface IOnboardingState {
  readonly telegram: IOnboardingObject[]
  readonly yandex_promo_page: IOnboardingObject[]
  readonly avito: IOnboardingObject[]
  readonly facebook: IOnboardingObject[]
  readonly google: IOnboardingObject[]
  readonly mytarget: IOnboardingObject[]
  readonly vk: IOnboardingObject[]
  readonly tiktok: IOnboardingObject[]
  readonly yandex_business: IOnboardingObject[]
  readonly profi: IOnboardingObject[]
  readonly yandex: IOnboardingObject[]
  readonly vkads: IOnboardingObject[]
  readonly vk_adsmarket: IOnboardingObject[]
}
