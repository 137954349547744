import React from 'react'

import { IIcons } from './types'

export default (props: IIcons) => {
  const { style } = props
  const defaultStyle = { width: 11, height: 11 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.58052 9.36509C1.32283 9.62503 1.07621 9.87419 0.829599 10.1228C0.803581 10.1491 0.781438 10.1834 0.749885 10.1989C0.683179 10.2316 0.596823 10.2908 0.545894 10.2706C0.496073 10.251 0.454278 10.1458 0.453725 10.0779C0.447636 9.25242 0.450127 8.42662 0.45068 7.60082C0.45068 7.58781 0.457877 7.5748 0.465627 7.54767C0.609278 7.54767 0.755697 7.54767 0.901839 7.54767C1.57526 7.54795 2.24867 7.54823 2.92209 7.54823C2.94507 7.54823 2.97081 7.54186 2.99073 7.54961C3.0555 7.57536 3.15099 7.59169 3.17203 7.6393C3.19279 7.68664 3.15625 7.78436 3.11335 7.8306C2.91185 8.04625 2.70094 8.25305 2.49031 8.45956C2.45128 8.49777 2.39786 8.52157 2.34721 8.55479C3.09785 9.29394 3.94564 9.74518 4.94927 9.84706C6.77743 10.0325 8.20896 9.34294 9.22199 7.81316C9.63938 7.1828 9.8467 6.47356 9.87963 5.71586C9.88323 5.63281 9.88351 5.54976 9.89153 5.46699C9.92336 5.14392 10.1592 4.93463 10.475 4.94626C10.7689 4.95706 11.0034 5.20344 11.0003 5.51488C10.9909 6.44864 10.7778 7.33396 10.315 8.14729C9.49933 9.5813 8.29227 10.5195 6.67446 10.8633C4.78928 11.2642 3.12719 10.7803 1.70452 9.47859C1.66743 9.44454 1.63034 9.41049 1.58052 9.36509Z" />
      <path d="M9.38563 1.58436C9.62505 1.34462 9.86779 1.0999 10.1125 0.856839C10.1645 0.805071 10.2174 0.751365 10.2783 0.711778C10.3793 0.646445 10.4546 0.678281 10.4872 0.793444C10.4983 0.832755 10.5044 0.87511 10.5044 0.916082C10.5055 1.72333 10.5052 2.5303 10.505 3.33755C10.505 3.3597 10.5011 3.38185 10.4983 3.41368C10.4488 3.41645 10.4042 3.42088 10.3596 3.42088C9.58492 3.42143 8.8102 3.42392 8.03547 3.41756C7.94801 3.41673 7.82263 3.39098 7.78222 3.33118C7.7144 3.23125 7.82207 3.1482 7.89238 3.07733C8.11934 2.84811 8.34852 2.6211 8.59375 2.37666C8.44429 2.24959 8.30783 2.12059 8.15864 2.00875C5.54662 0.0465451 1.83161 1.46338 1.19473 4.66746C1.1366 4.95953 1.13826 5.26294 1.10505 5.56026C1.06215 5.94755 0.659424 6.16874 0.312059 5.99516C0.0978271 5.88803 -0.00402988 5.70393 0.000121904 5.46669C0.0258629 4.06978 0.4928 2.84146 1.43276 1.80555C2.31294 0.835523 3.4079 0.237838 4.70575 0.0592796C6.43759 -0.179075 7.95908 0.302617 9.25527 1.48165C9.28599 1.50961 9.31533 1.53896 9.34605 1.56692C9.35242 1.57246 9.36211 1.57412 9.38563 1.58436Z" />
    </svg>
  )
}
