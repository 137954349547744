import * as Sentry from '@sentry/browser'
import axios from 'axios'
import jwtDecode from 'jwt-decode'
import Cookies from 'universal-cookie'

import { API_URL, SENTRY_MESSAGE } from '../const'
import { IDecodedJWT } from '../types'
import { jwt } from './index'

const cookies = new Cookies()

export function get(): any {
  let expireAccess = 0
  let expireRef = 0
  const accessToken = cookies.get('access')
  const refToken = cookies.get('refresh')

  if (accessToken) {
    const decodedAcc: IDecodedJWT = jwtDecode(accessToken)
    expireAccess = decodedAcc.exp * 1000
  }

  if (refToken) {
    const decodedRef: IDecodedJWT = jwtDecode(refToken)
    expireRef = decodedRef.exp * 1000
  }

  return {
    accessToken,
    expireAccess,
    expireRef,
    refToken,
  }
}

export function clear(): void {
  cookies.remove('access', { path: '/' })
  cookies.remove('refresh', { path: '/' })
}

export function set(access: string, refresh?: string): void {
  const decodedAcc: IDecodedJWT = jwtDecode(access)
  const expireAccess = decodedAcc.exp * 1000

  if (new Date(expireAccess) < new Date()) {
    Sentry.withScope(() => {
      Sentry.setExtra('response', {
        currentDate: new Date(),
        expireAccess,
        ...decodedAcc,
      })

      Sentry.captureMessage(SENTRY_MESSAGE.ERROR_EXPIRED_TOKEN_DATE, Sentry.Severity.Info)
    })
  }

  cookies.set('access', access, {
    expires: new Date(expireAccess),
    path: '/',
    sameSite: 'none',
    secure: true,
  })

  if (refresh) {
    const decodedRef: IDecodedJWT = jwtDecode(refresh)
    const expireRef = decodedRef.exp * 1000

    cookies.set('refresh', refresh, {
      expires: new Date(expireRef),
      path: '/',
      sameSite: 'none',
      secure: true,
    })
  }
}

export function refreshToken() {
  return axios({
    data: {
      refresh: jwt.get().refToken,
    },
    headers: {
      'Content-type': 'application/json',
    },
    method: 'POST',
    url: `${API_URL}/token-refresh/`,
  })
    .then((resp: any) => {
      if (resp.status === 200) {
        jwt.set(resp.data.access, resp.data.refresh)
      } else {
        jwt.clear()
        document.location.reload()

        return Promise.reject()
      }

      return resp
    })
    .catch((e) => {
      jwt.clear()
      document.location.reload()
      return Promise.reject(e)
    })
}
