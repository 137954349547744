import { createUseStyles } from 'react-jss'

import { TextGreyLighten25 } from '@/const/colors'
import { FontInteractive } from '@/const/fontStyles'

export default createUseStyles(
  {
    linkWrapper: {
      ...FontInteractive,
      letterSpacing: '-0.2px',
      color: TextGreyLighten25,
      textDecoration: 'none',
      flexShrink: 0,
      whiteSpace: 'nowrap',

      '& > span': {
        fontWeight: 500,
      },
    },
  },
  { name: 'Cashback' }
)
