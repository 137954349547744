import React from 'react'

import { SlateGreyLighten13 } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = SlateGreyLighten13, fillBg = 'none' } = props
  const defaultStyle = { width: 22, height: 22 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 22 22" fill={fillBg} xmlns="http://www.w3.org/2000/svg">
      <path d="M5.27344 4H16.7281" stroke={fill} strokeWidth="1.5" strokeLinecap="round" />
      <path
        d="M7.18359 4V6.18033C7.18359 7.02125 7.53653 7.82357 8.15642 8.3918L13.8472 13.6084C14.4671 14.1766 14.82 14.9789 14.82 15.8198V18.0002"
        stroke={fill}
        strokeWidth="1.5"
      />
      <path
        d="M14.8203 4V6.18033C14.8203 7.02125 14.4674 7.82357 13.8475 8.3918L8.15669 13.6084C7.5368 14.1766 7.18386 14.9789 7.18386 15.8198V18.0002"
        stroke={fill}
        strokeWidth="1.5"
      />
      <path d="M5.27344 18H16.7281" stroke={fill} strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  )
}
