import React from 'react'

import { BackgroundWhite } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = BackgroundWhite } = props
  const defaultStyle = { width: 40, height: 40 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20ZM20.7167 14.7649C18.7715 15.574 14.8836 17.2487 9.05319 19.7889C8.10643 20.1654 7.61047 20.5337 7.56532 20.8938C7.48902 21.5025 8.25123 21.7422 9.28917 22.0685C9.43035 22.1129 9.57663 22.1589 9.7266 22.2077C10.7478 22.5396 12.1214 22.9279 12.8355 22.9434C13.4833 22.9574 14.2063 22.6903 15.0044 22.1422C20.452 18.465 23.264 16.6063 23.4406 16.5663C23.5651 16.538 23.7377 16.5025 23.8546 16.6064C23.9716 16.7104 23.9601 16.9072 23.9477 16.96C23.8722 17.2819 20.8802 20.0635 19.3319 21.503C18.8492 21.9517 18.5068 22.27 18.4368 22.3427C18.28 22.5056 18.1202 22.6596 17.9667 22.8077C17.018 23.7222 16.3066 24.408 18.0061 25.528C18.8228 26.0662 19.4763 26.5112 20.1283 26.9552C20.8403 27.4401 21.5505 27.9238 22.4694 28.5261C22.7035 28.6795 22.9271 28.8389 23.1449 28.9942C23.9735 29.5849 24.718 30.1157 25.6377 30.031C26.1721 29.9818 26.7241 29.4793 27.0045 27.9806C27.6671 24.4386 28.9694 16.7641 29.2704 13.6016C29.2968 13.3246 29.2636 12.97 29.237 12.8143C29.2103 12.6586 29.1546 12.4369 28.9523 12.2727C28.7127 12.0782 28.3428 12.0372 28.1773 12.0401C27.4251 12.0534 26.271 12.4547 20.7167 14.7649Z"
        fill={fill}
      />
    </svg>
  )
}
