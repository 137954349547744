import React from 'react'

import { GreenDarken9, GreenLighten41 } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fillBg = GreenLighten41, fill = GreenDarken9 } = props
  const defaultStyle = { width: 24, height: 24 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="svg-plus-icon"
        d="M0 6C0 2.68629 2.68629 0 6 0H18C21.3137 0 24 2.68629 24 6V18C24 21.3137 21.3137 24 18 24H6C2.68629 24 0 21.3137 0 18V6Z"
        fill={fillBg}
      />
      <path
        d="M12.8281 11.1641H15.7969V12.8984H12.8281V16.2812H10.9844V12.8984H8V11.1641H10.9844V8H12.8281V11.1641Z"
        fill={fill}
      />
    </svg>
  )
}
