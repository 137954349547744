import React from 'react'

import { BackgroundWhite } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = BackgroundWhite } = props
  const defaultStyle = { width: 40, height: 40 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM22.8332 30.0608H26.5V9H21.1668C15.8034 9 12.985 11.7573 12.985 15.8183C12.985 19.0605 14.5305 20.9695 17.2878 22.9396L12.5 30.0608H16.4697L21.8029 22.0912L19.9544 20.8486C17.7123 19.3332 16.6215 18.151 16.6215 15.6061C16.6215 13.3633 18.1973 11.8486 21.197 11.8486H22.8332V30.0608Z"
        fill={fill}
      />
    </svg>
  )
}
