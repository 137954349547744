import React from 'react'

import { GreyBase } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = GreyBase } = props
  const defaultStyle = { width: 18, height: 18 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 9.00003C2 9.00003 4.54545 3.90912 9 3.90912C13.4545 3.90912 16 9.00003 16 9.00003C16 9.00003 13.4545 14.0909 9 14.0909C4.54545 14.0909 2 9.00003 2 9.00003Z"
        stroke={fill}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.99991 10.9091C10.0543 10.9091 10.909 10.0544 10.909 9.00003C10.909 7.94567 10.0543 7.09094 8.99991 7.09094C7.94555 7.09094 7.09082 7.94567 7.09082 9.00003C7.09082 10.0544 7.94555 10.9091 8.99991 10.9091Z"
        stroke={fill}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
