import { createSelector } from 'reselect'

import { getApp, getBrowser, getProfile } from '@/common/selectors'

export default createSelector([getApp, getBrowser, getProfile], (app, browser, profile) => ({
  isDesktop: browser.greaterThan.desktop,
  isMenuOpened: app.isMenuOpened,
  isProfileClient: profile.is_profile_client,
  inn: profile.inn,
  name: profile.name,
  profile_type: profile.profile_type,
}))
