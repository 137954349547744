import React from 'react'

import { GreyLighten26 } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = GreyLighten26 } = props
  const defaultStyle = { width: 13, height: 7 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 1L6.5 6L1 1" stroke={fill} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
