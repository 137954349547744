import React, { FC } from 'react'

import LogoIcon from '../Icons/LogoIcon'
import { StyledLogo } from './styled'

export interface ILogoProps {
  /** Color for fill SVG: HEX, RGBA, RGB, HSL or name */
  color?: string
  fill?: string
  fillBg?: string
}

/**
 * Logo SVG in `<figure>` tag. Size depends of parent block.
 */
const Logo: FC<ILogoProps> = (props: ILogoProps = { color: '#000' }) => (
  <StyledLogo {...props}>
    <LogoIcon fill={props.fill} fillBg={props.fillBg} />
  </StyledLogo>
)

Logo.defaultProps = {
  color: '#000',
}

export default Logo
