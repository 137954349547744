import React from 'react'

import { BackgroundWhite } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = BackgroundWhite } = props
  const defaultStyle = { width: 40, height: 40 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3313_438408)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20ZM32 20C32 26.6274 26.6274 32 20 32C13.3726 32 8 26.6274 8 20C8 13.3726 13.3726 8 20 8C21.8641 8 23.629 8.42506 25.203 9.18355C25.4542 8.94666 25.7741 8.64538 26.1043 8.3344L26.1051 8.33361C26.9246 7.56174 27.8072 6.7304 27.8619 6.6757C27.964 6.5736 28.1172 6.44597 28.3724 6.39492C28.6277 6.34387 28.9595 6.31834 29.419 6.52254C29.8785 6.72675 30.1337 7.41594 30.1337 7.74777V9.8664H32.1758C32.2183 9.84938 32.4004 9.85108 32.7884 9.99403C33.2733 10.1727 33.5031 10.7087 33.5541 11.1171C33.6023 11.5026 33.4232 11.7971 33.3385 11.9363L33.3244 11.9595C33.27 12.0501 31.6959 13.6991 30.7519 14.6652C31.5508 16.2722 32 18.0836 32 20ZM28.9493 15.5331C29.6217 16.8775 30 18.3946 30 20C30 25.5228 25.5228 30 20 30C14.4772 30 10 25.5228 10 20C10 14.4772 14.4772 10 20 10C21.5953 10 23.1034 10.3736 24.4415 11.038V12.7763L21.1487 16.2776C20.792 16.1751 20.4152 16.1202 20.0256 16.1202C17.7841 16.1202 15.967 17.9372 15.967 20.1787C15.967 22.4202 17.7841 24.2373 20.0256 24.2373C22.2671 24.2373 24.0842 22.4202 24.0842 20.1787C24.0842 19.6074 23.9661 19.0637 23.7531 18.5706L26.6622 15.5331H28.9493Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_3313_438408">
          <rect width="40" height="40" rx="20" fill={fill} />
        </clipPath>
      </defs>
    </svg>
  )
}
