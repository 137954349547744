import React from 'react'

import { RedBase } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = RedBase } = props
  const defaultStyle = { width: 18, height: 15 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.68615 0.851973L1.12715 12.777C1.00151 13.0054 0.937557 13.2625 0.941585 13.5232C0.945614 13.7838 1.01749 14.0389 1.15012 14.2632C1.28275 14.4876 1.47157 14.6736 1.69796 14.8028C1.92435 14.932 2.18049 14.9999 2.44115 15H15.5601C15.8208 14.9999 16.077 14.932 16.3033 14.8028C16.5297 14.6736 16.7185 14.4876 16.8512 14.2632C16.9838 14.0389 17.0557 13.7838 17.0597 13.5232C17.0637 13.2625 16.9998 13.0054 16.8742 12.777L10.3141 0.851973C10.1847 0.616792 9.99443 0.420673 9.7633 0.284084C9.53217 0.147494 9.26862 0.0754395 9.00015 0.0754395C8.73168 0.0754395 8.46813 0.147494 8.237 0.284084C8.00587 0.420673 7.81564 0.616792 7.68615 0.851973ZM9.00015 4.74997C9.19906 4.74997 9.38983 4.82899 9.53048 4.96964C9.67113 5.11029 9.75015 5.30106 9.75015 5.49997V9.49997C9.75015 9.69889 9.67113 9.88965 9.53048 10.0303C9.38983 10.171 9.19906 10.25 9.00015 10.25C8.80124 10.25 8.61047 10.171 8.46982 10.0303C8.32917 9.88965 8.25015 9.69889 8.25015 9.49997V5.49997C8.25015 5.30106 8.32917 5.11029 8.46982 4.96964C8.61047 4.82899 8.80124 4.74997 9.00015 4.74997ZM9.75015 11.75C9.75015 11.9489 9.67113 12.1396 9.53048 12.2803C9.38983 12.421 9.19906 12.5 9.00015 12.5C8.80124 12.5 8.61047 12.421 8.46982 12.2803C8.32917 12.1396 8.25015 11.9489 8.25015 11.75C8.25015 11.5511 8.32917 11.3603 8.46982 11.2196C8.61047 11.079 8.80124 11 9.00015 11C9.19906 11 9.38983 11.079 9.53048 11.2196C9.67113 11.3603 9.75015 11.5511 9.75015 11.75Z"
        fill={fill}
      />
    </svg>
  )
}
