import React from 'react'

import { IIcons } from './types'

export default (props: IIcons) => {
  const { style } = props
  const defaultStyle = { width: 23, height: 22 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5935_22288)">
        <path d="M22.8333 -0.09375H0.75V22.0613H22.8333V-0.09375Z" fill="#19AA1E" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.75 -0.09375H22.8333V7.17587L0.75 3.71414V-0.09375Z"
          fill="#FFB919"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.75 19.6375L22.8333 16.1758V22.0607H0.75V19.6375Z"
          fill="#82D714"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.75 3.26367L8.44465 4.47527C9.4108 3.85217 10.5495 3.5406 11.7917 3.5406C13.4824 3.5406 15.0351 4.19834 16.1393 5.30609C16.2773 5.44455 16.4154 5.58302 16.5534 5.75612L22.8333 6.7254V7.59083L17.1745 6.69076C17.5885 7.48697 17.8301 8.38701 17.8301 9.32168C17.8301 10.4987 17.554 11.6757 17.0019 12.8873V12.9219L16.7604 13.4065H16.2083C15.0696 13.4065 14.3105 13.7527 13.8275 14.3412C13.4479 14.8258 13.2064 15.4489 13.1373 16.1413V16.1759V16.2798V16.3144V16.4182C13.1029 16.7298 13.1029 17.0067 13.0683 17.2144L22.8333 15.7259V16.5913L0.75 20.053V19.1876L10.515 17.6644V17.4567V17.3875V17.3529V17.3182C10.515 17.0759 10.4805 16.7644 10.446 16.4182V16.349V16.2105C10.377 15.5182 10.1699 14.8604 9.75584 14.3758C9.27277 13.7527 8.51365 13.4065 7.4095 13.4065H7.37498H6.8229L6.58138 12.9219C6.0293 11.7103 5.75326 10.5333 5.75326 9.32168C5.75326 7.79851 6.37434 6.34459 7.44401 5.27148C7.4785 5.23684 7.51301 5.20223 7.54753 5.16763L0.75 4.16371V3.26367Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.7923 4.40625C14.8633 4.40625 16.9681 6.79484 16.9681 9.35651C16.9681 10.395 16.7611 11.4681 16.209 12.5759C13.1725 12.5759 12.4134 14.7568 12.2754 16.1415V16.2453C12.2409 16.73 12.2064 17.1108 12.2064 17.4223L11.3782 17.5608V17.5262C11.3782 17.2146 11.3438 16.7646 11.2748 16.2107V16.1761C11.1367 14.7914 10.4121 12.5759 7.34115 12.5759C6.82358 11.4681 6.58203 10.395 6.58203 9.35651C6.61655 6.79484 8.72137 4.40625 11.7923 4.40625Z"
          fill="#0073FA"
        />
      </g>
      <defs>
        <clipPath id="clip0_5935_22288">
          <rect x="0.75" width="22" height="22" rx="11" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
