import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import selector from './selector'
import useStyles from './styles'

import { getNumWithDelimiter } from '@/common/utils'
import { Delimiters, LINK_CASHBACK_PERSONAL } from '@/const'

const Cashback = () => {
  const classes = useStyles()
  const { cashback, isCashbackEnabled } = useSelector(selector)

  if (!cashback) {
    return null
  }

  if (!isCashbackEnabled) {
    return (
      <a className={classes.linkWrapper} href={LINK_CASHBACK_PERSONAL} rel="noopener noreferrer" target="_blank">
        <FormattedMessage id="app.cashback.link" />
      </a>
    )
  }

  return (
    <div className={classes.linkWrapper}>
      <FormattedMessage id="app.cashbackPerMonth.link" values={{ month: cashback.month }} />
      &nbsp;
      <span>{getNumWithDelimiter(cashback.amount, Delimiters.SPACE)}&nbsp;&#8381;</span>
    </div>
  )
}

export default Cashback
