import React, { ChangeEvent, memo, useState } from 'react'
import { DefaultTheme } from 'react-jss'
import cn from 'classnames'

import { PurpleBase } from '../../const/colors'
import CheckIcon from '../Icons/CheckIcon'
import useStyles from './styles'
import { CHECKBOX_POSITION, CHECKBOX_SIZE, ICheckboxSMBProps } from './types'

const Checkbox = memo((props: ICheckboxSMBProps & { theme?: DefaultTheme }) => {
  const {
    name,
    onChange,
    description,
    children,
    value,
    checked,
    disabled,
    fullWidth,
    className,
    position = CHECKBOX_POSITION.left,
    labelWidth,
    hasError = false,
    colorBase = PurpleBase,
    withTooltip,
    size = CHECKBOX_SIZE.size18,
  } = props

  const classes = useStyles({ labelWidth, fullWidth, colorBase, hasError })

  const [locChecked, setLocChecked] = useState(checked)

  const checkboxHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (disabled) {
      return
    }

    if (checked === undefined) {
      setLocChecked(!locChecked)
    }

    if (event) {
      onChange?.(event)
    }
  }

  return (
    <label
      className={cn(
        classes.root,
        classes[position],
        classes[size],
        {
          [classes.fullWidth]: fullWidth,
          [classes.disabledRoot]: disabled,
          [classes.tooltipHover]: !!withTooltip,
        },
        className
      )}
    >
      {(description || children) && (
        <div className={classes.description}>
          {description}
          {!description && children && <>{children}</>}
        </div>
      )}
      <input
        type="checkbox"
        onChange={checkboxHandler}
        checked={checked === undefined ? locChecked : checked}
        value={value}
        disabled={disabled}
        className={classes.input}
        name={name}
      />
      <div className={classes.inputWrapper}>
        {!!withTooltip && <div className={classes.tooltip}>{withTooltip}</div>}
        <div
          className={cn(classes.checkbox, {
            [classes.checked]: checked,
          })}
        >
          <div className={classes.checkedIcon}>
            <CheckIcon />
          </div>
        </div>
      </div>
    </label>
  )
})

export { Checkbox }
