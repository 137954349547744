import React from 'react'

import { SlateGreyLighten22 } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = SlateGreyLighten22 } = props
  const defaultStyle = { width: 22, height: 22 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.575 17.5025C17.8517 16.2269 18.7266 14.5499 18.9917 12.6766C19.0454 12.2969 18.843 11.9213 18.2815 11.9213H10.54C10.2929 11.9213 10.0906 11.7192 10.0906 11.4723V3.47486C10.0775 3.08679 9.79729 2.96918 9.48373 3.00658C2.56283 3.83336 -0.572739 12.429 4.50486 17.5025C7.83757 20.8325 13.2423 20.8325 16.575 17.5025Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9092 9.56211C11.9092 6.86431 11.9092 2.74144 11.9092 2.74144C11.9092 2.25423 12.2481 2.02091 12.7523 2.09765C14.4882 2.36187 16.1583 3.16179 17.495 4.49741C18.8233 5.82461 19.5895 7.39285 19.873 9C20.0166 9.81472 19.873 10.0786 18.9998 10.0786C18.9998 10.0786 15.0382 10.0777 12.4998 10.0786C12.0611 10.0788 11.9092 9.92861 11.9092 9.56211Z"
        fill={fill}
      />
    </svg>
  )
}
