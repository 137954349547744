import { Reducer } from 'redux'

import { CampaignFormsActionTypes, CampaignReportActionTypes, UpdateFilterStepsTypes } from './actions'
import { reducerName } from './CampaignReport/modals/actions'
import {
  AdsData,
  AdsDataList,
  AdsetsData,
  AdsetsDataList,
  AdsResult,
  AsideValues,
  CampaignsData,
  CampaignsDataList,
  ExtraFilterType,
  FilterSubtype,
  FilterType,
  ICounter,
  IFilterSteps,
  MassEditingTypes,
} from './types'

const adsInitialList: AdsDataList = {
  count: 0,
  current: 0,
  next: null,
  page_count: 0,
  previous: null,
  loading: false,
  results: [],
  allResults: [],
  request_error: false,
}

const adsetsInitialList: AdsetsDataList = {
  count: 0,
  current: 0,
  next: null,
  page_count: 0,
  previous: null,
  loading: false,
  results: [],
  allResults: [],
  request_error: false,
}

const campaignsInitialList: CampaignsDataList = {
  count: 0,
  current: 0,
  next: null,
  page_count: 0,
  previous: null,
  loading: false,
  results: [],
  allResults: [],
  request_error: false,
}

export const initialState: IFilterSteps = {
  filters: [
    {
      index: 1,
      type: FilterType.COMPANY,
      subtype: [FilterSubtype.STATUS],
      statuses: [],
      names: [],
    },
    {
      index: 2,
      type: FilterType.GROUP,
      subtype: [FilterSubtype.STATUS],
      statuses: [],
      names: [],
    },
    { index: 3, type: FilterType.ADS, subtype: [FilterSubtype.STATUS], statuses: [], names: [] },
  ],
  tabs: [
    {
      type: FilterType.ADS,
      selectedCount: null,
      count: 0,
      active: true,
    },
    {
      type: FilterType.COMPANY,
      selectedCount: 0,
      count: 0,
      active: false,
    },
    {
      type: FilterType.GROUP,
      selectedCount: null,
      count: 0,
      active: false,
    },
    {
      type: FilterType.GROUP,
      selectedCount: null,
      count: null,
      active: false,
    },
  ],
  modals: null,
  adsData: adsInitialList,
  campaignData: campaignsInitialList,
  adsetData: adsetsInitialList,
  undistributedData: adsInitialList,
  tableDataCount: {
    adsData: 0,
    campaignData: 0,
    adsetData: 0,
    undistributedData: 0,
    loading: false,
  },
  campaignSelectedList: campaignsInitialList,
  adsetSelectedList: adsetsInitialList,
  accountSelectedList: adsInitialList,
  hasActiveAccounts: {
    loading: false,
    hasActive: false,
  },
  settings: {
    ads: [],
    adsets: [],
    campaigns: [],
  },
  selectedUndistributedIds: [],
  selectedAdIds: [],
  selectedAdsetIds: [],
  selectedCampaignIds: [],
  counter: {
    count_ads: 0,
    count_group: 0,
    count_company: 0,
    count_undistributed: 0,
    count_group_in_company: 0,
    count_ads_in_company: 0,
    count_ads_in_group: 0,
    count_all_ads: 0,
    count_all_groups: 0,
    count_all_companies: 0,
    count_all_undistributed: 0,
    statuses_ads: [],
    statuses_group: [],
    statuses_company: [],
    statuses_undistributed: [],
    selected_ads: [],
    selected_adsets: [],
    selected_campaigns: [],
    selected_undistributed: [],
  },
  selectAllRowsType: {
    [FilterType.COMPANY]: false,
    [FilterType.GROUP]: false,
    [FilterType.ADS]: false,
    [ExtraFilterType.UNDISTRIBUTED]: false,
  },
  isUndistributedError: false,
  isAdError: false,
  isAdsetError: false,
  isCampaignError: false,
  isUndistributedLoading: false,
  isAdLoading: false,
  isAdsetLoading: false,
  isCampaignLoading: false,
  isForceUpdateLoading: false,
  aside: {
    activeNavItem: MassEditingTypes.limit,
    navErrors: {
      [MassEditingTypes.limit]: {
        isError: false,
        errorMessage: '',
      },
      [MassEditingTypes.schedule]: {
        isError: false,
        errorMessage: '',
      },
      [MassEditingTypes.stop]: {
        isError: false,
        errorMessage: '',
      },
    },
    navDisabled: {
      [MassEditingTypes.limit]: false,
      [MassEditingTypes.schedule]: false,
      [MassEditingTypes.stop]: false,
    },
    isOpened: false,
    isLoading: false,
    asideLimitDraft: null,
    asideStopDateDraft: null,
    asideValues: [],
    allAsideValues: [],
    isAll: false,
    isSetStopDateRule: false,
    isSetLimitRule: false,
  },
}

const setCounter = (state: IFilterSteps) => {
  const {
    counter,
    adsData,
    adsetData,
    campaignData,
    undistributedData,
    tableDataCount,
    selectedUndistributedIds,
    selectedAdIds,
    selectedAdsetIds,
    selectedCampaignIds,
  } = state

  const result: ICounter = Object.assign({}, counter)

  const adsDataAllResults = adsData.allResults.flatMap((r) => r.results)
  const adsetDataAllResults = adsetData.allResults.flatMap((r) => r.results)
  const campaignDataAllResults = campaignData.allResults.flatMap((r) => r.results)
  const undistributedDataAllResults = undistributedData.allResults.flatMap((r) => r.results)

  const selected_ads = adsDataAllResults.filter((item) => selectedAdIds.includes(item.id.toString())) as AdsResult[]
  const selected_adsets = adsetDataAllResults.filter((item) => selectedAdsetIds.includes(item.id.toString()))
  const selected_campaigns = campaignDataAllResults.filter((item) => selectedCampaignIds.includes(item.id.toString()))
  const selected_undistributed = undistributedDataAllResults.filter((item) =>
    selectedUndistributedIds.includes(item.id.toString())
  )

  result.selected_ads = selected_ads
  result.selected_adsets = selected_adsets
  result.selected_campaigns = selected_campaigns
  result.selected_undistributed = selected_undistributed

  result.statuses_ads = selected_ads.map((item) => item.status)
  result.statuses_undistributed = selected_undistributed.map((item) => item.status)
  result.statuses_group = selected_adsets.map((item) => item.status)
  result.statuses_company = selected_campaigns.map((item) => item.status)

  result.count_ads = adsDataAllResults.length
  result.count_group = adsetDataAllResults.length
  result.count_company = campaignDataAllResults.length
  result.count_undistributed = undistributedDataAllResults.length

  result.count_all_ads = tableDataCount.adsData
  result.count_all_groups = tableDataCount.adsetData
  result.count_all_companies = tableDataCount.campaignData
  result.count_all_undistributed = tableDataCount.undistributedData

  result.count_group_in_company =
    selectedCampaignIds.length > 0
      ? selected_campaigns.reduce((acc, item) => acc + item.adsets_count, 0)
      : campaignDataAllResults
          .filter((item) => item?.adsets_count > 0)
          .reduce((acc, item) => acc + item.adsets_count, 0)

  result.count_ads_in_company =
    selectedCampaignIds.length > 0
      ? selected_campaigns.reduce((acc, item) => acc + item.ads_count, 0)
      : campaignDataAllResults.filter((item) => item.ads_count > 0).reduce((acc, item) => acc + item.ads_count, 0)

  result.count_ads_in_group =
    selectedAdsetIds.length > 0
      ? selected_adsets.reduce((acc, item) => acc + item.ads_count, 0)
      : campaignDataAllResults.filter((item) => item.ads_count > 0).reduce((acc, item) => acc + item.ads_count, 0)

  return result
}

const campaignEditorReducer: Reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case UpdateFilterStepsTypes.CAMPAIGN_REPORT_UPDATE_FILTER_STATUSES: {
      const stateWithoutChangeableFilter = state.filters.filter((item: any) => item.type !== action.data.type)
      const updatedFilter = state.filters
        .filter((item: any) => item.type === action.data.type)
        .map((item: any) => {
          return {
            ...item,
            statuses: action.data.statusList ? action.data.statusList : [],
          }
        })

      const newState = {
        ...state,
        filters: [...stateWithoutChangeableFilter, ...updatedFilter],
      }

      return {
        ...newState,
        counter: setCounter(newState),
      }
    }
    case UpdateFilterStepsTypes.CAMPAIGN_REPORT_REMOVE_ALL_FILTER_STATUSES: {
      const stateWithoutStatuses = state.filters.map((item: any) => {
        return {
          ...item,
          statuses: [],
        }
      })

      const newState = {
        ...state,
        filters: [...stateWithoutStatuses],
      }

      return {
        ...newState,
        counter: setCounter(newState),
      }
    }
    case UpdateFilterStepsTypes.CAMPAIGN_REPORT_UPDATE_SEARCH_NAMES: {
      const stateWithoutChangeableFilter = state.filters.filter((item: any) => item.type !== action.data.type)
      const stateWithUpdatetdSearchNames = state.filters
        .filter((item: any) => item.type === action.data.type)
        .map((item: any) => {
          return {
            ...item,
            names: [action.data.name],
          }
        })

      const newState = {
        ...state,
        filters: [...stateWithoutChangeableFilter, ...stateWithUpdatetdSearchNames],
      }

      return {
        ...newState,
        counter: setCounter(newState),
      }
    }
    case UpdateFilterStepsTypes.CAMPAIGN_REPORT_REMOVE_SEARCH_NAME: {
      const stateWithoutChangeableFilter = state.filters.filter((item: any) => item.type !== action.data.type)
      const stateWithUpdatetdSearchNames = state.filters
        .filter((item: any) => item.type === action.data.type)
        .map((item: any) => {
          return {
            ...item,
            names: item.names.filter((name: any) => name !== action.data.name),
          }
        })

      const newState = {
        ...state,
        filters: [...stateWithoutChangeableFilter, ...stateWithUpdatetdSearchNames],
      }

      return {
        ...newState,
        counter: setCounter(newState),
      }
    }
    case CampaignReportActionTypes.COMPAIGN_REPORT_SET_ADS: {
      const { current, results, ...listParams } = action.data

      const newAllResults =
        results.length > 0
          ? state.adsData.allResults.some((r: any) => r.page === current)
            ? [...state.adsData.allResults.filter((r: any) => r.page !== current), { page: current, results: results }]
            : [...state.adsData.allResults, { page: current, results: results }]
          : []

      const newState = {
        ...state,
        adsData: {
          ...listParams,
          loading: false,
          results,
          current: current,
          allResults: newAllResults,
        },
      }

      return {
        ...newState,
        counter: setCounter(newState),
      }
    }
    case CampaignReportActionTypes.COMPAIGN_REPORT_SET_ADS_LOADING: {
      return {
        ...state,
        adsData: {
          ...state.adsData,
          loading: true,
        },
      }
    }
    case CampaignReportActionTypes.COMPAIGN_REPORT_SET_ADS_REQUEST_ERROR: {
      const isError = action.data
      return {
        ...state,
        adsData: {
          ...state.adsData,
          request_error: isError,
        },
      }
    }
    case CampaignReportActionTypes.COMPAIGN_REPORT_SET_COMPANY: {
      const { current, results, ...listParams } = action.data

      const newAllResults =
        results.length > 0
          ? state.campaignData.allResults.some((r: any) => r.page === current)
            ? [
                ...state.campaignData.allResults.filter((r: any) => r.page !== current),
                { page: current, results: results },
              ]
            : [...state.campaignData.allResults, { page: current, results: results }]
          : []

      const newState = {
        ...state,
        campaignData: {
          ...listParams,
          loading: false,
          results,
          current: current,
          allResults: newAllResults,
        },
      }

      return {
        ...newState,
        counter: setCounter(newState),
      }
    }
    case CampaignReportActionTypes.COMPAIGN_REPORT_SET_COMPANY_LOADING: {
      return {
        ...state,
        campaignData: {
          ...state.campaignData,
          loading: true,
        },
      }
    }
    case CampaignReportActionTypes.COMPAIGN_REPORT_SET_COMPANY_REQUEST_ERROR: {
      const isError = action.data
      return {
        ...state,
        campaignData: {
          ...state.campaignData,
          request_error: isError,
        },
      }
    }
    case CampaignReportActionTypes.COMPAIGN_REPORT_SET_GROUP: {
      const { current, results, ...listParams } = action.data

      const newAllResults =
        results.length > 0
          ? state.adsetData.allResults.some((r: any) => r.page === current)
            ? [
                ...state.adsetData.allResults.filter((r: any) => r.page !== current),
                { page: current, results: results },
              ]
            : [...state.adsetData.allResults, { page: current, results: results }]
          : []

      const newState = {
        ...state,
        adsetData: {
          ...listParams,
          loading: false,
          results,
          current: current,
          allResults: newAllResults,
        },
      }

      return {
        ...newState,
        counter: setCounter(newState),
      }
    }
    case CampaignReportActionTypes.COMPAIGN_REPORT_SET_GROUP_LOADING: {
      return {
        ...state,
        adsetData: {
          ...state.adsetData,
          loading: true,
        },
      }
    }
    case CampaignReportActionTypes.COMPAIGN_REPORT_SET_GROUP_REQUEST_ERROR: {
      const isError = action.data
      return {
        ...state,
        adsetData: {
          ...state.adsetData,
          request_error: isError,
        },
      }
    }
    case CampaignReportActionTypes.COMPAIGN_REPORT_SET_UNDISTRIBUTED: {
      const { current, results, ...listParams } = action.data

      const newAllResults =
        results.length > 0
          ? state.undistributedData.allResults.some((r: any) => r.page === current)
            ? [
                ...state.undistributedData.allResults.filter((r: any) => r.page !== current),
                { page: current, results: results },
              ]
            : [...state.undistributedData.allResults, { page: current, results: results }]
          : []

      const newState = {
        ...state,
        undistributedData: {
          ...listParams,
          current: current,
          loading: false,
          results,
          allResults: newAllResults,
        },
      }

      return {
        ...newState,
        counter: setCounter(newState),
      }
    }
    case CampaignReportActionTypes.COMPAIGN_REPORT_SET_UNDISTRIBUTED_LOADING: {
      return {
        ...state,
        undistributedData: {
          ...state.undistributedData,
          loading: true,
        },
      }
    }
    case CampaignReportActionTypes.COMPAIGN_REPORT_SET_UNDISTRIBUTED_REQUEST_ERROR: {
      const isError = action.data
      return {
        ...state,
        undistributedData: {
          ...state.undistributedData,
          request_error: isError,
        },
      }
    }

    case UpdateFilterStepsTypes.CAMPAIGN_REPORT_TABLE_CONFIG_LOADING: {
      return {
        ...state,
        tableDataCount: {
          ...state.tableDataCount,
          loading: true,
        },
      }
    }

    case UpdateFilterStepsTypes.CAMPAIGN_REPORT_TABLE_CONFIG: {
      const { adsets_count, campaigns_count, disorganized_ads_count, organized_ads_count, settings } = action.data

      const tableDataCount = {
        adsData: organized_ads_count,
        campaignData: campaigns_count,
        adsetData: adsets_count,
        undistributedData: disorganized_ads_count,
        loading: false,
      }

      const newState = { ...state, tableDataCount, settings }

      return {
        ...newState,
        counter: setCounter(newState),
      }
    }

    case CampaignFormsActionTypes.SET_CAMPAIGN_SELECTED_LOADING: {
      return { ...state, campaignSelectedList: { ...state.campaignSelectedList, loading: true } }
    }

    case CampaignFormsActionTypes.SET_CAMPAIGN_SELECTED: {
      const { results, ...listParams } = action.data
      return {
        ...state,
        campaignSelectedList: {
          ...listParams,
          loading: false,
          results: [...state.campaignSelectedList.results, ...results],
        },
      }
    }
    case CampaignFormsActionTypes.SET_CAMPAIGN_SELECTED_UPDATE: {
      const { results, ...listParams } = action.data
      return {
        ...state,
        campaignSelectedList: {
          ...listParams,
          loading: false,
          results,
        },
      }
    }

    case CampaignFormsActionTypes.SET_GROUP_SELECTED_LOADING: {
      return { ...state, adsetSelectedList: { ...state.adsetSelectedList, loading: true } }
    }

    case CampaignFormsActionTypes.SET_GROUP_SELECTED: {
      const { results, ...listParams } = action.data
      return {
        ...state,
        adsetSelectedList: {
          ...listParams,
          loading: false,
          results: [...state.adsetSelectedList.results, ...results],
        },
      }
    }

    case CampaignReportActionTypes.SET_GROUP_UPDATE_SELECTED: {
      const { results, ...listParams } = action.data

      return {
        ...state,
        adsetSelectedList: {
          ...listParams,
          loading: false,
          results: results,
        },
      }
    }

    case CampaignFormsActionTypes.SET_ACCOUNT_SELECTED_LOADING: {
      return { ...state, accountSelectedList: { ...state.accountSelectedList, loading: true } }
    }

    case CampaignFormsActionTypes.SET_ACCOUNT_SELECTED: {
      const { results, ...listParams } = action.data
      return {
        ...state,
        accountSelectedList: {
          ...listParams,
          loading: false,
          results: [...state.accountSelectedList.results, ...results],
        },
      }
    }

    case CampaignFormsActionTypes.SET_CHECK_ACTIVE_ACCOUNT_LOADING: {
      return { ...state, hasActiveAccounts: { ...state.hasActiveAccounts, loading: true } }
    }

    case CampaignReportActionTypes.SET_SELECTED_UNDISTRIBUTED_IDS: {
      const newState = { ...state, selectedUndistributedIds: [...action.data] }

      return {
        ...newState,
        counter: setCounter(newState),
      }
    }

    case CampaignReportActionTypes.SET_SELECTED_AD_IDS: {
      const newState = { ...state, selectedAdIds: [...action.data] }

      return {
        ...newState,
        counter: setCounter(newState),
      }
    }

    case CampaignReportActionTypes.SET_SELECTED_ADSET_IDS: {
      let newState = { ...state, selectedAdsetIds: [...action.data] }

      if (state.selectedAdsetIds.length !== action.data.length) {
        newState = {
          ...newState,
          selectedAdIds: [],
        }
      }

      return {
        ...newState,
        counter: setCounter(newState),
      }
    }

    case CampaignReportActionTypes.SET_SELECTED_CAMPAIGN_IDS: {
      let newState = { ...state, selectedCampaignIds: [...action.data] }

      if (state.selectedCampaignIds.length !== action.data.length) {
        newState = {
          ...newState,
          selectedAdsetIds: [],
          selectedAdIds: [],
        }
      }

      return {
        ...newState,
        counter: setCounter(newState),
      }
    }

    case CampaignReportActionTypes.SET_SELECTED_ALL_ROWS_TYPE: {
      return {
        ...state,
        selectAllRowsType: {
          ...state.selectAllRowsType,
          ...action.data,
        },
      }
    }

    case CampaignReportActionTypes.SET_UNDISTRIBUTED_ERROR: {
      return {
        ...state,
        isUndistributedError: action.data,
      }
    }

    case CampaignReportActionTypes.SET_AD_ERROR: {
      return {
        ...state,
        isAdError: action.data,
      }
    }

    case CampaignReportActionTypes.SET_ADSET_ERROR: {
      return {
        ...state,
        isAdsetError: action.data,
      }
    }

    case CampaignReportActionTypes.SET_CAMPAIGN_ERROR: {
      return {
        ...state,
        isCampaignError: action.data,
      }
    }

    case CampaignReportActionTypes.SET_UNDISTRIBUTED_LOADING: {
      return {
        ...state,
        isUndistributedLoading: action.data,
      }
    }

    case CampaignReportActionTypes.SET_AD_LOADING: {
      return {
        ...state,
        isAdLoading: action.data,
      }
    }

    case CampaignReportActionTypes.SET_ADSET_LOADING: {
      return {
        ...state,
        isAdsetLoading: action.data,
      }
    }

    case CampaignReportActionTypes.SET_CAMPAIGN_LOADING: {
      return {
        ...state,
        isCampaignLoading: action.data,
      }
    }

    case CampaignReportActionTypes.SET_FORCE_UPDATE_LOADING: {
      return {
        ...state,
        isForceUpdateLoading: action.data,
      }
    }

    case CampaignFormsActionTypes.SET_CHECK_ACTIVE_ACCOUNT: {
      const { results } = action.data
      return {
        ...state,
        hasActiveAccounts: {
          loading: false,
          hasActive: Boolean(results.length),
        },
      }
    }

    case CampaignReportActionTypes.SET_ASIDE_ACTIVE_NAV_ITEM: {
      return {
        ...state,
        aside: {
          ...state.aside,
          activeNavItem: action.data,
        },
      }
    }

    case CampaignReportActionTypes.SET_ASIDE_NAV_ERROR: {
      return {
        ...state,
        aside: {
          ...state.aside,
          navErrors: {
            ...state.aside.navErrors,
            [action.data.type]: action.data.value,
          },
        },
      }
    }

    case CampaignReportActionTypes.SET_ASIDE_NAV_DISABLED: {
      return {
        ...state,
        aside: {
          ...state.aside,
          navDisabled: {
            ...state.aside.navDisabled,
            [action.data.type]: action.data.value,
          },
        },
      }
    }

    case CampaignReportActionTypes.SET_ASIDE_RESET_NAV_DISABLED: {
      return {
        ...state,
        aside: {
          ...state.aside,
          navDisabled: {
            [MassEditingTypes.limit]: false,
            [MassEditingTypes.schedule]: false,
            [MassEditingTypes.stop]: false,
          },
        },
      }
    }

    case CampaignReportActionTypes.SET_ASIDE_LOADING: {
      return {
        ...state,
        aside: {
          ...state.aside,
          isLoading: action.data,
        },
      }
    }

    case CampaignReportActionTypes.SET_ASIDE_OPENED: {
      return {
        ...state,
        aside: {
          ...state.aside,
          isOpened: action.data.isOpened,
          isAll: action.data.isAll,
        },
      }
    }

    case CampaignReportActionTypes.SET_ASIDE_STOP_DATE_RULE: {
      return {
        ...state,
        aside: {
          ...state.aside,
          isSetStopDateRule: action.data.isSetStopDateRule,
        },
      }
    }

    case CampaignReportActionTypes.SET_ASIDE_LIMIT_RULE: {
      return {
        ...state,
        aside: {
          ...state.aside,
          isSetLimitRule: action.data.isSetLimitRule,
        },
      }
    }

    case CampaignReportActionTypes.SET_ASIDE_VALUES: {
      return {
        ...state,
        aside: {
          ...state.aside,
          asideValues: action.data,
        },
      }
    }

    case CampaignReportActionTypes.SET_ALL_ASIDE_VALUES: {
      return {
        ...state,
        aside: {
          ...state.aside,
          allAsideValues: action.data,
        },
      }
    }

    case CampaignReportActionTypes.UPDATE_ASIDE_VALUES: {
      return {
        ...state,
        aside: {
          ...state.aside,
          asideValues: [...state.aside.asideValues, ...action.data],
        },
      }
    }

    case CampaignReportActionTypes.REMOVE_ASIDE_VALUES: {
      return {
        ...state,
        aside: {
          ...state.aside,
          asideValues: [],
        },
      }
    }

    case CampaignReportActionTypes.REMOVE_ALL_ASIDE_VALUES: {
      return {
        ...state,
        aside: {
          ...state.aside,
          allAsideValues: [],
        },
      }
    }

    case CampaignReportActionTypes.SET_ASIDE_LIMIT_DRAFT: {
      return {
        ...state,
        aside: {
          ...state.aside,
          asideLimitDraft: action.data,
        },
      }
    }

    case CampaignReportActionTypes.REMOVE_ASIDE_LIMIT_DRAFT: {
      const allData = [
        ...state.adsData.allResults.flatMap((r: AdsData) => r.results),
        ...state.adsetData.allResults.flatMap((r: AdsetsData) => r.results),
        ...state.campaignData.allResults.flatMap((r: CampaignsData) => r.results),
      ]

      const newAsideValues = state.aside.asideValues.map((values: AsideValues) => ({
        ...values,
        limit: {
          ...values.limit,
          date: values.limit.created_at ? allData.find((v) => v.id === values.id)?.daily_budget?.amount : 0,
          rule: values.limit.created_at ? allData.find((v) => v.id === values.id)?.daily_budget?.rule : null,
        },
      }))

      const newAllAsideValues = state.aside.allAsideValues.map((values: AsideValues) => ({
        ...values,
        limit: {
          ...values.limit,
          date: values.limit.created_at ? allData.find((v) => v.id === values.id)?.daily_budget?.amount : 0,
          rule: values.limit.created_at ? allData.find((v) => v.id === values.id)?.daily_budget?.rule : null,
        },
      }))

      return {
        ...state,
        aside: {
          ...state.aside,
          asideValues: newAsideValues,
          allAsideValues: newAllAsideValues,
          asideLimitDraft: null,
        },
      }
    }

    case CampaignReportActionTypes.SET_ASIDE_STOP_DATE_DRAFT: {
      return {
        ...state,
        aside: {
          ...state.aside,
          asideStopDateDraft: action.data,
        },
      }
    }

    case CampaignReportActionTypes.REMOVE_ASIDE_STOP_DATE_DRAFT: {
      const allData = [
        ...state.adsData.allResults.flatMap((r: AdsData) => r.results),
        ...state.adsetData.allResults.flatMap((r: AdsetsData) => r.results),
        ...state.campaignData.allResults.flatMap((r: CampaignsData) => r.results),
      ]

      const newAsideValues = state.aside.asideValues.map((values: AsideValues) => ({
        ...values,
        stop: {
          ...values.stop,
          date: values.stop.created_at ? allData.find((v) => v.id === values.id)?.day_stop?.date_stop : null,
          rule: values.stop.created_at ? allData.find((v) => v.id === values.id)?.day_stop?.rule : null,
        },
      }))

      const newAllAsideValues = state.aside.allAsideValues.map((values: AsideValues) => ({
        ...values,
        stop: {
          ...values.stop,
          date: values.stop.created_at ? allData.find((v) => v.id === values.id)?.day_stop?.date_stop : null,
          rule: values.stop.created_at ? allData.find((v) => v.id === values.id)?.day_stop?.rule : null,
        },
      }))

      return {
        ...state,
        aside: {
          ...state.aside,
          asideValues: newAsideValues,
          allAsideValues: newAllAsideValues,
          asideStopDateDraft: null,
        },
      }
    }

    case `${reducerName}.OPEN_MODAL`: {
      return {
        ...state,
        modals: action.payload,
      }
    }

    case `${reducerName}.CLOSE_MODAL`: {
      return {
        ...state,
        modals: null,
      }
    }
    default: {
      return state
    }
  }
}

export default campaignEditorReducer
