import React from 'react'

import { SlateGreyLighten22 } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = SlateGreyLighten22 } = props
  const defaultStyle = { width: 22, height: 22 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="3" y="11" width="4" height="9" rx="1" fill={fill} />
      <rect x="9" y="7" width="4" height="13" rx="1" fill={fill} />
      <rect x="15" y="2" width="4" height="18" rx="1" fill={fill} />
    </svg>
  )
}
