import React from 'react'

import { Black } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = Black } = props
  const defaultStyle = { width: 16, height: 14 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6667 0.333496H12.0001C11.6319 0.333496 11.3334 0.631973 11.3334 1.00016V13.0002C11.3334 13.3684 11.6319 13.6668 12.0001 13.6668H14.6667C15.0349 13.6668 15.3334 13.3684 15.3334 13.0002V1.00016C15.3334 0.631973 15.0349 0.333496 14.6667 0.333496ZM12.6667 12.3335V1.66683H14.0001V12.3335H12.6667ZM6.66675 3.66683H9.33342C9.7016 3.66683 10.0001 3.96531 10.0001 4.3335V13.0002C10.0001 13.3684 9.7016 13.6668 9.33342 13.6668H6.66675C6.29856 13.6668 6.00008 13.3684 6.00008 13.0002V4.3335C6.00008 3.96531 6.29856 3.66683 6.66675 3.66683ZM7.33342 12.3335V5.00016H8.66675V12.3335H7.33342ZM1.33341 7.00016H4.00008C4.36827 7.00016 4.66675 7.29864 4.66675 7.66683V13.0002C4.66675 13.3684 4.36827 13.6668 4.00008 13.6668H1.33341C0.965225 13.6668 0.666748 13.3684 0.666748 13.0002V7.66683C0.666748 7.29864 0.965225 7.00016 1.33341 7.00016ZM3.33341 12.3335H2.00008V8.3335H3.33341V12.3335Z"
        fill={fill}
      />
    </svg>
  )
}
