import { Dispatch } from 'react'
import { AxiosError, AxiosPromise, AxiosResponse } from 'axios'
import { AnyAction } from 'redux'

import { CampaignFormsActionTypes } from '../../actions'
import { setTableConfig } from '../../actions'
import { ExtraFilterType } from '../../types'
import { MyThunkDispatch } from './../../../../store/store'
import { CreateCompanyFormData, CreateGroupFormData } from './types'

import { closeModal, openModal } from '@/common/actions'
import { API_URL } from '@/const'
import { customAxios } from '@/utils'

export const reducerName = 'campaignEditor.modals'

export const openCampaignEditorReportModal = openModal(reducerName)
export const closeCampaignEditorReportModal = closeModal(reducerName)

export const addCampaign = (id: string, body: CreateCompanyFormData) => {
  const url = `${API_URL}/telegram_editor/${id}/campaigns/`

  try {
    const response = customAxios(url, 'POST', body, true)
    return response
  } catch (error) {
    return Promise.reject(error)
  }
}

export const deleteCampaign = (id: string, isAll: boolean, query: string, campaign_ids: string[]) => {
  let queryString

  if (isAll) {
    if (query) {
      queryString = `?all=True&${query}`
    } else {
      queryString = '?all=True'
    }
  } else {
    queryString = '?ids=' + JSON.stringify(campaign_ids)
  }

  try {
    const url = `${API_URL}/telegram_editor/${id}/campaigns/delete/${queryString}`
    const response = customAxios(url, 'DELETE', false, true)
    return response
  } catch (error) {
    return Promise.reject(error)
  }
}

export const renameCampaign = (id: string, campaign_id: string, body: { name: string }) => {
  try {
    const url = `${API_URL}/telegram_editor/${id}/campaigns/${campaign_id}/`
    const response = customAxios(url, 'PATCH', body, true)
    return response
  } catch (error) {
    return Promise.reject(error)
  }
}

export function addAdset(id: string, body: CreateGroupFormData): AxiosPromise<{ name: string }> {
  try {
    const response = customAxios(`${API_URL}/telegram_editor/${id}/adsets/`, 'POST', body, true)
    return response
  } catch (error) {
    return Promise.reject(error)
  }
}

export const deleteAdset = (id: string, isAll: boolean, query: string, adset_ids: string[]) => {
  let queryString

  if (isAll) {
    if (query) {
      queryString = `?all=True&${query}`
    } else {
      queryString = '?all=True'
    }
  } else {
    queryString = '?ids=' + JSON.stringify(adset_ids)
  }

  try {
    const url = `${API_URL}/telegram_editor/${id}/adsets/delete/${queryString}`
    const response = customAxios(url, 'DELETE', false, true)
    return response
  } catch (error) {
    return Promise.reject(error)
  }
}

export const renameAdset = (id: string, adset_id: string, body: { name: string }) => {
  try {
    const url = `${API_URL}/telegram_editor/${id}/adsets/${adset_id}/`
    const response = customAxios(url, 'PATCH', body, true)
    return response
  } catch (error) {
    return Promise.reject(error)
  }
}

export const moveAds = (
  id: string,
  adset_id: string,
  isAll: boolean,
  query: string,
  currentTab: string,
  body: { name: string; ads_ids?: string[] }
) => {
  if (isAll) {
    const organizedType = currentTab === ExtraFilterType.UNDISTRIBUTED ? 'organized=False' : 'organized=True'
    let queryString = `?${organizedType}`

    if (query) {
      queryString = `?${organizedType}&${query}`
    }

    try {
      const url = `${API_URL}/telegram_editor/${id}/ads/update/${queryString}`
      const response = customAxios(url, 'PATCH', { adset_id }, true)
      return response
    } catch (error) {
      return Promise.reject(error)
    }
  }

  try {
    const url = `${API_URL}/telegram_editor/${id}/adsets/${adset_id}/`
    const response = customAxios(url, 'PATCH', body, true)
    return response
  } catch (error) {
    return Promise.reject(error)
  }
}

export const moveAdsToUndistributed = (
  id: string,
  ads_ids: string[],
  isAll: boolean,
  query: string,
  body: { adset_id: null }
) => {
  let queryString

  if (isAll) {
    if (query) {
      queryString = `?${query}`
    } else {
      queryString = ''
    }
  } else {
    queryString = ''
  }

  const bodyData = isAll ? body : { adset_id: null, ids: ads_ids }

  try {
    const url = `${API_URL}/telegram_editor/${id}/ads/update/${queryString}`
    const response = customAxios(url, 'PATCH', bodyData, true)
    return response
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getOrganizedAds = (id: string, adsets_id: string) => {
  try {
    const url = `${API_URL}/telegram_editor/${id}/ads/?organized=True&adset_id=${adsets_id}`
    const response = customAxios(url, 'GET', null, true)
    return response
  } catch (error) {
    return Promise.reject(error)
  }
}

export const renameAds = (id: string, ads_id: string, body: { name: string }): AxiosPromise => {
  try {
    const url = `${API_URL}/telegram_editor/${id}/ads/${ads_id}/`
    const response = customAxios(url, 'PATCH', body, true)
    return response
  } catch (error) {
    return Promise.reject(error)
  }
}

export function getSelectedCampaign(id: string, page: number, isUpdate?: boolean) {
  return (dispatch: Dispatch<AnyAction>): AxiosPromise<any> => {
    dispatch({ type: CampaignFormsActionTypes.SET_CAMPAIGN_SELECTED_LOADING })

    const url = `${API_URL}/telegram_editor/${id}/campaigns/?page=${page}`

    return customAxios(url, 'GET', null, true)
      .then((response: AxiosResponse<any>) => {
        if (isUpdate) {
          dispatch({ type: CampaignFormsActionTypes.SET_CAMPAIGN_SELECTED_UPDATE, data: response.data })
        } else {
          dispatch({ type: CampaignFormsActionTypes.SET_CAMPAIGN_SELECTED, data: response.data })
        }
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function getSelectedGroup(id: string, groupId: string, page: number, isUpdate: boolean) {
  return (dispatch: Dispatch<AnyAction>): AxiosPromise<any> => {
    dispatch({ type: CampaignFormsActionTypes.SET_GROUP_SELECTED_LOADING })

    const url = `${API_URL}/telegram_editor/${id}/adsets/?campaign_id=${groupId}&page=${page}`

    return customAxios(url, 'GET', null, true)
      .then((response: AxiosResponse<any>) => {
        if (isUpdate) {
          dispatch({ type: CampaignFormsActionTypes.SET_GROUP_UPDATE_SELECTED, data: response.data })
        } else {
          dispatch({ type: CampaignFormsActionTypes.SET_GROUP_SELECTED, data: response.data })
        }
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export const updateTableSettings = (id: number, body: any) => {
  return async (dispatch: MyThunkDispatch): Promise<AxiosResponse<any, any>> => {
    try {
      const url = `${API_URL}/telegram_editor/${id}/table_config/`
      const response = await customAxios(url, 'PATCH', body, true)
      await dispatch(setTableConfig(id))
      return response
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
