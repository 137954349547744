import React from 'react'
import cn from 'classnames'

import useStyles from './styles'

import Logo from '@/components/Logo'
import { BackgroundWhite } from '@/const/colors'

interface ILogoHeader {
  className?: string
}

const LogoHeader = (props: ILogoHeader) => {
  const classes = useStyles()
  const { className } = props

  return (
    <div className={cn(classes.wrapper, className)}>
      <div className={classes.logoWrapper}>
        <div className={classes.mobile}>
          <Logo color={BackgroundWhite} fillBg={BackgroundWhite} />
        </div>
        <div className={classes.desktop}>
          <Logo />
        </div>
      </div>
    </div>
  )
}

export default LogoHeader
