import React from 'react'

import { GreenBase } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = GreenBase } = props
  const defaultStyle = { width: 16, height: 12 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.73089 11.2976L0.264228 6.408C-0.026878 6.11424 -0.026878 5.63795 0.264228 5.34417L1.31843 4.28034C1.60954 3.98655 2.08156 3.98655 2.37267 4.28034L6.25801 7.57415L13.2494 0.519144C13.5405 0.225389 14.0125 0.225389 14.3036 0.519144L15.3579 1.58297C15.649 1.87673 15.649 2.35302 15.3579 2.6468L6.78513 11.2976C6.494 11.5913 6.022 11.5913 5.73089 11.2976Z"
        fill={fill}
      />
    </svg>
  )
}
