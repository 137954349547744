import React, { ReactNode } from 'react'
import { DefaultTheme } from 'react-jss'

import useStyles from './styles'

interface IContentWrapper {
  isAuthorized: boolean
  children: ReactNode | null
}

const ContentWrapper = (props: IContentWrapper & { theme?: DefaultTheme }) => {
  const { children, isAuthorized } = props

  const classes = useStyles({ isAuthorized })

  return <div className={classes.contentWrapper}>{children}</div>
}

export default ContentWrapper
