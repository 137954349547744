import React from 'react'

import { IIcons } from './types'

export default (props: IIcons) => {
  const { style } = props
  const defaultStyle = { width: 100, height: 100 }

  return (
    <svg
      style={{ ...defaultStyle, ...style }}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="100px"
      height="100px"
    >
      <defs>
        <linearGradient
          id="GradientColor"
          x1="-45.7638"
          y1="0.352571"
          x2="64.2598"
          y2="10.6164"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9E4FF1" />
          <stop offset="1" stopColor="#844FF1" />
        </linearGradient>
      </defs>
      <circle cx="50" cy="50" r="44" strokeLinecap="round" />
    </svg>
  )
}
