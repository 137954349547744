import React from 'react'

import { PurpleBase } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = PurpleBase, fillBg = 'none' } = props
  const defaultStyle = { width: 20, height: 20 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 20 20" fill={fillBg} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.6586 4.37129C12.1004 4.37129 12.4586 4.01312 12.4586 3.57129C12.4586 3.12946 12.1004 2.77129 11.6586 2.77129V4.37129ZM15.7597 9.62171C15.7597 9.17988 15.4015 8.82171 14.9597 8.82171C14.5179 8.82171 14.1597 9.17988 14.1597 9.62171H15.7597ZM7.04475 8.63675C6.70962 8.34883 6.20454 8.3871 5.91662 8.72223C5.62869 9.05736 5.66696 9.56244 6.00209 9.85037L7.04475 8.63675ZM8.7242 11.1343L8.20287 11.7411L8.7603 12.22L9.28468 11.7052L8.7242 11.1343ZM16.9874 4.14213C17.3027 3.83258 17.3073 3.32607 16.9977 3.0108C16.6882 2.69554 16.1817 2.6909 15.8664 3.00045L16.9874 4.14213ZM11.6586 2.77129H4.85547V4.37129H11.6586V2.77129ZM2.05547 5.57129V14.4284H3.65547V5.57129H2.05547ZM4.85547 17.2284H12.9597V15.6284H4.85547V17.2284ZM15.7597 14.4284V9.62171H14.1597V14.4284H15.7597ZM6.00209 9.85037L8.20287 11.7411L9.24552 10.5275L7.04475 8.63675L6.00209 9.85037ZM9.28468 11.7052L16.9874 4.14213L15.8664 3.00045L8.16371 10.5635L9.28468 11.7052ZM12.9597 17.2284C14.5061 17.2284 15.7597 15.9748 15.7597 14.4284H14.1597C14.1597 15.0912 13.6225 15.6284 12.9597 15.6284V17.2284ZM2.05547 14.4284C2.05547 15.9748 3.30907 17.2284 4.85547 17.2284V15.6284C4.19273 15.6284 3.65547 15.0912 3.65547 14.4284H2.05547ZM4.85547 2.77129C3.30907 2.77129 2.05547 4.02489 2.05547 5.57129H3.65547C3.65547 4.90855 4.19273 4.37129 4.85547 4.37129V2.77129Z"
        fill={fill}
      />
    </svg>
  )
}
