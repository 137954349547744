import { AnyAction } from 'redux'

import { closeModal, openModal } from '../../common/actions'
import { AppActionTypes } from './types'

export function setAppLoaded(loaded: boolean): AnyAction {
  return { type: AppActionTypes.SET_LOADED, data: loaded }
}

export function toggleModal(isShowed: boolean): AnyAction {
  return { type: AppActionTypes.SET_MODAL, data: isShowed }
}

export function toggleMenu(loaded: boolean): AnyAction {
  return { type: AppActionTypes.TOGGLE_MENU, data: loaded }
}

export function toggleFinanceMenu(loaded: boolean): AnyAction {
  return { type: AppActionTypes.TOGGLE_FINANCE_MENU, data: loaded }
}

export function toggleToolMenu(loaded: boolean): AnyAction {
  return { type: AppActionTypes.TOGGLE_TOOL_MENU, data: loaded }
}

export function toggleAnalyticsMenu(loaded: boolean): AnyAction {
  return { type: AppActionTypes.TOGGLE_ANALYTICS_MENU, data: loaded }
}

export const reducerName = 'app.modals'

export const openAppModal = openModal(reducerName)
export const closeAppModal = closeModal(reducerName)
