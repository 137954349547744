import React from 'react'

import { GreyBase } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = GreyBase } = props
  const defaultStyle = { width: 18, height: 18 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.65002 6L9.50002 5.99999M9.50002 5.99999C9.50002 6.82842 10.1716 7.5 11 7.5C11.8285 7.5 12.5 6.82845 12.5 6.00003M9.50002 5.99999C9.50002 5.17157 10.1716 4.5 11 4.5C11.8285 4.5 12.5 5.1716 12.5 6.00003M12.5 6.00003L14.35 6.00001M8.50002 12C8.50002 12.8284 7.82845 13.5 7.00002 13.5C6.1716 13.5 5.50002 12.8284 5.50002 12M8.50002 12C8.50002 11.1716 7.82845 10.5 7.00002 10.5C6.1716 10.5 5.50002 11.1716 5.50002 12M8.50002 12L14.35 12M5.50002 12L3.65002 12"
        stroke={fill}
        strokeWidth="1.2"
        strokeLinecap="round"
      />
    </svg>
  )
}
