import { createUseStyles } from 'react-jss'

import { PurpleBase, PurpleLighten43, SlateGreyLighten50, TextGreyBase } from '../../const/colors'
import { WidthBPs } from '../../const/commonStyles'
import { FontBody1, FontBody1Accent, FontBody2, FontBody2Accent, FontH5 } from '../../const/fontStyles'

export default createUseStyles(
  {
    wrapper: {
      maxHeight: 332,

      [`@media (min-width: ${WidthBPs.SMALL})`]: {
        maxHeight: 430,
      },
    },
    description: {
      ...FontBody1,
      color: TextGreyBase,
      margin: '0 0 24px',
    },
    subtitle: {
      ...FontH5,
      color: TextGreyBase,
      margin: '0 0 24px',
    },
    card: {
      backgroundColor: SlateGreyLighten50,
      borderRadius: 8,
      padding: 16,
      marginBottom: 12,
      display: 'flex',

      '&:last-of-type': {
        marginBottom: 24,
      },
    },
    cardIcon: {
      height: 22,
      width: 22,
      borderRadius: '50%',
      backgroundColor: PurpleLighten43,
      color: PurpleBase,
      ...FontBody2Accent,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexShrink: 0,
    },
    cardBlock: {
      marginLeft: 12,
    },
    cardTitle: {
      ...FontBody1Accent,
      color: TextGreyBase,
      margin: '0 0 9px',
    },
    cardText: {
      color: TextGreyBase,
      ...FontBody2,
      margin: 0,
    },
    cardTextList: {
      margin: '9px 0 0 0',
    },
    link: {
      textDecoration: 'none',
      color: PurpleBase,
      ...FontBody1Accent,
    },
    helpButton: {
      appearance: 'none',
      display: 'inline',
      backgroundColor: 'transparent',
      border: 0,
      padding: 0,
      cursor: 'pointer',
      outline: 'none',
      color: PurpleBase,
      ...FontBody1Accent,
    },
    bold: {
      ...FontBody1Accent,
    },
  },
  { name: 'NewOffer' }
)
