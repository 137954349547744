import React from 'react'

import { IIcons } from './types'

export default (props: IIcons) => {
  const { style } = props
  const defaultStyle = { width: 40, height: 40 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" fill="#0077FF" />
      <path
        d="M21.2197 27.973C13.1118 27.973 8.1913 22.3477 8 13H12.1064C12.2344 19.8666 15.3585 22.7807 17.7533 23.3747V13H21.69V18.9245C24 18.6685 26.4164 15.973 27.2305 13H31.101C30.4811 16.6577 27.8504 19.3531 25.9892 20.465C27.8504 21.3639 30.845 23.717 32 27.973H27.744C26.845 25.128 24.6415 22.9245 21.69 22.6253V27.973H21.2197Z"
        fill="white"
      />
    </svg>
  )
}
