import React from 'react'

import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = '#B09DE3', fillBg = '#F3F1FB' } = props
  const defaultStyle = { width: 22, height: 22 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="11" cy="11" r="11" fill={fillBg} />
      <circle cx="11" cy="11" r="3.14286" fill={fill} />
    </svg>
  )
}
