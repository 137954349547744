import React from 'react'

import { IIcons } from './types'

export default (props: IIcons) => {
  const { style } = props
  const defaultStyle = { width: 31, height: 30 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.453613" width="30" height="30" rx="15" fill="#F0F3F7" />
      <path d="M6.28687 15L7.95353 16.6667L9.6202 15" stroke="#607793" strokeLinecap="square" />
      <path d="M15.4536 10.8333V15L17.9536 17.5" stroke="#607793" strokeLinecap="square" />
      <path
        d="M15.4536 22.5C19.5957 22.5 22.9536 19.1421 22.9536 15C22.9536 10.8579 19.5957 7.5 15.4536 7.5C11.3115 7.5 7.95361 10.8579 7.95361 15C7.95361 14.9794 7.95361 15.2572 7.95361 15.8333"
        stroke="#607793"
        strokeLinecap="square"
      />
    </svg>
  )
}
