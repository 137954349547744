import React from 'react'

import { GreyBase } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = GreyBase } = props
  const defaultStyle = { width: 20, height: 20 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="20" rx="4" fill="white" />
      <path d="M14.167 10.5949V5.83301H9.40509" stroke={fill} strokeLinecap="square" />
      <path d="M14.167 5.83301L13.5718 6.42825" stroke={fill} strokeLinecap="round" />
      <path d="M13.5718 6.42871L5.83368 14.1668" stroke={fill} strokeLinecap="square" />
    </svg>
  )
}
