import React from 'react'

import { IIcons } from './types'

export default (props: IIcons) => {
  const { style } = props
  const defaultStyle = { width: 40, height: 40 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
        fill="#F0F3F7"
      />
      <path
        d="M18.0817 17.6646V16.8551C17.8081 16.8105 17.5318 16.7861 17.2548 16.7821C14.5732 16.7762 12.199 18.5611 11.3938 21.1882C10.5885 23.8154 11.539 26.6752 13.7403 28.2482C12.0914 26.4357 11.6335 23.7972 12.5712 21.5112C13.509 19.2252 15.6671 17.7187 18.0817 17.6646Z"
        fill="#25F4EE"
      />
      <path
        d="M18.2307 26.8348C19.73 26.8327 20.9624 25.6195 21.0281 24.0811V10.3523H23.4701C23.4203 10.0654 23.3965 9.77439 23.3991 9.483H20.059V23.1986C20.0034 24.7448 18.7681 25.9697 17.2616 25.9722C16.8114 25.9682 16.3685 25.8545 15.9695 25.6404C16.494 26.3868 17.3344 26.8307 18.2307 26.8348Z"
        fill="#25F4EE"
      />
      <path
        d="M28.0307 15.0104V14.2473C27.132 14.2476 26.2532 13.9752 25.5046 13.4643C26.1608 14.2478 27.0475 14.7905 28.0307 15.0104Z"
        fill="#25F4EE"
      />
      <path
        d="M25.5041 13.4642C24.7667 12.6018 24.3602 11.4932 24.3605 10.3455H23.469C23.7017 11.6279 24.4376 12.7557 25.5041 13.4642Z"
        fill="#FE2C55"
      />
      <path
        d="M17.2558 20.2126C15.9692 20.2193 14.8521 21.1247 14.5466 22.4084C14.241 23.6921 14.8253 25.0246 15.9637 25.6404C15.3468 24.7657 15.2597 23.6096 15.7381 22.6478C16.2166 21.686 17.1798 21.081 18.2313 21.0818C18.5117 21.0854 18.7902 21.1301 19.0583 21.2145V17.7243C18.7845 17.6821 18.5082 17.6599 18.2313 17.6579H18.0828V20.3121C17.8133 20.2379 17.5347 20.2043 17.2558 20.2126Z"
        fill="#FE2C55"
      />
      <path
        d="M28.0299 15.0103V17.6645C26.3661 17.6612 24.7456 17.1203 23.3977 16.1185V23.0924C23.3906 26.5723 20.6419 29.3895 17.2538 29.3894C15.9955 29.3917 14.7675 28.993 13.7393 28.2481C15.4553 30.1438 18.126 30.7678 20.4716 29.8211C22.8172 28.8745 24.3598 26.55 24.3603 23.9616V17.0076C25.7127 18.0029 27.3353 18.5368 28.999 18.5338V15.1165C28.6733 15.1154 28.3485 15.0799 28.0299 15.0103Z"
        fill="#FE2C55"
      />
      <path
        d="M23.4001 23.0921V16.1182C24.7521 17.1143 26.3749 17.6482 28.0388 17.6443V14.9901C27.0558 14.777 26.1669 14.2413 25.5062 13.464C24.4398 12.7555 23.7039 11.6277 23.4712 10.3453H21.0291V24.0808C20.9802 25.3062 20.1811 26.3654 19.038 26.7198C17.8948 27.0743 16.6585 26.6463 15.9576 25.6534C14.8191 25.0376 14.2349 23.705 14.5404 22.4213C14.846 21.1377 15.9631 20.2323 17.2497 20.2255C17.5302 20.2281 17.8088 20.2728 18.0766 20.3583V17.7041C15.6485 17.7463 13.474 19.2581 12.5336 21.5577C11.5932 23.8574 12.0646 26.5105 13.7352 28.3208C14.7736 29.0411 16.0033 29.4142 17.2562 29.3892C20.6443 29.3892 23.393 26.572 23.4001 23.0921Z"
        fill="black"
      />
    </svg>
  )
}
