import React from 'react'
import Lottie from 'react-lottie-player'

export interface ILottieRendererProps {
  source: any
  width?: number
  height?: number
  loop?: boolean
  play?: boolean
  speed?: number
  goTo?: number
  onComplete?: () => void
}

const LottieRenderer = (props: ILottieRendererProps) => {
  const { loop = true, play = true, speed, goTo, onComplete } = props
  return (
    <Lottie
      loop={loop}
      play={play}
      speed={speed}
      goTo={goTo}
      animationData={props.source}
      onComplete={onComplete}
      rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
      style={{ width: props.width, height: props.height }}
    />
  )
}

export default LottieRenderer
