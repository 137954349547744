import React from 'react'

import { PurpleLighten6 } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = PurpleLighten6 } = props
  const defaultStyle = { width: 16, height: 16 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 2.5C4.6875 2.5 2 5.1875 2 8.5C2 11.8125 4.6875 14.5 8 14.5C11.3125 14.5 14 11.8125 14 8.5C14 5.1875 11.3125 2.5 8 2.5Z"
        stroke={fill}
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
      <path d="M8 4.5V9L10.4 10.9" stroke={fill} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
