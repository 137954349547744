import { Reducer } from 'redux'

import { IPromocodeState, PromocodeActionTypes } from './types'

export const initialState: IPromocodeState = {
  code: '',
  terms: '',
}

const reducer: Reducer<IPromocodeState> = (state = initialState, action: any) => {
  switch (action.type) {
    case PromocodeActionTypes.SET_PROMOCODE: {
      return { ...action.data }
    }
    default: {
      return state
    }
  }
}

export { reducer as promocodeReducer }
