import React from 'react'

import { GreyBase } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = GreyBase } = props
  const defaultStyle = { width: 18, height: 18 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.9999 3.66699H4.99992C3.52716 3.66699 2.33325 4.8609 2.33325 6.33366V13.0003C2.33325 14.4731 3.52716 15.667 4.99992 15.667H12.9999C14.4727 15.667 15.6666 14.4731 15.6666 13.0003V6.33366C15.6666 4.8609 14.4727 3.66699 12.9999 3.66699Z"
        stroke={fill}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M6.33325 2.3335V5.00016" stroke={fill} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.6665 2.3335V5.00016" stroke={fill} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M2.33325 7.66699H15.6666" stroke={fill} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
