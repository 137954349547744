import { Column } from 'react-table'

import { FilterTabsType, TableColumnType } from '../../types'

export interface TableRef {
  resetSelection: (ids: string[]) => void
}
export interface TableProps<Data extends Record<string, unknown>> {
  columns: Column<Data>[]
  data: Data[]
  visibleColumns: TableColumnType[] | []
  isProductTourOpened?: boolean
  columnHeaderClick: (column: any, tab: FilterTabsType) => void
  tab: FilterTabsType
  changeHandler?: (id: string[]) => void
  checkedRow?: string[]
  setHasChecked?: (hasChecked: boolean) => void
  tableRef?: React.Ref<TableRef>
}

export enum SORT_STATUS {
  ASC = 'ASC',
  DESC = 'DESC',
  NONE = 'none',
}

export interface ISortIndicatorType {
  sortDirection: SORT_STATUS
}
