import React from 'react'

import { GreyBase } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = GreyBase } = props
  const defaultStyle = { width: 18, height: 18 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4690_45)">
        <circle cx="9" cy="9" r="7.75" stroke={fill} strokeWidth="1.3" />
        <path
          d="M11.5 5.57607C11.5 5.57607 10.8432 4.88749 9.5 5.00541C7.70507 5.16299 6.5 6.94538 6.5 9C6.5 11.0546 7.70507 12.837 9.5 12.9946C10.8043 13.1091 11.5 12.4239 11.5 12.4239M5.5 8H9.5M5.5 10H9.5"
          stroke={fill}
          strokeWidth="1.2"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4690_45">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
