import React from 'react'
import { FormattedMessage } from 'react-intl'
import cn from 'classnames'

import useGlobalStyles from '../../const/globalStyles'
import useStyles from './styles'

const OFFER_LINK = 'http://files.aitarget.com/smb/transfer_agreement.pdf'

const NewOffer = () => {
  const classes = useStyles()
  const globalClasses = useGlobalStyles()

  const helpButtonHandler = () => {
    const carrotquest = (window as any).carrotquest

    if (typeof carrotquest !== 'undefined') {
      carrotquest.open()
    }
  }

  return (
    <div className={cn(classes.wrapper, globalClasses.scrollbar)}>
      <p className={classes.description}>
        <FormattedMessage
          id="app.modal.newOffer.description"
          values={{
            extra: (
              <a href={OFFER_LINK} rel="noopener noreferrer" target="_blank" className={classes.link}>
                <FormattedMessage id="app.modal.newOffer.descriptionExtra" />
              </a>
            ),
          }}
        />
      </p>
      <h5 className={classes.subtitle}>
        <FormattedMessage id="app.modal.newOffer.subtitle" />
      </h5>
      {Array.from({ length: 2 }, (v, k) => k + 1).map((index) => (
        <div className={classes.card} key={index}>
          <div className={classes.cardIcon}>{index}</div>
          <div className={classes.cardBlock}>
            <p className={classes.cardTitle}>
              <FormattedMessage id={`app.modal.newOffer.card_${index}Title`} />
            </p>
            <p className={classes.cardText}>
              {index === 1 ? (
                Array.from({ length: 2 }, (v, k) => k + 1).map((index) => (
                  <p className={classes.cardTextList} key={index}>
                    <FormattedMessage
                      id={`app.modal.newOffer.card1Text_${index}`}
                      values={{
                        button: (
                          <button className={classes.helpButton} onClick={helpButtonHandler}>
                            <FormattedMessage id="app.modal.newOffer.cardButton" />
                          </button>
                        ),
                        bold1: (
                          <span className={classes.bold}>
                            <FormattedMessage id={`app.modal.newOffer.card1Text_${index}Bold1`} />
                          </span>
                        ),
                        bold2: (
                          <span className={classes.bold}>
                            <FormattedMessage id={`app.modal.newOffer.card1Text_${index}Bold2`} />
                          </span>
                        ),
                        bold3: (
                          <span className={classes.bold}>
                            <FormattedMessage id={`app.modal.newOffer.card1Text_${index}Bold3`} />
                          </span>
                        ),
                        bold4: (
                          <span className={classes.bold}>
                            <FormattedMessage id={`app.modal.newOffer.card1Text_${index}Bold4`} />
                          </span>
                        ),
                      }}
                    />
                  </p>
                ))
              ) : (
                <FormattedMessage id={`app.modal.newOffer.card_${index}Text`} />
              )}
            </p>
          </div>
        </div>
      ))}
      <p className={classes.description}>
        <FormattedMessage id="app.modal.newOffer.info" />
      </p>
    </div>
  )
}

export default NewOffer
