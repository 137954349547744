import React from 'react'

import { SlateGreyLighten22, White } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = SlateGreyLighten22 } = props
  const defaultStyle = { width: 14, height: 15 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_21934_54582)">
        <path
          d="M7 0.9375C3.61758 0.9375 0.875 3.68008 0.875 7.0625C0.875 10.4449 3.61758 13.1875 7 13.1875C10.3824 13.1875 13.125 10.4449 13.125 7.0625C13.125 3.68008 10.3824 0.9375 7 0.9375ZM7 12.1484C4.1918 12.1484 1.91406 9.8707 1.91406 7.0625C1.91406 4.2543 4.1918 1.97656 7 1.97656C9.8082 1.97656 12.0859 4.2543 12.0859 7.0625C12.0859 9.8707 9.8082 12.1484 7 12.1484Z"
          fill={fill}
        />
        <path
          d="M6.34884 8.49261C6.34884 8.07549 6.4 7.74306 6.50233 7.4953C6.60465 7.24754 6.8062 6.97625 7.10698 6.68145C7.41085 6.38351 7.6031 6.17182 7.68372 6.04637C7.80775 5.85506 7.86977 5.64807 7.86977 5.4254C7.86977 5.1306 7.7969 4.90636 7.65116 4.75269C7.50853 4.59588 7.29767 4.51747 7.0186 4.51747C6.75194 4.51747 6.53643 4.59431 6.37209 4.74798C6.21085 4.89852 6.13023 5.10394 6.13023 5.36425H5C5.0062 4.80914 5.19225 4.37007 5.55814 4.04704C5.92713 3.72401 6.41395 3.5625 7.0186 3.5625C7.64186 3.5625 8.12713 3.72245 8.47442 4.04234C8.82481 4.36223 9 4.80914 9 5.38306C9 5.89427 8.76434 6.39763 8.29302 6.89315L7.72093 7.46237C7.51628 7.69758 7.41085 8.04099 7.40465 8.49261H6.34884ZM6.26977 9.95565C6.26977 9.77061 6.32713 9.62164 6.44186 9.50874C6.55659 9.3927 6.71163 9.33468 6.90698 9.33468C7.10543 9.33468 7.26202 9.39427 7.37674 9.51344C7.49147 9.62948 7.54884 9.77688 7.54884 9.95565C7.54884 10.1281 7.49302 10.2724 7.3814 10.3884C7.26977 10.5045 7.11163 10.5625 6.90698 10.5625C6.70233 10.5625 6.54419 10.5045 6.43256 10.3884C6.32403 10.2724 6.26977 10.1281 6.26977 9.95565Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_21934_54582">
          <rect width="14" height="14" fill={White} transform="translate(0 0.0625)" />
        </clipPath>
      </defs>
    </svg>
  )
}
