import React from 'react'

import { IIcons } from './types'

export default (props: IIcons) => {
  const { style } = props
  const defaultStyle = { width: 40, height: 40 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20Z"
        fill="#323555"
      />
      <path
        d="M26.4516 8.57422V31.4011H22.5711V11.6198V11.561H22.5122H20.7272C17.5616 11.561 15.6707 13.118 15.6707 15.7237C15.6707 18.5177 16.8513 19.8089 19.3326 21.4929L21.3034 22.8216L15.5484 31.4222H11.3528L16.5009 23.753L16.5338 23.7059L16.4868 23.673C13.6928 21.6716 11.8325 19.5949 11.8325 15.959C11.8325 11.3353 15.1487 8.57422 20.7061 8.57422H26.4516Z"
        fill="white"
      />
    </svg>
  )
}
