import React from 'react'

import { SlateGreyLighten22, SlateGreyLighten49 } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = SlateGreyLighten22, fillBg = SlateGreyLighten49 } = props
  const defaultStyle = { width: 18, height: 15 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="14.6666" cy="14" r="13.25" fill={fillBg} stroke={fill} strokeWidth="1.5" />
      <path d="M11.6041 7L7.66663 9.625" stroke={fill} strokeWidth="1.5" strokeLinecap="round" />
      <path
        d="M9.41663 20.7979V20.7979C12.3161 17.8984 17.0171 17.8984 19.9166 20.7979V20.7979"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path d="M17.7291 7L21.6666 9.625" stroke={fill} strokeWidth="1.5" strokeLinecap="round" />
      <circle cx="10.7291" cy="13.125" r="1.3125" fill={fill} />
      <circle cx="18.6041" cy="13.125" r="1.3125" fill={fill} />
    </svg>
  )
}
