import React from 'react'

import { RedBase, RedLighten33 } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = RedBase, fillBg = RedLighten33 } = props
  const defaultStyle = { width: 24, height: 24 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect id="svg-minus-icon" width="24" height="24" rx="8" fill={fillBg} />
      <path d="M14.0508 12.7656H9.94922V11.2344H14.0508V12.7656Z" fill={fill} />
    </svg>
  )
}
