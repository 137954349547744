import React from 'react'

import { BackgroundWhite } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = BackgroundWhite } = props
  const defaultStyle = { width: 40, height: 40 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20ZM19.3186 11.5933C14.1721 11.5933 10 15.7654 10 20.9119C10 26.0584 14.1721 30.2306 19.3186 30.2306C24.4652 30.2306 28.6373 26.0584 28.6373 20.9119V20.9119H25.5311C25.531 24.3429 22.7496 27.1242 19.3186 27.1242C15.8876 27.1242 13.1062 24.3428 13.1062 20.9118C13.1062 17.4808 15.8876 14.6994 19.3186 14.6994V11.5933ZM22.4252 11.5933L25.5314 8.5V14.6994H31.7788L28.6687 17.8056H25.5314V17.8058H24.6526L22.3265 20.1319C22.391 20.3812 22.4253 20.6426 22.4253 20.9121C22.4253 22.6276 21.0346 24.0183 19.3191 24.0183C17.6036 24.0183 16.2128 22.6276 16.2128 20.9121C16.2128 19.1965 17.6036 17.8058 19.3191 17.8058C19.6061 17.8058 19.884 17.8448 20.1479 17.9176L22.4252 15.6403V11.5933Z"
        fill={fill}
      />
    </svg>
  )
}
