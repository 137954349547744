import { AxiosError, AxiosResponse } from 'axios'
import { AnyAction, Dispatch } from 'redux'

import { API_URL } from '../../const'
import { AdAccountsActionTypes, IAdAccountsState } from '../../store/adaccounts/types'
import { ThunkResult } from '../../store/store'
import { Platforms } from '../../types'
import { customAxios } from '../../utils'
import { IVKAdsMarketAgencyFormAccountBody, IVKAdsMarketLegalFormAccountBody } from './types'

export enum AddVKAdsMarketActionTypes {
  SET_VK_ADS_MARKET_SUBJECTS = 'SET_VK_ADS_MARKET_SUBJECTS',
  SET_VK_ADS_MARKET_LOADING = 'SET_VK_ADS_MARKET_LOADING',
  SET_VK_ADS_MARKET_ERROR = 'SET_VK_ADS_MARKET_ERROR',
  SET_VK_ADS_MARKET_REDIRECT = 'SET_VK_ADS_MARKET_REDIRECT',
  REMOVE_VK_ADS_MARKET_ERROR = 'REMOVE_VK_ADS_MARKET_ERROR',
}

export function setRedirect(route: string): AnyAction {
  return { type: AddVKAdsMarketActionTypes.SET_VK_ADS_MARKET_REDIRECT, data: route }
}

export function setLoading(isLoading: boolean): AnyAction {
  return { type: AddVKAdsMarketActionTypes.SET_VK_ADS_MARKET_LOADING, data: isLoading }
}

export function createVKAdsMarketAdAccount(
  body: IVKAdsMarketAgencyFormAccountBody | IVKAdsMarketLegalFormAccountBody
): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/vk_adsmarket/accounts/`, 'POST', body, true)
      .then((response: AxiosResponse<IAdAccountsState>) => {
        dispatch({
          type: AdAccountsActionTypes.SET_AD_ACCOUNT,
          data: response.data,
          network: Platforms.VK_ADS_MARKET,
        })
        return response
      })
      .catch((error: AxiosError) => {
        dispatch({
          type: AddVKAdsMarketActionTypes.SET_VK_ADS_MARKET_ERROR,
          data: error.response?.data,
        })
        dispatch({
          type: AddVKAdsMarketActionTypes.SET_VK_ADS_MARKET_LOADING,
          data: false,
        })
        return Promise.reject(error)
      })
  }
}
