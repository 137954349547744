import React from 'react'

import { White } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = White } = props
  const defaultStyle = { width: 26, height: 26 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26 13C26 20.1797 20.1797 26 13 26C5.8203 26 0 20.1797 0 13C0 5.8203 5.8203 0 13 0C20.1797 0 26 5.8203 26 13ZM8.41667 16.421C6.97242 17.1358 5.71244 18.1738 4.73453 19.4545L6.38267 21.1527C7.13509 20.0755 8.13656 19.1961 9.30189 18.5891C10.4672 17.9822 11.7619 17.6657 13.0758 17.6667C14.3734 17.6656 15.6524 17.9742 16.8067 18.5667C17.9611 19.1592 18.9574 20.0185 19.713 21.0733L21.3498 19.3677C20.3686 18.1099 19.1133 17.0927 17.6794 16.3936C16.2456 15.6944 14.6711 15.3318 13.0758 15.3333C11.4597 15.3319 9.86513 15.7042 8.41667 16.421ZM16.2998 12.7998C17.175 11.9247 17.6667 10.7377 17.6667 9.5C17.6667 8.26232 17.175 7.07534 16.2998 6.20017C15.4247 5.325 14.2377 4.83333 13 4.83333C11.7623 4.83333 10.5753 5.325 9.70017 6.20017C8.825 7.07534 8.33333 8.26232 8.33333 9.5C8.33333 10.7377 8.825 11.9247 9.70017 12.7998C10.5753 13.675 11.7623 14.1667 13 14.1667C14.2377 14.1667 15.4247 13.675 16.2998 12.7998ZM15.3333 9.5C15.3333 10.1188 15.0875 10.7123 14.6499 11.1499C14.2123 11.5875 13.6188 11.8333 13 11.8333C12.3812 11.8333 11.7877 11.5875 11.3501 11.1499C10.9125 10.7123 10.6667 10.1188 10.6667 9.5C10.6667 8.88116 10.9125 8.28767 11.3501 7.85008C11.7877 7.4125 12.3812 7.16667 13 7.16667C13.6188 7.16667 14.2123 7.4125 14.6499 7.85008C15.0875 8.28767 15.3333 8.88116 15.3333 9.5Z"
        fill={fill}
      />
    </svg>
  )
}
