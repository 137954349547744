import React from 'react'

import { IIcons } from './types'

export default (props: IIcons) => {
  const { style } = props
  const defaultStyle = { width: 40, height: 40 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 40C31.0457 40 40 31.0457 40 20C40 8.95431 31.0457 0 20 0C8.95431 0 0 8.95431 0 20C0 31.0457 8.95431 40 20 40Z"
        fill="#2AABEE"
      />
      <path
        d="M20 40C31.0457 40 40 31.0457 40 20C40 8.95431 31.0457 0 20 0C8.95431 0 0 8.95431 0 20C0 31.0457 8.95431 40 20 40Z"
        fill="url(#paint0_linear_1118_233862)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.0532 19.7889C14.8836 17.2487 18.7715 15.574 20.7167 14.7649C26.271 12.4547 27.4251 12.0534 28.1773 12.0401C28.3428 12.0372 28.7127 12.0782 28.9523 12.2727C29.1546 12.4369 29.2103 12.6586 29.237 12.8143C29.2636 12.97 29.2968 13.3246 29.2704 13.6016C28.9694 16.7641 27.6671 24.4386 27.0045 27.9806C26.7241 29.4793 26.1721 29.9818 25.6377 30.031C24.4762 30.1379 23.5943 29.2635 22.4694 28.5261C20.7091 27.3722 19.7147 26.6539 18.0061 25.528C16.0314 24.2267 17.3115 23.5115 18.4368 22.3427C18.7313 22.0368 23.8487 17.3823 23.9477 16.96C23.9601 16.9072 23.9716 16.7104 23.8546 16.6064C23.7377 16.5025 23.5651 16.538 23.4406 16.5663C23.264 16.6064 20.452 18.465 15.0044 22.1422C14.2063 22.6903 13.4833 22.9574 12.8355 22.9434C12.1214 22.9279 10.7478 22.5396 9.7266 22.2077C8.4741 21.8005 7.47864 21.5853 7.56532 20.8938C7.61047 20.5337 8.10643 20.1654 9.0532 19.7889Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1118_233862"
          x1="20"
          y1="0"
          x2="20"
          y2="39.7033"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2AABEE" />
          <stop offset="1" stopColor="#229ED9" />
        </linearGradient>
      </defs>
    </svg>
  )
}
