import React from 'react'

import { GreyLighten47 } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = GreyLighten47 } = props
  const defaultStyle = { width: 22, height: 22 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 22 22" fill="none">
      <path
        d="M19.6798 14.7213V18.329C19.6798 19.8017 18.4816 20.9999 17.0089 20.9999H14.0485H6.95184H5.99092C4.51817 20.9999 3.32001 19.8017 3.32001 18.329V4.67092C3.32001 3.19816 4.51817 2 5.99092 2H6.95175H14.0485H17.0089C18.4816 2 19.6798 3.19816 19.6798 4.67092V8.27857C19.6688 8.2783 19.658 8.27696 19.647 8.27696H18.3369V4.67092C18.3369 3.93866 17.7411 3.34293 17.0089 3.34293H16.3645C16.5898 3.73444 16.7195 4.18764 16.7195 4.67092V8.27696H14.3291C12.552 8.27696 11.1061 9.72285 11.1061 11.5C11.1061 13.2771 12.552 14.723 14.3291 14.723H16.7195V18.3291C16.7195 18.8124 16.5899 19.2656 16.3645 19.6571H17.0089C17.7411 19.6571 18.3369 19.0613 18.3369 18.3291V14.723H19.647C19.6581 14.723 19.6688 14.7216 19.6798 14.7213ZM18.3369 13.3801H16.7195H14.3291C13.2908 13.3801 12.449 12.5384 12.449 11.5C12.449 10.4616 13.2908 9.61989 14.3291 9.61989H19.647V13.3801H18.3369ZM15.2943 11.5C15.2943 10.9971 14.8852 10.5879 14.3822 10.5879C13.8792 10.5879 13.4701 10.997 13.4701 11.5C13.4701 12.003 13.8792 12.4121 14.3822 12.4121C14.8852 12.4121 15.2943 12.003 15.2943 11.5Z"
        fill={fill}
      />
    </svg>
  )
}
