import { createSelector } from 'reselect'

import IApplicationState from '../types/state'

export const IsNotViewableUsnFeature = 'isNotViewableUsnFeature'
export const IsNotViewableTelegramFeature = 'isNotViewableTelegramFeature'

const getIsNotViewable = (viewableValueName: string) => {
  const viewableValue = window.localStorage.getItem(viewableValueName)

  return (viewableValue && JSON.parse(viewableValue)) ?? true
}

export const getApp = (state: IApplicationState) => state.app
export const getBrowser = (state: IApplicationState) => state.browser
export const getUser = (state: IApplicationState) => state.user
export const getPromo = (state: IApplicationState) => state.promo
export const getProfile = (state: IApplicationState) => state.user.profile
export const getPolls = (state: IApplicationState) => state.polls
export const getReferral = (state: IApplicationState) => state.referral
export const getAudiences = (state: IApplicationState) => state.audiences
export const getClients = (state: IApplicationState) => state.clients
export const getAddTelegram = (state: IApplicationState) => state.addTelegram
export const getAddYandexPromo = (state: IApplicationState) => state.addYandexPromo
export const getAddVKAds = (state: IApplicationState) => state.addVKAds
export const getAddVKAdsMarket = (state: IApplicationState) => state.addVKAdsMarket
export const getBudget = (state: IApplicationState) => state.budget
export const getAdsMarkingAccount = (state: IApplicationState) => state.adsMarkingAccounts
export const getAdsMarkingContracts = (state: IApplicationState) => state.adsMarkingContracts
export const getAdsMarkingCounterparties = (state: IApplicationState) => state.adsMarkingCounterparties
export const getAdsMarkingAddContract = (state: IApplicationState) => state.adsMarkingAddContract
export const getCampaignEditor = (state: IApplicationState) => state.campaignEditor
export const getCampaignEditorFilters = (state: IApplicationState) => state.campaignEditor.filters
export const getCampaignEditorCampaignData = (state: IApplicationState) => state.campaignEditor.campaignData
export const getCampaignEditorAdsData = (state: IApplicationState) => state.campaignEditor.adsData
export const getCampaignEditorAdsetData = (state: IApplicationState) => state.campaignEditor.adsetData
export const getCampaignEditorUndistributedData = (state: IApplicationState) => state.campaignEditor.undistributedData
export const getCampaignEditorTableDataCount = (state: IApplicationState) => state.campaignEditor.tableDataCount
export const getCampaignEditorSettings = (state: IApplicationState) => state.campaignEditor.settings
export const getCampaignEditorAccountSelectedList = (state: IApplicationState) =>
  state.campaignEditor.accountSelectedList
export const getCampaignEditorHasActiveAccounts = (state: IApplicationState) => state.campaignEditor.hasActiveAccounts
export const getCampaignEditorCounter = (state: IApplicationState) => state.campaignEditor.counter
export const getCampaignEditorSelectedAllRowsType = (state: IApplicationState) => state.campaignEditor.selectAllRowsType
export const getCampaignEditorSelectedCampaignIds = (state: IApplicationState) =>
  state.campaignEditor.selectedCampaignIds
export const getCampaignEditorSelectedAdsetIds = (state: IApplicationState) => state.campaignEditor.selectedAdsetIds
export const getCampaignEditorSelectedAdIds = (state: IApplicationState) => state.campaignEditor.selectedAdIds
export const getCampaignEditorSelectedUndistributedIds = (state: IApplicationState) =>
  state.campaignEditor.selectedUndistributedIds
export const getCampaignEditorAdError = (state: IApplicationState) => state.campaignEditor.isAdError
export const getCampaignEditorAdsetError = (state: IApplicationState) => state.campaignEditor.isAdsetError
export const getCampaignEditorCampaignError = (state: IApplicationState) => state.campaignEditor.isCampaignError
export const getCampaignEditorUndistributedError = (state: IApplicationState) =>
  state.campaignEditor.isUndistributedError
export const getCampaignEditorAdLoading = (state: IApplicationState) => state.campaignEditor.isAdLoading
export const getCampaignEditorAdsetLoading = (state: IApplicationState) => state.campaignEditor.isAdsetLoading
export const getCampaignEditorCampaignLoading = (state: IApplicationState) => state.campaignEditor.isCampaignLoading
export const getCampaignEditorUndistributeLoading = (state: IApplicationState) =>
  state.campaignEditor.isUndistributedLoading
export const getCampaignEditorForceUpdateLoading = (state: IApplicationState) =>
  state.campaignEditor.isForceUpdateLoading
export const getCampaignEditorAside = (state: IApplicationState) => state.campaignEditor.aside
export const getCampaignEditorAsideOpened = (state: IApplicationState) => state.campaignEditor.aside.isOpened

export const profileSelector = createSelector([getProfile], (profile) => profile)

export const getAnalytics = (state: IApplicationState) => state.analytics

export const getImportVK = (state: IApplicationState) => state.importVK

export const getLanguage = (state: IApplicationState) => state.user.language

export const getIsNotViewableUsnFeature = () => getIsNotViewable(IsNotViewableUsnFeature)
export const getIsNotViewableTelegramFeature = () => getIsNotViewable(IsNotViewableTelegramFeature)

export const getAccounts = (state: IApplicationState) => state.adAccounts

export const getAccountCounts = (state: IApplicationState) => {
  const accountCounts = state.user.profile.account_counts
  return (
    accountCounts.archived_accounts +
    accountCounts.avito +
    accountCounts.telegram +
    accountCounts.yandex +
    accountCounts.vk +
    accountCounts.mytarget +
    accountCounts.google +
    accountCounts.facebook +
    accountCounts.tiktok +
    accountCounts.vkads +
    accountCounts.yandex_promo_page +
    accountCounts.vk_adsmarket
  )
}
