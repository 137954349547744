import React from 'react'

import { IIcons } from './types'

export default (props: IIcons) => {
  const { style } = props
  const defaultStyle = { width: 40, height: 40 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
        fill="#FAFAFA"
      />
      <g clipPath="url(#clip0)">
        <path d="M8.5415 20L10.6248 22.0833L12.7082 20" stroke="#2E2E2E" strokeWidth="2" strokeLinecap="square" />
        <path d="M20 14.791V19.9993L23.125 23.1243" stroke="#2E2E2E" strokeWidth="2" strokeLinecap="square" />
        <path
          d="M20 29.375C25.1777 29.375 29.375 25.1777 29.375 20C29.375 14.8223 25.1777 10.625 20 10.625C14.8223 10.625 10.625 14.8223 10.625 20C10.625 19.9743 10.625 20.3215 10.625 21.0417"
          stroke="#2E2E2E"
          strokeWidth="2"
          strokeLinecap="square"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect x="7.5" y="7.5" width="25" height="25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
