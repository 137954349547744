import React from 'react'

import { IIcons } from './types'

export default (props: IIcons) => {
  const { style } = props
  const defaultStyle = { width: 11, height: 11 }

  return (
    <svg style={{ ...defaultStyle, ...style }} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.48565 8.21244C5.26772 7.98509 5.0554 7.76084 4.83952 7.54022C4.04064 6.72557 3.24023 5.91249 2.44237 5.09681C2.37516 5.02845 2.31202 4.95128 2.26518 4.8679C2.19288 4.73739 2.24023 4.6276 2.38229 4.58824C2.46477 4.56493 2.55387 4.55665 2.63992 4.55613C3.14807 4.55302 3.65673 4.55458 4.16487 4.55458C4.21375 4.55458 4.26263 4.55458 4.32577 4.55458C4.32577 4.48466 4.32577 4.42977 4.32577 4.37539C4.32577 3.3365 4.32526 2.29761 4.32628 1.25872C4.32679 0.813334 4.48972 0.446667 4.86243 0.202741C5.24278 -0.0463646 5.65062 -0.0675981 6.04929 0.148362C6.47037 0.376234 6.67251 0.753777 6.67455 1.2349C6.67862 2.26446 6.67557 3.29403 6.67557 4.3236C6.67557 4.39299 6.67557 4.46239 6.67557 4.55406C6.7331 4.55406 6.78147 4.55406 6.82984 4.55406C7.33341 4.55406 7.83748 4.55199 8.34104 4.55509C8.43626 4.55561 8.53453 4.56442 8.62567 4.59031C8.76263 4.62863 8.8054 4.73377 8.74074 4.86117C8.70561 4.93005 8.65774 4.99634 8.60377 5.05123C7.58493 6.09012 6.56457 7.12746 5.5442 8.16531C5.53453 8.17515 5.52282 8.1824 5.48565 8.21244Z" />
      <path d="M5.50998 10.9997C3.98503 10.9997 2.46008 10.9997 0.935642 10.9997C0.465173 10.9997 0.118941 10.7257 0.0196533 10.2762C-0.0663957 9.88778 0.136252 9.45896 0.507434 9.29634C0.645927 9.23575 0.807841 9.20468 0.959064 9.20468C3.01049 9.19898 5.06192 9.20105 7.11335 9.20105C8.09807 9.20105 9.08229 9.20053 10.067 9.20209C10.5181 9.2026 10.8659 9.46259 10.9703 9.87069C11.12 10.4554 10.6989 10.9966 10.0853 10.9987C9.0049 11.0023 7.92394 11.0002 6.84349 11.0002C6.39848 10.9997 5.95449 10.9997 5.50998 10.9997Z" />
    </svg>
  )
}
