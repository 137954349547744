import {
  setAsideActiveNavItem,
  setAsideLimitRule,
  setAsideNavDisabled,
  setAsideOpened,
  setAsideStopDateRule,
  setAsideValues,
  updateAsideValues,
} from '../actions'
import { openCampaignEditorReportModal } from '../CampaignReport/modals/actions'
import { MODALS } from '../CampaignReport/modals/types'
import { SORT_STATUS } from '../components/Table/types'
import {
  AdsetsResult,
  AdsResult,
  CampaignsResult,
  DailyLevel,
  ExtraFilterType,
  FilterTabsType,
  FilterType,
  IFilterStepsData,
  Inherits,
  MassEditingTypes,
} from '../types'
import { checkHeredity, inheritsModalText } from '../utils'
import { ITableDataAdsSelectedIds, ITableDataGroupSelectedIds } from './types'

import { MyThunkDispatch } from '@/store/store'

const enum EntityType {
  STATUS = 'status',
  NAME = 'name',
}

type Entity = EntityType.STATUS | EntityType.NAME

interface ISortableGenerateQueryType {
  direction: string
  accessor: string
}

export const ADS_ID = 'ads_id'
export const ADSET_ID = 'adset_id'
export const CAMPAIGN_ID = 'campaign_id'
export const DELIMITER = '&'

const ADS_STATUS = 'ads_status'
const ADS_NAME = 'ads_name'
const ADSETS_STATUS = 'adsets_status'
const ADSETS_NAME = 'adsets_name'
const CAMPAIGNS_STATUS = 'campaigns_status'
const CAMPAIGNS_NAME = 'campaigns_name'

export const addThousandsSeparator = (number: string) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const getSortableGenerateQueryString = (sort: ISortableGenerateQueryType) => {
  if (sort.direction === SORT_STATUS.NONE) {
    return ''
  }
  if (sort.direction === SORT_STATUS.ASC) {
    return `ordering=${sort.accessor}`
  }
  if (sort.direction === SORT_STATUS.DESC) {
    return `ordering=-${sort.accessor}`
  }
  return ''
}

interface IAdsGenerateQueryString {
  selectedIds: ITableDataAdsSelectedIds
  filters: IFilterStepsData[]
  sort?: ISortableGenerateQueryType
}

export const adsGenerateQueryString = ({
  selectedIds,
  filters,
  sort = { direction: 'none', accessor: '' },
}: IAdsGenerateQueryString) => {
  const filterString = getFilterString(filters)
  const sortString = getSortableGenerateQueryString(sort)

  const { adsetsIds = [], campaignIds = [] } = selectedIds

  let queryString = ''

  if (adsetsIds.length !== 0 || campaignIds.length !== 0) {
    if (adsetsIds.length > 0) {
      queryString += adsetsIds.map((id) => `${ADSET_ID}=${id}`).join(DELIMITER)
    }

    if (campaignIds.length > 0) {
      if (queryString !== '') {
        queryString += DELIMITER
      }
      queryString += campaignIds.map((id) => `${CAMPAIGN_ID}=${id}`).join(DELIMITER)
    }
  }

  if (filterString.length !== 0) {
    if (queryString !== '') {
      queryString += DELIMITER
    }
    queryString += filterString
  }

  if (sortString.length !== 0) {
    if (queryString !== '') {
      queryString += DELIMITER
    }
    queryString += sortString
  }

  return queryString
}

interface IGroupGenerateQueryString {
  selectedIds: ITableDataGroupSelectedIds
  filters: IFilterStepsData[]
  sort?: ISortableGenerateQueryType
}

export const groupGenerateQueryString = ({
  selectedIds,
  filters,
  sort = { direction: 'none', accessor: '' },
}: IGroupGenerateQueryString) => {
  const filterString = getFilterString(filters)
  const sortString = getSortableGenerateQueryString(sort)

  const { campaignIds = [] } = selectedIds

  let queryString = ''

  if (campaignIds.length !== 0) {
    queryString += campaignIds.map((id) => `${CAMPAIGN_ID}=${id}`).join(DELIMITER)
  }

  if (filterString.length !== 0) {
    if (queryString !== '') {
      queryString += DELIMITER
    }
    queryString += filterString
  }

  if (sortString.length !== 0) {
    if (queryString !== '') {
      queryString += DELIMITER
    }
    queryString += sortString
  }

  return queryString
}

interface ICompanyGenerateQueryString {
  filters: IFilterStepsData[]
  sort?: ISortableGenerateQueryType
}

export const companyGenerateQueryString = ({
  filters,
  sort = { direction: 'none', accessor: '' },
}: ICompanyGenerateQueryString) => {
  const filterString = getFilterString(filters)
  const sortString = getSortableGenerateQueryString(sort)

  let queryString = ''

  if (filterString.length > 0) {
    queryString += filterString
  }

  if (sortString.length > 0) {
    if (queryString.length > 0) {
      queryString += '&'
    }
    queryString += sortString
  }

  return queryString
}

export const undistributedGenerateQueryString = (
  filters: IFilterStepsData[],
  sort: ISortableGenerateQueryType = { direction: 'none', accessor: '' }
) => {
  const filterString = getFilterString(filters, FilterType.ADS)
  const sortString = getSortableGenerateQueryString(sort)

  let queryString = ''

  if (filterString.length > 0) {
    queryString += filterString
  }

  if (sortString.length > 0) {
    if (queryString.length > 0) {
      queryString += DELIMITER
    }
    queryString += sortString
  }

  return queryString
}

const getFilterString = (filters: IFilterStepsData[], specificType?: FilterType) => {
  const companyFilters = filters.find((filter) => filter.type === FilterType.COMPANY)
  const groupFilters = filters.find((filter) => filter.type === FilterType.GROUP)
  const adsFilters = filters.find((filter) => filter.type === FilterType.ADS)

  const hasFilters =
    Boolean(companyFilters?.statuses.length) ||
    Boolean(groupFilters?.statuses.length) ||
    Boolean(adsFilters?.statuses.length) ||
    Boolean(companyFilters?.names.length) ||
    Boolean(groupFilters?.names.length) ||
    Boolean(adsFilters?.names.length)

  let queryString = ''

  if (hasFilters && specificType) {
    const result = filters.find((filter) => filter.type === specificType)
    if (result && Boolean(result.statuses.length)) {
      queryString += `${getQueryPrefix(specificType, EntityType.STATUS)}=${result.statuses.join(',')}`
    }
    if (result && Boolean(result.names.length)) {
      if (queryString !== '') {
        queryString += DELIMITER
      }
      queryString += `${getQueryPrefix(specificType, EntityType.NAME)}=${result.names.join(',')}`
    }
    return queryString
  }

  if (hasFilters) {
    if (companyFilters && Boolean(companyFilters.statuses.length)) {
      queryString += `${CAMPAIGNS_STATUS}=${companyFilters.statuses.join(',')}`
    }

    if (companyFilters && Boolean(companyFilters.names.length)) {
      if (queryString !== '') {
        queryString += DELIMITER
      }
      queryString += `${CAMPAIGNS_NAME}=${companyFilters.names.join(',')}`
    }

    if (groupFilters && Boolean(groupFilters.statuses.length)) {
      if (queryString !== '') {
        queryString += DELIMITER
      }
      queryString += `${ADSETS_STATUS}=${groupFilters.statuses.join(',')}`
    }

    if (groupFilters && Boolean(groupFilters.names.length)) {
      if (queryString !== '') {
        queryString += DELIMITER
      }
      queryString += `${ADSETS_NAME}=${groupFilters.names.join(',')}`
    }

    if (adsFilters && Boolean(adsFilters.statuses.length)) {
      if (queryString !== '') {
        queryString += DELIMITER
      }
      queryString += `${ADS_STATUS}=${adsFilters.statuses.join(',')}`
    }

    if (adsFilters && Boolean(adsFilters.names.length)) {
      if (queryString !== '') {
        queryString += DELIMITER
      }
      queryString += `${ADS_NAME}=${adsFilters.names.join(',')}`
    }
  }

  return queryString
}

const getQueryPrefix = (type: FilterTabsType, entity: Entity) => {
  switch (type) {
    case FilterType.ADS:
    case ExtraFilterType.UNDISTRIBUTED:
      return entity === EntityType.STATUS ? ADS_STATUS : ADS_NAME
    case FilterType.GROUP:
      return entity === EntityType.STATUS ? ADSETS_STATUS : ADSETS_NAME
    case FilterType.COMPANY:
      return entity === EntityType.STATUS ? CAMPAIGNS_NAME : CAMPAIGNS_STATUS
  }
}

interface ICheckAndOpenCurrentAsideHandler {
  id: number
  type: DailyLevel
  navItem: MassEditingTypes
  data: Array<AdsResult | AdsetsResult | CampaignsResult> | []
  dispatch: MyThunkDispatch
  isRule?: boolean //TODO SMB-5624
}

export const checkAndOpenCurrentAsideHandler = (props: ICheckAndOpenCurrentAsideHandler) => () => {
  const { id, type, navItem, data, dispatch, isRule } = props

  const currentValue = data.find((value) => value.id === id)

  if (!currentValue) {
    return
  }

  const limitLevel = currentValue.daily_budget?.level
  const stopLevel = currentValue.day_stop?.level
  const currentLevel = navItem === MassEditingTypes.limit ? limitLevel : stopLevel

  if (currentLevel) {
    const currentInherits = checkHeredity(type, currentLevel)

    const inheritsText = inheritsModalText()

    const inheritsLevel = Object.keys(currentInherits).filter((key) => currentInherits[key as Inherits])[0]

    if (currentInherits.child || currentInherits.father) {
      dispatch(
        openCampaignEditorReportModal({
          name: MODALS.WARNING_ASIDE,
          data: { text: inheritsText[type][inheritsLevel as Inherits] },
        })
      )
      return
    }

    if (navItem === MassEditingTypes.limit && stopLevel !== undefined) {
      const { child, father } = checkHeredity(type, stopLevel)
      if (child || father) {
        dispatch(setAsideNavDisabled(MassEditingTypes.stop, true))
      }
    }

    if (navItem === MassEditingTypes.stop && limitLevel !== undefined) {
      const { child, father } = checkHeredity(type, limitLevel)
      if (child || father) {
        dispatch(setAsideNavDisabled(MassEditingTypes.limit, true))
      }
    }
  }

  const newAside = [
    {
      id: Number(id),
      type: type,
      name: currentValue.name,
      limit: {
        budget_id: currentValue.daily_budget?.id ?? null,
        created_at: currentValue.daily_budget?.created_at ?? null,
        amount: currentValue.daily_budget?.amount ?? 0,
        rule: currentValue.daily_budget?.rule ?? null,
      },
      stop: {
        stop_id: currentValue.day_stop?.id ?? null,
        created_at: currentValue.day_stop?.created_at ?? null,
        date: currentValue.day_stop?.date_stop ? new Date(currentValue.day_stop?.date_stop).toString() : null,
        rule: currentValue.day_stop?.rule ?? null,
      },
    },
  ]

  if (navItem === MassEditingTypes.stop) {
    dispatch(setAsideStopDateRule({ isSetStopDateRule: Boolean(isRule) }))
  }

  if (navItem === MassEditingTypes.limit) {
    dispatch(setAsideLimitRule({ isSetLimitRule: Boolean(isRule) }))
  }

  dispatch(setAsideValues(newAside))
  dispatch(setAsideActiveNavItem(navItem))
  dispatch(setAsideOpened({ isOpened: true, isAll: false }))
}

interface ICheckAndOpenNewAsideHandler {
  id: number
  type: DailyLevel
  navItem: MassEditingTypes
  data: Array<AdsResult | AdsetsResult | CampaignsResult> | []
  dispatch: MyThunkDispatch
}

export const checkAndOpenNewAsideHandler = (props: ICheckAndOpenNewAsideHandler) => () => {
  const { id, navItem, dispatch, data, type } = props

  const currentValue = data.find((value) => value.id === id)

  if (!currentValue) {
    return
  }

  const limitLevel = currentValue?.daily_budget?.level
  const stopLevel = currentValue?.day_stop?.level

  if (navItem === MassEditingTypes.limit && stopLevel !== undefined) {
    const { child, father } = checkHeredity(type, stopLevel)
    if (child || father) {
      dispatch(setAsideNavDisabled(MassEditingTypes.stop, true))
    }
  }

  if (navItem === MassEditingTypes.stop && limitLevel !== undefined) {
    const { child, father } = checkHeredity(type, limitLevel)
    if (child || father) {
      dispatch(setAsideNavDisabled(MassEditingTypes.limit, true))
    }
  }

  const newAside = [
    {
      id: currentValue.id,
      type: type,
      name: currentValue.name,
      limit: {
        budget_id: currentValue.daily_budget?.id ?? null,
        created_at: currentValue.daily_budget?.created_at ?? null,
        amount: currentValue.daily_budget?.amount ?? 0,
        rule: currentValue.daily_budget?.rule ?? null,
      },
      stop: {
        stop_id: currentValue.day_stop?.id ?? null,
        created_at: currentValue.day_stop?.created_at ?? null,
        date: currentValue.day_stop?.date_stop ? new Date(currentValue.day_stop?.date_stop).toString() : null,
        rule: currentValue.day_stop?.rule ?? null,
      },
    },
  ]

  dispatch(updateAsideValues(newAside))
  dispatch(setAsideActiveNavItem(navItem))
  dispatch(setAsideOpened({ isOpened: true, isAll: false }))
}
