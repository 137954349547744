import React from 'react'

import { GreyLighten47 } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = GreyLighten47 } = props
  const defaultStyle = { width: 22, height: 22 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.8906 6.85938C13.1368 6.85938 12.5234 6.24605 12.5234 5.49219V1H5.53125C4.3466 1 3.38281 1.96379 3.38281 3.14844V18.8516C3.38281 20.0362 4.3466 21 5.53125 21H16.4688C17.6534 21 18.6172 20.0362 18.6172 18.8516V6.85938H13.8906ZM6.58594 15.0625H9.42656C9.75016 15.0625 10.0125 15.3248 10.0125 15.6484C10.0125 15.972 9.75016 16.2344 9.42656 16.2344H6.58594C6.26234 16.2344 6 15.972 6 15.6484C6 15.3248 6.26234 15.0625 6.58594 15.0625ZM6 12.5234C6 12.1998 6.26234 11.9375 6.58594 11.9375H15.1797C15.5033 11.9375 15.7656 12.1998 15.7656 12.5234C15.7656 12.847 15.5033 13.1094 15.1797 13.1094H6.58594C6.26234 13.1094 6 12.847 6 12.5234ZM15.1797 8.8125C15.5033 8.8125 15.7656 9.07484 15.7656 9.39844C15.7656 9.72203 15.5033 9.98438 15.1797 9.98438H6.58594C6.26234 9.98438 6 9.72203 6 9.39844C6 9.07484 6.26234 8.8125 6.58594 8.8125H15.1797Z"
        fill={fill}
      />
      <path
        d="M13.6953 5.49219C13.6953 5.59989 13.7829 5.6875 13.8906 5.6875H18.356C18.2488 5.48961 18.1109 5.30785 17.9453 5.1511L14.1788 1.58774C14.0326 1.44946 13.8698 1.3341 13.6954 1.24301L13.6953 5.49219Z"
        fill={fill}
      />
    </svg>
  )
}
