import { AxiosError, AxiosResponse } from 'axios'
import { AnyAction, Dispatch } from 'redux'

import { API_URL } from '../../const'
import { ThunkResult } from '../../store/store'
import { customAxios } from '../../utils'
import { IConfirmClientRequest } from './types'

export enum ConfirmClientActionTypes {
  INITIALIZE_CONFIRMATION = 'INITIALIZE_CONFIRMATION',
  AGREE_TERMS = 'AGREE_TERMS',
  PROVIDE_DATA = 'PROVIDE_DATA',
  SET_STEP = 'SET_STEP',
  SET_CLIENT_CONFIRMATION = 'SET_CLIENT_CONFIRMATION',
  SET_REDIRECT = 'SET_REDIRECT',
  SET_ERROR = 'SET_ERROR',
}

export function initializeConfirmation(clientConfirmation: boolean): AnyAction {
  return { type: ConfirmClientActionTypes.INITIALIZE_CONFIRMATION, data: clientConfirmation }
}

export function agreeTerms(value: boolean): AnyAction {
  return { type: ConfirmClientActionTypes.AGREE_TERMS, data: value }
}

export function provideData(value: boolean): AnyAction {
  return { type: ConfirmClientActionTypes.PROVIDE_DATA, data: value }
}

export function setStep(step: number): AnyAction {
  return { type: ConfirmClientActionTypes.SET_STEP, data: step }
}

export function setRedirect(value: boolean): AnyAction {
  return { type: ConfirmClientActionTypes.SET_REDIRECT, data: value }
}

export function setError(error: { [key: string]: string[] }): AnyAction {
  return { type: ConfirmClientActionTypes.SET_ERROR, data: error }
}

export function getClientConfirmation(uuid: string): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/profile_agency/client/confirm/${uuid}`, 'GET', null, false)
      .then((response: AxiosResponse<any>) => {
        dispatch({
          type: ConfirmClientActionTypes.SET_CLIENT_CONFIRMATION,
          data: response.data,
        })
        return response
      })
      .catch((error: AxiosError) => {
        dispatch({
          type: ConfirmClientActionTypes.SET_REDIRECT,
          data: true,
        })

        return Promise.reject(error)
      })
  }
}

export function confirmClient(uuid: string, body: IConfirmClientRequest): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/profile_agency/client/confirm/${uuid}`, 'POST', body, false)
      .then((response: AxiosResponse<any>) => {
        return response
      })
      .catch((error: AxiosError) => {
        dispatch({
          type: ConfirmClientActionTypes.SET_ERROR,
          data: error.response?.data,
        })

        return Promise.reject(error)
      })
  }
}
