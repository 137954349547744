export enum StoriesActionTypes {
  SET_STORIES = 'SET_STORIES',
  SET_STORY_CARD_WATCHED = 'SET_STORY_CARD_WATCHED',
  SET_STORY_WATCHED = 'SET_STORY_WATCHED',
}

export interface IStory {
  id: number
  contents: IStoryCard[]
  is_watched: boolean
  name: string
  preview: null | IStoryPreview
}

export interface IStoryPreview {
  file: string
  text: string
}

export interface IStoryCard {
  body_text: string
  button_is_transparent: boolean
  button_name: string
  button_url: string
  file: string
  header_size: 'big' | 'small' | ''
  header_text: string
  id: number
  is_watched: boolean
  body_text_color: 'black' | 'white'
}

export type IStoriesState = IStory[]
