import React from 'react'

import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = '#E3D8FC' } = props
  const defaultStyle = { width: 26, height: 18 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 26 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.6681 12.186C12.8271 12.5456 13.8147 12.3469 14.3327 12.186L20.2241 9.41845L20.8002 14.0768C20.8002 15.5281 17.308 17.6429 13.0002 17.6429C8.69226 17.6429 5.20012 15.5281 5.20012 14.0768L5.77619 9.41804L11.6681 12.186Z"
        fill={fill}
      />
      <path
        d="M0.717541 4.60083L12.0495 0.143802C12.667 -0.047934 13.3333 -0.047934 13.9504 0.143802L25.2828 4.60083C26.2391 4.89767 26.2391 6.17577 25.2828 6.47261L23.4033 7.21188C23.7799 7.72861 24.0091 8.34329 24.0335 9.01157C24.4248 9.23821 24.7002 9.64713 24.7002 10.1353C24.7002 10.5779 24.4694 10.9503 24.1371 11.1884L25.1743 15.905C25.2645 16.3152 24.9557 16.7044 24.5401 16.7044H22.2607C21.8447 16.7044 21.5359 16.3152 21.6261 15.905L22.6633 11.1884C22.3309 10.9503 22.1002 10.5779 22.1002 10.1353C22.1002 9.66027 22.363 9.26284 22.7364 9.0321C22.7106 8.51816 22.4897 8.05383 22.1287 7.71328L13.9508 10.93C13.5827 11.0442 12.8767 11.1867 12.0499 10.93L0.717541 6.47302C-0.239182 6.17577 -0.239182 4.89808 0.717541 4.60083Z"
        fill={fill}
      />
    </svg>
  )
}
