export enum IMPORT_VK_MODE {
  ADD = 'add',
  RULES = 'rules',
}

export enum IMPORT_VK_STATUS {
  IN_PROGRESS = 'in_progress',
  ERROR = 'error',
  SUCCESS = 'success',
}

export interface IVkCategories {
  id: number
  name: string
}

export interface IFlags {
  [key: string]: string
}

export interface IVkFeed {
  id: number
  created_at: Date
  source: string
  feed_type: string
  feed_status: string
  flags: IFlags
}

export interface IVkUserBody {
  code: string
  redirect_uri?: string
}

export interface IVkUserResponse {
  name: string
  photo: string
}

export interface IVkUser {
  vk_user_id: number
  name: string
  photo: string
}

export interface IVkGroups {
  id: string
  name: string
  photo_200: string
  screen_name: string
}

export interface IImportRulesBody {
  vk_group: string
  client_feed: string
  vk_category: string
}

export interface IImportRulesResponse {
  id: number
  vk_group: string
  vk_category: number
  client_feed: number
  products_count_total: number
  products_count_upload: number
  products_count_error: number
  autoupdate: boolean
  status: IMPORT_VK_STATUS
  products_ready: boolean
  created_at: string
  updated_at: string
  last_success_update: null | string
  flags: IFlags
}

export interface IImportRule {
  id: number
  vk_group: IVkGroups
  vk_category: IVkCategories
  client_feed: IVkFeed
  products_count_total: number
  products_count_upload: number
  products_count_error: number
  autoupdate: boolean
  status: IMPORT_VK_STATUS
  products_ready: boolean
  created_at: string
  updated_at: string
  last_success_update: null | string
  flags: IFlags
}

export interface IProduct {
  feed_internal_id: string
  name: string
  image: string
  flags: IFlags
}

export interface IErrorProducts {
  page_count: number
  count: number
  current: number
  next: null | number
  previous: null | number
  results: IProduct[]
}
