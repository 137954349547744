import React from 'react'

import { IIcons } from './types'

export default (props: IIcons) => {
  const { style } = props
  const defaultStyle = { width: 66, height: 37 }

  return (
    <svg
      style={{ ...defaultStyle, ...style }}
      width="66"
      height="37"
      viewBox="0 0 66 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.507812" width="8" height="8" rx="4" fill="#C9CFD3" />
      <rect x="14.5" y="0.507812" width="51" height="8" rx="4" fill="#C9CFD3" />
      <path
        d="M14.5 4.50781C14.5 2.29867 16.2909 0.507812 18.5 0.507812H59C61.2091 0.507812 63 2.29867 63 4.50781C63 6.71695 61.2091 8.50781 59 8.50781H18.5C16.2909 8.50781 14.5 6.71695 14.5 4.50781Z"
        fill="url(#paint0_linear_20055_261973)"
      />
      <path
        d="M7 4.50781C7 6.71695 5.5 8.50781 4.5 8.50781C2.29086 8.50781 0.5 6.71695 0.5 4.50781C0.5 2.29867 2.29086 0.507812 4.5 0.507812C5.5 0.507812 7 2.29867 7 4.50781Z"
        fill="#D3D3D3"
      />
      <path
        d="M7 4.50781C7 6.71695 5.5 8.50781 4.5 8.50781C2.29086 8.50781 0.5 6.71695 0.5 4.50781C0.5 2.29867 2.29086 0.507812 4.5 0.507812C5.5 0.507812 7 2.29867 7 4.50781Z"
        fill="url(#paint1_linear_20055_261973)"
      />
      <rect x="0.5" y="14.5078" width="8" height="8" rx="4" fill="#C9CFD3" />
      <rect x="14.5" y="14.5078" width="51" height="8" rx="4" fill="#C9CFD3" />
      <path
        d="M14.5 18.5078C14.5 16.2987 16.2909 14.5078 18.5 14.5078H59C61.2091 14.5078 63 16.2987 63 18.5078C63 20.717 61.2091 22.5078 59 22.5078H18.5C16.2909 22.5078 14.5 20.717 14.5 18.5078Z"
        fill="url(#paint2_linear_20055_261973)"
      />
      <path
        d="M7 18.5078C7 20.717 5.5 22.5078 4.5 22.5078C2.29086 22.5078 0.5 20.717 0.5 18.5078C0.5 16.2987 2.29086 14.5078 4.5 14.5078C5.5 14.5078 7 16.2987 7 18.5078Z"
        fill="#D3D3D3"
      />
      <path
        d="M7 18.5078C7 20.717 5.5 22.5078 4.5 22.5078C2.29086 22.5078 0.5 20.717 0.5 18.5078C0.5 16.2987 2.29086 14.5078 4.5 14.5078C5.5 14.5078 7 16.2987 7 18.5078Z"
        fill="url(#paint3_linear_20055_261973)"
      />
      <rect x="0.5" y="28.5078" width="8" height="8" rx="4" fill="#C9CFD3" />
      <rect x="14.5" y="28.5078" width="51" height="8" rx="4" fill="#C9CFD3" />
      <path
        d="M14.5 32.5078C14.5 30.2987 16.2909 28.5078 18.5 28.5078H59C61.2091 28.5078 63 30.2987 63 32.5078C63 34.717 61.2091 36.5078 59 36.5078H18.5C16.2909 36.5078 14.5 34.717 14.5 32.5078Z"
        fill="url(#paint4_linear_20055_261973)"
      />
      <path
        d="M7 32.5078C7 34.717 5.5 36.5078 4.5 36.5078C2.29086 36.5078 0.5 34.717 0.5 32.5078C0.5 30.2987 2.29086 28.5078 4.5 28.5078C5.5 28.5078 7 30.2987 7 32.5078Z"
        fill="#D3D3D3"
      />
      <path
        d="M7 32.5078C7 34.717 5.5 36.5078 4.5 36.5078C2.29086 36.5078 0.5 34.717 0.5 32.5078C0.5 30.2987 2.29086 28.5078 4.5 28.5078C5.5 28.5078 7 30.2987 7 32.5078Z"
        fill="url(#paint5_linear_20055_261973)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_20055_261973"
          x1="61.5"
          y1="1.00781"
          x2="59.4986"
          y2="13.3216"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CED8E0" />
          <stop offset="1" stopColor="#E2E6EA" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_20055_261973"
          x1="6.79897"
          y1="1.00781"
          x2="0.595216"
          y2="6.12329"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CED8E0" />
          <stop offset="1" stopColor="#E2E6EA" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_20055_261973"
          x1="61.5"
          y1="15.0078"
          x2="59.4986"
          y2="27.3216"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CED8E0" />
          <stop offset="1" stopColor="#E2E6EA" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_20055_261973"
          x1="6.79897"
          y1="15.0078"
          x2="0.595216"
          y2="20.1233"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CED8E0" />
          <stop offset="1" stopColor="#E2E6EA" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_20055_261973"
          x1="61.5"
          y1="29.0078"
          x2="59.4986"
          y2="41.3216"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CED8E0" />
          <stop offset="1" stopColor="#E2E6EA" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_20055_261973"
          x1="6.79897"
          y1="29.0078"
          x2="0.595216"
          y2="34.1233"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CED8E0" />
          <stop offset="1" stopColor="#E2E6EA" />
        </linearGradient>
      </defs>
    </svg>
  )
}
