import { IAdAccount } from '../adaccounts/types'
import { UserProfileStatuses } from '../user/types'

import { CompanyType } from '@/types'

export enum ClientsActionTypes {
  SET_CLIENTS = 'SET_CLIENTS',
  SET_CLIENT_ACCOUNTS = 'SET_CLIENT_ACCOUNTS',
  SET_CLIENT_REQUESTS = 'SET_CLIENT_REQUESTS',
}

export type AccountTypes = 'facebook' | 'google' | 'vk' | 'mytarget' | 'tiktok' | 'yandex'

export type AccountExtraTypes =
  | AccountTypes
  | 'archived_fb'
  | 'archived_google'
  | 'archived_yandex'
  | 'archived_vk'
  | 'archived_mytarget'
  | 'archived_tiktok'
  | 'archived_accounts'
  | 'archived_vkads'

interface ICashback {
  amount: number
  month: string
}

export type ICountedAccounts = {
  [key in AccountExtraTypes]: number
}

export interface IClientsResult {
  readonly id: number
  readonly name: string
  readonly is_pay_own: boolean
  readonly inn: string
  readonly balance: string
  readonly counted_accounts: ICountedAccounts
  readonly cashback: ICashback
}

export interface IClientsObject {
  page_count: number
  count: number
  current: number
  next: number | null
  previous: number | null
  results: IClientsResult[]
}

export interface IClientProfile {
  inn: string
  name: string
  personal_phone: string
  profile_type: CompanyType
}

export interface IClientsState {
  loading: boolean
  clients: IClientsResult[]
  pagination_object: IClientsObject
  clientAccounts: { clientProfile: IClientProfile; [key: number]: IAdAccount[] } | null
  requests: IClientRequest[]
}

export interface IClientRequest {
  client: string
  client_email: string
  client_id: number
  client_inn: string
  client_legal_status: UserProfileStatuses
  client_policy_status: UserProfileStatuses
  id: number
  request_approved: ClientRequestStatuses
  wait_doc_reason: string
}

export enum ClientRequestStatuses {
  WAITED = 'waited',
  SUCCESS = 'success',
  REJECTED = 'rejected',
}

export type ClientPageType = number | 'all'
