import { Reducer } from 'redux'

import { AddYandexPromoActionTypes } from './actions'
import { reducerName } from './modals/actions'
import { IAddYandexPromoState } from './types'

export const initialState: IAddYandexPromoState = {
  error: null,
  isLoading: false,
  modals: null,
  redirect: '',
}

const addYandexPromoReducer: Reducer<IAddYandexPromoState> = (state = initialState, action: any) => {
  switch (action.type) {
    case AddYandexPromoActionTypes.SET_YANDEX_PROMO_PAGE_LOADING: {
      return { ...state, isLoading: action.data }
    }
    case AddYandexPromoActionTypes.SET_YANDEX_PROMO_PAGE_ERROR: {
      return { ...state, error: action.data }
    }
    case AddYandexPromoActionTypes.SET_YANDEX_PROMO_PAGE_REDIRECT: {
      return { ...state, redirect: action.data }
    }
    case AddYandexPromoActionTypes.REMOVE_YANDEX_PROMO_PAGE_ERROR: {
      return {
        ...state,
        error: null,
      }
    }
    case `${reducerName}.OPEN_MODAL`: {
      return {
        ...state,
        modals: action.payload,
      }
    }
    case `${reducerName}.CLOSE_MODAL`: {
      return {
        ...state,
        modals: null,
      }
    }
    default: {
      return state
    }
  }
}

export default addYandexPromoReducer
