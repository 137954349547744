import React from 'react'

import { IIcons } from './types'

export default (props: IIcons) => {
  const { style } = props
  const defaultStyle = { width: 41, height: 42 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 41 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.5 42C31.5457 42 40.5 32.598 40.5 21C40.5 9.40202 31.5457 0 20.5 0C9.45431 0 0.5 9.40202 0.5 21C0.5 32.598 9.45431 42 20.5 42Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.73361 20.7783C15.564 18.1111 19.4519 16.3527 21.3972 15.5031C26.9514 13.0775 28.1055 12.6561 28.8577 12.6422C29.0232 12.6391 29.3931 12.6822 29.6327 12.8863C29.8351 13.0587 29.8907 13.2916 29.9174 13.455C29.944 13.6185 29.9772 13.9908 29.9508 14.2817C29.6498 17.6023 28.3475 25.6605 27.6849 29.3796C27.4046 30.9533 26.8525 31.481 26.3181 31.5326C25.1567 31.6448 24.2747 30.7267 23.1498 29.9524C21.3896 28.7408 20.3951 27.9866 18.6865 26.8044C16.7119 25.4381 17.9919 24.6871 19.1173 23.4599C19.4118 23.1387 24.5291 18.2514 24.6281 17.808C24.6405 17.7526 24.652 17.5459 24.5351 17.4368C24.4181 17.3276 24.2455 17.3649 24.121 17.3946C23.9444 17.4367 21.1324 19.3883 15.6849 23.2494C14.8867 23.8249 14.1637 24.1053 13.5159 24.0906C12.8018 24.0744 11.4282 23.6666 10.407 23.3181C9.15452 22.8906 8.15906 22.6646 8.24574 21.9385C8.29089 21.5604 8.78685 21.1736 9.73361 20.7783Z"
        fill="url(#paint0_linear_2421_434800)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2421_434800"
          x1="19.1005"
          y1="12.642"
          x2="19.1005"
          y2="31.4018"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2AABEE" />
          <stop offset="1" stopColor="#229ED9" />
        </linearGradient>
      </defs>
    </svg>
  )
}
