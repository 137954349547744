import React from 'react'

import { GreyBase } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = GreyBase } = props
  const defaultStyle = { width: 18, height: 18 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8" r="5.3" stroke={fill} strokeWidth="1.4" />
      <path
        d="M12.495 11.505C12.2216 11.2317 11.7784 11.2317 11.505 11.505C11.2317 11.7784 11.2317 12.2216 11.505 12.495L12.495 11.505ZM15.505 16.495C15.7784 16.7683 16.2216 16.7683 16.495 16.495C16.7683 16.2216 16.7683 15.7784 16.495 15.505L15.505 16.495ZM11.505 12.495L15.505 16.495L16.495 15.505L12.495 11.505L11.505 12.495Z"
        fill={fill}
      />
    </svg>
  )
}
