import { Reducer } from 'redux'

import { ConfirmClientActionTypes } from './actions'

export interface IConfirmClientState {
  initialized: boolean
  step: number
  termsAgreed: boolean
  providingDataAgreed: boolean
  clientConfirmation: {
    agency: string
    id: string
    is_activated: boolean
    is_new_client: boolean
    client: string
  }
  redirect: boolean
  error: {
    [key: string]: string[]
  }
}

export const initialState: IConfirmClientState = {
  clientConfirmation: {
    agency: '',
    client: '',
    id: '',
    is_activated: false,
    is_new_client: false,
  },
  termsAgreed: false,
  providingDataAgreed: false,
  initialized: false,
  step: 1,
  redirect: false,
  error: {},
}

const confirmClientReducer: Reducer<IConfirmClientState> = (state = initialState, action: any) => {
  switch (action.type) {
    case ConfirmClientActionTypes.INITIALIZE_CONFIRMATION: {
      return { ...state, initialized: action.data }
    }
    case ConfirmClientActionTypes.AGREE_TERMS: {
      return { ...state, termsAgreed: action.data }
    }
    case ConfirmClientActionTypes.PROVIDE_DATA: {
      return { ...state, providingDataAgreed: action.data }
    }
    case ConfirmClientActionTypes.SET_STEP: {
      return { ...state, step: action.data }
    }
    case ConfirmClientActionTypes.SET_CLIENT_CONFIRMATION: {
      return { ...state, clientConfirmation: action.data }
    }
    case ConfirmClientActionTypes.SET_REDIRECT: {
      return { ...state, redirect: action.data }
    }
    case ConfirmClientActionTypes.SET_ERROR: {
      return { ...state, error: action.data }
    }
    default: {
      return state
    }
  }
}

export default confirmClientReducer
