import React from 'react'

import { GreyBase } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = GreyBase, fillBg = GreyBase } = props
  const defaultStyle = { width: 18, height: 18 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="16" height="16" rx="8" fill={fillBg} />
      <path
        d="M8.00018 12C8.20057 12 8.39275 11.9204 8.53444 11.7787C8.67614 11.637 8.75574 11.4448 8.75574 11.2444V7.57966C8.75574 7.37928 8.67614 7.1871 8.53444 7.0454C8.39275 6.90371 8.20057 6.82411 8.00018 6.82411C7.7998 6.82411 7.60762 6.90371 7.46593 7.0454C7.32423 7.1871 7.24463 7.37928 7.24463 7.57966V11.2444C7.24463 11.4448 7.32423 11.637 7.46593 11.7787C7.60762 11.9204 7.7998 12 8.00018 12Z"
        fill={fill}
      />
      <path
        d="M7.99973 4.00005C8.23548 4.00005 8.46157 4.0937 8.62827 4.2604C8.79497 4.4271 8.88862 4.6532 8.88862 4.88894C8.88862 5.12469 8.79497 5.35078 8.62827 5.51748C8.46157 5.68418 8.23548 5.77783 7.99973 5.77783C7.76398 5.77783 7.53789 5.68418 7.37119 5.51748C7.20449 5.35078 7.11084 5.12469 7.11084 4.88894C7.11084 4.6532 7.20449 4.4271 7.37119 4.2604C7.53789 4.0937 7.76398 4.00005 7.99973 4.00005V4.00005Z"
        fill={fill}
      />
    </svg>
  )
}
