import React from 'react'

import { White } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = White } = props
  const defaultStyle = { width: 27, height: 19 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 27 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.89014 16.8194L8.07514 9.80438L14.7851 15.5994L24.5451 2.48438M24.5451 2.48438L20.5801 3.70438M24.5451 2.48438V6.75438"
        stroke={fill}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
