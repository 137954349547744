import React from 'react'

import { BackgroundGrey, GreenBase } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = BackgroundGrey, fillBg = GreenBase } = props
  const defaultStyle = { width: 17, height: 16 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" width="16" height="16" rx="8" fill={fillBg} stroke="transparent" />
      <path d="M5.30078 7.71429L7.62805 10L11.7008 6" stroke={fill} strokeLinecap="round" />
    </svg>
  )
}
