import React from 'react'

import { GreyBase } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = GreyBase } = props
  const defaultStyle = { width: 18, height: 18 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.5 6.63242V14.5391C14.5 14.9266 14.3551 15.2982 14.0973 15.5721C13.8394 15.8461 13.4897 16 13.125 16H4.875C4.51033 16 4.16059 15.8461 3.90273 15.5721C3.64487 15.2982 3.5 14.9266 3.5 14.5391V3.46087C3.5 3.07342 3.64487 2.70184 3.90273 2.42788C4.16059 2.15391 4.51033 2 4.875 2H10.0725M14.5 6.63242C14.5 6.54848 14.4932 6.46491 14.4797 6.38261C14.462 6.27409 14.4327 6.16779 14.3923 6.06578C14.3212 5.88641 14.2171 5.72393 14.0861 5.5879L11.0336 2.41635C10.8501 2.22568 10.6216 2.09495 10.375 2.03583C10.2765 2.0122 10.1751 2.00001 10.0725 2M14.5 6.63242H11.4444C11.0798 6.63242 10.73 6.4785 10.4722 6.20454C10.2143 5.93057 10.0694 5.55899 10.0694 5.17155L10.0725 2"
        stroke={fill}
        strokeWidth="1.3"
        strokeLinecap="round"
      />
    </svg>
  )
}
