import { createUseStyles } from 'react-jss'

import { TextGreyBase } from '../../../const/colors'
import { FontBody1, FontH4 } from '../../../const/fontStyles'
import { PurpleLighten6 } from './../../../const/colors'

export default createUseStyles(
  {
    bgImage: {
      width: '100%',
    },
    header: {
      marginBottom: 24,
    },
    title: {
      ...FontH4,
      margin: '0 0 24px',
      color: TextGreyBase,
    },
    link: {
      color: PurpleLighten6,
    },
    description: {
      ...FontBody1,
      color: TextGreyBase,
    },
    descriptionBottom: {
      marginTop: 24,
    },
  },
  { name: 'RedirectAitargetModal' }
)
