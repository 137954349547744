import React from 'react'

import { PurpleBase } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fillBg = '#C4C4C4', fill = PurpleBase } = props
  const defaultStyle = { width: 50, height: 50 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="25" cy="25" r="24.5" stroke={fillBg} />
      <path
        d="M26.8182 28.715H24.2434L23.9601 16.063H27.1138L26.8182 28.715ZM23.8738 32.5833C23.8738 32.1151 24.0258 31.7291 24.3297 31.4253C24.6335 31.1132 25.0483 30.9571 25.5739 30.9571C26.0995 30.9571 26.5143 31.1132 26.8182 31.4253C27.122 31.7291 27.274 32.1151 27.274 32.5833C27.274 33.035 27.1262 33.4128 26.8305 33.7167C26.5348 34.0205 26.116 34.1725 25.5739 34.1725C25.0319 34.1725 24.613 34.0205 24.3173 33.7167C24.0217 33.4128 23.8738 33.035 23.8738 32.5833Z"
        fill={fill}
      />
    </svg>
  )
}
