import React from 'react'
import { FormattedMessage, IntlShape } from 'react-intl'
import { ReactourStep } from 'reactour'
import { Classes } from 'jss'

import {
  deleteBudgets,
  deleteDaysStop,
  removeAllAsideValues,
  removeAsideLimitDraft,
  removeAsideStopDateDraft,
  removeAsideValues,
  setAds,
  setAsideLimitRule,
  setAsideNavError,
  setAsideOpened,
  setAsideStopDateRule,
  setCompany,
  setGroup,
  setUndistributed,
} from '../actions'
import { openCampaignEditorReportModal } from '../CampaignReport/modals/actions'
import { MODALS } from '../CampaignReport/modals/types'
import { MIXPANEL_KEYS_MASS_EDITING, MIXPANEL_PARAMS_MASS_EDITING } from '../constants'
import dailyBudgetImage from '../media/daily-budget-image.png'
import massManagementImage from '../media/mass-management-image.png'
import {
  adsGenerateQueryString,
  companyGenerateQueryString,
  groupGenerateQueryString,
  undistributedGenerateQueryString,
} from '../TableData/utils'
import { Budget, Budgets, DirectionType } from '../types'
import {
  AdsetsResult,
  AdsResult,
  AsideValues,
  CampaignsResult,
  DailyLevel,
  Days,
  DaysStop,
  ExtraFilterType,
  FilterTabsType,
  FilterType,
  ICounter,
  IFilterStepsData,
  IInherits,
  IInheritsModalText,
  Inherits,
  MassEditingTypes,
  MetricsType,
  RuleRequestBody,
  RULES_FIELDS,
  TableColumnType,
  TARGETS_FIELDS,
} from '../types'
import useStyles from './styles'

import { Mixpanel } from '@/analytics'
import { Button, BUTTON_SIZE, BUTTON_TYPE, BUTTON_VARIANT } from '@/components/ButtonSMB'
import { MyThunkDispatch } from '@/store/store'
import { capitalizeFirstLetter, getWordByNumber } from '@/utils'

export const getProductTourSteps = (onCloseClick: () => void, hasAds: boolean): ReactourStep[] => {
  const classes = useStyles()

  return [
    {
      content: (
        <div>
          <h4>
            <FormattedMessage id="campaignEditor.productTour.title1" />
          </h4>
          <p>
            <FormattedMessage id="campaignEditor.productTour.text1" />
          </p>
        </div>
      ),
      position: 'center',
    },
    {
      content: (
        <div>
          <h4>
            <FormattedMessage id="campaignEditor.productTour.title2" />
          </h4>
          <p>
            <FormattedMessage id="campaignEditor.productTour.text2" />
          </p>
        </div>
      ),
      position: 'bottom',
      selector: '[data-tour-id="undistributed-tab"]',
      style: { top: '16px' },
    },
    {
      content: (
        <div>
          <h4>
            <FormattedMessage id="campaignEditor.productTour.title3" />
          </h4>
          <p>
            <FormattedMessage id="campaignEditor.productTour.text3" />
          </p>
        </div>
      ),
      position: 'bottom',
      selector: '[data-tour-id="create-button"]',
      style: { top: '16px' },
    },
    {
      content: (
        <div>
          <h4>
            <FormattedMessage id="campaignEditor.productTour.title4" />
          </h4>
          <p>
            <FormattedMessage id="campaignEditor.productTour.text4" />
          </p>
        </div>
      ),
      position: 'bottom',
      selector: '[data-tour-id="distributed-tabs"]',
      style: { top: '16px' },
    },
    {
      content: (
        <div>
          <h4>
            <FormattedMessage id="campaignEditor.productTour.title5" />
          </h4>
          {!hasAds && (
            <div className={classes.imageWrapper}>
              <img src={massManagementImage} width="281" height="108" alt="" />
            </div>
          )}
          <p>
            <FormattedMessage id="campaignEditor.productTour.text5" />
          </p>
        </div>
      ),
      position: hasAds ? 'right' : 'center',
      selector: hasAds ? '[data-tour-id="mass-management"]' : undefined,
      style: { left: '16px' },
    },
    {
      content: (
        <div>
          <h4>
            <FormattedMessage id="campaignEditor.productTour.title6" />
          </h4>
          <p>
            <FormattedMessage id="campaignEditor.productTour.text6" />
          </p>
          <div className={classes.productTourButton}>
            <Button
              size={BUTTON_SIZE.size40}
              variant={BUTTON_VARIANT.green}
              type={BUTTON_TYPE.button}
              onClick={onCloseClick}
            >
              <FormattedMessage id="campaignEditor.productTour.button6" />
            </Button>
          </div>
        </div>
      ),
      position: 'left',
      selector: '[data-tour-id="guide-button"]',
      style: { top: 0, left: '-16px' },
    },
  ]
}

export const getSecondProductTourSteps = (onCloseClick: () => void, classes: Classes): ReactourStep[] => {
  return [
    {
      content: (
        <div>
          <h4>
            <FormattedMessage id="campaignEditor.productTourSecond.title" />
          </h4>
          <div className={classes.productTourText}>
            <FormattedMessage id="campaignEditor.productTourSecond.firstMessage" />
          </div>
          <div className={classes.productTourText}>
            <FormattedMessage id="campaignEditor.productTourSecond.secondMessage" />
          </div>
          <div className={classes.productTourImageWrapper}>
            <img src={dailyBudgetImage} width="324" height="93" alt="" />
          </div>
          <div>
            <Button
              size={BUTTON_SIZE.size40}
              variant={BUTTON_VARIANT.green}
              type={BUTTON_TYPE.button}
              onClick={onCloseClick}
            >
              <FormattedMessage id="campaignEditor.productTourSecond.closeButton" />
            </Button>
          </div>
        </div>
      ),
      position: 'bottom',
      selector: '[data-tour-id="advanced-editing"]',
      style: { top: '16px' },
    },
  ]
}

interface IGetThirdProductTourSteps {
  onCloseHandler: () => void
  classes: Classes
  type: MassEditingTypes
}

export const getThirdProductTourSteps = (props: IGetThirdProductTourSteps): ReactourStep[] => {
  const { onCloseHandler, classes, type } = props

  return [
    {
      content: (
        <div>
          <h4>
            <FormattedMessage id="campaignEditor.productTourThird.title" />
          </h4>
          <div className={classes.productTourText}>
            {type === MassEditingTypes.stop && (
              <FormattedMessage id="campaignEditor.productTourThird.firstMessage.stop" />
            )}
            {type === MassEditingTypes.limit && (
              <FormattedMessage id="campaignEditor.productTourThird.firstMessage.limit" />
            )}
          </div>
          <div className={classes.productTourText}>
            <FormattedMessage id="campaignEditor.productTourThird.secondMessage" />
          </div>
          <div>
            <Button
              size={BUTTON_SIZE.size40}
              variant={BUTTON_VARIANT.green}
              type={BUTTON_TYPE.button}
              onClick={onCloseHandler}
            >
              <FormattedMessage id="campaignEditor.productTourThird.closeButton" />
            </Button>
          </div>
        </div>
      ),
      position: 'bottom',
      selector: '[data-tour-id="rule-setting"]',
      style: { top: '12px' },
    },
  ]
}

export const getNameForAnalytic = (activeTab: string) => {
  let result = ''

  switch (activeTab) {
    case FilterType.COMPANY: {
      result = 'Campaigns'
      break
    }
    case FilterType.GROUP: {
      result = 'Groups'
      break
    }
    case FilterType.ADS: {
      result = 'Ads'
      break
    }
    case ExtraFilterType.UNDISTRIBUTED: {
      result = 'Unassigned'
      break
    }
    default:
      result = ''
  }
  return result
}

export const checkHeredity = (type: DailyLevel, level: DailyLevel): IInherits => {
  const inherits: IInherits = {
    father:
      (type === DailyLevel.AD && (level === DailyLevel.ADSET || level === DailyLevel.CAMPAIGN)) ||
      (type === DailyLevel.ADSET && level === DailyLevel.CAMPAIGN),
    owner: level === type,
    child:
      (type === DailyLevel.CAMPAIGN && (level === DailyLevel.ADSET || level === DailyLevel.AD)) ||
      (type === DailyLevel.ADSET && level === DailyLevel.AD),
  }

  return inherits
}

export const inheritsModalText = () => {
  const inheritsModalText: IInheritsModalText = {
    [DailyLevel.CAMPAIGN]: {
      [Inherits.CHILD]: (
        <FormattedMessage id="campaignEditor.selectedCount.warningAsideModal.campaign.hasInheritsFromChild" />
      ),
    },
    [DailyLevel.ADSET]: {
      [Inherits.CHILD]: (
        <FormattedMessage id="campaignEditor.selectedCount.warningAsideModal.adsets.hasInheritsFromChild" />
      ),
      [Inherits.FATHER]: (
        <FormattedMessage id="campaignEditor.selectedCount.warningAsideModal.adsets.hasInheritsFromFather" />
      ),
    },
    [DailyLevel.AD]: {
      [Inherits.FATHER]: (
        <FormattedMessage id="campaignEditor.selectedCount.warningAsideModal.ads.hasInheritsFromFather" />
      ),
    },
    [DailyLevel.UNDISTRIBUTED]: {
      [Inherits.FATHER]: '',
    },
  }

  return inheritsModalText
}

export const createNewAside = (values: AdsResult[] | AdsetsResult[] | CampaignsResult[] | [], type: DailyLevel) => {
  const newAside = values.map((value) => ({
    id: value.id,
    type: type,
    name: value.name,
    limit: {
      budget_id: value.daily_budget?.id ?? null,
      created_at: value.daily_budget?.created_at ?? null,
      amount: value.daily_budget?.amount ?? 0,
      rule: value.daily_budget?.rule ?? null,
    },
    stop: {
      stop_id: value.day_stop?.id ?? null,
      created_at: value.day_stop?.created_at ?? null,
      date: value.day_stop?.date_stop ? new Date(value.day_stop?.date_stop).toString() : null,
      rule: value.day_stop?.rule ?? null,
    },
  }))
  return newAside
}

interface IGetCountDescription {
  type: FilterTabsType
  selectedCount: number
  intl: IntlShape
  prefix: boolean
}

export const getCountDescription = (params: IGetCountDescription) => {
  const { type, selectedCount, intl, prefix } = params
  const prefixBlock = `${
    prefix
      ? selectedCount > 1
        ? intl.formatMessage({ id: 'campaignEditor.selectedCount.many' })
        : intl.formatMessage({ id: 'campaignEditor.selectedCount.feminine' })
      : ''
  }`
  switch (type) {
    case FilterType.COMPANY:
      return `${prefixBlock} ${selectedCount} ${intl.formatMessage({
        id: getWordByNumber(selectedCount, [
          'campaignEditor.selectedCount.company.one',
          'campaignEditor.selectedCount.company.two',
          'campaignEditor.selectedCount.company.many',
        ]),
      })}`
    case FilterType.GROUP:
      return `${prefixBlock} ${selectedCount} ${intl.formatMessage({
        id: getWordByNumber(selectedCount, [
          'campaignEditor.selectedCount.group.one',
          'campaignEditor.selectedCount.group.two',
          'campaignEditor.selectedCount.group.many',
        ]),
      })}`
    case FilterType.ADS:
      return `${prefixBlock} ${selectedCount} ${intl.formatMessage({
        id: getWordByNumber(selectedCount, [
          'campaignEditor.selectedCount.ads.one',
          'campaignEditor.selectedCount.ads.two',
          'campaignEditor.selectedCount.ads.many',
        ]),
      })}`
    case ExtraFilterType.UNDISTRIBUTED:
      return `${prefixBlock} ${selectedCount} ${intl.formatMessage({
        id: getWordByNumber(selectedCount, [
          'campaignEditor.selectedCount.undistributed.one',
          'campaignEditor.selectedCount.undistributed.two',
          'campaignEditor.selectedCount.undistributed.many',
        ]),
      })}`
  }
}

interface IgetCountRemoveModalDescriptionn {
  type: DailyLevel
  selectedCount: number
  intl: IntlShape
}

export const getCountRemoveModalDescription = (params: IgetCountRemoveModalDescriptionn) => {
  const { type, selectedCount, intl } = params
  const prefixBlock = intl.formatMessage({ id: 'campaignEditor.asideRemoveModal.textPrefix' })
  switch (type) {
    case DailyLevel.CAMPAIGN:
      return `${prefixBlock} ${selectedCount} ${intl.formatMessage({
        id: getWordByNumber(selectedCount, [
          'campaignEditor.asideRemoveModal.text.campaign.one',
          'campaignEditor.asideRemoveModal.text.campaign.two',
          'campaignEditor.asideRemoveModal.text.campaign.many',
        ]),
      })}`
    case DailyLevel.ADSET:
      return `${prefixBlock} ${selectedCount} ${intl.formatMessage({
        id: getWordByNumber(selectedCount, [
          'campaignEditor.asideRemoveModal.text.adset.one',
          'campaignEditor.asideRemoveModal.text.adset.two',
          'campaignEditor.asideRemoveModal.text.adset.many',
        ]),
      })}`
    case DailyLevel.AD:
      return `${prefixBlock} ${selectedCount} ${intl.formatMessage({
        id: getWordByNumber(selectedCount, [
          'campaignEditor.asideRemoveModal.text.ad.one',
          'campaignEditor.asideRemoveModal.text.ad.two',
          'campaignEditor.asideRemoveModal.text.ad.many',
        ]),
      })}`
  }
}

export const checkLimitCampaignsInherits = (values: CampaignsResult[] | []) => {
  return values.some(
    (value) => value.daily_budget?.level === DailyLevel.ADSET || value.daily_budget?.level === DailyLevel.AD
  )
}

export const checkStopDateCampaignsInherits = (values: CampaignsResult[] | []) => {
  return values.some((value) => value.day_stop?.level === DailyLevel.ADSET || value.day_stop?.level === DailyLevel.AD)
}

export const checkLimitAdsetsInherits = (values: AdsetsResult[] | []) => {
  const isHasLimitChild = values.some((value) => value.daily_budget?.level === DailyLevel.AD)
  const isHasLimitFather = values.some((value) => value.daily_budget?.level === DailyLevel.CAMPAIGN)

  return {
    isHasLimitChild,
    isHasLimitFather,
  }
}

export const checkStopDateAdsetsInherits = (values: AdsetsResult[] | []) => {
  const isHasStopDateChild = values.some((value) => value.day_stop?.level === DailyLevel.AD)
  const isHasStopDateFather = values.some((value) => value.day_stop?.level === DailyLevel.CAMPAIGN)

  return {
    isHasStopDateChild,
    isHasStopDateFather,
  }
}

export const checkLimitAdInherits = (values: AdsResult[] | []) => {
  return values.some(
    (value) => value.daily_budget?.level === DailyLevel.CAMPAIGN || value.daily_budget?.level === DailyLevel.ADSET
  )
}

export const checkStopDateAdInherits = (values: AdsResult[] | []) => {
  return values.some(
    (value) => value.day_stop?.level === DailyLevel.CAMPAIGN || value.day_stop?.level === DailyLevel.ADSET
  )
}

export const showAdFatherInheritsAlert = (dispatch: MyThunkDispatch) => {
  const inheritsText = inheritsModalText()

  dispatch(
    openCampaignEditorReportModal({
      name: MODALS.WARNING_ASIDE,
      data: { text: inheritsText[DailyLevel.AD][Inherits.FATHER] },
    })
  )
}

export const showCampaignsChildInheritsAlert = (dispatch: MyThunkDispatch) => {
  const inheritsText = inheritsModalText()

  dispatch(
    openCampaignEditorReportModal({
      name: MODALS.WARNING_ASIDE,
      data: { text: inheritsText[DailyLevel.CAMPAIGN][Inherits.CHILD] },
    })
  )
}

export const showAdsetsFatherInheritsAlert = (dispatch: MyThunkDispatch) => {
  const inheritsText = inheritsModalText()

  dispatch(
    openCampaignEditorReportModal({
      name: MODALS.WARNING_ASIDE,
      data: { text: inheritsText[DailyLevel.ADSET][Inherits.FATHER] },
    })
  )
}

export const showAdsetsChildInheritsAlert = (dispatch: MyThunkDispatch) => {
  const inheritsText = inheritsModalText()

  dispatch(
    openCampaignEditorReportModal({
      name: MODALS.WARNING_ASIDE,
      data: { text: inheritsText[DailyLevel.ADSET][Inherits.CHILD] },
    })
  )
}

export const asideCatchErrorHandler = (error: any, navItem: MassEditingTypes, dispatch: MyThunkDispatch) => {
  let errorText = ''

  if (error.response.data && error.response.status === 400) {
    if (error.response.data.non_field_errors) {
      errorText = error.response.data.non_field_errors
    }

    if (error.response.data.budgets) {
      Object.keys(error.response.data.budgets).forEach((key) => {
        Object.keys(error.response.data.budgets[key]).forEach((value) => {
          errorText += `${error.response.data.budgets[key][value][0]}. `
        })
      })

      dispatch(dispatch(setAsideNavError(MassEditingTypes.limit, { isError: true, errorMessage: errorText })))

      return Promise.reject(error)
    }

    if (error.response.data) {
      Object.keys(error.response.data).forEach((key) => {
        errorText += `${error.response.data[key]}. `
      })
      dispatch(
        openCampaignEditorReportModal({
          name: MODALS.ERROR_ASIDE,
          data: { text: errorText },
        })
      )
    }
  } else {
    dispatch(
      openCampaignEditorReportModal({
        name: MODALS.ERROR_ASIDE,
      })
    )
  }
}
interface IHasCheckAdBudget {
  type: DailyLevel
  counter: ICounter
  dispatch: MyThunkDispatch
  intl: IntlShape
}

export const hasCheckAdBudget = (props: IHasCheckAdBudget) => {
  const { type, counter, dispatch, intl } = props
  const lookingDataByType = type === DailyLevel.AD ? counter.selected_ads : counter.selected_undistributed

  const hasBudget = lookingDataByType.some((value: any) => value.daily_budget?.created_at)

  if (hasBudget) {
    dispatch(
      openCampaignEditorReportModal({
        name: MODALS.WARNING_ASIDE,
        data: {
          title: intl.formatMessage({ id: 'campaignEditor.snackbar.button.move.alertTitle' }),
          text: intl.formatMessage({ id: 'campaignEditor.snackbar.button.move.alertText' }),
        },
      })
    )
    return true
  }
  return false
}

export const getMixpanelParamsMassEditingByDirectionType = (type: DirectionType) => {
  switch (type) {
    case DirectionType.SET_IN:
      return MIXPANEL_PARAMS_MASS_EDITING.SET_LIMIT
    case DirectionType.DECREASE_BY:
      return MIXPANEL_PARAMS_MASS_EDITING.DECREASE_LIMIT
    case DirectionType.INCREASE_BY:
      return MIXPANEL_PARAMS_MASS_EDITING.INCREASE_LIMIT
    default:
      return ''
  }
}

export const getMassEditingMixpanelParams = (type: MassEditingTypes) => {
  switch (type) {
    case MassEditingTypes.limit:
      return MIXPANEL_PARAMS_MASS_EDITING.BUDGET_LIMIT
    case MassEditingTypes.stop:
      return MIXPANEL_PARAMS_MASS_EDITING.STOP_AD
    default:
      return ''
  }
}

interface IUpdateTableByType {
  type: DailyLevel
  telegramId: string
  dispatch: MyThunkDispatch
  selectedAdsetIds: string[]
  selectedCampaignIds: string[]
  filters: IFilterStepsData[]
}

const updateTableByType = (props: IUpdateTableByType) => {
  const { type, telegramId, dispatch, selectedAdsetIds, selectedCampaignIds, filters } = props

  switch (type) {
    case DailyLevel.CAMPAIGN: {
      return dispatch(setCompany({ id: telegramId, page: 1, query: companyGenerateQueryString({ filters }) }))
    }
    case DailyLevel.ADSET: {
      const selectedIds = { campaignIds: selectedCampaignIds }
      return dispatch(setGroup({ id: telegramId, page: 1, query: groupGenerateQueryString({ selectedIds, filters }) }))
    }
    case DailyLevel.AD: {
      const selectedIds = { adsetsIds: selectedAdsetIds, campaignIds: selectedCampaignIds }
      return (() => {
        dispatch(setAds({ id: telegramId, page: 1, query: adsGenerateQueryString({ selectedIds, filters }) })),
          dispatch(setUndistributed({ id: telegramId, page: 1, query: undistributedGenerateQueryString(filters) })) //TODO can look at a current tab
      })()
    }

    case DailyLevel.UNDISTRIBUTED: {
      dispatch(setUndistributed({ id: telegramId, page: 1, query: undistributedGenerateQueryString(filters) }))
    }
  }
}

interface IRemoveStopDateHandler {
  dispatch: MyThunkDispatch
  stopDateData: AsideValues[]
  currentType: DailyLevel
  telegramId: string
  countLimits: number
  intl: IntlShape
  selectedAdsetIds: string[]
  selectedCampaignIds: string[]
  filters: IFilterStepsData[]
}

export const removeStopDate = async (props: IRemoveStopDateHandler) => {
  const {
    dispatch,
    stopDateData,
    currentType,
    telegramId,
    countLimits,
    intl,
    selectedAdsetIds,
    selectedCampaignIds,
    filters,
  } = props

  Mixpanel.track(MIXPANEL_KEYS_MASS_EDITING.MASS_EDITING_DELETE, {
    type: getMassEditingMixpanelParams(MassEditingTypes.stop),
  })
  const removeSubmit = async () => {
    const stopDateDelete = stopDateData
      .filter((value) => value.stop.created_at !== null)
      .map((value) => ({
        [Days.LEVEL_ID]: Number(value.stop.stop_id),
      }))
    const deleteStopDaysValues = {
      [DaysStop.DAYS_STOP]: stopDateDelete,
    }
    const result = await dispatch(deleteDaysStop(telegramId, deleteStopDaysValues))
    if (result.status < 400) {
      dispatch(setAsideOpened({ isOpened: false, isAll: false }))
      dispatch(removeAsideValues())
      dispatch(removeAllAsideValues())
      dispatch(removeAsideStopDateDraft())
      dispatch(setAsideStopDateRule({ isSetStopDateRule: false }))
      updateTableByType({
        type: currentType,
        telegramId,
        dispatch,
        selectedAdsetIds,
        selectedCampaignIds,
        filters,
      })

      Mixpanel.track(MIXPANEL_KEYS_MASS_EDITING.MASS_EDITING_DELETE_POPUP_CONFIRM, {
        type: getMassEditingMixpanelParams(MassEditingTypes.stop),
        result: MIXPANEL_PARAMS_MASS_EDITING.SUCCESS,
      })
      return
    }
    Mixpanel.track(MIXPANEL_KEYS_MASS_EDITING.MASS_EDITING_DELETE_POPUP_CONFIRM, {
      type: getMassEditingMixpanelParams(MassEditingTypes.stop),
      result: MIXPANEL_PARAMS_MASS_EDITING.FAIL,
    })
  }

  dispatch(
    openCampaignEditorReportModal({
      name: MODALS.REMOVE_ASIDE,
      data: {
        type: MassEditingTypes.stop,
        text: getCountRemoveModalDescription({
          type: currentType,
          selectedCount: countLimits,
          intl,
        }),
        onRemove: removeSubmit,
      },
    })
  )
}

interface IRemoveLimitHandler {
  dispatch: MyThunkDispatch
  limitData: AsideValues[]
  currentType: DailyLevel
  telegramId: string
  countLimits: number
  intl: IntlShape
  selectedAdsetIds: string[]
  selectedCampaignIds: string[]
  filters: IFilterStepsData[]
}

export const removeLimit = async (props: IRemoveLimitHandler) => {
  const {
    dispatch,
    limitData,
    currentType,
    telegramId,
    countLimits,
    intl,
    selectedAdsetIds,
    selectedCampaignIds,
    filters,
  } = props

  Mixpanel.track(MIXPANEL_KEYS_MASS_EDITING.MASS_EDITING_DELETE, {
    type: getMassEditingMixpanelParams(MassEditingTypes.limit),
  })
  const removeSubmit = async () => {
    const budgetsDelete = limitData
      .filter((value) => value.limit.created_at !== null)
      .map((value) => ({
        [Budget.LEVEL_ID]: Number(value.limit.budget_id),
      }))

    const deleteBudgetsValues = {
      [Budgets.BUDGETS]: budgetsDelete,
    }
    const result = await dispatch(deleteBudgets(telegramId, deleteBudgetsValues))

    if (result.status < 400) {
      dispatch(setAsideOpened({ isOpened: false, isAll: false }))
      dispatch(removeAsideValues())
      dispatch(removeAllAsideValues())
      dispatch(removeAsideLimitDraft())
      dispatch(setAsideLimitRule({ isSetLimitRule: false }))
      updateTableByType({
        type: currentType,
        telegramId,
        dispatch,
        selectedAdsetIds,
        selectedCampaignIds,
        filters,
      })

      Mixpanel.track(MIXPANEL_KEYS_MASS_EDITING.MASS_EDITING_DELETE_POPUP_CONFIRM, {
        type: getMassEditingMixpanelParams(MassEditingTypes.limit),
        result: MIXPANEL_PARAMS_MASS_EDITING.SUCCESS,
      })
      return
    }
    Mixpanel.track(MIXPANEL_KEYS_MASS_EDITING.MASS_EDITING_DELETE_POPUP_CONFIRM, {
      type: getMassEditingMixpanelParams(MassEditingTypes.limit),
      result: MIXPANEL_PARAMS_MASS_EDITING.FAIL,
    })
  }

  dispatch(
    openCampaignEditorReportModal({
      name: MODALS.REMOVE_ASIDE,
      data: {
        type: MassEditingTypes.limit,
        text: getCountRemoveModalDescription({
          type: currentType,
          selectedCount: countLimits,
          intl,
        }),
        onRemove: removeSubmit,
      },
    })
  )
}

const changeMetricsDescription = (type: TableColumnType | MetricsType.VIEWS) => {
  switch (type) {
    case TableColumnType.CTR:
      return TableColumnType.CTR.toUpperCase()
    case TableColumnType.CPC:
      return TableColumnType.CPC.toUpperCase()
    case TableColumnType.CPS:
      return TableColumnType.CPS.toUpperCase()
    case TableColumnType.CLICKS:
      return capitalizeFirstLetter(TableColumnType.CLICKS)
    case TableColumnType.JOINED:
      return capitalizeFirstLetter(TableColumnType.JOINED)
    case MetricsType.VIEWS:
      return capitalizeFirstLetter(MetricsType.VIEWS)
  }
}

interface IGetStringFromRule {
  rule: RuleRequestBody | null | undefined
  type: MassEditingTypes
  limit?: number | string
}

export const getStringFromRule = (props: IGetStringFromRule) => {
  const { rule, type, limit } = props
  const isLimit = type === MassEditingTypes.limit

  if (rule && rule[RULES_FIELDS.TARGETS].length > 1) {
    const firstRule = rule[RULES_FIELDS.TARGETS][0]
    const secondRule = rule[RULES_FIELDS.TARGETS][1]
    const condition = rule[RULES_FIELDS.CONDITION]

    const firstRuleString = `
      ${changeMetricsDescription(firstRule[TARGETS_FIELDS.NAME])}
      ${firstRule[TARGETS_FIELDS.SYMBOL]}
      ${firstRule[TARGETS_FIELDS.VALUE]}`

    const secondRuleString = `
      ${changeMetricsDescription(secondRule[TARGETS_FIELDS.NAME])}
      ${secondRule[TARGETS_FIELDS.SYMBOL]}
      ${secondRule[TARGETS_FIELDS.VALUE]}`

    return (
      <>
        {isLimit ? (
          <FormattedMessage
            id="campaignEditor.asideTable.limit.rule"
            values={{ limit: limit, rule: firstRuleString }}
          />
        ) : (
          <FormattedMessage id="campaignEditor.asideTable.stop.rule" values={{ rule: firstRuleString }} />
        )}
        &nbsp;
        <FormattedMessage id={`campaignEditor.rulesForm_${condition}`} />
        &nbsp;
        <span>{secondRuleString}</span>
      </>
    )
  }

  if (rule && rule[RULES_FIELDS.TARGETS].length === 1) {
    const firstRule = rule[RULES_FIELDS.TARGETS][0]
    const firstRuleString = `
      ${changeMetricsDescription(firstRule[TARGETS_FIELDS.NAME])}
      ${firstRule[TARGETS_FIELDS.SYMBOL]}
      ${firstRule[TARGETS_FIELDS.VALUE]}`

    return isLimit ? (
      <FormattedMessage id="campaignEditor.asideTable.limit.rule" values={{ limit: limit, rule: firstRuleString }} />
    ) : (
      <FormattedMessage id="campaignEditor.asideTable.stop.rule" values={{ rule: firstRuleString }} />
    )
  }
}
