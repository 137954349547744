import { Reducer } from 'redux'

import { IMyCashbackState, MyCashbackActionTypes } from './types'

export const initialState: IMyCashbackState = {
  history: {
    count: 0,
    current: 1,
    next: null,
    page_count: 1,
    previous: null,
    results: [],
  },
  platforms: {
    cashback_condition_fb: false,
    cashback_condition_mytarget: false,
    cashback_condition_vk: false,
    cashback_condition_yandex: false,
    cashback_condition_yandex_promo: false,
    cashback_condition_avito: false,
    cashback_condition_vkads: false,
    cashback_condition_vk_adsmarket: false,
  },
  progress: {
    conditions: [],
    current_cashback: '0.00',
    current_condition_id: null,
    current_percent: '0.00',
    current_spend: '0.00',
    to_new_condition: null,
  },
  spends: {
    facebook_current_cashback: '0.00',
    mytarget_current_cashback: '0.00',
    percent: '0.00',
    total_all_time_cashback: '0.00',
    total_current_cashback: '0.00',
    total_current_spends: '0.00',
    vk_current_cashback: '0.00',
    avito_current_cashback: '0.00',
    vkads_current_cashback: '0.00',
  },
}

const reducer: Reducer<IMyCashbackState> = (state = initialState, action: any) => {
  switch (action.type) {
    case MyCashbackActionTypes.SET_SPENDS: {
      return {
        ...state,
        spends: action.data,
      }
    }
    case MyCashbackActionTypes.SET_PROGRESS: {
      return {
        ...state,
        progress: action.data,
      }
    }
    case MyCashbackActionTypes.SET_HISTORY: {
      return {
        ...state,
        history: action.data,
      }
    }
    case MyCashbackActionTypes.SET_PLATFORMS: {
      return {
        ...state,
        platforms: action.data,
      }
    }
    default: {
      return state
    }
  }
}

export { reducer as myCashbackReducer }
