import React from 'react'

import { IIcons } from './types'

export default (props: IIcons) => {
  const { style } = props
  const defaultStyle = { width: 30, height: 30 }

  return (
    <svg
      style={{ ...defaultStyle, ...style }}
      viewBox="0 0 30 30"
      fill="none"
      stroke="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="30" height="30" rx="15" />
      <path
        d="M12.3377 15.3639L17.1322 10.6954C17.7143 10.129 18.6607 10.129 19.2429 10.6954C19.825 11.2623 19.825 12.1843 19.2429 12.7512L12.6467 19.0903C11.6749 20.0366 10.1006 20.0366 9.12885 19.0903C8.15708 18.1446 8.15708 16.6106 9.12885 15.6648L15.6539 9.3935C17.0142 8.06885 19.2205 8.06885 20.5789 9.3935C21.9402 10.7186 21.9402 12.865 20.5799 14.1897L15.8566 18.7894"
        strokeLinecap="square"
        fill="none"
      />
    </svg>
  )
}
