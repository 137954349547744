import React from 'react'

import { SlateGreyLighten22 } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = SlateGreyLighten22 } = props
  const defaultStyle = { width: 18, height: 18 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.6552 4.30427C15.0947 4.72922 15.117 5.44116 14.7049 5.89444L8.56859 12.6444C8.16423 13.0892 7.48986 13.1209 7.04797 12.7158L3.36616 9.34083C2.91585 8.92805 2.87529 8.21697 3.27556 7.75259C3.67584 7.28821 4.36537 7.24638 4.81568 7.65916L7.70347 10.3063L10.4084 7.33094L13.1132 4.35557C13.5253 3.90229 14.2157 3.87933 14.6552 4.30427Z"
        fill={fill}
      />
    </svg>
  )
}
