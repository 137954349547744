import { AxiosError, AxiosResponse } from 'axios'
import { AnyAction, Dispatch } from 'redux'

import { ThunkResult } from '../store'
import { IReferral, ReferralActionTypes } from './types'

import { API_URL } from '@/const'
import { customAxios } from '@/utils'

export function getReferral(): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/promocodes/referral/`, 'GET', null, true)
      .then((response: AxiosResponse<IReferral>) => {
        dispatch({ type: ReferralActionTypes.SET_REFERRAL, data: response.data })
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function setReferral(): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/promocodes/referral/`, 'POST', {}, true)
      .then((response: AxiosResponse<IReferral>) => {
        dispatch({ type: ReferralActionTypes.SET_REFERRAL, data: response.data })
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}
