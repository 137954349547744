import React from 'react'

import { GreyLighten47 } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = GreyLighten47 } = props
  const defaultStyle = { width: 22, height: 22 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 2C6.03725 2 2 6.03725 2 11C2 15.9628 6.03725 20 11 20C15.9628 20 20 15.9628 20 11C20 6.03725 15.9628 2 11 2ZM15.2803 15.6553C15.134 15.8015 14.942 15.875 14.75 15.875C14.558 15.875 14.366 15.8015 14.2197 15.6553L10.4698 11.9052C10.3288 11.765 10.25 11.5745 10.25 11.375V6.5C10.25 6.08525 10.586 5.75 11 5.75C11.414 5.75 11.75 6.08525 11.75 6.5V11.0645L15.2803 14.5947C15.5735 14.888 15.5735 15.362 15.2803 15.6553Z"
        fill={fill}
      />
    </svg>
  )
}
