import React from 'react'

import { SlateGreyLighten22 } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = SlateGreyLighten22 } = props
  const defaultStyle = { width: 14, height: 14 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14ZM4.50357 4.96067C4.47459 5.14733 4.62606 5.30533 4.81309 5.30533H4.81375H5.32544C5.49798 5.30533 5.63759 5.16733 5.68106 4.998C5.83121 4.416 6.29285 4.06333 6.95733 4.06333C7.72652 4.06333 8.2257 4.54267 8.2257 5.19533C8.2257 5.79067 7.98401 6.126 7.30571 6.54733C6.53652 7.02133 6.19407 7.54133 6.22239 8.35533L6.22436 8.48867C6.22523 8.57649 6.26031 8.66042 6.32196 8.72222C6.38362 8.78401 6.46688 8.81867 6.55364 8.81867H7.06072C7.14805 8.81867 7.23181 8.78355 7.29356 8.72104C7.35531 8.65852 7.39 8.57374 7.39 8.48533V8.46533C7.39 7.87067 7.59679 7.56467 8.32119 7.12533C9.06206 6.66867 9.5 6.03933 9.5 5.14333C9.5 3.90667 8.50098 3 7.01924 3C5.53815 3 4.68467 3.82667 4.50357 4.96067ZM7.52369 10.2733C7.52369 10.684 7.21219 11 6.80257 11C6.40349 11 6.08673 10.684 6.08673 10.2727C6.08673 9.86133 6.40349 9.54533 6.80257 9.54533C7.21219 9.54533 7.52369 9.86133 7.52369 10.2733Z"
        fill={fill}
      />
    </svg>
  )
}
