import React from 'react'

import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = '#B09DE3', fillBg = '#EAE5F8' } = props
  const defaultStyle = { width: 17, height: 21 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 17 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1613_302381)">
        <path
          d="M16.0192 9C16.0192 11.0095 14.8174 13.7495 13.2004 16.031C12.401 17.1589 11.5266 18.1365 10.6982 18.8244C9.84283 19.5346 9.13973 19.85 8.66919 19.85C8.19865 19.85 7.49555 19.5346 6.64018 18.8244C5.81173 18.1365 4.93734 17.1589 4.13796 16.031C2.52098 13.7495 1.31919 11.0095 1.31919 9C1.31919 4.94071 4.6099 1.65 8.66919 1.65C12.7285 1.65 16.0192 4.94071 16.0192 9Z"
          fill={fillBg}
          stroke={fill}
          strokeWidth="1.3"
        />
        <circle cx="8.68896" cy="8.88965" r="2.85" fill="#F5F6F7" stroke={fill} strokeWidth="1.3" />
      </g>
      <defs>
        <clipPath id="clip0_1613_302381">
          <rect width="15.9722" height="20.2314" fill="white" transform="translate(0.669189 0.289062)" />
        </clipPath>
      </defs>
    </svg>
  )
}
