export const enum INDIVIDUAL_DATA_ERROR_FORM_FIELDS {
  NAME = 'name',
  INN = 'inn',
  PERSONAL_PHONE = 'personal_phone',
  REQUEST_ERROR = 'request_error',
}

export interface IIndividualDataErrorForm {
  [INDIVIDUAL_DATA_ERROR_FORM_FIELDS.NAME]: string
  [INDIVIDUAL_DATA_ERROR_FORM_FIELDS.INN]: string
  [INDIVIDUAL_DATA_ERROR_FORM_FIELDS.PERSONAL_PHONE]: string
  [INDIVIDUAL_DATA_ERROR_FORM_FIELDS.REQUEST_ERROR]: string
}
