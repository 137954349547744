import React from 'react'

import { IIcons } from './types'

export default (props: IIcons) => {
  const { style } = props
  const defaultStyle = { width: 40, height: 40 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
        fill="#F0F3F7"
      />
      <g clipPath="url(#clip0_151_2373)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.3186 11.5933C14.1721 11.5933 10 15.7654 10 20.9119C10 26.0584 14.1721 30.2305 19.3186 30.2305C24.4652 30.2305 28.6373 26.0584 28.6373 20.9119C28.6373 20.9119 28.6373 20.9119 28.6373 20.9119H25.5311C25.531 24.3429 22.7496 27.1242 19.3186 27.1242C15.8876 27.1242 13.1062 24.3428 13.1062 20.9118C13.1062 17.4807 15.8876 14.6994 19.3186 14.6993V11.5933Z"
          fill="#FC2C38"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.5315 8.5L22.4252 11.5933V15.6403L20.1479 17.9176C19.8841 17.8448 19.6061 17.8058 19.3191 17.8058C17.6036 17.8058 16.2129 19.1965 16.2129 20.9121C16.2129 22.6276 17.6036 24.0183 19.3191 24.0183C21.0346 24.0183 22.4253 22.6276 22.4253 20.9121C22.4253 20.6426 22.391 20.3812 22.3265 20.1319L24.6526 17.8058H25.5315V17.8056H28.6687L31.7789 14.6994H25.5315V8.5Z"
          fill="#FC2C38"
        />
      </g>
      <defs>
        <clipPath id="clip0_151_2373">
          <rect width="22" height="22" fill="white" transform="translate(10 8.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}
