import { Reducer } from 'redux'

import { FeedActionTypes, IFeedState } from './types'

const initialState: IFeedState = []

const reducer: Reducer<IFeedState> = (state = initialState, action: any) => {
  switch (action.type) {
    case FeedActionTypes.SET_FEEDS: {
      return [...action.data]
    }
    default: {
      return state
    }
  }
}

export { reducer as feedsReducer }
