import React from 'react'

import { IIcons } from './types'

export default (props: IIcons) => {
  const { style } = props
  const defaultStyle = { width: 40, height: 40 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.6487 39.4569C23.1569 39.812 21.6004 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20C40 21.6004 39.812 23.1569 39.4569 24.6487C37.5314 22.4144 34.6809 21 31.5 21C30.2181 21 28.9898 21.2297 27.8541 21.6503C27.1848 21.1212 26.5378 20.7299 25.9892 20.465C27.8504 19.3531 30.4811 16.6577 31.101 13H27.2305C26.4164 15.973 24 18.6685 21.69 18.9245V13H17.7533V23.3747C15.3585 22.7807 12.2344 19.8666 12.1064 13H8C8.1913 22.3477 13.1118 27.973 21.2197 27.973H21.607C21.214 29.0754 21 30.2627 21 31.5C21 34.6809 22.4144 37.5314 24.6487 39.4569ZM21.69 27.7484V22.6253C22.7497 22.7327 23.713 23.0856 24.5541 23.6256C23.2933 24.7385 22.3018 26.1496 21.69 27.7484Z"
        fill="#2E77F6"
      />
      <rect x="23" y="23" width="17" height="17" rx="8.50001" fill="#313131" />
      <path
        d="M30.6073 28.9925C30.6073 29.814 29.9414 30.48 29.1198 30.48C28.2983 30.48 27.6323 29.814 27.6323 28.9925C27.6323 28.171 28.2983 27.505 29.1198 27.505C29.9414 27.505 30.6073 28.171 30.6073 28.9925Z"
        fill="white"
      />
      <path
        d="M28.5251 31.075C26.882 31.075 25.55 32.407 25.55 34.05C25.55 34.3786 25.8164 34.645 26.145 34.645H32.0951C32.4237 34.645 32.6901 34.3786 32.6901 34.05C32.6901 32.407 31.3581 31.075 29.7151 31.075H28.5251Z"
        fill="white"
      />
      <path
        d="M33.8801 30.48C34.7016 30.48 35.3676 29.814 35.3676 28.9925C35.3676 28.171 34.7016 27.505 33.8801 27.505C33.0586 27.505 32.3926 28.171 32.3926 28.9925C32.3926 29.814 33.0586 30.48 33.8801 30.48Z"
        fill="white"
      />
      <path
        d="M33.6251 34.3475C33.6251 34.5118 33.7583 34.645 33.9226 34.645L36.8548 34.645C37.1834 34.645 37.4498 34.3786 37.4498 34.05C37.4498 32.407 36.1179 31.075 34.4748 31.075H33.2848C33.1349 31.075 32.9875 31.0861 32.8436 31.1075C32.6007 31.1436 32.5558 31.4548 32.7312 31.6266C33.2828 32.1666 33.6251 32.9196 33.6251 33.7525V34.3475Z"
        fill="white"
      />
    </svg>
  )
}
