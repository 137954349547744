import React from 'react'

import { White } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = White } = props
  const defaultStyle = { width: 14, height: 12 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.33008 6.00195H12.6634"
        stroke={fill}
        strokeWidth="1.41667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.70508 1.04297L12.6634 6.0013L7.70508 10.9596"
        stroke={fill}
        strokeWidth="1.41667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
