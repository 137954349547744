import { createGlobalStyle } from 'styled-components'

import { BackgroundGrey } from '../../const/colors'
import { baseFontProps, baseTextClr } from '../../const/commonStyles'

export default createGlobalStyle`
  :root {
    --app-height: 100%;
  }

  * {
    box-sizing: border-box;
    -webkit-print-color-adjust: exact;
  }

  html {
    -webkit-print-color-adjust: exact;
  }

  body {
    padding: 0;
    margin: 0;
    ${baseFontProps.join('')};
    color: ${baseTextClr};

    background-color: ${BackgroundGrey};

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    ${(props: any) => (props.isShowedModal ? 'height: var(--app-height); overflow: hidden;' : null)};
  }

  img {
    display: block;
  }

  text {
    ${baseFontProps.join('')};
  }

  a {
    font: inherit;
    color: inherit;
  }

  @keyframes fade {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`
