import React from 'react'

import { GreyBase } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = GreyBase } = props
  const defaultStyle = { width: 18, height: 18 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.0589 4.29045L12.5059 2.84294C13.0657 2.28349 14.1126 2.42315 14.8445 3.15512C15.5769 3.8875 15.7165 4.93452 15.1571 5.49438L13.7101 6.94148M11.0589 4.29045L10.3352 5.01379L3.68865 11.6612M11.0589 4.29045L13.7101 6.94148M13.7101 6.94148L12.9856 7.66523L6.35509 14.2966M3.68865 11.6612L6.35509 14.2966M3.68865 11.6612C2.96324 12.3867 2.32727 15.243 2.54251 15.4581C2.75774 15.6732 5.62622 15.0256 6.35509 14.2966"
        stroke={fill}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
