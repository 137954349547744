import React from 'react'

import { GreyBase } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = GreyBase } = props
  const defaultStyle = { width: 18, height: 18 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_975_69226" maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="18">
        <path d="M3.65039 6L9.50039 5.99999" stroke="#2E2E2E" strokeWidth="1.2" strokeLinecap="round" />
        <path d="M12.5 6.00003L14.35 6.00001" stroke="#2E2E2E" strokeWidth="1.2" strokeLinecap="round" />
        <path
          d="M12.4 6C12.4 6.7732 11.7732 7.4 11 7.4C10.2268 7.4 9.6 6.7732 9.6 6C9.6 5.2268 10.2268 4.6 11 4.6C11.7732 4.6 12.4 5.2268 12.4 6Z"
          stroke={fill}
          strokeWidth="1.2"
        />
        <path
          d="M8.4 12C8.4 12.7732 7.7732 13.4 7 13.4C6.2268 13.4 5.6 12.7732 5.6 12C5.6 11.2268 6.2268 10.6 7 10.6C7.7732 10.6 8.4 11.2268 8.4 12Z"
          stroke={fill}
          strokeWidth="1.2"
        />
        <path d="M3.65039 12L5.50039 12" stroke="#2E2E2E" strokeWidth="1.2" strokeLinecap="round" />
        <path d="M8.5 12L14.35 12" stroke="#2E2E2E" strokeWidth="1.2" strokeLinecap="round" />
      </mask>
      <g mask="url(#mask0_975_69226)">
        <rect width="18" height="18" fill="white" />
      </g>
    </svg>
  )
}
