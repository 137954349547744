import React, { Component, ReactNode } from 'react'
import { connect } from 'react-redux'

import history from '../../store/history'
import IApplicationState from '../../types/state'
import Aside from './components/Aside'
import Content from './components/Content'
import PageWrapper from './components/PageWrapper'
import PreviewWrapper from './components/PreviewWrapper'
import GlobalStyle from './globalStyle'
import { IAppProps } from './types'

import { Routes } from '@/const/routes'

export class App extends Component<IAppProps> {
  public componentDidMount(): void {
    const winw = window as any

    // Fire event for GTM
    if (winw.dataLayer?.push && this.props.gaUserId) {
      winw.dataLayer?.push({
        event: 'ym-user-id',
        userId: this.props.gaUserId,
      })
    }

    window.addEventListener('resize', this.calcAppHeight)
    this.calcAppHeight()
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.calcAppHeight)
  }

  public render() {
    return this.getContent()
  }

  private calcAppHeight = () => {
    const doc = document.documentElement
    doc.style.setProperty('--app-height', `${window.innerHeight}px`)
  }

  private getContent(): ReactNode {
    const { isAuthorized, isDesktop, isShowedModal, isClientConfirmation } = this.props

    const isHeaderNeeded = history.location.pathname.includes(Routes.CONFIRM_CLIENT)

    return isAuthorized || isClientConfirmation || isHeaderNeeded ? (
      <PageWrapper>
        <Content />
        <GlobalStyle isShowedModal={isShowedModal} />
      </PageWrapper>
    ) : (
      <PreviewWrapper>
        {isDesktop && <Aside />}
        <Content />
        <GlobalStyle isShowedModal={isShowedModal} />
      </PreviewWrapper>
    )
  }
}

const mapStateToProps = (state: IApplicationState) => ({
  email: state.user.email,
  full_name: state.user.profile.full_name,
  gaUserId: state.user.ga_user_id,
  has_profile: state.user.has_profile,
  id: state.user.profile.inn,
  industry: state.user.profile.industry,
  isAuthorized: state.user.isAuthorized,
  isClientConfirmation: state.confirmClient.initialized,
  isDesktop: state.browser.greaterThan.medium,
  isShowedModal: state.app.isShowedModal,
  isSubscribed: state.user.subscribe,
})

const AppClass = connect(mapStateToProps)(App)

export default AppClass
