import React from 'react'

import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = '#B09DE3', fillBg = '#EAE5F8' } = props
  const defaultStyle = { width: 24, height: 24 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.5 5C4.84315 5 3.5 6.34315 3.5 8V16C3.5 17.6569 4.84315 19 6.5 19H18.5C20.1569 19 21.5 17.6569 21.5 16V8C21.5 6.34315 20.1569 5 18.5 5H6.5Z"
        fill={fillBg}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 8C3.5 6.34315 4.84315 5 6.5 5H18.5C20.1569 5 21.5 6.34315 21.5 8V16C21.5 17.6569 20.1569 19 18.5 19H6.5C4.84315 19 3.5 17.6569 3.5 16V8ZM6.5 6.3H18.5C18.6923 6.3 18.8772 6.33193 19.0496 6.39079L13.2984 10.6084C12.8232 10.9569 12.1769 10.9569 11.7017 10.6084L5.9506 6.39074C6.12294 6.33191 6.30774 6.3 6.5 6.3ZM4.96093 7.27706C4.8577 7.49645 4.8 7.74149 4.8 8V16C4.8 16.9389 5.56112 17.7 6.5 17.7H18.5C19.4389 17.7 20.2 16.9389 20.2 16V8C20.2 7.74154 20.1423 7.49654 20.0391 7.27719L14.0672 11.6567C13.1344 12.3408 11.8656 12.3408 10.9329 11.6567L4.96093 7.27706Z"
        fill={fill}
      />
    </svg>
  )
}
