import React from 'react'

import { GreyBase } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = GreyBase } = props
  const defaultStyle = { width: 14, height: 16 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.3335 9.05623V1.33268C6.3335 0.964492 6.63197 0.666016 7.00016 0.666016C7.36835 0.666016 7.66683 0.964492 7.66683 1.33268V9.05635L9.19549 7.5277C9.45584 7.26735 9.87795 7.26735 10.1383 7.5277C10.3986 7.78805 10.3986 8.21016 10.1383 8.47051L7.48082 11.128C7.35952 11.2542 7.18901 11.3327 7.00016 11.3327C6.8099 11.3327 6.63825 11.253 6.51679 11.1251L3.86215 8.47051C3.6018 8.21016 3.6018 7.78805 3.86215 7.5277C4.1225 7.26735 4.54461 7.26735 4.80496 7.5277L6.3335 9.05623ZM0.333496 11.3327V13.3327C0.333496 14.4373 1.22893 15.3327 2.3335 15.3327H11.6668C12.7714 15.3327 13.6668 14.4373 13.6668 13.3327V11.3327C13.6668 10.9645 13.3684 10.666 13.0002 10.666C12.632 10.666 12.3335 10.9645 12.3335 11.3327V13.3327C12.3335 13.7009 12.035 13.9993 11.6668 13.9993H2.3335C1.96531 13.9993 1.66683 13.7009 1.66683 13.3327V11.3327C1.66683 10.9645 1.36835 10.666 1.00016 10.666C0.631973 10.666 0.333496 10.9645 0.333496 11.3327Z"
        fill={fill}
      />
    </svg>
  )
}
