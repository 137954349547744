import { AxiosError, AxiosPromise, AxiosResponse } from 'axios'
import { AnyAction, Dispatch } from 'redux'

import { API_URL } from '../../const'
import { IIndustry } from '../../store/adaccounts/types'
import { ThunkResult } from '../../store/store'
import { customAxios } from '../../utils'
import {
  IImportRule,
  IImportRulesBody,
  IImportRulesResponse,
  IMPORT_VK_MODE,
  IVkCategories,
  IVkFeed,
  IVkGroups,
  IVkUserBody,
} from './types'

export enum ImportVKActionTypes {
  SET_STEP = 'SET_STEP',
  SET_MODE = 'SET_MODE',
  SET_FEED_LINK = 'SET_FEED_LINK',
  GET_FEED = 'GET_FEED',
  SEND_FEED_LINK = 'SEND_FEED_LINK',
  RESET_FEED_LINK_RESPONSE = 'RESET_FEED_LINK_RESPONSE',
  SET_IMPORT_RULES = 'SET_IMPORT_RULES',
  SET_IMPORT_RULES_ERROR = 'SET_IMPORT_RULES_ERROR',
  SET_IS_LOADING = 'SET_IS_LOADING',
  SET_USER = 'SET_USER',
  SET_GROUPS = 'SET_GROUPS',
  SET_ID_GROUP = 'SET_ID_GROUP',
  SET_CATEGORIES = 'SET_CATEGORIES',
  SET_ID_CATEGORY = 'SET_ID_CATEGORY',
  SET_ERROR_PRODUCT = 'SET_ERROR_PRODUCT',
}

export function setStep(step: number): AnyAction {
  return { type: ImportVKActionTypes.SET_STEP, data: step }
}

export function setMode(mode: IMPORT_VK_MODE): AnyAction {
  return { type: ImportVKActionTypes.SET_MODE, data: mode }
}

export function setIsLoading(isLoading: boolean): AnyAction {
  return { type: ImportVKActionTypes.SET_IS_LOADING, data: isLoading }
}

export function getFeeds(): AxiosPromise<IIndustry[]> {
  return customAxios(`${API_URL}/vk_shop/client_feeds/`, 'GET', null, true)
    .then((response: AxiosResponse<IVkFeed[]>): any => {
      if (response.status === 200) {
        return response.data
      }

      return Promise.reject(response)
    })
    .catch((error: AxiosError) => Promise.reject(error))
}

export function setFeedLink(feedLink: string): AnyAction {
  return { type: ImportVKActionTypes.SET_FEED_LINK, data: feedLink }
}

export function sendFeedLink(body: { source: string }): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch({ type: ImportVKActionTypes.SET_IS_LOADING, data: true })

    return customAxios(`${API_URL}/vk_shop/client_feeds/`, 'POST', body, true)
      .then((response: AxiosResponse<IVkFeed>) => {
        dispatch({ type: ImportVKActionTypes.SEND_FEED_LINK, data: response.data })

        if (response.data.feed_status === 'success') {
          dispatch({ type: ImportVKActionTypes.SET_STEP, data: 2 })
        }

        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
      .finally(() => {
        dispatch({ type: ImportVKActionTypes.SET_IS_LOADING, data: false })
      })
  }
}

export function resetFeedLinkResponse(): AnyAction {
  return { type: ImportVKActionTypes.RESET_FEED_LINK_RESPONSE }
}

export function getImportRules(): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/vk_shop/import_rules/`, 'GET', null, true)
      .then((response: AxiosResponse<IImportRule[]>): any => {
        if (response.status === 200) {
          dispatch({ type: ImportVKActionTypes.SET_IMPORT_RULES, data: response.data })
          dispatch({
            type: ImportVKActionTypes.SET_MODE,
            data: response.data.length ? IMPORT_VK_MODE.RULES : IMPORT_VK_MODE.ADD,
          })
          return response
        }
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function sendImportRules(body: IImportRulesBody): ThunkResult<Promise<any>> {
  return (dispatch: any) => {
    dispatch({ type: ImportVKActionTypes.SET_IS_LOADING, data: true })

    return customAxios(`${API_URL}/vk_shop/import_rules/`, 'POST', body, true)
      .then((response: AxiosResponse<IImportRulesResponse>): any => {
        if (response.status === 201) {
          dispatch(getImportRules())
          dispatch({
            type: ImportVKActionTypes.SET_MODE,
            data: IMPORT_VK_MODE.RULES,
          })

          return response
        }
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 400) {
          dispatch({ type: ImportVKActionTypes.SET_IMPORT_RULES_ERROR, data: error.response.data })
        }

        return Promise.reject(error)
      })
      .finally(() => {
        dispatch({ type: ImportVKActionTypes.SET_IS_LOADING, data: false })
      })
  }
}

export function resetImportRuleError(): AnyAction {
  return { type: ImportVKActionTypes.SET_IMPORT_RULES_ERROR, data: null }
}

export function deleteImportRules(importRulesId: number, withProducts?: boolean): ThunkResult<Promise<any>> {
  return (dispatch: any) => {
    return customAxios(
      `${API_URL}/vk_shop/import_rules/${importRulesId}/${withProducts ? '?with_products=true' : ''}`,
      'DELETE',
      null,
      true
    )
      .then((response: AxiosResponse<any>): any => {
        dispatch(getImportRules())

        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function updateManualImportRules(importRulesId: number): ThunkResult<Promise<any>> {
  return (dispatch: any) => {
    return customAxios(`${API_URL}/vk_shop/import_rules/${importRulesId}/manual_update/`, 'POST', null, true)
      .then((): any => {
        dispatch(getImportRules())
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function updateImportRules(importRulesId: number, isAutoupdate: boolean): ThunkResult<Promise<any>> {
  return (dispatch: any) => {
    return customAxios(`${API_URL}/vk_shop/import_rules/${importRulesId}/`, 'PATCH', { autoupdate: isAutoupdate }, true)
      .then((): any => {
        dispatch(getImportRules())
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function setVkUser(body: IVkUserBody): ThunkResult<Promise<any>> {
  return (dispatch: any) => {
    return customAxios(`${API_URL}/vk_shop/vk_user/`, 'POST', body, true)
      .then((): any => {
        dispatch(getVkUser())
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function getVkUser(): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch({ type: ImportVKActionTypes.SET_IS_LOADING, data: true })

    return customAxios(`${API_URL}/vk_shop/vk_user/`, 'GET', null, true)
      .then((response: AxiosResponse<any[]>): any => {
        if (response.status === 200) {
          dispatch({ type: ImportVKActionTypes.SET_USER, data: response.data })
          return response
        }
      })
      .catch((error: AxiosError) => Promise.reject(error))
      .finally(() => {
        dispatch({ type: ImportVKActionTypes.SET_IS_LOADING, data: false })
      })
  }
}

export function getVkGroups(): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch({ type: ImportVKActionTypes.SET_IS_LOADING, data: true })

    return customAxios(`${API_URL}/vk_shop/vk_groups/`, 'GET', null, true)
      .then((response: AxiosResponse<any[]>): any => {
        if (response.status === 200) {
          dispatch({ type: ImportVKActionTypes.SET_GROUPS, data: response.data })
          return response
        }
      })
      .catch((error: AxiosError) => Promise.reject(error))
      .finally(() => {
        dispatch({ type: ImportVKActionTypes.SET_IS_LOADING, data: false })
      })
  }
}

export function sendVkGroup(body: IVkGroups | undefined): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch({ type: ImportVKActionTypes.SET_IS_LOADING, data: true })

    return customAxios(`${API_URL}/vk_shop/vk_groups/`, 'POST', body, true)
      .then((response: AxiosResponse<IVkFeed>) => {
        if (response.status === 201) {
          dispatch({ type: ImportVKActionTypes.SET_STEP, data: 4 })
        }
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
      .finally(() => {
        dispatch({ type: ImportVKActionTypes.SET_IS_LOADING, data: false })
      })
  }
}

export function setIdVkGroup(id: string): AnyAction {
  return { type: ImportVKActionTypes.SET_ID_GROUP, data: id }
}

export function getVkCategories(): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch({ type: ImportVKActionTypes.SET_IS_LOADING, data: true })

    return customAxios(`${API_URL}/vk_shop/vk_categories/`, 'GET', null, true)
      .then((response: AxiosResponse<IVkCategories[]>): any => {
        if (response.status === 200) {
          dispatch({ type: ImportVKActionTypes.SET_CATEGORIES, data: response.data })
          return response
        }
      })
      .catch((error: AxiosError) => Promise.reject(error))
      .finally(() => {
        dispatch({ type: ImportVKActionTypes.SET_IS_LOADING, data: false })
      })
  }
}

export function setIdVkCategory(id: string): AnyAction {
  return { type: ImportVKActionTypes.SET_ID_CATEGORY, data: id }
}

export function getErrorProduct(importRuleId: number, page?: number | null): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    let url = `${API_URL}/vk_shop/import_rules/${importRuleId}/error_products/`

    if (page) {
      url = `${url}?page=${page}`
    }

    return customAxios(url, 'GET', null, true)
      .then((response: AxiosResponse<IVkFeed>) => {
        if (response.status === 200) {
          dispatch({
            type: ImportVKActionTypes.SET_ERROR_PRODUCT,
            data: {
              id: importRuleId,
              item: response.data,
            },
          })
        }
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}
