import { AxiosError, AxiosResponse } from 'axios'
import { AnyAction, Dispatch } from 'redux'

import { API_URL } from '../../const'
import { ThunkResult } from '../../store/store'
import { customAxios } from '../../utils'

export enum BudgetActionTypes {
  SET_BUDGET_REDIRECT = 'SET_BUDGET_REDIRECT',
  SET_BUDGET_EXCHANGE_RATE = 'SET_BUDGET_EXCHANGE_RATE',
}

export function setRedirect(route: string): AnyAction {
  return { type: BudgetActionTypes.SET_BUDGET_REDIRECT, data: route }
}

export function getExchangeRate(): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/dashboard/exchange_rate/`, 'GET', null, true)
      .then((response: AxiosResponse<any>) => {
        dispatch({
          type: BudgetActionTypes.SET_BUDGET_EXCHANGE_RATE,
          data: response.data,
        })
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}
