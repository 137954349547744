import React from 'react'

import { GreyBase } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = GreyBase } = props
  const defaultStyle = { width: 18, height: 18 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 18 18" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.9553 2.05988C12.0443 2.10953 12.1132 2.18674 12.1506 2.27892C12.1881 2.37109 12.192 2.47277 12.1617 2.56735L10.5245 7.68756H13.5453C13.6341 7.68753 13.7209 7.71252 13.7951 7.75944C13.8693 7.80637 13.9276 7.87317 13.9628 7.95162C13.998 8.03006 14.0085 8.1167 13.9931 8.20085C13.9777 8.285 13.9371 8.36298 13.8762 8.42515L6.60372 15.8622C6.53336 15.9342 6.43972 15.9812 6.33807 15.9954C6.23643 16.0097 6.13277 15.9904 6.04399 15.9406C5.95521 15.8909 5.88655 15.8137 5.8492 15.7216C5.81184 15.6295 5.808 15.528 5.8383 15.4335L7.47551 10.3124H4.45471C4.36594 10.3124 4.27909 10.2875 4.20491 10.2405C4.13072 10.1936 4.07243 10.1268 4.03724 10.0484C4.00205 9.96992 3.99149 9.88327 4.00688 9.79912C4.02226 9.71497 4.06291 9.637 4.12381 9.57483L11.3963 2.13775C11.4666 2.06583 11.5601 2.01891 11.6616 2.00461C11.7631 1.99031 11.8666 2.00949 11.9553 2.05901V2.05988Z"
        fill={fill}
      />
    </svg>
  )
}
