import React from 'react'

import { White } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = White } = props
  const defaultStyle = { width: 13, height: 16 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.1768 14.3911L3.55022 17.0014C3.33544 17.2162 2.9872 17.2162 2.77241 17.0014L1.16072 14.3911C0.945928 14.1763 0.945928 13.8281 1.16072 13.6133C1.37551 13.3985 1.72374 13.3985 1.93853 13.6133L2.61132 14.6133L2.61132 1.6125C2.61132 1.30874 2.85756 1.0625 3.16132 1.0625C3.46507 1.0625 3.71132 1.30874 3.71132 1.6125L3.71132 14.6133L4.39898 13.6133C4.61377 13.3985 4.96201 13.3985 5.1768 13.6133C5.39159 13.8281 5.39159 14.1763 5.1768 14.3911Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5157 3.83351L10.8891 1.2232C10.6743 1.00841 10.3261 1.00841 10.1113 1.2232L8.49958 3.83351C8.2848 4.0483 8.2848 4.39654 8.49958 4.61133C8.71437 4.82612 9.06261 4.82612 9.2774 4.61133L9.95018 3.61133L9.95018 16.6121C9.95018 16.9159 10.1964 17.1621 10.5002 17.1621C10.8039 17.1621 11.0502 16.9159 11.0502 16.6121L11.0502 3.61133L11.7378 4.61133C11.9526 4.82612 12.3009 4.82612 12.5157 4.61133C12.7305 4.39654 12.7305 4.0483 12.5157 3.83351Z"
        fill={fill}
      />
    </svg>
  )
}
