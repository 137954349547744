import { FORM_FIELDS } from '../../types'

export enum MODALS {
  DETAILS = 'details',
  EXECUTOR = 'executor',
  ADVERTISER = 'advertiser',
  SAVE = 'save',
  WARNING = 'warning',
}

export enum EXECUTOR_TYPE {
  MY_AGENCY = 'my_agency',
  OTHET_AGENCY = 'other_agency',
}

export enum AgencySelectionTypes {
  ADDED_AGENCY = 'addedAgency',
  NEW_AGENCY = 'newAgency',
}

export type RussianFields =
  | FORM_FIELDS.RU_INN
  | FORM_FIELDS.RU_NAME
  | FORM_FIELDS.PHONE_NUMBER
  | FORM_FIELDS.CONTRACTOR_LEGAL_STATUS
  | FORM_FIELDS.TAX_NUMBER

export type OtherFields =
  | FORM_FIELDS.CONTRACTOR_LEGAL_STATUS
  | FORM_FIELDS.OTHER_REGISTRATION_NUMBER
  | FORM_FIELDS.OTHER_COUNTRY
  | FORM_FIELDS.OTHER_PAYMENT_NUMBER
  | FORM_FIELDS.TAX_NUMBER

export type FormField = {
  hasError: boolean
  touched: boolean
  errorText: string
  required: boolean
  value: string
}

export type FormFieldOkveds = {
  hasError: boolean
  touched: boolean
  errorText: string
  required: boolean
  value: string[]
}

export type FormRussianFieldsType = {
  // TODO:Remove after approve k.mikhailova
  [key: string]: FormField // | FormFieldOkveds
  [FORM_FIELDS.RU_INN]: FormField
  [FORM_FIELDS.RU_KPP]: FormField
  [FORM_FIELDS.RU_CEO]: FormField
  [FORM_FIELDS.RU_LEGAL_ADDRESS]: FormField
  [FORM_FIELDS.CONTRACTOR_LEGAL_STATUS]: FormField
  [FORM_FIELDS.RU_NAME]: FormField
  [FORM_FIELDS.PHONE_NUMBER]: FormField
  [FORM_FIELDS.TAX_NUMBER]: FormField
  // TODO:Remove after approve k.mikhailova
  // [FORM_FIELDS.OKVED_CODES]: FormFieldOkveds
}

export type FormOtherFieldsType = {
  [key: string]: FormField
  [FORM_FIELDS.CONTRACTOR_LEGAL_STATUS]: FormField
  [FORM_FIELDS.OTHER_REGISTRATION_NUMBER]: FormField
  [FORM_FIELDS.OTHER_COUNTRY]: FormField
  [FORM_FIELDS.OTHER_PAYMENT_NUMBER]: FormField
  [FORM_FIELDS.TAX_NUMBER]: FormField
  [FORM_FIELDS.PHONE_NUMBER]: FormField
}
