import React from 'react'

import { GreyBase } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = GreyBase } = props
  const defaultStyle = { width: 18, height: 18 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.26559 5.19569V8.15305L5.45355 7.23929L5 8.63523L7.81219 9.54914L6.07391 11.9416L7.26203 12.8043L9.00032 10.4123L10.7381 12.8043L11.9258 11.9417L10.1873 9.54977L13 8.63586L12.5465 7.23984L9.73379 8.15364V5.19629H8.2656L8.26559 5.19569Z"
        fill={fill}
      />
    </svg>
  )
}
