import React, { ChangeEvent } from 'react'
import { DefaultTheme } from 'react-jss'

import { Checkbox } from './CheckboxSMB'
import { ICheckboxGroupSMBProps, ICheckboxSMBProps } from './types'

const CheckboxGroup = (props: ICheckboxGroupSMBProps & { theme?: DefaultTheme }) => {
  const { name, onChange, children, value: checkedValues, onEventCall } = props

  const onCheckboxChange = (checkboxValue: any, event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      onChange([...checkedValues, checkboxValue])
    } else {
      onChange(checkedValues.filter((currentValue) => currentValue !== checkboxValue))
    }
    if (onEventCall) {
      onEventCall(checkboxValue)
    }
  }

  const CustomCheckbox = (checkboxProps: ICheckboxSMBProps) => {
    const { value: checkboxValue, ...rest } = checkboxProps

    const checked = checkedValues ? checkedValues.indexOf(checkboxValue) >= 0 : false

    return (
      <Checkbox
        {...rest}
        name={name}
        checked={checked}
        onChange={onCheckboxChange.bind(null, checkboxValue)}
        value={checkboxValue}
      />
    )
  }

  return children(CustomCheckbox)
}

export { CheckboxGroup }
