import React from 'react'

import useStyles from './styles'

const PageWrapper: React.FC = ({ children }) => {
  const classes = useStyles()

  return <div className={classes.pageWrapper}>{children}</div>
}

export default PageWrapper
