import { FilterStatus } from './types'

export const STATUS_LIST: FilterStatus[] = [
  FilterStatus.ACTIVE,
  FilterStatus.IN_REVIEW,
  FilterStatus.ON_HOLD,
  FilterStatus.STOPPED,
  FilterStatus.DECLINED,
  FilterStatus.MIXED,
]

export const ANALYTICS_IDEA_URL = 'https://forms.gle/7S2kQn78YxbGp5iJ6'

export const enum MIXPANEL_KEYS_MASS_EDITING {
  MASS_EDITING_CLOSE = 'MassEditingClose',
  MASS_EDITING_CHOOSE_EDITION_TYPE = 'MassEditingChooseEditionType',
  MASS_EDITING_DROP_DOWN = 'MassEditingDropDown',
  MASS_EDITING_APPLY = 'MassEditingApply',
  MASS_EDITING_DELETE = 'MassEditingDelete',
  MASS_EDITING_CANCEL = 'MassEditingCancel',
  MASS_EDITING_SAVE = 'MassEditingSave',
  MASS_EDITING_POPUP_CLOSE = 'MassEditingPopUpClose',
  MASS_EDITING_POPUP_DO_NOT_SHOW_AGAIN = 'MassEditingPopUpDoNotShowAgain',
  MASS_EDITING_POPUP_CANCEL = 'MassEditingPopUpCancel',
  MASS_EDITING_POPUP_CONFIRM = 'MassEditingPopUpConfirm',
  MASS_EDITING_DELETE_POPUP_CLOSE = 'MassEditingDeletePopUpClose',
  MASS_EDITING_DELETE_POPUP_CANCEL = 'MassEditingDeletePopUpCancel',
  MASS_EDITING_DELETE_POPUP_CONFIRM = 'MassEditingDeletePopUpConfirm',
  MASS_EDITING_STOP_AD_RULE_SELECT = 'MassEditingStopAdRuleSelect',
  MASS_EDITING_STOP_AD_CHOOSE_DATE = 'MassEditingStopAdChooseDate',
  MASS_EDITING_STOP_AD_RULE_SET = 'MassEditingStopAdRuleSet',
  MASS_EDITING_BACK_TO_SIMPLE_RULES = 'MassEditingBackToSimpleRules',
  MASS_EDITING_ADD_ANOTHER_RULE = 'MassEditingAddAnotherRule',
}

export const enum MIXPANEL_PARAMS_MASS_EDITING {
  INCREASE_LIMIT = 'IncreaseLimit',
  SET_LIMIT = 'SetLimit',
  DECREASE_LIMIT = 'DecreaseLimit',
  BUDGET_LIMIT = 'BudgetLimit',
  STOP_AD = 'StopAd',
  SUCCESS = 'Success',
  FAIL = 'Fail',
  RULE = 'Rule',
  DATE = 'Date',
}
