import React from 'react'

import { BackgroundWhite } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = BackgroundWhite, fillBg = '#A8D87B' } = props
  const defaultStyle = { width: 24, height: 24 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill={fillBg}
      />
      <path d="M7.5 12L11.25 15L16.5 9" stroke={fill} strokeWidth="2" />
    </svg>
  )
}
