import React, { FC } from 'react'

import LoaderIcon from '../Icons/LoaderIcon'
import { LoaderBlock, LoaderIconWrapper } from './styled'

/**
 * Component for spinner
 */
const Loader: FC<any> = (props: any) => {
  const { pastDelay } = props

  if (!pastDelay) {
    return null
  }

  return (
    <LoaderBlock data-testid="loader">
      <LoaderIconWrapper>
        <LoaderIcon />
      </LoaderIconWrapper>
    </LoaderBlock>
  )
}

Loader.defaultProps = {
  pastDelay: true,
}

export default Loader
