import React from 'react'

import { SlateGreyLighten22 } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = SlateGreyLighten22 } = props
  const defaultStyle = { width: 22, height: 22 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.66399 3.0498H8.0362C9.17606 3.0498 10.1001 3.97384 10.1001 5.1137V8.48591C10.1001 9.62577 9.17606 10.5498 8.0362 10.5498H4.66399C3.52413 10.5498 2.6001 9.62577 2.6001 8.48591V5.1137C2.6001 3.97384 3.52413 3.0498 4.66399 3.0498Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3822 2.90339L19.7668 5.28791C20.5728 6.09391 20.5728 7.40069 19.7668 8.2067L17.3822 10.5912C16.5762 11.3972 15.2695 11.3972 14.4635 10.5912L12.0789 8.2067C11.2729 7.40069 11.2729 6.09391 12.0789 5.28791L14.4635 2.90339C15.2695 2.09739 16.5762 2.09739 17.3822 2.90339Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1093 12.494H17.4815C18.6214 12.494 19.5454 13.4181 19.5454 14.5579V17.9302C19.5454 19.07 18.6214 19.9941 17.4815 19.9941H14.1093C12.9694 19.9941 12.0454 19.07 12.0454 17.9302V14.5579C12.0454 13.4181 12.9694 12.494 14.1093 12.494Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.66399 12.494H8.0362C9.17606 12.494 10.1001 13.4181 10.1001 14.5579V17.9302C10.1001 19.07 9.17606 19.994 8.0362 19.994H4.66399C3.52414 19.994 2.6001 19.07 2.6001 17.9302V14.5579C2.6001 13.4181 3.52413 12.494 4.66399 12.494Z"
        fill={fill}
      />
    </svg>
  )
}
