import React from 'react'

import { White } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = White } = props
  const defaultStyle = { width: 36, height: 17 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 36 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.46808 2.94748C9.46808 3.75535 8.9296 4.48036 8.08045 4.48036C7.23131 4.48036 6.56856 3.85892 5.32487 3.85892C4.4343 3.85892 3.62554 4.33535 3.62554 5.20536C3.62554 7.33895 10.545 5.9718 10.545 11.3172C10.545 14.2804 8.10013 16.125 5.24203 16.125C3.64626 16.125 0.208252 15.7521 0.208252 13.805C0.208252 12.9971 0.74777 12.3332 1.59588 12.3332C2.56929 12.3332 3.73013 13.1411 5.07634 13.1411C6.44326 13.1411 7.18988 12.3746 7.18988 11.3586C7.18988 8.91429 0.270385 10.385 0.270385 5.57822C0.270385 2.67716 2.65317 0.875 5.40771 0.875C6.56856 0.875 9.46808 1.31 9.46808 2.94748ZM10.8743 8.5C10.8743 4.19035 13.7749 0.875 18.001 0.875C22.1638 0.875 25.1265 4.29289 25.1265 8.5C25.1265 12.7889 22.2477 16.124 18.001 16.124C13.7956 16.124 10.8743 12.7889 10.8743 8.5ZM21.8956 8.5C21.8956 5.99251 20.5898 3.7336 18.001 3.7336C15.4111 3.7336 14.1063 5.99251 14.1063 8.5C14.1063 11.0272 15.3696 13.2654 18.001 13.2654C20.6323 13.2643 21.8956 11.0272 21.8956 8.5ZM34.7157 2.94748C34.7157 3.75535 34.1761 4.48036 33.326 4.48036C32.4758 4.48036 31.8151 3.85892 30.5714 3.85892C29.6819 3.85892 28.8721 4.33535 28.8721 5.20536C28.8721 7.33895 35.7916 5.9718 35.7916 11.3172C35.7916 14.2804 33.3487 16.125 30.4886 16.125C28.8949 16.125 25.4548 15.7521 25.4548 13.805C25.4548 12.9971 25.9943 12.3332 26.8445 12.3332C27.8169 12.3332 28.9787 13.1411 30.3229 13.1411C31.6898 13.1411 32.4364 12.3746 32.4364 11.3586C32.4364 8.91429 25.5169 10.385 25.5169 5.57822C25.5169 2.67716 27.8997 0.875 30.6543 0.875C31.8151 0.875 34.7157 1.31 34.7157 2.94748Z"
        fill={fill}
      />
    </svg>
  )
}
