import { Reducer } from 'redux'

import { BudgetActionTypes, IAllBalancesState } from './types'

export const initialState: IAllBalancesState = {
  allBalances: [],
  hasProfileErrors: false,
}

const reducer: Reducer<IAllBalancesState> = (state = initialState, action: any): IAllBalancesState => {
  switch (action.type) {
    case BudgetActionTypes.SET_ALL_BALANCES: {
      return {
        ...state,
        allBalances: action.data.all_balances,
        hasProfileErrors: action.data.has_profile_errors,
      }
    }
    default: {
      return state
    }
  }
}

export { reducer as budgetReducer }
