import { InvoiceStatus } from '../../types'

export enum InvoicesActionTypes {
  SET_INVOICES = 'SET_INVOICES',
}

export interface IInvoiceObject {
  id: number
  created_at: string
  full_number: string
  amount: number
  tax: number
  commission: number
  real_amount: number
  is_deleted: boolean
  status: InvoiceStatus
  unique_url: string
}

export interface IInvoicesState {
  page_count: number
  count: number
  current: number
  next: string | null
  previous: string | null
  results: IInvoiceObject[]
}

export interface IInvoiceUpdateResponse {
  email: string
}
