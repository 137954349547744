import { Reducer } from 'redux'

import { IStoriesState, IStory, IStoryCard, StoriesActionTypes } from './types'

const initialState: IStoriesState = []

const reducer: Reducer<IStoriesState> = (state = initialState, action: any) => {
  switch (action.type) {
    case StoriesActionTypes.SET_STORIES: {
      return [...action.data]
    }
    case StoriesActionTypes.SET_STORY_WATCHED: {
      const index = state.findIndex((story: IStory) => story.id === action.data.id)

      return [
        ...state.slice(0, index),
        {
          ...state[index],
          ...action.data,
        },
        ...state.slice(index + 1),
      ]
    }
    case StoriesActionTypes.SET_STORY_CARD_WATCHED: {
      const storyIndex = state.findIndex((story: IStory) => story.id === action.data.storyId)
      const storyCardIndex = state[storyIndex].contents.findIndex((card: IStoryCard) => card.id === action.data.data.id)

      return [
        ...state.slice(0, storyIndex),
        {
          ...state[storyIndex],
          contents: [
            ...state[storyIndex].contents.slice(0, storyCardIndex),
            {
              ...state[storyIndex].contents[storyCardIndex],
              ...action.data.data,
            },
            ...state[storyIndex].contents.slice(storyCardIndex + 1),
          ],
        },
        ...state.slice(storyIndex + 1),
      ]
    }
    default: {
      return state
    }
  }
}

export { reducer as storiesReducer }
