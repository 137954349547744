import React from 'react'

import { IIcons } from './types'

export default (props: IIcons) => {
  const { style } = props
  const defaultStyle = { width: 25, height: 25 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="23.9209" height="24" rx="0.5" stroke="#C4C4C4" />
      <path d="M12.4605 15.625L12.4605 7.5" stroke="#2E2E2E" strokeLinecap="square" />
      <path d="M15.5756 10L12.4605 6.875L9.3454 10" stroke="#2E2E2E" strokeLinecap="square" />
      <path d="M17.4447 18.125H7.47632" stroke="#2E2E2E" strokeLinecap="square" />
    </svg>
  )
}
