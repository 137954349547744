import React from 'react'

import { RedBase, RedLighten33 } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fillBg = RedLighten33, fill = RedBase } = props
  const defaultStyle = { width: 66, height: 67 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 66 67" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="33" cy="33.0964" rx="33" ry="33.0964" fill={fillBg} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.2929 22.2423C24.6834 21.8518 25.3166 21.8518 25.7071 22.2423L33 29.5352L40.2929 22.2423C40.6834 21.8518 41.3166 21.8518 41.7071 22.2423L43.7577 24.2929C44.1482 24.6834 44.1482 25.3166 43.7577 25.7071L36.4648 33L43.7577 40.2929C44.1482 40.6834 44.1482 41.3166 43.7577 41.7071L41.7071 43.7577C41.3166 44.1482 40.6834 44.1482 40.2929 43.7577L33 36.4648L25.7071 43.7577C25.3166 44.1482 24.6834 44.1482 24.2929 43.7577L22.2423 41.7071C21.8517 41.3166 21.8517 40.6834 22.2423 40.2929L29.5352 33L22.2423 25.7071C21.8517 25.3166 21.8517 24.6834 22.2423 24.2929L24.2929 22.2423Z"
        fill={fill}
      />
    </svg>
  )
}
