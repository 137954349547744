export const enum FormErrorMessages {
  REQUIRED = 'required',
  REQUIRED_COMPANY_NAME = 'required_company_name',
  REQUIRED_GROUP_NAME = 'required_group_name',
  REQUIRED_ADS_NAME = 'required_ads_name',
}

export enum INPUT_TYPE {
  PASSWORD = 'password',
  TEXT = 'text',
  NUMBER = 'number',
}

export enum FORM_ERROR_TYPE {
  MANUAL = 'manual',
}

export const NON_NUMBER_CHARACTERS = ['e', 'E', '+', '-']

export const MAX_PASSWORD_LENGTH = 100

const SPACE_REGULAR_EXPRESSION = /\s/g

export const SPACE_REGEX = new RegExp(SPACE_REGULAR_EXPRESSION)
