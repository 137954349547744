import { Dispatch } from 'react'
import { AxiosError, AxiosPromise, AxiosResponse } from 'axios'
import { AnyAction } from 'redux'

import { API_URL } from '../../../const'
import { customAxios } from '../../../utils'
import { CounterpartiesActionTypes } from './types'

export function getCounterparties(page?: number) {
  return (dispatch: Dispatch<AnyAction>): AxiosPromise<any> => {
    dispatch({ type: CounterpartiesActionTypes.SET_COUNTERPARTIES_LOADING })

    let url = `${API_URL}/ad_mark/contractors/`

    if (page) {
      url = `${url}?page=${page}`
    }

    return customAxios(url, 'GET', null, true)
      .then((response: AxiosResponse<any>) => {
        dispatch({ type: CounterpartiesActionTypes.SET_COUNTERPARTIES, data: response.data })
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function updateCounterparties(name: string) {
  return (dispatch: Dispatch<AnyAction>): AxiosPromise<any> => {
    dispatch({ type: CounterpartiesActionTypes.SET_COUNTERPARTIES_LOADING })
    dispatch({ type: CounterpartiesActionTypes.CLEAR_COUNTERPARTIES })

    const url = `${API_URL}/ad_mark/contractors/?q=${name}`

    return customAxios(url, 'GET', null, true)
      .then((response: AxiosResponse<any>) => {
        dispatch({ type: CounterpartiesActionTypes.UPDATE_COUNTERPARTIES, data: response.data })

        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}
