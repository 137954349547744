import React from 'react'

import { PurpleBase } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = PurpleBase, fillBg = '#C4C4C4' } = props
  const defaultStyle = { width: 51, height: 50 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="25.5" cy="25" r="24.5" stroke={fillBg} />
      <path
        d="M17.7539 19.2188C17.7539 18.1719 18.0938 17.3242 18.7734 16.6758C19.4531 16.0195 20.3438 15.6914 21.4453 15.6914C22.5625 15.6914 23.4609 16.0156 24.1406 16.6641C24.8203 17.3047 25.1602 18.1758 25.1602 19.2773V20.1211C25.1602 21.1758 24.8203 22.0234 24.1406 22.6641C23.4609 23.3047 22.5703 23.625 21.4688 23.625C20.3594 23.625 19.4609 23.3047 18.7734 22.6641C18.0938 22.0156 17.7539 21.1445 17.7539 20.0508V19.2188ZM20.0039 20.1211C20.0039 20.5898 20.1367 20.9688 20.4023 21.2578C20.6758 21.5391 21.0312 21.6797 21.4688 21.6797C21.9062 21.6797 22.2539 21.5352 22.5117 21.2461C22.7695 20.957 22.8984 20.5703 22.8984 20.0859V19.2188C22.8984 18.75 22.7695 18.3711 22.5117 18.082C22.2539 17.793 21.8984 17.6484 21.4453 17.6484C21.0156 17.6484 20.668 17.793 20.4023 18.082C20.1367 18.3633 20.0039 18.7578 20.0039 19.2656V20.1211ZM25.9102 28.8164C25.9102 27.7617 26.2539 26.9141 26.9414 26.2734C27.6289 25.625 28.5195 25.3008 29.6133 25.3008C30.7227 25.3008 31.6172 25.6211 32.2969 26.2617C32.9844 26.8945 33.3281 27.7695 33.3281 28.8867V29.7305C33.3281 30.7773 32.9922 31.625 32.3203 32.2734C31.6484 32.9141 30.7539 33.2344 29.6367 33.2344C28.5117 33.2344 27.6094 32.9102 26.9297 32.2617C26.25 31.6133 25.9102 30.7539 25.9102 29.6836V28.8164ZM28.1602 29.7305C28.1602 30.1602 28.3008 30.5273 28.582 30.832C28.8633 31.1367 29.2148 31.2891 29.6367 31.2891C30.5898 31.2891 31.0664 30.7617 31.0664 29.707V28.8164C31.0664 28.3477 30.9336 27.9727 30.668 27.6914C30.4023 27.4023 30.0508 27.2578 29.6133 27.2578C29.1758 27.2578 28.8242 27.4023 28.5586 27.6914C28.293 27.9727 28.1602 28.3594 28.1602 28.8516V29.7305ZM22.1367 31.7227L20.4844 30.832L28.8164 17.4961L30.4688 18.3867L22.1367 31.7227Z"
        fill={fill}
      />
    </svg>
  )
}
