import { Reducer } from 'redux'

import { reducerName } from './modals/actions'
import {
  CounterpartiesActionTypes,
  CounterpartiesFilterActionTypes,
  CounterpartiesFormsActionTypes,
  ICounterpartiesState,
} from './types'

const initialList = {
  count: 0,
  current: 0,
  next: null,
  page_count: 0,
  previous: null,
  results: [],
  loading: false,
}

export const initialState: ICounterpartiesState = {
  counterpartiesList: initialList,
  counterpartiesSelectedList: initialList,
  hasCounterparties: false,
  hasFilters: false,
  modals: null,
  filterValue: '',
}

const AdsMarkingCounterpartiesReducer: Reducer<ICounterpartiesState> = (state = initialState, action: any) => {
  switch (action.type) {
    case CounterpartiesActionTypes.SET_COUNTERPARTIES: {
      const { results, ...listParams } = action.data
      return {
        ...state,
        hasCounterparties: Boolean(results.length),
        hasFilters: false,
        counterpartiesList: {
          ...listParams,
          loading: false,
          results: [...state.counterpartiesList.results, ...results],
        },
      }
    }
    case CounterpartiesActionTypes.UPDATE_COUNTERPARTIES: {
      return { ...state, hasFilters: true, counterpartiesList: { ...action.data, loading: false } }
    }
    case CounterpartiesActionTypes.CLEAR_COUNTERPARTIES: {
      return {
        ...state,
        counterpartiesList: {
          ...state.counterpartiesList,
          results: [],
        },
      }
    }
    case CounterpartiesActionTypes.SET_COUNTERPARTIES_LOADING: {
      return { ...state, counterpartiesList: { ...state.counterpartiesList, loading: true } }
    }
    case CounterpartiesFormsActionTypes.SET_COUNTERPARTIES_SELECTED: {
      const { results, ...listParams } = action.data
      return {
        ...state,
        counterpartiesSelectedList: {
          ...listParams,
          loading: false,
          results: [...state.counterpartiesSelectedList.results, ...results],
        },
      }
    }
    case CounterpartiesFormsActionTypes.SET_COUNTERPARTIES_SELECTED_LOADING: {
      return { ...state, constractsSelectedList: { ...state.counterpartiesSelectedList, loading: true } }
    }
    case CounterpartiesFilterActionTypes.SET_COUNTERPARTIES_FILTER: {
      return { ...state, filterValue: action.data }
    }
    case CounterpartiesFilterActionTypes.CLEAR_COUNTERPARTIES_FILTER: {
      return { ...state, filterValue: '' }
    }
    case `${reducerName}.OPEN_MODAL`: {
      return {
        ...state,
        modals: action.payload,
      }
    }
    case `${reducerName}.CLOSE_MODAL`: {
      return {
        ...state,
        modals: null,
      }
    }
    default: {
      return state
    }
  }
}

export default AdsMarkingCounterpartiesReducer
