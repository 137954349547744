import React from 'react'

import { SlateGreyLighten13 } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = SlateGreyLighten13 } = props
  const defaultStyle = { width: 44, height: 27 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 44 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M43.2179 7.39541C42.6366 3.63921 39.417 0.777344 35.4819 0.777344C34.5876 0.777344 33.7379 0.95621 32.933 1.22451L36.2421 7.35069H43.2179V7.39541Z"
        fill={fill}
      />
      <path
        d="M35.4821 16.5184C39.3724 16.5184 42.592 13.6565 43.2181 9.9003H35.4821C35.0349 9.9003 34.5878 9.632 34.4089 9.22955L30.7421 2.43262C28.864 3.86355 27.6567 6.09938 27.6567 8.64823C27.6567 12.9857 31.1446 16.5184 35.4821 16.5184Z"
        fill={fill}
      />
      <path
        d="M12.7211 13.3878C13.0341 13.3431 13.3472 13.1642 13.5707 12.8959L18.3107 6.54615L19.6075 7.52992C19.8758 7.7535 20.2782 7.7535 20.5913 7.61935C20.9043 7.4852 21.1279 7.17218 21.1279 6.81445L21.3067 1.62732C21.3067 1.3143 21.1726 1.00129 20.949 0.822422C20.6807 0.643555 20.3677 0.598838 20.0994 0.688271L15.2253 2.34279C14.9122 2.47694 14.6439 2.74524 14.5992 3.10297C14.5545 3.4607 14.6887 3.77372 14.957 3.9973L16.2985 4.98107L12.3187 10.3024L8.3389 7.35105C8.0706 7.12747 7.71287 7.03803 7.39985 7.12747C7.08684 7.17218 6.77382 7.35105 6.55024 7.61935L1.00537 15.0423C0.602923 15.6236 0.692357 16.4285 1.27367 16.831C1.49726 17.0098 1.76556 17.0993 2.03386 17.0993C2.43631 17.0993 2.83876 16.9204 3.06234 16.5627L7.8023 10.1682L11.7821 13.1195C12.0504 13.3431 12.3634 13.4325 12.7211 13.3878Z"
        fill={fill}
      />
      <path
        d="M5.16419 19.6035H1.63158C1.13969 19.6035 0.737244 20.006 0.737244 20.4978V25.4614C0.737244 25.9533 1.13969 26.3557 1.63158 26.3557H5.16419C5.65607 26.3557 6.05852 25.9533 6.05852 25.4614V20.4531C6.05852 19.9612 5.65607 19.6035 5.16419 19.6035Z"
        fill={fill}
      />
      <path
        d="M13.0792 16.0713H9.54654C9.05465 16.0713 8.6522 16.4737 8.6522 16.9656V25.4618C8.6522 25.9537 9.05465 26.3561 9.54654 26.3561H13.0792C13.571 26.3561 13.9735 25.9537 13.9735 25.4618V16.9656C13.9735 16.4737 13.571 16.0713 13.0792 16.0713Z"
        fill={fill}
      />
      <path
        d="M21.0387 12.5381H17.5061C17.0142 12.5381 16.6118 12.9405 16.6118 13.4324V25.4612C16.6118 25.9531 17.0142 26.3555 17.5061 26.3555H21.0387C21.5306 26.3555 21.933 25.9531 21.933 25.4612V13.4324C21.933 12.9405 21.5306 12.5381 21.0387 12.5381Z"
        fill={fill}
      />
      <path
        d="M42.3237 21.0352H28.551C28.0591 21.0352 27.6567 21.4376 27.6567 21.9295V25.4621C27.6567 25.954 28.0591 26.3564 28.551 26.3564H42.3685C42.8603 26.3564 43.2628 25.954 43.2628 25.4621V21.9295C43.2181 21.4376 42.8156 21.0352 42.3237 21.0352Z"
        fill={fill}
      />
    </svg>
  )
}
