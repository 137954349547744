import React from 'react'

import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = '#ACACAC' } = props
  const defaultStyle = { width: 6, height: 10 }

  return (
    <svg
      style={{ ...defaultStyle, ...style }}
      width="6"
      height="10"
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 1L5 5L1 9" stroke={fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
