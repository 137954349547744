import React from 'react'

import { IIcons } from './types'

export default (props: IIcons) => {
  const { style } = props
  const defaultStyle = { width: 40, height: 40 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="#F5F6F7" stroke="#A3ACBA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.4283 21.555H21.9874C23.1189 21.555 24.0721 21.3767 24.847 21.0201C25.6219 20.6566 26.2083 20.1355 26.606 19.4566C27.0106 18.7708 27.2129 17.9479 27.2129 16.9878C27.2129 16.1032 27.0106 15.3214 26.606 14.6425C26.2083 13.9567 25.6219 13.4218 24.847 13.0378C24.0721 12.6469 23.1189 12.4515 21.9874 12.4515H16.4533V19.94H14.324V21.555H16.4533V22.9642H14.324V24.5895H16.4533V27.4286H18.4283V24.5895H21.8639V22.9642H18.4283V21.555ZM18.4283 19.94H16.4533V21.555H18.4283V19.94ZM18.4283 19.94H21.9874C22.7349 19.94 23.3486 19.82 23.8287 19.58C24.3087 19.34 24.6619 19.0005 24.8882 18.5616C25.1213 18.1227 25.2379 17.605 25.2379 17.0084C25.2379 16.5215 25.1282 16.0552 24.9087 15.6094C24.6962 15.1637 24.3498 14.7968 23.8698 14.5088C23.3966 14.2207 22.7692 14.0767 21.9874 14.0767H18.4283V19.94Z"
        fill="#555D67"
      />
      <path d="M18.4283 19.94H16.4533V21.555H18.4283V19.94Z" fill="#555D67" />
    </svg>
  )
}
