import React from 'react'

import { SlateGreyLighten22 } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = SlateGreyLighten22 } = props
  const defaultStyle = { width: 23, height: 22 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.19629 14C3.19629 13.4477 3.644 13 4.19629 13H6.19629C6.74857 13 7.19629 13.4477 7.19629 14V19C7.19629 19.5523 6.74857 20 6.19629 20H4.19629C3.644 20 3.19629 19.5523 3.19629 19V14Z"
        fill={fill}
      />
      <path
        d="M9.19629 10C9.19629 9.44771 9.644 9 10.1963 9H12.1963C12.7486 9 13.1963 9.44772 13.1963 10V19C13.1963 19.5523 12.7486 20 12.1963 20H10.1963C9.644 20 9.19629 19.5523 9.19629 19V10Z"
        fill={fill}
      />
      <path
        d="M15.1963 14C15.1963 13.4477 15.644 13 16.1963 13H18.1963C18.7486 13 19.1963 13.4477 19.1963 14V19C19.1963 19.5523 18.7486 20 18.1963 20H16.1963C15.644 20 15.1963 19.5523 15.1963 19V14Z"
        fill={fill}
      />
      <path
        d="M7.19629 8C7.19629 9.10457 6.30086 10 5.19629 10C4.09172 10 3.19629 9.10457 3.19629 8C3.19629 6.89543 4.09172 6 5.19629 6C6.30086 6 7.19629 6.89543 7.19629 8Z"
        fill={fill}
      />
      <path
        d="M13.1963 4C13.1963 5.10457 12.3009 6 11.1963 6C10.0917 6 9.19629 5.10457 9.19629 4C9.19629 2.89543 10.0917 2 11.1963 2C12.3009 2 13.1963 2.89543 13.1963 4Z"
        fill={fill}
      />
      <path
        d="M19.1963 8C19.1963 9.10457 18.3009 10 17.1963 10C16.0917 10 15.1963 9.10457 15.1963 8C15.1963 6.89543 16.0917 6 17.1963 6C18.3009 6 19.1963 6.89543 19.1963 8Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1963 3.2188L17.5568 7.45917L16.8357 8.54083L11.1963 4.7812L5.55684 8.54083L4.83573 7.45917L11.1963 3.2188Z"
        fill={fill}
      />
    </svg>
  )
}
