import React from 'react'

import { GreyBase } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = GreyBase } = props
  const defaultStyle = { width: 18, height: 18 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.78784 5.12115H14.4545L13.4012 14.6012C13.3651 14.9274 13.2099 15.2289 12.9653 15.4478C12.7208 15.6668 12.4041 15.7878 12.0758 15.7878H6.16651C5.83827 15.7878 5.52155 15.6668 5.27701 15.4478C5.03246 15.2289 4.87728 14.9274 4.84117 14.6012L3.78784 5.12115Z"
        stroke={fill}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.018 3.21926C6.12584 2.99057 6.29648 2.79725 6.51001 2.66186C6.72354 2.52647 6.97117 2.45459 7.224 2.45459H11.0187C11.2716 2.45446 11.5194 2.52629 11.7331 2.66169C11.9467 2.79708 12.1175 2.99047 12.2253 3.21926L13.1213 5.12126H5.12134L6.018 3.21926Z"
        stroke={fill}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M2.45459 5.12115H15.7879" stroke={fill} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.78784 8.45459V11.7879" stroke={fill} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.4546 8.45459V11.7879" stroke={fill} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
