import React from 'react'

import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = '#F0F3F7' } = props
  const defaultStyle = { width: 40, height: 40 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 0C8.95431 0 0 8.95431 0 20C0 31.0457 8.95431 40 20 40C31.0457 40 40 31.0457 40 20C40 8.95431 31.0457 0 20 0ZM17.6599 12.1536C17.2652 12.5345 16.9994 13.0046 16.8061 13.5152L16.8057 13.5159L16.8059 13.5152C16.7978 13.5314 16.7878 13.5455 16.7777 13.5597C16.7676 13.5739 16.7576 13.5881 16.7495 13.6043C15.1467 16.384 13.5519 19.1718 11.9571 21.9596L11.9571 21.9597L11.957 21.9598C11.7637 22.2962 11.5724 22.6325 11.3811 22.9689L11.3808 22.9693C11.1896 23.3055 10.9984 23.6416 10.8052 23.9778C10.8086 23.988 10.8134 23.9968 10.8202 24.0035C10.7892 24.0093 10.7799 24.0311 10.7701 24.0541C10.7632 24.0703 10.756 24.0871 10.7408 24.0993C10.7044 24.1594 10.6665 24.2167 10.629 24.2735C10.5754 24.3547 10.5224 24.4349 10.475 24.5208C10.0804 25.1853 9.92733 25.9066 10.032 26.6765C10.2495 28.2649 11.498 29.4644 13.0847 29.5778C14.4701 29.667 15.5253 29.0754 16.2421 27.884C16.2923 27.8032 16.3363 27.7192 16.3798 27.636C16.4061 27.5858 16.4323 27.5358 16.4596 27.4869C16.4729 27.447 16.4806 27.4125 16.4875 27.3744C16.4942 27.379 16.5031 27.3816 16.5159 27.3816C17.0169 26.5217 17.5136 25.6575 18.0091 24.7952L18.0092 24.795L18.0104 24.7929C18.1916 24.4776 18.3727 24.1626 18.5537 23.8481C19.0428 23.0091 19.5239 22.1782 20.0049 21.3393C20.034 21.376 20.0625 21.4134 20.0843 21.4574C20.4451 22.0873 20.8067 22.7179 21.1685 23.349L21.1708 23.3532L21.1713 23.3541L21.174 23.3586C22.0299 24.8517 22.8875 26.3476 23.7411 27.8435C24.466 29.1078 25.7789 29.7561 27.1804 29.5454C29.4679 29.1969 30.7164 26.6279 29.5646 24.5937C28.6116 22.9017 27.6431 21.2136 26.6752 19.5266C26.2523 18.7896 25.8296 18.0528 25.4084 17.3161C25.1318 16.8298 24.8526 16.3453 24.5734 15.8609C24.0149 14.892 23.4565 13.9231 22.9195 12.9398C21.8483 10.9947 19.2386 10.6057 17.6599 12.1536Z"
        fill={fill}
      />
    </svg>
  )
}
