import React from 'react'

import { GreyLighten47 } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = GreyLighten47 } = props
  const defaultStyle = { width: 18, height: 18 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.9887 8.19037C17.8675 8.19037 17.7759 8.19037 17.6843 8.19037C16.2971 8.19037 14.9105 8.18986 13.5233 8.19037C11.978 8.19089 10.6405 9.49667 10.6348 11.0103C10.6287 12.5715 11.9401 13.8947 13.5054 13.8993C14.9857 13.9034 16.466 13.9003 17.9366 13.9003C17.957 13.9233 17.9785 13.9356 17.9785 13.9484C17.9806 14.6886 18.022 15.4318 17.9713 16.1689C17.8961 17.2562 17.0529 17.9963 15.9533 17.9984C13.6645 18.003 11.3752 17.9999 9.08648 17.9999C6.78032 17.9999 4.47467 17.9999 2.16851 17.9999C0.819205 17.9999 0.00102337 17.186 0.00102337 15.8429C0 12.6242 0.00102337 9.40658 0 6.18895C0 5.74414 0.0941498 5.32594 0.347433 4.95636C0.761897 4.35184 1.3575 4.09283 2.07385 4.09232C5.06925 4.08976 8.06413 4.0913 11.0595 4.09079C12.659 4.09079 14.2591 4.08976 15.8586 4.09079C17.1562 4.09181 17.9862 4.91797 17.9882 6.20892C17.9898 6.85592 17.9887 7.50242 17.9887 8.19037Z"
        fill={fill}
      />
      <path
        d="M14.7023 3.28571C14.5954 3.28571 14.5033 3.28571 14.4112 3.28571C10.3367 3.28571 6.26263 3.2893 2.1881 3.28213C1.50654 3.28111 0.89968 3.46641 0.357295 3.87334C0.330176 3.89382 0.303057 3.91481 0.273379 3.93119C0.261099 3.93835 0.242678 3.93528 0.19458 3.9404C0.229375 3.86106 0.251377 3.79247 0.287195 3.73258C0.577831 3.24476 1.01481 2.94993 1.55719 2.82093C5.47208 1.88984 9.38851 0.962837 13.3054 0.03993C14.1011 -0.147415 14.7172 0.345518 14.7233 1.16042C14.7284 1.83251 14.7238 2.5046 14.7223 3.1772C14.7218 3.20177 14.7136 3.22583 14.7023 3.28571Z"
        fill={fill}
      />
      <path
        d="M17.9995 8.99925C17.9995 10.3716 17.9995 11.7188 17.9995 13.0845C17.9309 13.0881 17.8736 13.0937 17.8163 13.0937C16.3795 13.0942 14.9422 13.1014 13.5054 13.0907C12.6938 13.0845 12.0435 12.7523 11.6853 12.0029C11.321 11.2407 11.3737 10.4801 11.8695 9.78191C12.2277 9.2772 12.7496 9.01563 13.3534 9.00642C14.892 8.9839 16.4307 8.99925 17.9995 8.99925ZM14.7314 11.0345C14.7273 10.4182 14.1245 9.82234 13.5079 9.82541C12.8898 9.82797 12.2947 10.4248 12.2927 11.0426C12.2906 11.6697 12.9011 12.2732 13.5284 12.2645C14.1465 12.2558 14.7355 11.6533 14.7314 11.0345Z"
        fill={fill}
      />
    </svg>
  )
}
