import React from 'react'

import { SlateGreyLighten22 } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = SlateGreyLighten22 } = props
  const defaultStyle = { width: 23, height: 22 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.4132 6.39516L19.9437 16.5C20.2664 18.6303 18.9852 20.3772 16.8024 20.3772H5.5C3.24901 20.3772 1.94896 18.5037 2.24902 16.5L3.76225 6.39505C3.88765 5.62182 4.55637 5.03662 5.35061 5.03662H6.29107V6.58326C6.29107 7.56556 7.10617 8.3598 8.08847 8.35994C9.07081 8.35994 9.86505 7.56567 9.86505 6.58337V5.03673H12.352V6.58337C12.352 7.56571 13.1463 8.35994 14.1286 8.35994C15.1109 8.35994 15.9052 7.56567 15.9052 6.58337V5.03673H16.8248C17.6189 5.03673 18.2878 5.6219 18.4132 6.39516ZM6.00001 11C6.07972 14.7458 8.12994 17 11.5082 17H11.7042V14.8571C12.934 14.9769 13.8521 15.8599 14.2267 17H16C15.5188 15.2945 14.271 14.3516 13.4955 13.9914C14.271 13.5458 15.3671 12.4657 15.6254 11H14.0127C13.6735 12.1914 12.6667 13.2715 11.7042 13.3741V11H10.0639V15.1574C9.06606 14.9193 7.76437 13.7516 7.71103 11H6.00001Z"
        fill={fill}
      />
      <path
        d="M8.06773 7.39849C8.50671 7.39849 8.88284 7.0432 8.88284 6.58338V4.34716C8.88284 3.13496 9.86513 2.1318 11.0982 2.1318C12.3104 2.1318 13.3136 3.1141 13.3136 4.34716V6.58353C13.3136 7.02251 13.6688 7.39863 14.1287 7.39863C14.5885 7.39863 14.9438 7.04335 14.9438 6.58353V4.34716C14.9438 2.2363 13.2299 0.522461 11.1191 0.522461C9.00824 0.522461 7.29436 2.23634 7.29436 4.34716V6.58353C7.27351 7.04319 7.62879 7.39849 8.06777 7.39849H8.06773Z"
        fill={fill}
      />
    </svg>
  )
}
