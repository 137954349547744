import React from 'react'

import { BackgroundWhite } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = BackgroundWhite } = props
  const defaultStyle = { width: 23, height: 22 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5981_14934)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.5 22C17.5751 22 22.5 17.0751 22.5 11C22.5 10.5074 22.4676 10.0224 22.4049 9.54688H21.6478H15.2975C15.3115 9.88583 15.3074 10.2387 15.3035 10.5704V10.5704V10.5705L15.3035 10.5706C15.3017 10.719 15.3 10.8632 15.3 11V11.0115C15.2412 14.8555 13.5976 18.6393 10.8189 21.228L9.99299 21.8976C10.4857 21.9651 10.9888 22 11.5 22ZM8.09064 21.4614L8.59376 21.1074C11.7725 18.8704 13.7373 14.9784 13.8 10.994C13.8001 10.8193 13.802 10.6551 13.8039 10.4974V10.4974C13.808 10.1629 13.8117 9.85755 13.7958 9.54242C13.7581 8.79688 14.3283 8.04688 15.1822 8.04688H22.0991C20.8088 3.40591 16.5521 0 11.5 0C9.65535 0 7.91675 0.454058 6.38987 1.2565C8.83963 3.70565 10.401 7.15866 10.401 11.0358C10.401 14.8769 8.79338 18.2922 6.36269 20.7292C6.91269 21.0202 7.49032 21.2659 8.09064 21.4614ZM5.07058 19.9264C2.302 17.9288 0.5 14.6748 0.5 11C0.5 7.30617 2.3207 4.03758 5.11362 2.04271L5.63248 2.63162C7.63523 4.78531 8.90096 7.73278 8.90096 11.0358C8.90096 14.3075 7.58908 17.2392 5.56657 19.395L5.07058 19.9264Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_5981_14934">
          <rect width="22" height="22" fill={fill} transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}
