import React from 'react'

import useStyles from './styles'

const PreviewWrapper: React.FC = ({ children }) => {
  const classes = useStyles()

  return <div className={classes.previewWrapper}>{children}</div>
}

export default PreviewWrapper
