import { Reducer } from 'redux'

import { AntibanActionTypes, AntibanTaskSlugs, IAntibanState } from './types'

const initialState: IAntibanState = {
  tasks: [],
}

const reducer: Reducer<IAntibanState> = (state = initialState, action: any) => {
  switch (action.type) {
    case AntibanActionTypes.SET_TASKS: {
      return {
        ...state,
        tasks: [...action.data.filter((x: AntibanTaskSlugs, i: number, a: AntibanTaskSlugs[]) => a.indexOf(x) === i)],
      }
    }
    case AntibanActionTypes.SET_TASK: {
      return {
        ...state,
        tasks: !state.tasks.includes(action.data) ? [...state.tasks, action.data] : state.tasks,
      }
    }
    default: {
      return state
    }
  }
}

export { reducer as antibanReducer }
