import { Reducer } from 'redux'

import { IReferral, ReferralActionTypes } from './types'

export const initialState: IReferral = {
  code: '',
  referrals: 0,
}

const reducer: Reducer<IReferral> = (state = initialState, action: any) => {
  switch (action.type) {
    case ReferralActionTypes.SET_REFERRAL: {
      if (action.data && action.data.code) {
        return { ...action.data }
      }
      return state
    }
    default: {
      return state
    }
  }
}

export { reducer as referralReducer }
