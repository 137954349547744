import React from 'react'

import { SlateGreyLighten22 } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = SlateGreyLighten22 } = props
  const defaultStyle = { width: 24, height: 24 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.43075 9.50929C5.9985 9.19164 5.39261 9.27161 5.05759 9.69054L3.70568 11.3811C3.35096 11.8246 3.43642 12.4742 3.8938 12.8109L13.1325 19.6124C13.5647 19.9307 14.1711 19.8509 14.5063 19.4317L15.8575 17.7421C16.2123 17.2984 16.1267 16.6487 15.6691 16.312L13.7079 14.8691C13.7026 14.8669 13.6971 14.8642 13.692 14.8615L8.47293 11.0154C8.47027 11.0129 8.46744 11.0103 8.46496 11.0078L8.44903 10.9925L6.43075 9.50929Z"
        fill={fill}
      />
      <path
        d="M3.61619 14.4032C3.21276 14.1059 2.64665 14.1794 2.33256 14.5699V14.5699C1.99896 14.9847 2.07833 15.5939 2.50708 15.9094L10.3576 21.6856C10.7612 21.9825 11.3272 21.9079 11.6399 21.5164V21.5164C11.9707 21.1024 11.8907 20.4962 11.4638 20.1821L7.56641 17.3146L3.61619 14.4032Z"
        fill={fill}
      />
      <path
        d="M17.3719 2.15689C16.4174 2.18895 15.4851 2.61259 14.8574 3.39574C14.3316 4.05198 14.151 4.8238 14.2229 5.59235H14.2149C14.4056 6.53126 14.458 7.56875 13.858 8.34396C13.3153 9.04502 12.3764 9.24451 11.4944 9.35478C11.1472 9.40132 10.8045 9.51317 10.4856 9.68122C9.9591 9.95864 10.025 10.6665 10.5041 11.0196L13.6253 13.3203C14.0787 13.6545 14.7389 13.5477 14.8939 13.0062C14.9976 12.6442 15.0303 12.2721 14.9848 11.9164C14.8683 11.0711 14.8226 10.1522 15.3894 9.4688C16.0159 8.71327 17.0747 8.50071 18.0702 8.43503V8.4274C18.8635 8.29557 19.5907 7.92844 20.1165 7.27198C21.2322 5.87941 20.9587 3.87973 19.5056 2.81005C18.87 2.34214 18.1142 2.13196 17.3719 2.15689Z"
        fill={fill}
      />
    </svg>
  )
}
