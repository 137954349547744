import React from 'react'

import { GreyBase } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = GreyBase } = props
  const defaultStyle = { width: 19, height: 16 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.8744 9.54789C4.97357 9.54789 6.01958 9.54789 7.06558 9.54788C10.5654 9.54788 14.0651 9.54827 17.5649 9.54673C18.1193 9.54634 18.5516 9.17841 18.6279 8.65291C18.7018 8.14474 18.3902 7.67086 17.8743 7.52946C17.7406 7.49286 17.5953 7.48786 17.4551 7.48786C13.0241 7.48593 8.59317 7.4867 4.1622 7.48709C4.08168 7.48709 4.00115 7.48709 3.87402 7.48709C3.95492 7.40194 4 7.35186 4.04777 7.30408C5.55494 5.79576 7.06442 4.28975 8.56889 2.77911C9.13447 2.21122 8.92257 1.27194 8.18131 1.04964C7.7633 0.924042 7.39884 1.03038 7.08947 1.34013C5.71444 2.71708 4.33788 4.09249 2.96209 5.46867C2.24009 6.19066 1.51694 6.91188 0.797263 7.63619C0.561864 7.87313 0.383486 8.14204 0.364222 8.49187C0.351508 8.71956 0.408913 8.92529 0.545684 9.10598C0.623122 9.20808 0.70634 9.30709 0.796492 9.39763C2.87502 11.4789 4.95624 13.5578 7.03322 15.6401C7.2794 15.8871 7.56219 16.0239 7.91086 15.9961C8.33273 15.9626 8.64518 15.7534 8.78811 15.352C8.93798 14.9309 8.859 14.5429 8.53422 14.2181C7.04015 12.7244 5.54647 11.23 4.05317 9.7359C4.00462 9.6862 3.958 9.63573 3.8744 9.54789Z"
        fill={fill}
      />
    </svg>
  )
}
