import { Reducer } from 'redux'

import { IInvoicesState, InvoicesActionTypes } from './types'

export const initialState: IInvoicesState = {
  count: 0,
  current: 0,
  next: null,
  page_count: 0,
  previous: null,
  results: [],
}

const reducer: Reducer<IInvoicesState> = (state = initialState, action: any) => {
  switch (action.type) {
    case InvoicesActionTypes.SET_INVOICES: {
      return { ...action.data }
    }
    default: {
      return state
    }
  }
}

export { reducer as invoicesReducer }
