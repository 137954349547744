import styled from 'styled-components'

import Header from '../Header'

export const StyledHeader = styled(Header)`
  grid-area: header;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 99;
`
