import React, { useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { CountryData } from 'react-phone-input-2'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import debounce from 'lodash.debounce'

import { MIXPANEL_KEYS_LEGAL_MODAL, MIXPANEL_PARAMS_CLOSE_TYPE } from '../consts'
import { IModalProps } from '../types'
import selector from './selector'
import useStyles from './styles'
import { ILegalDataErrorForm, LEGAL_DATA_ERROR_FORM_FIELDS } from './types'

import { onSendMixpanelEvent } from '@/analytics'
import Alert from '@/components/Alert'
import { Button, BUTTON_SIZE, BUTTON_VARIANT } from '@/components/ButtonSMB'
import { ErrorMessage } from '@/components/ErrorMessage'
import { Asterisk } from '@/components/Icons'
import { Countries, InputPhone } from '@/components/InputPhone'
import { Modal } from '@/components/ModalSMB'
import { RedBase } from '@/const/colors'
import { PurpleBase } from '@/const/colors'
import { FORM_ERROR_TYPE } from '@/const/form'
import { updateClientProfile } from '@/store/clients'
import { MyThunkDispatch } from '@/store/store'
import { updateProfile } from '@/store/user'
import { IUpdateProfileData } from '@/store/user/types'
import { CLOSE_MODAL_STATUS } from '@/types'

const AdsMarkingDataErrorLegalModal = (props: IModalProps) => {
  const { onClose, data } = props

  const { formatMessage } = useIntl()
  const classes = useStyles()
  const dispatch: MyThunkDispatch = useDispatch()
  const { personalPhone } = useSelector(selector)
  const [isLoading, setIsLoading] = useState(false)

  const clientId = data?.clientId
  const callBack = data?.callBack

  const closeHandler = useCallback(
    (status: CLOSE_MODAL_STATUS) => {
      if (data?.onCloseModal) {
        data.onCloseModal(status)
      }

      if (status === CLOSE_MODAL_STATUS.SUCCESS) {
        onSendMixpanelEvent(MIXPANEL_KEYS_LEGAL_MODAL.AD_MARK_I_POR_OOO_POPUP_TO_CHANGE_DATA_SAVE)
      }

      onClose(status)
    },
    [onClose]
  )

  const {
    handleSubmit,
    control,
    trigger,
    setError,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm<ILegalDataErrorForm>({
    shouldFocusError: false,
    defaultValues: {
      [LEGAL_DATA_ERROR_FORM_FIELDS.PERSONAL_PHONE]: personalPhone || '',
      [LEGAL_DATA_ERROR_FORM_FIELDS.REQUEST_ERROR]: '',
    },
  })

  const hasErrors = Object.values(errors).length > 0

  const isPhoneNumberValid = (phone: string, country: CountryData) => {
    if (errors.request_error) {
      clearErrors(LEGAL_DATA_ERROR_FORM_FIELDS.REQUEST_ERROR)
    }
    const minPhoneNumberLength = country.format.replace(/[^.]/g, '').length
    const dialCode = country.dialCode

    return !!phone && !!dialCode && phone.length >= minPhoneNumberLength && phone.startsWith(dialCode)
  }

  const onPhoneInputChange = (value: string, country: CountryData): void => {
    if (!isPhoneNumberValid(value, country)) {
      setError(LEGAL_DATA_ERROR_FORM_FIELDS.PERSONAL_PHONE, {
        type: FORM_ERROR_TYPE.MANUAL,
        message: formatMessage({ id: 'personalPhone.errorMessage.length' }),
      })
    } else {
      clearErrors(LEGAL_DATA_ERROR_FORM_FIELDS.PERSONAL_PHONE)
      setValue(LEGAL_DATA_ERROR_FORM_FIELDS.PERSONAL_PHONE, value)
    }
  }

  const debouncePhoneChangeHandler = debounce(onPhoneInputChange, 100)

  useEffect(() => {
    const validateAndSubmit = async () => {
      const isValid = await trigger()
      if (isValid) {
        handleSubmit(onSubmit)()
      }
    }

    validateAndSubmit()
  }, [trigger, handleSubmit])

  const closeModalHandler = (status: CLOSE_MODAL_STATUS) => () => {
    onSendMixpanelEvent(MIXPANEL_KEYS_LEGAL_MODAL.AD_MARK_I_POR_OOO_POPUP_TO_CHANGE_DATA_CANCEL, {
      type: status === CLOSE_MODAL_STATUS.CROSS ? MIXPANEL_PARAMS_CLOSE_TYPE.CROSS : MIXPANEL_PARAMS_CLOSE_TYPE.CANCEL,
    })
    return closeHandler(status)
  }

  const onSubmit = async (data: ILegalDataErrorForm) => {
    setIsLoading(true)

    const profileRequestBody: IUpdateProfileData = {
      [LEGAL_DATA_ERROR_FORM_FIELDS.PERSONAL_PHONE]: data.personal_phone.startsWith('+')
        ? data.personal_phone
        : `+${data.personal_phone}`,
    }

    try {
      clientId
        ? await updateClientProfile(Number(clientId), profileRequestBody)
        : await dispatch(updateProfile(profileRequestBody))

      if (callBack) {
        const result = callBack()

        if (result instanceof Promise) {
          await result
        }
      }

      closeHandler(CLOSE_MODAL_STATUS.SUCCESS)
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        const resp = error.response
        Object.keys(resp?.data).forEach((field) => {
          const key = field as LEGAL_DATA_ERROR_FORM_FIELDS
          if (resp?.data[field]) {
            setError(key, {
              type: FORM_ERROR_TYPE.MANUAL,
              message: resp.data[field].join(' '),
            })
          }
        })
      }
      return
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      isOpen
      closeCallback={closeModalHandler(CLOSE_MODAL_STATUS.CROSS)}
      bodyContent={
        <div className={classes.root}>
          <h4 className={classes.title}>
            <FormattedMessage id="budget.adsMarkingDataErrorModal.title" />
          </h4>
          <Alert text={formatMessage({ id: 'budget.adsMarkingDataErrorModal.alertDesription' })} />
          <form className={classes.form}>
            <section>
              <label htmlFor={LEGAL_DATA_ERROR_FORM_FIELDS.PERSONAL_PHONE} className={classes.label}>
                <FormattedMessage id="personalPhone.phoneLabel" />{' '}
                <Asterisk style={{ width: 16, height: 16 }} fill={PurpleBase} />
              </label>
              <Controller
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: formatMessage({ id: 'personalPhone.errorMessage.required' }),
                  },
                }}
                name={LEGAL_DATA_ERROR_FORM_FIELDS.PERSONAL_PHONE}
                render={({ field }) => (
                  <InputPhone
                    onChange={debouncePhoneChangeHandler}
                    onBlur={field.onBlur}
                    value={field.value}
                    defaultCountry={Countries.RUSSIA}
                    disabled={isLoading}
                    inputStyle={{
                      borderColor: errors[LEGAL_DATA_ERROR_FORM_FIELDS.PERSONAL_PHONE]?.message ? RedBase : '',
                    }}
                  />
                )}
              />
              {Boolean(errors[LEGAL_DATA_ERROR_FORM_FIELDS.PERSONAL_PHONE]?.message) && (
                <ErrorMessage>{errors[LEGAL_DATA_ERROR_FORM_FIELDS.PERSONAL_PHONE]?.message}</ErrorMessage>
              )}
            </section>
          </form>
        </div>
      }
      footerContent={
        <div className={classes.footer}>
          <Button
            label={formatMessage({ id: 'adsMarking.adsMarkingDataErrorModal.cancel' })}
            size={BUTTON_SIZE.size40}
            variant={BUTTON_VARIANT.secondaryGray}
            disabled={isLoading}
            onClick={closeModalHandler(CLOSE_MODAL_STATUS.CANCEL)}
          />
          <Button
            size={BUTTON_SIZE.size40}
            variant={BUTTON_VARIANT.primary}
            label={formatMessage({ id: 'common.save' })}
            disabled={isLoading || hasErrors}
            isLoading={isLoading}
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      }
      maxWidth="684px"
    />
  )
}

export default AdsMarkingDataErrorLegalModal
