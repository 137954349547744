import { Reducer } from 'redux'

import { reducerName } from './Modals/action'
import { ContractorsFormsActionTypes, ContractsActionTypes, ContractsFormsActionTypes, IContractsState } from './types'

const initialList = {
  count: 0,
  current: 0,
  next: null,
  page_count: 0,
  previous: null,
  results: [],
  loading: false,
}

export const initialState: IContractsState = {
  contractList: initialList,
  constractsSelectedList: {
    results: [],
    loading: false,
  },
  contractorsSelectedList: {
    results: [],
    loading: false,
  },
  hasContracts: false,
  hasFilters: false,
  modals: null,
}

const AdsMarkingContractReducer: Reducer<IContractsState> = (state = initialState, action: any) => {
  switch (action.type) {
    case ContractsActionTypes.SET_CONTRACTS: {
      const { results, ...listParams } = action.data
      return {
        ...state,
        hasContracts: Boolean(results.length),
        hasFilters: false,
        contractList: {
          ...listParams,
          loading: false,
          results: [...state.contractList.results, ...results],
        },
      }
    }
    case ContractsActionTypes.UPDATE_CONTRACTS: {
      return { ...state, hasFilters: true, contractList: { ...action.data, loading: false } }
    }
    case ContractsActionTypes.CLEAR_CONTRACTS: {
      return {
        ...state,
        contractList: {
          ...state.contractList,
          results: [],
        },
      }
    }
    case ContractsActionTypes.SET_CONTRACTS_LOADING: {
      return { ...state, contractList: { ...state.contractList, loading: true } }
    }
    case ContractsFormsActionTypes.SET_CONTRACTS_SELECTED: {
      return {
        ...state,
        constractsSelectedList: {
          loading: false,
          results: action.data,
        },
      }
    }
    case ContractsFormsActionTypes.SET_CONTRACTS_SELECTED_LOADING: {
      return { ...state, constractsSelectedList: { ...state.constractsSelectedList, loading: true } }
    }
    case ContractorsFormsActionTypes.SET_CONTRACTORS_SELECTED: {
      return {
        ...state,
        contractorsSelectedList: {
          loading: false,
          results: action.data,
        },
      }
    }
    case ContractorsFormsActionTypes.SET_CONTRACTORS_SELECTED_LOADING: {
      return {
        ...state,
        contractorsSelectedList: { ...state.contractorsSelectedList, loading: true },
      }
    }
    case `${reducerName}.OPEN_MODAL`: {
      return {
        ...state,
        modals: action.payload,
      }
    }
    case `${reducerName}.CLOSE_MODAL`: {
      return {
        ...state,
        modals: null,
      }
    }
    default: {
      return state
    }
  }
}

export default AdsMarkingContractReducer
