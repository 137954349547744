import React from 'react'

import { PurpleBase } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = PurpleBase, fillBg = 'none' } = props
  const defaultStyle = { width: 20, height: 20 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 20 20" fill={fillBg} xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.78 8.7H3.21641V13.818C3.21641 14.7017 3.93275 15.418 4.8164 15.418H15.18C16.0637 15.418 16.78 14.7017 16.78 13.818V8.7ZM16.78 7.3H3.21641V6.18164C3.21641 5.29799 3.93275 4.58164 4.81641 4.58164H15.18C16.0637 4.58164 16.78 5.29798 16.78 6.18164V7.3ZM18.18 6.18164C18.18 4.52479 16.8369 3.18164 15.18 3.18164H4.81641C3.15955 3.18164 1.81641 4.52479 1.81641 6.18164V13.818C1.81641 15.4749 3.15955 16.818 4.8164 16.818H15.18C16.8369 16.818 18.18 15.4749 18.18 13.818V6.18164Z"
        fill={fill}
      />
    </svg>
  )
}
