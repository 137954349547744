import { createUseStyles } from 'react-jss'

import { asideWidth, WidthBPs } from '@/const/commonStyles'

export default createUseStyles(
  {
    previewWrapper: {
      display: 'grid',
      backgroundColor: '#25134D',
      gridTemplateColumns: '1fr',
      gridTemplateAreas: `'content'`,
      minHeight: '100vh',

      [`@media (min-width: ${WidthBPs.MEDIUM})`]: {
        gridTemplateColumns: `${asideWidth} auto`,
        gridTemplateAreas: `'aside content'`,
        overflow: 'hidden',
      },
    },
  },
  { name: 'PreviewWrapper' }
)
