import React from 'react'

import { White } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fillBg = '#A8D87B', fill = White } = props
  const defaultStyle = { width: 16, height: 16 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.27772 15.1111C12.4505 15.1111 15.8333 11.7284 15.8333 7.55556C15.8333 3.38274 12.4505 0 8.27772 0C4.1049 0 0.722168 3.38274 0.722168 7.55556C0.722168 11.7284 4.1049 15.1111 8.27772 15.1111Z"
        fill={fillBg}
      />
      <path d="M5.44409 7.55564L7.8052 9.44453L11.1108 5.66675" stroke={fill} strokeWidth="2" />
    </svg>
  )
}
