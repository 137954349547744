import { Reducer } from 'redux'

import { AddVKAdsActionTypes } from './actions'
import { IAddVKAdsState } from './types'

export const initialState: IAddVKAdsState = {
  error: null,
  isLoading: false,
}

const addVKAdsReducer: Reducer<IAddVKAdsState> = (state = initialState, action: any) => {
  switch (action.type) {
    case AddVKAdsActionTypes.SET_ADD_VK_ADS_LOADING: {
      return { ...state, isLoading: action.data }
    }
    case AddVKAdsActionTypes.SET_ADD_VK_ADS_ERROR: {
      return { ...state, error: action.data }
    }
    case AddVKAdsActionTypes.UPDATE_ADD_VK_ADS_ERROR: {
      return {
        ...state,
        error: {
          ...state.error,
          ...action.data,
        },
      }
    }
    default: {
      return state
    }
  }
}

export default addVKAdsReducer
