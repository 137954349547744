import React from 'react'

import { SlateGreyLighten22 } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = SlateGreyLighten22 } = props
  const defaultStyle = { width: 22, height: 22 }

  return (
    <svg
      style={{ ...defaultStyle, ...style }}
      width="23"
      height="24"
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2998 24C20.0612 24 22.2998 21.7614 22.2998 19C22.2998 16.2386 20.0612 14 17.2998 14C14.5384 14 12.2998 16.2386 12.2998 19C12.2998 21.7614 14.5384 24 17.2998 24ZM16.2172 16.9681C16.0212 16.7735 15.7047 16.7746 15.5101 16.9706C15.3155 17.1665 15.3167 17.4831 15.5126 17.6777L17.698 19.8476H16.3649C16.0888 19.8476 15.8649 20.0714 15.8649 20.3476C15.8649 20.6237 16.0888 20.8476 16.3649 20.8476H18.9112C19.0438 20.8476 19.1709 20.7949 19.2647 20.7011C19.3585 20.6073 19.4112 20.4802 19.4112 20.3476V17.8229C19.4112 17.5467 19.1873 17.3229 18.9112 17.3229C18.635 17.3229 18.4112 17.5467 18.4112 17.8229V19.1465L16.2172 16.9681Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2998 9.99999C20.0612 9.99999 22.2998 7.76141 22.2998 4.99998C22.2998 2.23855 20.0612 -3.05176e-05 17.2998 -3.05176e-05C14.5384 -3.05176e-05 12.2998 2.23855 12.2998 4.99998C12.2998 7.76141 14.5384 9.99999 17.2998 9.99999ZM15.5127 5.99692C15.3172 6.19192 15.3168 6.5085 15.5118 6.70402C15.7068 6.89955 16.0233 6.89997 16.2189 6.70497L18.3994 4.53023L18.3929 5.86327C18.3915 6.13941 18.6143 6.36436 18.8904 6.36571C19.1666 6.36706 19.3915 6.1443 19.3929 5.86816L19.4053 3.32193C19.406 3.18933 19.3539 3.06189 19.2606 2.96767C19.1673 2.87344 19.0404 2.82014 18.9078 2.8195L16.3831 2.80717C16.107 2.80582 15.882 3.02858 15.8807 3.30472C15.8793 3.58086 16.1021 3.80581 16.3782 3.80716L17.7018 3.81362L15.5127 5.99692Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5498 4.99994C4.5498 4.58573 4.88559 4.24994 5.2998 4.24994H12.2998C12.714 4.24994 13.0498 4.58573 13.0498 4.99994C13.0498 5.41415 12.714 5.74994 12.2998 5.74994H6.0498V18.25H12.2998C12.714 18.25 13.0498 18.5858 13.0498 19C13.0498 19.4142 12.714 19.75 12.2998 19.75H5.2998C4.88559 19.75 4.5498 19.4142 4.5498 19V4.99994Z"
        fill={fill}
      />
      <path
        d="M0.833293 12.6402C0.524254 12.2694 0.524255 11.7307 0.833294 11.3598L4.53159 6.92187C4.93139 6.44212 5.66824 6.44212 6.06804 6.92187L9.76634 11.3598C10.0754 11.7307 10.0754 12.2694 9.76634 12.6402L6.06804 17.0782C5.66824 17.5579 4.93139 17.5579 4.53159 17.0782L0.833293 12.6402Z"
        fill={fill}
      />
    </svg>
  )
}
