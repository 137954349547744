import { OnboardingStatuses } from '@/pages/Accounts/types'
import { AgencyDocsReceivedStatus, CloseDocTypes, DocumentMethodTypes } from '@/types'
import { Industries, LANGUAGE } from '@/types'

export enum UserActionTypes {
  CLEAR_PROFILE = 'CLEAR_PROFILE',
  SET_AUTHORIZED = 'SET_AUTHORIZED',
  SET_ATOKEN = 'SET_ATOKEN',
  SET_RTOKEN = 'SET_RTOKEN',
  SET_EXPIRES = 'SET_EXPIRES',
  SET_EMAIL = 'SET_EMAIL',
  SET_PHONE = 'SET_PHONE',
  SET_HAS_PROFILE = 'SET_HAS_PROFILE',
  SET_PROFILE = 'SET_PROFILE',
  SET_SUBSCRIBE = 'SET_SUBSCRIBE',
  SET_ALLOW_CREATE_ACCOUNTS = 'SET_ALLOW_CREATE_ACCOUNTS',
  SET_MAIL_PROVIDER = 'SET_MAIL_PROVIDER',
  SET_GA_USER_ID = 'SET_GA_USER_ID',
  SET_FBP = 'SET_FBP',
  SET_FBC = 'SET_FBC',
  SET_AB_TESTS = 'SET_AB_TESTS',
  SET_AVAILABLE_PLUGINS = 'SET_AVAILABLE_PLUGINS',
  REMOVE_AVAILABLE_PLUGIN = 'REMOVE_AVAILABLE_PLUGIN',
  SET_TELEGRAM = 'SET_TELEGRAM',
  SET_FB_CREDENTIAL = 'SET_FB_CREDENTIAL',
  SET_MYTARGET_MANAGER = 'SET_MYTARGET_MANAGER',
  SET_TIKTOK_MANAGER = 'SET_TIKTOK_MANAGER',
  SET_YANDEX_AGENCY = 'SET_YANDEX_AGENCY',
  SET_USER_ID = 'SET_USER_ID',
  REMOVE_CONNECTED_PLUGIN = 'REMOVE_CONNECTED_PLUGIN',
  SET_CONNECTED_PLUGIN = 'SET_CONNECTED_PLUGIN',
}

export enum UserProfileStatuses {
  NEW = 'new',
  APPROVED = 'approved',
  DOUBTFUL = 'doubtful',
  REJECTED = 'rejected',
  WAITDOCS = 'waitdocs',
}

export enum UserDocumentsStatuses {
  MISSED = 'missed',
  LOADED = 'loaded',
  CHECKED = 'checked',
}

export interface IUserState {
  readonly language: LANGUAGE
  readonly email: string
  readonly accessToken?: string
  readonly has_profile?: boolean
  readonly profile: IUserProfile
  readonly isAuthorized: boolean
  readonly refreshToken?: string
  readonly subscribe?: boolean
  readonly tokenExpire?: number
  readonly mailProvider?: string
  readonly ga_user_id: string
  readonly fbp: string
  readonly fbc: string
  readonly ab_tests: IABTest[]
  readonly telegram_enabled: boolean
  readonly fb_credential_enabled: boolean
  readonly id: number | null
  readonly phone: string
}

export interface IUserProfile {
  readonly account_counts: {
    archived_accounts: number
    archived_avito: number
    archived_fb: number
    archived_google: number
    archived_mytarget: number
    archived_telegram: number
    archived_tiktok: number
    archived_vk: number
    archived_vkads: number
    archived_vk_adsmarket: number
    archived_yandex: number
    archived_yandex_promo_page: number
    avito: number
    facebook: number
    google: number
    mytarget: number
    telegram: number
    tiktok: number
    vk: number
    vkads: number
    vk_adsmarket: number
    yandex_business: number
    profi: number
    yandex: number
    yandex_promo_page: number
  }
  readonly actual_address: string | null
  readonly ads_marking_product_tour: boolean
  readonly ads_marking_advertise_product_tour: boolean
  readonly campaign_editor_product_tour: boolean
  readonly campaign_editor_product_tour_second: boolean
  readonly campaign_editor_product_tour_third: boolean
  readonly ads_marking_success: boolean
  readonly agency: string
  readonly agency_clients: IAgencyClient[]
  readonly agency_docs_received_status: AgencyDocsReceivedStatus
  readonly allow_create_accounts: {
    facebook: boolean
    google: boolean
    mytarget: boolean
    vk: boolean
    yandex: boolean
    tiktok: boolean
    telegram: boolean
    vkads: boolean
    yandex_promo_page: boolean
    vk_adsmarket: boolean
  }
  readonly autopayment: boolean
  readonly balance: number
  readonly business_manager_id: string
  readonly cashback: IUserCashBack | null
  readonly ceo: string
  readonly closedoc_type: CloseDocTypes
  readonly contract_agency_status: boolean
  readonly contract_avito_status: boolean
  readonly contract_client_status: boolean
  readonly contract_telegram_status: boolean
  readonly contract_yandex_promo_page_status: boolean
  readonly contract_vkads_status: boolean
  readonly contract_vk_adsmarket_status: boolean
  readonly created_at: string
  readonly documents_methods: DocumentMethodTypes | null
  readonly documents_status: UserDocumentsStatuses | ''
  readonly facebook_commission_percent: number
  readonly full_name: string
  readonly google_ads_email: string
  readonly google_mc: boolean
  readonly has_yandex_agency: boolean
  readonly id: number | null
  readonly industry: string
  readonly inn: string
  readonly is_last_30_days_spending: boolean
  readonly is_profile_agency: boolean
  readonly is_profile_client: boolean
  readonly kpp: string
  readonly legal_address: string
  readonly legal_status?: UserProfileStatuses
  readonly name: string
  readonly new_aitarget_offer: boolean
  readonly onboarding: OnboardingTypes
  readonly partner_status: boolean
  readonly paying_customer: boolean
  readonly personal_phone: string | null
  readonly policy_status?: UserProfileStatuses
  readonly profile_checks: {
    legal: boolean
    payment: boolean
    policy: boolean
    invoice: boolean
  }
  readonly profile_type: string
  readonly promocode: number | null
  readonly telegram_commission: ITelegramCommission
  readonly website: string
  readonly google_ads_mcc_id: string
  readonly optimizer: boolean
  readonly feedgen: boolean
  readonly onboarding_optimizer_is_finished: boolean
  readonly available_plugins: IUserAvailablePlugin[]
  readonly smart_targetings: boolean
  readonly fb_optimizer_auth: boolean
  readonly has_mytarget_manager: boolean
  readonly has_tiktok_manager: boolean
  readonly audiences_product_tour_is_finished: boolean
  readonly post_sale_onboarding: boolean
  readonly contract_type: string
  readonly transactions_filter_export: boolean
  readonly antiban: boolean
  readonly connected_plugins: IUserPlugin[]
  readonly profile_is_approved: boolean
  readonly creatives_sent: boolean
  readonly usn_product_tour_is_finished: boolean
  readonly vk_shop_promo: boolean
  readonly analytics_product_tour: boolean
  readonly tg_analytics_product_tour: boolean
  readonly tg_analytics_banner: boolean
  readonly agent_client: boolean
  readonly is_ord_ready: boolean
  readonly is_cashback_enabled: boolean
  readonly new_referral_program: boolean
  readonly okved_codes: string[]
}

interface ITgCommissionIndividual {
  account_type: Industries
  value: number
}

export interface ITelegramCommission {
  accounts_subjects: string[]
  cryptocurrencies: number
  education: number
  others: number
  real_estate: number
  big_data: number
  mts_pro_big_data: number
  tg_commission_individual: ITgCommissionIndividual[]
  tg_euro_rate_individual: ITgEuroRateIndividual[]
}

interface ITgEuroRateIndividual {
  account_type: Industries
  value: number
}

export interface IUserCashBack {
  amount: number
  month: string
}

export interface IAuthorizeUserData {
  email: string
  password: string
}

export interface IChangePasswordData {
  old_password: string
  new_password: string
}

export interface ITokenRefreshResponse {
  readonly access: string
  readonly refresh: string
}

export interface IUserInfoResponse {
  email: string
  phone: string
  password: string
  has_profile: string
  promo: string
  subscribe: boolean
  ga_user_id: string
  fbc: string
  fbp: string
  ab_tests: IABTest[]
  telegram_enabled: boolean
  fb_credential_enabled: boolean
  id: number
}

export const enum SIGN_UP_FORM_FIELDS {
  EMAIL = 'email',
  PHONE = 'phone',
  PASSWORD = 'password',
  PASSWORD_REPEAT = 'password_repeat',
  TERMS = 'terms',
  PROMO = 'promo',
  REQUEST_ERROR = 'request_error',
}

export const enum SIGN_UP_FIELDS {
  FBC = 'fbc',
  FBP = 'fbp',
  REFERER = 'referer',
  FLAGS = 'flags',
}

export type IAddUserData = Pick<
  ISignupUserData,
  | SIGN_UP_FORM_FIELDS.EMAIL
  | SIGN_UP_FORM_FIELDS.PASSWORD
  | SIGN_UP_FORM_FIELDS.PASSWORD_REPEAT
  | SIGN_UP_FIELDS.REFERER
> & {
  subscribe: boolean
}

export interface ISignupUserData {
  [SIGN_UP_FORM_FIELDS.EMAIL]: string
  [SIGN_UP_FORM_FIELDS.PHONE]: string
  [SIGN_UP_FIELDS.FBC]: string
  [SIGN_UP_FIELDS.FBP]: string
  [SIGN_UP_FORM_FIELDS.PASSWORD]: string
  [SIGN_UP_FORM_FIELDS.PASSWORD_REPEAT]: string
  [SIGN_UP_FORM_FIELDS.PROMO]: string
  [SIGN_UP_FIELDS.REFERER]: string
  [SIGN_UP_FIELDS.FLAGS]?: { [key: string]: string | boolean }
}

export interface ISignupResponse {
  email: string
  password: string
  promo: string
  subscribe: boolean
  ga_user_id: string
  id: number
}

export interface ISigninResponse {
  access: string
  refresh: string
}

export interface ICreateProfileData {
  ceo?: string
  contract_fb_status?: boolean
  contract_gg_status?: boolean
  documents_methods?: string
  google_ads_email?: string
  google_ads_mcc_id?: string
  inn?: string
  kpp?: string
  legal_address?: string
  name?: string
  profile_type?: string
  source?: string
  website?: string
  contract_vk_status?: boolean
  client_user_id?: number
  instagram_username?: string
  actual_address?: string
  is_profile_agency?: boolean
  industry_id?: number
  partner_status?: boolean
  personal_phone?: string
}

export interface IUpdateProfileData {
  actual_address?: string
  business_manager_id?: string
  contract_fb_status?: boolean
  documents_methods?: DocumentMethodTypes | null
  contract_gg_status?: boolean
  google_ads_email?: string
  google_ads_mcc_id?: string
  google_mc?: boolean
  onboarding?: OnboardingTypes
  contract_vk_status?: boolean
  personal_phone?: string
  inn?: string
  kpp?: string
  legal_address?: string
  ceo?: string
  name?: string
  profile_type?: string
  contract_mt_status?: boolean
  website?: string
  instagram_username?: string
  source?: string
  contract_agency_status?: boolean
  agency_docs_received_status?: AgencyDocsReceivedStatus
  contract_avito_status?: boolean
  contract_yandex_promo_page_status?: boolean
  contract_telegram_status?: boolean
  new_aitarget_offer?: boolean
  contract_vkads_status?: boolean
  contract_vk_adsmarket_status?: boolean
  autopayment?: boolean
  new_referral_program?: boolean
}

export interface IUpdateUserData {
  subscribe?: boolean
  fbp?: string
}

export type OnboardingTypes = OnboardingStatuses

export const enum PLUGINS {
  FEEDGEN = 'feedgen',
  OPTIMIZER = 'optimizer',
  ONBOARDING_OPTIMIZER_IS_FINISHED = 'onboarding_optimizer_is_finished',
  SMART_TARGETINGS = 'smart_targetings',
  REFERRAL_IS_ANNOUNCED = 'referral_is_announced',
  FB_OPTIMIZER_AUTH = 'fb_optimizer_auth',
  AUDIENCES_PRODUCT_TOUR_IS_FINISHED = 'audiences_product_tour_is_finished',
  POST_SALE_ONBOARDING = 'post_sale_onboarding',
  TRANSACTIONS_FILTER_EXPORT = 'transactions_filter_export',
  ANTIBAN = 'antiban',
  PROFILE_IS_APPROVED = 'profile_is_approved',
  CREATIVES_SENT = 'creatives_sent',
  USN_PRODUCT_TOUR_IS_FINISHED = 'usn_product_tour_is_finished',
  VK_SHOP_PROMO = 'vk_shop_promo',
  ANALYTICS_PRODUCT_TOUR = 'analytics_product_tour',
  TG_ANALYTICS_PRODUCT_TOUR = 'tg_analytics_product_tour',
  TG_ANALYTICS_BANNER = 'tg_analytics_banner',
  ADS_MARKING_PRODUCT_TOUR = 'ads_marking_product_tour',
  ADS_MARKING_ADVERTISE_PRODUCT_TOUR = 'ads_marking_advertise_product_tour',
  ADS_MARKING_SUCCESS = 'ads_marking_success',
  CAMPAIGN_EDITOR_PRODUCT_TOUR = 'campaign_editor_product_tour',
  CAMPAIGN_EDITOR_PRODUCT_TOUR_SECOND = 'campaign_editor_product_tour_second',
  CAMPAIGN_EDITOR_PRODUCT_TOUR_THIRD = 'campaign_editor_product_tour_third',
  CAMPAIGN_HIDE_LIMIT_ATTENTION = 'campaign_hide_limit_attention',
  CAMPAIGN_HIDE_STOP_DATE_ATTENTION = 'campaign_hide_stop_date_attention',
}

export interface IUserPlugin {
  created_at: string
  id: number
  plugin: PLUGINS
  updated_at: string
}
export interface IUserAvailablePlugin {
  name: string
  slug: PLUGINS
}

export interface IAgencyClient {
  id: number
  name: string
  contract_client_status: boolean
  contract_mt_status: boolean
  contract_vk_status: boolean
  contract_vkads_status: boolean
  contract_vk_adsmarket_status: boolean
}

export enum IndustryType {
  AGENCY = 'agency',
  CLIENT = 'client',
}

export interface IABTest {
  ab_test: string
  test_group: ABTestTypes
}

export type ABTestTypes = 'test' | 'control'
