import React from 'react'

import { Black, PurpleBase } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = PurpleBase, fillBg = Black } = props
  const defaultStyle = { width: 19, height: 19 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.05511 2.8H2.49951C2.11291 2.8 1.79951 3.1134 1.79951 3.5V7.0556C1.79951 7.4422 2.11291 7.7556 2.49951 7.7556H6.05511C6.44171 7.7556 6.75511 7.4422 6.75511 7.0556V3.5C6.75511 3.1134 6.44171 2.8 6.05511 2.8ZM2.49951 1.5C1.39494 1.5 0.499512 2.39543 0.499512 3.5V7.0556C0.499512 8.16017 1.39494 9.0556 2.49951 9.0556H6.05511C7.15968 9.0556 8.05511 8.16017 8.05511 7.0556V3.5C8.05511 2.39543 7.15968 1.5 6.05511 1.5H2.49951Z"
        fill={fillBg}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7313 4.78319L14.2171 2.269C13.9438 1.99563 13.5006 1.99563 13.2272 2.269L10.713 4.78319C10.4396 5.05656 10.4396 5.49977 10.713 5.77314L13.2272 8.28733C13.5006 8.56069 13.9438 8.56069 14.2171 8.28733L16.7313 5.77314C17.0047 5.49977 17.0047 5.05655 16.7313 4.78319ZM15.1364 1.34976C14.3553 0.568712 13.089 0.568711 12.308 1.34976L9.79377 3.86395C9.01272 4.645 9.01272 5.91133 9.79377 6.69238L12.308 9.20656C13.089 9.98761 14.3553 9.98761 15.1364 9.20657L17.6506 6.69238C18.4316 5.91133 18.4316 4.645 17.6506 3.86395L15.1364 1.34976Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5004 12.2434H11.9448C11.5582 12.2434 11.2448 12.5568 11.2448 12.9434V16.499C11.2448 16.8856 11.5582 17.199 11.9448 17.199H15.5004C15.887 17.199 16.2004 16.8856 16.2004 16.499V12.9434C16.2004 12.5568 15.887 12.2434 15.5004 12.2434ZM11.9448 10.9434C10.8403 10.9434 9.94482 11.8388 9.94482 12.9434V16.499C9.94482 17.6035 10.8403 18.499 11.9448 18.499H15.5004C16.605 18.499 17.5004 17.6035 17.5004 16.499V12.9434C17.5004 11.8388 16.605 10.9434 15.5004 10.9434H11.9448Z"
        fill={fillBg}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.05511 12.2434H2.49951C2.11291 12.2434 1.79951 12.5568 1.79951 12.9434V16.499C1.79951 16.8856 2.11291 17.199 2.49951 17.199H6.05511C6.44171 17.199 6.75511 16.8856 6.75511 16.499V12.9434C6.75511 12.5568 6.44171 12.2434 6.05511 12.2434ZM2.49951 10.9434C1.39494 10.9434 0.499512 11.8388 0.499512 12.9434V16.499C0.499512 17.6035 1.39494 18.499 2.49951 18.499H6.05511C7.15968 18.499 8.05511 17.6035 8.05511 16.499V12.9434C8.05511 11.8388 7.15968 10.9434 6.05511 10.9434H2.49951Z"
        fill={fillBg}
      />
    </svg>
  )
}
