import React from 'react'

import { YellowDarken20 } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = YellowDarken20 } = props
  const defaultStyle = { width: 10, height: 11 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1.05469L9 9.05469M9 1.05469L1 9.05469" stroke={fill} strokeWidth="2" strokeLinecap="round" />
    </svg>
  )
}
