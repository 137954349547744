import React, { useCallback } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import axios from 'axios'

import { IModalProps } from '../AppModals'
import selector from './selector'
import useStyles from './styles'
import { INDIVIDUAL_INFO_FORM_FIELDS, IndividualInfoFormData } from './types'

import { Button, BUTTON_SIZE, BUTTON_VARIANT } from '@/components/ButtonSMB'
import { Asterisk } from '@/components/Icons'
import { Input } from '@/components/InputSMB'
import { Modal } from '@/components/ModalSMB'
import { FORM_ERROR_TYPE, INN_REGEX, MAX_INN_LENGTH, REGULAR_EXPRESSION_CYRILLIC_NAME } from '@/const'
import { PurpleBase } from '@/const/colors'
import { Routes } from '@/const/routes'
import { closeAppModal } from '@/store/app'
import { MODALS } from '@/store/app/types'
import { MyThunkDispatch } from '@/store/store'
import { updateProfile } from '@/store/user'
import { isValidINN } from '@/utils/form'

export type FormFields = 'inn' | 'name'

const IndividualInfoModal = (props: IModalProps) => {
  const { onClose } = props

  const { profile } = useSelector(selector)
  const dispatch: MyThunkDispatch = useDispatch()

  const { formatMessage } = useIntl()

  const isBalancePage = useLocation().pathname === Routes.BALANCE

  const {
    control,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setError,
  } = useForm<IndividualInfoFormData>({
    shouldFocusError: false,
    defaultValues: {
      [INDIVIDUAL_INFO_FORM_FIELDS.INN]: profile.inn || '',
      [INDIVIDUAL_INFO_FORM_FIELDS.NAME]: profile.name || '',
      [INDIVIDUAL_INFO_FORM_FIELDS.REQUEST_ERROR]: '',
    },
  })

  const classes = useStyles()

  const cancelHandler = useCallback(() => onClose(), [onClose])

  const onSubmit = async (data: IndividualInfoFormData) => {
    const { inn, name } = data

    try {
      await dispatch(updateProfile({ name, inn }))
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        const resp = error.response
        if (resp?.data) {
          Object.keys(resp.data).forEach((field) => {
            const key = field as INDIVIDUAL_INFO_FORM_FIELDS
            if (resp.data[field]) {
              setError(key, {
                type: FORM_ERROR_TYPE.MANUAL,
                message: resp.data[field].join(' '),
              })
            }
          })
        }
      }
    }

    dispatch(closeAppModal({ name: MODALS.INDIVIDUAL_INFO }))
  }

  const handleNameChange = (value: string, onChange: (value: string) => void) => {
    if (!REGULAR_EXPRESSION_CYRILLIC_NAME.test(value)) {
      setError(INDIVIDUAL_INFO_FORM_FIELDS.NAME, {
        type: FORM_ERROR_TYPE.MANUAL,
        message: formatMessage({ id: 'budget.adsMarkingDataErrorModal.name.errorsMessage' }),
      })
    } else {
      clearErrors(INDIVIDUAL_INFO_FORM_FIELDS.NAME)
    }
    onChange(value)
  }

  const handleInnChange = (value: string, onChange: (value: string) => void) => {
    if (value.length > MAX_INN_LENGTH) {
      return
    }
    if (!new RegExp(INN_REGEX).test(value)) {
      setError(INDIVIDUAL_INFO_FORM_FIELDS.INN, {
        type: FORM_ERROR_TYPE.MANUAL,
        message: formatMessage({ id: 'app.modal.innLength.errorsMessage' }),
      })
    } else if (!isValidINN(value)) {
      setError(INDIVIDUAL_INFO_FORM_FIELDS.INN, {
        type: FORM_ERROR_TYPE.MANUAL,
        message: formatMessage({ id: 'app.modal.innNotValid.errorsMessage' }),
      })
    } else {
      clearErrors(INDIVIDUAL_INFO_FORM_FIELDS.INN)
    }
    onChange(value)
  }

  return (
    <Modal
      isOpen={true}
      closeCallback={cancelHandler.bind(this, 'cross')}
      headerContent={formatMessage({ id: 'app.modal.individualInfo.title' })}
      bodyContent={
        <div>
          {isBalancePage && (
            <div className={classes.balance_info}>
              <FormattedMessage id="app.modal.individualInfo.balance_info" />
            </div>
          )}
          <form className={classes.form}>
            <section>
              <label className={classes.label}>
                <FormattedMessage id="app.modal.individualInfo.fio_label" />{' '}
                <Asterisk style={{ width: 16, height: 16 }} fill={PurpleBase} />
              </label>
              <Controller
                name={INDIVIDUAL_INFO_FORM_FIELDS.NAME}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: formatMessage({ id: 'form.errorMessage.required' }),
                  },
                  pattern: {
                    value: REGULAR_EXPRESSION_CYRILLIC_NAME,
                    message: formatMessage({ id: 'app.modal.individualInfo.name.errorsMessage' }),
                  },
                }}
                render={({ field: { ref, onChange }, field, fieldState }) => (
                  <Input
                    {...field}
                    fullWidth
                    inputRef={ref}
                    id={INDIVIDUAL_INFO_FORM_FIELDS.NAME}
                    placeholder={formatMessage({ id: 'app.modal.individualInfo.fio_placeholder' })}
                    error={Boolean(errors[INDIVIDUAL_INFO_FORM_FIELDS.NAME])}
                    errorMsg={fieldState.error?.message}
                    onChange={(e) => handleNameChange(e.target.value, onChange)}
                  />
                )}
              />
            </section>
            <section>
              <label className={classes.label}>
                <FormattedMessage id="app.modal.individualInfo.inn_label" />{' '}
                <Asterisk style={{ width: 16, height: 16 }} fill={PurpleBase} />
              </label>
              <Controller
                name={INDIVIDUAL_INFO_FORM_FIELDS.INN}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: formatMessage({ id: 'form.errorMessage.required' }),
                  },
                  pattern: {
                    value: new RegExp(INN_REGEX),
                    message: formatMessage({ id: 'app.modal.innLength.errorsMessage' }),
                  },
                  validate: {
                    isValidINN: (value) =>
                      isValidINN(value) || formatMessage({ id: 'app.modal.innNotValid.errorsMessage' }),
                  },
                }}
                render={({ field: { ref, onChange }, field, fieldState }) => (
                  <Input
                    {...field}
                    fullWidth
                    inputRef={ref}
                    id={INDIVIDUAL_INFO_FORM_FIELDS.INN}
                    placeholder={formatMessage({ id: 'app.modal.individualInfo.inn_placeholder' })}
                    errorMsg={fieldState.error?.message}
                    error={Boolean(errors[INDIVIDUAL_INFO_FORM_FIELDS.INN])}
                    onChange={(e) => handleInnChange(e.target.value, onChange)}
                  />
                )}
              />
            </section>
          </form>
        </div>
      }
      footerContent={
        <div className={classes.footer}>
          <Button
            label={formatMessage({ id: 'app.modal.individualInfo.btn_cancel' })}
            size={BUTTON_SIZE.size40}
            variant={BUTTON_VARIANT.secondaryPurple}
            onClick={cancelHandler}
          />
          <Button
            label={formatMessage({ id: 'common.save' })}
            size={BUTTON_SIZE.size40}
            variant={BUTTON_VARIANT.primary}
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      }
      maxWidth="460px"
    />
  )
}

export default IndividualInfoModal
