import React from 'react'

import { OrangeBase, White } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fillBg = OrangeBase, fill = White } = props
  const defaultStyle = { width: 20, height: 20 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <g>
        <rect width="20" height="20" rx="10" fill={fillBg} />
        <circle id="Ellipse 147" cx="10" cy="15" r="1" fill={fill} />
        <rect id="Rectangle 1386" x="9" y="4" width="2" height="8" rx="1" fill={fill} />
      </g>
    </svg>
  )
}
