import { createUseStyles } from 'react-jss'

export default createUseStyles(
  {
    productTourButton: {
      display: 'flex',
      marginTop: 16,
    },
    imageWrapper: {
      marginBottom: 25,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  { name: 'AdsMarkingUtils' }
)
