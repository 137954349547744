import React from 'react'

import { Black } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = Black } = props
  const defaultStyle = { width: 15, height: 15 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.875 8.125V3.125H6.875" stroke={fill} strokeLinecap="square" />
      <path d="M11.875 3.125L11.25 3.75" stroke={fill} strokeLinecap="round" />
      <path d="M11.25 3.75L3.125 11.875" stroke={fill} strokeLinecap="square" />
    </svg>
  )
}
