import React from 'react'

import { GreyBase } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = GreyBase, fillBg = GreyBase } = props
  const defaultStyle = { width: 18, height: 18 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.00034 4.50002C9.22578 4.50002 9.44198 4.58958 9.60138 4.74898C9.76079 4.90839 9.85034 5.12459 9.85034 5.35002V9.4729C9.85034 9.69834 9.76079 9.91454 9.60138 10.0739C9.44198 10.2333 9.22578 10.3229 9.00034 10.3229C8.77491 10.3229 8.55871 10.2333 8.3993 10.0739C8.23989 9.91454 8.15034 9.69834 8.15034 9.4729V5.35002C8.15034 5.12459 8.23989 4.90839 8.3993 4.74898C8.55871 4.58958 8.77491 4.50002 9.00034 4.50002Z"
        fill={fill}
      />
      <path
        d="M8.99995 13.5C9.26517 13.5 9.51952 13.3947 9.70706 13.2071C9.89459 13.0196 9.99995 12.7652 9.99995 12.5C9.99995 12.2348 9.89459 11.9805 9.70706 11.7929C9.51952 11.6054 9.26517 11.5 8.99995 11.5C8.73473 11.5 8.48038 11.6054 8.29284 11.7929C8.10531 11.9805 7.99995 12.2348 7.99995 12.5C7.99995 12.7652 8.10531 13.0196 8.29284 13.2071C8.48038 13.3947 8.73473 13.5 8.99995 13.5Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99995 2.40002C10.7504 2.40002 12.4291 3.09537 13.6669 4.33311C14.9046 5.57085 15.6 7.2496 15.6 9.00003C15.6 10.7505 14.9046 12.4292 13.6669 13.6669C12.4291 14.9047 10.7504 15.6 8.99995 15.6C7.24952 15.6 5.57079 14.9047 4.33305 13.6669C3.09531 12.4292 2.39995 10.7505 2.39995 9.00003C2.39995 7.2496 3.09531 5.57085 4.33305 4.33311C5.57079 3.09537 7.24952 2.40002 8.99995 2.40002ZM14.6568 3.34316C13.1565 1.84287 11.1217 1.00002 8.99995 1.00002C6.87822 1.00002 4.84339 1.84287 3.3431 3.34316C1.84281 4.84346 0.99995 6.87829 0.99995 9.00003C0.99995 11.1218 1.84281 13.1566 3.3431 14.6569C4.84339 16.1572 6.87822 17 8.99995 17C11.1217 17 13.1565 16.1572 14.6568 14.6569C16.1571 13.1566 17 11.1218 17 9.00003C17 6.87829 16.1571 4.84346 14.6568 3.34316Z"
        fill={fillBg}
      />
    </svg>
  )
}
