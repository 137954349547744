import { ExtraPlatformTypes, IPlatform } from './types'

import { NetworkNames } from '@/const'

export const PlatformValues: { [key in ExtraPlatformTypes]: ExtraPlatformTypes } = {
  all: 'all',
  // facebook: 'facebook',
  mytarget: 'mytarget',
  vk: 'vk',
  yandex: 'yandex',
  avito: 'avito',
  vkads: 'vkads',
}

export const PLATFORMS: IPlatform[] = [
  {
    field: 'total_current_cashback',
    name: 'Все площадки',
    value: PlatformValues.all,
  },
  // {
  //   field: 'facebook_current_cashback',
  //   name: NetworkNames.facebook,
  //   value: PlatformValues.facebook
  // },
  {
    field: 'vk_current_cashback',
    name: NetworkNames.vk,
    value: PlatformValues.vk,
  },
  {
    field: 'vkads_current_cashback',
    name: NetworkNames.vkads,
    value: PlatformValues.vkads,
  },
  {
    field: 'mytarget_current_cashback',
    name: NetworkNames.mytarget,
    value: PlatformValues.mytarget,
  },
  {
    //TODO Ivan Marchenko fix it
    field: 'total_current_cashback',
    name: 'Я.Директ',
    value: PlatformValues.yandex,
  },
  {
    field: 'avito_current_cashback',
    name: NetworkNames.avito,
    value: PlatformValues.avito,
  },
]
