import React from 'react'

import { GreyBase, PurpleLighten6 } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style } = props
  const defaultStyle = { width: 16, height: 16 }

  return (
    <svg style={{ ...defaultStyle, ...style }} fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0122 12.3223L14.3616 14.6655"
        stroke={PurpleLighten6}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="7.84418"
        cy="7.84394"
        r="5.99237"
        stroke={GreyBase}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
