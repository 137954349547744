import { createUseStyles } from 'react-jss'

import { TextGreyBase, TextSlateGreyDarken12 } from '@/const/colors'
import { WidthBPs } from '@/const/commonStyles'
import { FontCaption, FontH4 } from '@/const/fontStyles'

export default createUseStyles(
  {
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    title: {
      ...FontH4,
      color: TextGreyBase,
      margin: '0 0 24px',
      width: '100%',
    },
    form: {
      margin: '24px 0 40px',
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridGap: 20,
      width: '100%',
    },
    label: {
      ...FontCaption,
      color: TextSlateGreyDarken12,
      display: 'flex',
      alignItems: 'center',
      marginBottom: 4,
    },
    footer: {
      display: 'grid',
      gridTemplateRows: '1fr',
      gridGap: 8,
      width: '100%',

      [`@media (min-width: ${WidthBPs.SMALL})`]: {
        gridTemplateColumns: '86px 138px',
        justifyContent: 'flex-end',
      },
    },
  },
  { name: 'AdsMarkingDataErrorIndividualModal' }
)
