import React from 'react'

import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = '#F54C5A' } = props
  const defaultStyle = { width: 26, height: 26 }

  return (
    <svg
      style={{ ...defaultStyle, ...style }}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 26 26"
    >
      <path
        fill={fill}
        d="M23.9,13.5c0,0.1,0,0.2,0,0.3c0,3.4,0,6.8,0,10.2c0,0.7-0.3,0.9-1,0.9c-6.6,0-13.3,0-20,0c-0.7,0-1-0.3-1-1
	c0-3.4,0-6.8,0-10.2c0-0.1,0-0.2,0-0.3c-0.1,0-0.1,0-0.2,0c-0.5,0-0.8-0.3-0.9-0.9c0-1,0-2,0-3c0-0.5,0-0.9,0-1.4
	c0-0.6,0.3-0.9,0.9-0.9c2.1,0,4.2,0,6.4,0c0.1,0,0.2,0,0.3,0c0,0,0,0,0,0C8.2,7.2,7.8,7,7.4,6.8C6.6,6.3,5.9,5.6,5.7,4.6
	C5.4,3.4,5.9,2.1,7,1.5C8,0.8,9.4,0.9,10.4,1.6c0.7,0.5,1.2,1.3,1.6,2.1c0.4,0.7,0.7,1.5,1,2.2c0-0.1,0.1-0.2,0.1-0.2
	c0.5-1.2,1-2.4,1.8-3.4c0.8-1,1.9-1.5,3.2-1.2c1.2,0.3,1.9,1.1,2.2,2.3c0.2,1.2-0.2,2.2-1.1,2.9c-0.5,0.3-1,0.6-1.5,0.9
	c-0.1,0-0.2,0.1-0.3,0.2c0.1,0,0.2,0,0.3,0c2.1,0,4.2,0,6.4,0c0.7,0,1,0.3,1,1c0,1.4,0,2.7,0,4.1C25,13.3,24.8,13.5,23.9,13.5z
	 M3.2,23.9c3.1,0,6.2,0,9.3,0c0-3.5,0-6.9,0-10.4c-3.1,0-6.2,0-9.3,0C3.2,17,3.2,20.5,3.2,23.9z M13.6,13.5c0,3.5,0,6.9,0,10.4
	c3.1,0,6.2,0,9.3,0c0-3.5,0-6.9,0-10.4C19.7,13.5,16.6,13.5,13.6,13.5z M2.1,12.5c3.5,0,6.9,0,10.4,0c0-1.3,0-2.6,0-4
	c-3.5,0-6.9,0-10.4,0C2.1,9.8,2.1,11.1,2.1,12.5z M23.9,8.5c-3.5,0-6.9,0-10.4,0c0,1.3,0,2.7,0,4c3.5,0,6.9,0,10.4,0
	C23.9,11.1,23.9,9.8,23.9,8.5z M12.3,7.3c0-0.1,0-0.1,0-0.1C11.9,6.1,11.5,5,11,4c-0.4-0.6-0.8-1.3-1.4-1.7C8.9,1.9,8.2,2,7.5,2.4
	C6.9,2.8,6.6,3.6,6.7,4.3C6.9,5,7.3,5.5,7.9,5.8c1,0.6,2.1,0.9,3.2,1.2C11.5,7.1,11.9,7.2,12.3,7.3z M13.7,7.3
	c1.4-0.3,2.8-0.6,4-1.3c0.3-0.2,0.7-0.4,0.9-0.6c0.9-0.8,0.9-2.2-0.1-2.9c-0.8-0.6-1.8-0.5-2.6,0.2c-0.5,0.5-0.8,1.1-1.1,1.7
	C14.4,5.3,14,6.2,13.7,7.3z"
      />
    </svg>
  )
}
