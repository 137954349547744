import { API_URL } from '../../const'
import { customAxios } from '../../utils'
import { OnboardingActionTypes } from './types'

export function getAccountsOnboarding() {
  return (dispatch: any): Promise<any> => {
    return customAxios(`${API_URL}/onboarding/setup_hints/`, 'GET', null, true).then((resp: any) => {
      dispatch({ type: OnboardingActionTypes.SET_ACCOUNTS_ONBOARDING, data: resp.data })

      return resp
    })
  }
}
