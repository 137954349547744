import React from 'react'
import { RouteComponentProps } from 'react-router'
import { History } from 'history'

import {
  AdAccountExtraTypes,
  AdAccountsPageType,
  AdAccountTypes,
  IAccountOnboardingBody,
  IAdAccount,
  IAdAccountsState,
} from '../../store/adaccounts/types'
import { ClientPageType, IClientRequest, IClientsResult } from '../../store/clients/types'
import { IOnboardingObject, IOnboardingState } from '../../store/onboarding/types'
import { IStoriesState } from '../../store/stories/types'
import { IUserProfile, OnboardingTypes, PLUGINS } from '../../store/user/types'
import { GroupingTabValues } from '../../types'
import { IAccountsFilled, IProfileAdmarkInfo } from '../AdsMarking/AccountsRoot/types'

interface IPropsFromState {
  adAccounts: IAdAccountsState
  isAllowCreateAccounts: any
  isFacebookAccount: boolean
  isGoogleAccount: boolean
  onboarding: IOnboardingState
  history?: History
  isDesktop: boolean
  autopayment: boolean
  onboardingStatus: OnboardingTypes
  isVKAccount: boolean
  isVKAdsMarketAccount: boolean
  profile: IUserProfile
  stories: IStoriesState
  accountCounts: number
  profileStatus: boolean
  archivedAccounts: number
  isMyTargetAccount: boolean
  isTikTokAccount: boolean
  isYandexAccount: boolean
  isTelegramAccount: boolean
  isYandexPromoPageAccount: boolean
  clients: IClientsResult[]
  clientRequests: IClientRequest[]
  isRejectedProfile: boolean
  isViewAdsMarking: boolean
  adsMarkingaccountsFilled: IAccountsFilled
  profileAdmarkInfo: IProfileAdmarkInfo
  isShowSuccessAlert: boolean
}

interface IPropsFromDispatch {
  createFBAdAccount: () => Promise<any>
  createGoogleAdAccount: () => Promise<any>
  getAdAccounts: (
    network: AdAccountExtraTypes,
    page: AdAccountsPageType,
    isAgency: boolean,
    term?: string
  ) => Promise<any>
  getAccountsOnboarding: () => Promise<any>
  getAllowCreateAccounts: () => Promise<any>
  getProfile: () => Promise<any>
  getProfileAdmarkInfo: () => Promise<any>
  getAccountsFilled: () => Promise<any>
  getStories: () => Promise<any>
  setUserPlugin: (plugin: PLUGINS) => Promise<any>
  updateAccountOnboarding: (accountId: number, network: AdAccountTypes, body: IAccountOnboardingBody) => Promise<any>
  getClients: (page: ClientPageType, term?: string) => Promise<any>
  getClientRequests: () => Promise<any>
}

export interface IAccountsState {
  activeFilter: AdAccountExtraTypes
  isAddAccountPopupOpen: boolean
  isPageLoading: boolean
  isShowedAutopaymentModal: boolean
  isEmptyPopupShown: boolean
  isOnboardingProductTourOpen: boolean
  startAtStep: number
  isOnboardingShown: boolean
  selectedAccount: null | IAdAccount
  searchTerm: string
  isSearching: boolean
  isMoreLoading: boolean
  isAccountsLoading: boolean
  groupBy: GroupingTypes
}

export type LoadingTypes = 'isPageLoading' | 'isMoreLoading' | 'isSearching' | 'isAccountsLoading'

export type IAccountsProps = RouteComponentProps<{ section: GroupingTypes; tab: AdAccountExtraTypes }> &
  IPropsFromState &
  IPropsFromDispatch

export interface IHistoryModalProps {
  onButtonClick: () => void
  email?: string
}

export enum OnboardingStatuses {
  START = 'start',
  MIDDLE = 'middle',
  FINISH = 'finish',
}

export interface IOnboardingPageState {
  stepsCount: number
  activeStep: number
}

interface IOnboardingPropsFromDispatch {
  updateProfile: (data: { onboarding: OnboardingTypes }) => Promise<any>
}

interface IOnboardingPropsFromState {
  isDesktop: boolean
  onboardingStatus: OnboardingTypes
  profile: IUserProfile
}

export type IOnboardingProps = IOnboardingPropsFromState & IOnboardingPropsFromDispatch

export interface IConditionsProps {
  network: AdAccountExtraTypes
  isAgency: boolean
  onButtonClick: (network: AdAccountTypes) => (event: React.MouseEvent) => void
  isDisabled: boolean
  isAgencyClient: boolean
}

export interface IFacebookTableProps {
  isAgency: boolean
}

export interface IEmptyAccountProps {
  account: IAdAccount
}

export interface IOnboardingHintProps {
  onboarding: IOnboardingObject[]
  onClose: () => void
  onUpdate: (setupHint: string, hintStatus: 'done' | 'not done', title: string) => void
  account: IAdAccount
  onClickLink: (title: string) => (event: React.MouseEvent) => void
}

export type GroupingTypes = GroupingTabValues
