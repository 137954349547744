import { ISelectItem } from '../../../../types'

import { CampaignStatusTypes } from '@/store/audiences/types'

export const CAMPAIGN_FILTERS: ISelectItem[] = [
  {
    value: '',
    label: 'Все',
  },
  {
    value: CampaignStatusTypes.WITH_ISSUES,
    label: 'Отклонено',
  },
  {
    value: CampaignStatusTypes.PAUSED,
    label: 'Черновик',
  },
  {
    value: CampaignStatusTypes.IN_PROCESS,
    label: 'В обработке',
  },
  {
    value: CampaignStatusTypes.ACTIVE,
    label: 'Действующие',
  },
  {
    value: CampaignStatusTypes.LEARNING_PHASE,
    label: 'На обучении',
  },
]
