import React from 'react'

import { PurpleLighten6 } from '../../const/colors'
import { IIcons } from './types'

const CheckBoxCircledIcon = (props: IIcons) => {
  const { style, fill = PurpleLighten6, fillBg = 'none' } = props
  const defaultStyle = { width: 16, height: 16 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 16 16" fill={fillBg} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.6673 7.05715V7.67048C14.6665 9.1081 14.201 10.5069 13.3402 11.6584C12.4794 12.8098 11.2695 13.6521 9.89089 14.0597C8.51227 14.4674 7.03882 14.4184 5.6903 13.9202C4.34177 13.422 3.19042 12.5012 2.40796 11.2952C1.6255 10.0892 1.25385 8.66253 1.34844 7.22803C1.44303 5.79353 1.99879 4.42803 2.93284 3.3352C3.86689 2.24236 5.12917 1.48074 6.53144 1.16391C7.93371 0.847089 9.40083 0.99204 10.714 1.57715"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6667 2.33594L8 9.00927L6 7.00927"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CheckBoxCircledIcon
