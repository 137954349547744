import React from 'react'

import LottieRenderer from '../LottieRenderer'
import loader from './loader-dots.json'

export interface IDotsLoaderProps {
  width?: number
  height?: number
  className?: string
}

const DotsLoader = (props: IDotsLoaderProps) => {
  const { height, width } = props

  return <LottieRenderer source={loader} height={height} width={width} />
}

export default DotsLoader
