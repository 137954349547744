import React from 'react'

import { SlateGreyLighten34, SlateGreyLighten46 } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = SlateGreyLighten34, fillBg = SlateGreyLighten46 } = props
  const defaultStyle = { width: 49, height: 62 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 49 62" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.1382 2.99935C22.3398 0.133152 26.6602 0.133157 28.8618 2.99935L46.8603 26.4313C48.9298 29.1256 48.9297 32.8744 46.8603 35.5687L28.8618 59.0007C26.6602 61.8669 22.3398 61.8668 20.1382 59.0006L2.13973 35.5687C0.0702457 32.8744 0.0702503 29.1256 2.13974 26.4313L20.1382 2.99935Z"
        fill={fillBg}
        stroke={fill}
      />
    </svg>
  )
}
