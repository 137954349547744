export enum PromoActionTypes {
  SET_PROMO = 'SET_PROMO',
  SET_LOADING = 'SET_LOADING',
}

export interface IPromoState {
  readonly campaign_slug: string
  readonly promo_url: string | null
  readonly loading: boolean
}
