import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CSSTransition } from 'react-transition-group'

import Balance from '../Balance'
import Cashback from '../Cashback'
import SubMenu from '../SubMenu'
import selector from './selector'
import { Header, StyledBalanceButton, SubMenuWrapper, Toggle, Wrapper } from './styled'

import '../../styles.css'

import { Mixpanel } from '@/analytics'
import ArrowLeftIcon from '@/components/Icons/ArrowLeftIcon'
import { GreyLighten26 } from '@/const/colors'
import { TRANSITION_TIMEOUT } from '@/const/commonStyles'
import { Routes } from '@/const/routes'
import { toggleMenu } from '@/store/app'

const Menu = () => {
  const { isMenuOpened, isDesktop, isProfileClient } = useSelector(selector)

  const dispatch = useDispatch()

  const onMenuToggle = () => {
    dispatch(toggleMenu(!isMenuOpened))
  }

  const onBalanceClick = () => {
    Mixpanel.track('GoToBalance')
  }

  const isCashbackShown = !isProfileClient

  return (
    <CSSTransition
      timeout={TRANSITION_TIMEOUT}
      in={isMenuOpened}
      classNames={isDesktop ? 'menu' : 'mobile-menu'}
      unmountOnExit={false}
    >
      <Wrapper isOpened={isMenuOpened} isDesktop={isDesktop}>
        <CSSTransition
          timeout={TRANSITION_TIMEOUT}
          in={isMenuOpened}
          classNames={isDesktop ? 'opacity' : ''}
          unmountOnExit={isDesktop}
        >
          <Header isExtraSpace={!isCashbackShown}>
            <Balance />
            {isCashbackShown && <Cashback />}
            <StyledBalanceButton
              theme="pink"
              bType="link"
              to={Routes.BALANCE}
              size="small"
              data-testid="go-to-balance"
              onClick={onBalanceClick}
            >
              Пополнить баланс
            </StyledBalanceButton>
          </Header>
        </CSSTransition>
        <CSSTransition
          timeout={TRANSITION_TIMEOUT}
          in={isMenuOpened}
          classNames={isDesktop ? (isCashbackShown ? 'submenu' : 'submenu-no-cashback') : ''}
          unmountOnExit={false}
        >
          <SubMenuWrapper isMenuOpened={isMenuOpened}>
            <SubMenu />
          </SubMenuWrapper>
        </CSSTransition>
        <Toggle isMenuOpened={isMenuOpened} onClick={onMenuToggle}>
          <ArrowLeftIcon style={{ width: 19, height: 19 }} fill={GreyLighten26} />
        </Toggle>
      </Wrapper>
    </CSSTransition>
  )
}

export default Menu
