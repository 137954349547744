import React from 'react'

import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fillBg = '#C6CCD4' } = props
  const defaultStyle = { width: 20, height: 20 }

  return (
    <svg
      style={{ ...defaultStyle, ...style }}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
        fill={fillBg}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.52587 9.89396C7.44107 8.62386 9.38499 7.78653 10.3576 7.38197C13.1347 6.22688 13.7118 6.02623 14.0879 6.0196C14.1706 6.01814 14.3556 6.03865 14.4754 6.13586C14.5766 6.21795 14.6044 6.32885 14.6177 6.40668C14.6311 6.48451 14.6477 6.66181 14.6345 6.80034C14.484 8.38157 13.8328 12.2188 13.5015 13.9898C13.3613 14.7392 13.0853 14.9905 12.8181 15.015C12.2374 15.0685 11.7964 14.6313 11.234 14.2626C10.3538 13.6856 9.85662 13.3265 9.0023 12.7635C8.01499 12.1129 8.65502 11.7553 9.21769 11.1709C9.36494 11.0179 11.9236 8.69065 11.9731 8.47953C11.9793 8.45313 11.9851 8.3547 11.9266 8.30273C11.8681 8.25076 11.7818 8.26853 11.7195 8.28267C11.6313 8.3027 10.2253 9.23203 7.50149 11.0706C7.10239 11.3447 6.74091 11.4782 6.41703 11.4712C6.05998 11.4635 5.37315 11.2693 4.86257 11.1034C4.23632 10.8998 3.73859 10.7922 3.78193 10.4464C3.8045 10.2664 4.05248 10.0822 4.52587 9.89396Z"
        fill="white"
      />
    </svg>
  )
}
