import styled from 'styled-components'

import { redClrDark, redClrDarkest } from '../../const/colors'

export const ErrorBlock = styled.div`
  z-index: 1000;
  position: fixed;
  bottom: 15px;
  right: 15px;

  width: 250px;
  padding: 5px 10px;
  border-radius: 3px;

  background-color: ${redClrDarkest};
  border: 1px solid ${redClrDark};

  & p {
    font-size: 0.8rem;
    margin: 0.3rem 0;
  }
`
