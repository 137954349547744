import React from 'react'

import { SlateGreyLighten34, SlateGreyLighten46 } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = SlateGreyLighten34, fillBg = SlateGreyLighten46 } = props
  const defaultStyle = { width: 122, height: 70 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 122 70" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M93.9355 12.4143C96.8039 10.2155 100.977 11.3338 102.362 14.6721L111.025 35.558C112.327 38.6961 111.357 42.3172 108.66 44.384L90.7146 58.14C87.8462 60.3387 83.673 59.2205 82.2883 55.8822L73.6249 34.9963C72.3233 31.8582 73.2935 28.2371 75.9898 26.1703L93.9355 12.4143Z"
        fill={fillBg}
        stroke={fill}
      />
      <path
        d="M27.5645 12.4143C24.6961 10.2155 20.5229 11.3338 19.1382 14.6721L10.4748 35.558C9.17317 38.6961 10.1434 42.3172 12.8397 44.384L30.7854 58.14C33.6538 60.3387 37.827 59.2205 39.2117 55.8822L47.8751 34.9963C49.1767 31.8582 48.2065 28.2371 45.5102 26.1703L27.5645 12.4143Z"
        fill={fillBg}
        stroke={fill}
      />
      <path
        d="M56.6382 6.99935C58.8398 4.13315 63.1602 4.13316 65.3618 6.99935L83.3603 30.4313C85.4298 33.1256 85.4297 36.8744 83.3603 39.5687L65.3618 63.0007C63.1602 65.8669 58.8398 65.8668 56.6382 63.0006L38.6397 39.5687C36.5702 36.8744 36.5703 33.1256 38.6397 30.4313L56.6382 6.99935Z"
        fill={fillBg}
        stroke={fill}
      />
    </svg>
  )
}
