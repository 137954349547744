import React from 'react'

import { GreyBase } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = GreyBase } = props
  const defaultStyle = { width: 18, height: 18 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 9L6 9" stroke={fill} strokeWidth="1.6" strokeLinecap="round" />
    </svg>
  )
}
