import { AxiosError, AxiosResponse } from 'axios'
import { AnyAction, Dispatch } from 'redux'

import { API_URL } from '../../const'
import { customAxios } from '../../utils'
import { ThunkResult } from '../store'
import { ISubscription, ISubscriptionBodyRequest, NotificationsActionTypes } from './types'

export function getSubscriptions(): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/notifications/user_subscribers/`, 'GET', null, true)
      .then((response: AxiosResponse<ISubscription[]>) => {
        dispatch({ type: NotificationsActionTypes.SET_SUBSCRIPTIONS, data: response.data })
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function updateSubscription(id: number, body: ISubscriptionBodyRequest): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/notifications/user_subscribers/${id}/`, 'PATCH', body, true)
      .then((response: AxiosResponse<ISubscription>) => {
        if (response.status === 200) {
          dispatch({
            data: {
              ...response.data,
              id,
            },
            type: NotificationsActionTypes.UPDATE_SUBSCRIPTION,
          })
        }

        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function getTelegramCode(): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/telegram/`, 'GET', null, true)
      .then((response: AxiosResponse<{ code?: string }>) => {
        dispatch({ type: NotificationsActionTypes.SET_TELEGRAM_CODE, data: response.data })
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function setTelegramCode(): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/telegram/`, 'POST', {}, true)
      .then((response: AxiosResponse<{ code?: string }>) => {
        dispatch({ type: NotificationsActionTypes.SET_TELEGRAM_CODE, data: response.data })
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}
