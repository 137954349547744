import { createUseStyles } from 'react-jss'

import { WidthBPs } from '@/const/commonStyles'

export default createUseStyles(
  {
    wrapper: {
      width: '100%',
      height: 50,
      display: 'flex',
      flexShrink: 0,
      alignItems: 'center',
      justifyContent: 'flex-start',
      background: 'linear-gradient(267.05deg, #3b3a5a -74.13%, #814ef0 106.5%)',

      [`@media (min-width: ${WidthBPs.MEDIUM})`]: {
        background: 'none',
        height: 46,
      },
    },

    logoWrapper: {
      display: 'grid',
      alignItems: 'center',
      width: 83,
      height: 30,
      margin: 0,
      fontSize: 0,

      '& figure': {
        width: 83,
        height: 'auto',
        margin: 0,
      },

      '& svg': {
        width: 83,
      },

      [`@media (min-width: ${WidthBPs.MEDIUM})`]: {
        width: 127,
        height: 46,

        '& svg': {
          width: 127,
        },

        '& figure': {
          width: 127,
        },
      },
    },
    desktop: {
      display: 'none',

      [`@media (min-width: ${WidthBPs.MEDIUM})`]: {
        display: 'block',
      },
    },
    mobile: {
      display: 'block',
      padding: '0 20px',

      [`@media (min-width: ${WidthBPs.MEDIUM})`]: {
        display: 'none',
      },
    },
  },
  { name: 'LogoHeader' }
)
