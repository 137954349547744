import React from 'react'

import { OrangeLighten31, OrangeSecondary } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fillBg = OrangeLighten31, fill = OrangeSecondary } = props
  const defaultStyle = { width: 66, height: 67 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 66 67" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="33" cy="33.0964" rx="33" ry="33.0964" fill={fillBg} />
      <path
        d="M35.5918 18.5625C36.1569 18.5625 36.6097 19.0304 36.5913 19.5951L36.012 37.3412C35.9944 37.8805 35.5522 38.3086 35.0126 38.3086H32.2599C31.7207 38.3086 31.2786 37.8811 31.2605 37.3422L30.6637 19.5961C30.6447 19.031 31.0976 18.5625 31.6631 18.5625H35.5918ZM30.4727 44.4219C30.4727 43.5885 30.7591 42.8984 31.332 42.3516C31.918 41.7917 32.6862 41.5117 33.6367 41.5117C34.6003 41.5117 35.3685 41.7917 35.9414 42.3516C36.5143 42.8984 36.8008 43.5885 36.8008 44.4219C36.8008 45.2292 36.5143 45.9128 35.9414 46.4727C35.3685 47.0326 34.6003 47.3125 33.6367 47.3125C32.6862 47.3125 31.918 47.0326 31.332 46.4727C30.7591 45.9128 30.4727 45.2292 30.4727 44.4219Z"
        fill={fill}
      />
    </svg>
  )
}
