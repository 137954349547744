import React from 'react'

import { IIcons } from './types'

export default (props: IIcons) => {
  const { style } = props
  const defaultStyle = { width: 40, height: 40 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="20" fill="#F0F3F7" />
      <path
        d="M16.8061 13.5152C16.9994 13.0046 17.2652 12.5345 17.6599 12.1536C19.2386 10.6057 21.8483 10.9947 22.9195 12.9398C23.725 14.4147 24.5788 15.8573 25.4084 17.3161C26.7938 19.7393 28.1953 22.1624 29.5646 24.5937C30.7164 26.6279 29.4679 29.1969 27.1804 29.5454C25.7789 29.7561 24.466 29.1078 23.7411 27.8435C22.5248 25.7121 21.3005 23.5807 20.0843 21.4574C20.0601 21.4087 20.0279 21.3682 19.9957 21.3277C19.8668 21.2223 19.8104 21.0684 19.7299 20.9306C19.1902 19.9743 18.6345 19.0261 18.0948 18.0779C17.7485 17.4619 17.386 16.8541 17.0397 16.2382C16.7255 15.6871 16.5806 15.0874 16.5967 14.4553C16.6208 14.1311 16.6611 13.8069 16.8061 13.5152Z"
        fill="#3C8BD9"
      />
      <path
        d="M16.8058 13.5152C16.7334 13.8069 16.6689 14.0987 16.6528 14.4066C16.6286 15.0874 16.7978 15.7195 17.1361 16.3111C18.0221 17.8429 18.9081 19.3827 19.786 20.9225C19.8666 21.0602 19.931 21.198 20.0116 21.3277C19.5283 22.1705 19.045 23.0053 18.5537 23.8481C17.8771 25.0232 17.2005 26.2065 16.5159 27.3816C16.4837 27.3816 16.4756 27.3654 16.4676 27.3411C16.4595 27.2762 16.4837 27.2195 16.4998 27.1547C16.83 25.939 16.5562 24.8612 15.7265 23.9373C15.2191 23.3781 14.5747 23.062 13.8337 22.9567C12.8672 22.8189 12.0134 23.0701 11.2482 23.6779C11.1113 23.7833 11.0226 23.9373 10.8616 24.0183C10.8293 24.0183 10.8132 24.0021 10.8052 23.9778C11.1918 23.3051 11.5704 22.6325 11.957 21.9598C13.5518 19.172 15.1466 16.3841 16.7495 13.6043C16.7656 13.5719 16.7897 13.5476 16.8058 13.5152Z"
        fill="#FABC04"
      />
      <path
        d="M10.8375 24.0021C10.9905 23.8643 11.1355 23.7185 11.2966 23.5888C13.2539 22.0328 16.1938 23.1593 16.6207 25.623C16.7254 26.2146 16.669 26.7819 16.4918 27.3492C16.4838 27.3978 16.4757 27.4383 16.4596 27.4869C16.3871 27.6166 16.3227 27.7544 16.2421 27.8841C15.5253 29.0754 14.4701 29.667 13.0847 29.5778C11.498 29.4644 10.2495 28.265 10.032 26.6765C9.92733 25.9066 10.0804 25.1853 10.475 24.5208C10.5556 24.3749 10.6522 24.2452 10.7408 24.0994C10.7811 24.0669 10.765 24.0021 10.8375 24.0021Z"
        fill="#34A852"
      />
    </svg>
  )
}
