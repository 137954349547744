import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'

import selector from './selector'

import { Routes } from '@/const/routes'

const Index = () => {
  const { isAgentClient } = useSelector(selector)

  return <Redirect to={isAgentClient ? Routes.ANALYTICS : Routes.ACCOUNTS} />
}

export default Index
