import { createUseStyles } from 'react-jss'

import { TextGreyBase, White } from '../../const/colors'
import { FontBody1, FontH4 } from '../../const/fontStyles'
import { IModalSMBProps } from './types'

export default createUseStyles(
  {
    root: {
      display: 'none',
      position: 'fixed',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      background: (props: IModalSMBProps) => (props.mask ? 'rgba(28, 31, 35, 0.85)' : 'transparent'),
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1000,
      overflowY: (props: IModalSMBProps) => (props.isScrollable ? 'auto' : 'inherit'),
    },
    isOpen: {
      display: 'flex',
    },
    modal: {
      position: 'relative',
      backgroundColor: White,
      boxShadow: '0 16px 24px rgba(0, 0, 0, 0.06), 0 2px 6px rgba(0, 0, 0, 0.04), 0 0 1px rgba(0, 0, 0, 0.04)',
      borderRadius: 8,
      maxWidth: (props: IModalSMBProps) => (props.maxWidth ? props.maxWidth : 460),
      maxHeight: (props: IModalSMBProps) => (props.isScrollable ? '100vh' : 'inherit'),
      width: 'calc(100% - 40px)',
    },
    closeButton: {
      position: 'absolute',
      top: 18,
      right: 18,
    },
    header: {
      padding: '24px 56px 0 24px',
      ...FontH4,
      color: TextGreyBase,
    },
    body: {
      borderRadius: '8px 8px 0 0',
      padding: 24,
      backgroundColor: (props: IModalSMBProps) => (props.isScrollable ? White : 'inherit'),
      ...FontBody1,
    },
    footer: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '0 24px 24px 24px',
      backgroundColor: (props: IModalSMBProps) => (props.isScrollable ? White : 'inherit'),
      borderBottomLeftRadius: (props: IModalSMBProps) => (props.isScrollable ? 8 : 'inherit'),
      borderBottomRightRadius: (props: IModalSMBProps) => (props.isScrollable ? 8 : 'inherit'),
    },
  },
  { name: 'ModalSMB' }
)
