import React from 'react'

import { IIcons } from './types'

export default (props: IIcons) => {
  const { style } = props
  const defaultStyle = { width: 104, height: 104 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_dddd)">
        <rect x="32" y="8" width="40" height="40" rx="4" fill="url(#paint0_radial)" />
        <rect x="32.5" y="8.5" width="39" height="39" rx="3.5" stroke="white" />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41 28C41 34.0751 45.9249 39 52 39C58.0751 39 63 34.0751 63 28C63 21.9249 58.0751 17 52 17C45.9249 17 41 21.9249 41 28ZM61 28C61 32.9706 56.9706 37 52 37C47.0294 37 43 32.9706 43 28C43 23.0294 47.0294 19 52 19C56.9706 19 61 23.0294 61 28ZM50.5858 28L48.2929 30.2929C47.9024 30.6834 47.9024 31.3166 48.2929 31.7071C48.6834 32.0976 49.3166 32.0976 49.7071 31.7071L52 29.4142L54.2929 31.7071C54.6834 32.0976 55.3166 32.0976 55.7071 31.7071C56.0976 31.3166 56.0976 30.6834 55.7071 30.2929L53.4142 28L55.7071 25.7071C56.0976 25.3166 56.0976 24.6834 55.7071 24.2929C55.3166 23.9024 54.6834 23.9024 54.2929 24.2929L52 26.5858L49.7071 24.2929C49.3166 23.9024 48.6834 23.9024 48.2929 24.2929C47.9024 24.6834 47.9024 25.3166 48.2929 25.7071L50.5858 28Z"
        fill="white"
      />
      <mask id="mask0" maskUnits="userSpaceOnUse" x="41" y="17" width="22" height="22">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41 28C41 34.0751 45.9249 39 52 39C58.0751 39 63 34.0751 63 28C63 21.9249 58.0751 17 52 17C45.9249 17 41 21.9249 41 28ZM61 28C61 32.9706 56.9706 37 52 37C47.0294 37 43 32.9706 43 28C43 23.0294 47.0294 19 52 19C56.9706 19 61 23.0294 61 28ZM50.5858 28L48.2929 30.2929C47.9024 30.6834 47.9024 31.3166 48.2929 31.7071C48.6834 32.0976 49.3166 32.0976 49.7071 31.7071L52 29.4142L54.2929 31.7071C54.6834 32.0976 55.3166 32.0976 55.7071 31.7071C56.0976 31.3166 56.0976 30.6834 55.7071 30.2929L53.4142 28L55.7071 25.7071C56.0976 25.3166 56.0976 24.6834 55.7071 24.2929C55.3166 23.9024 54.6834 23.9024 54.2929 24.2929L52 26.5858L49.7071 24.2929C49.3166 23.9024 48.6834 23.9024 48.2929 24.2929C47.9024 24.6834 47.9024 25.3166 48.2929 25.7071L50.5858 28Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0)">
        <rect x="40" y="16" width="24" height="24" fill="white" />
      </g>
      <defs>
        <filter
          id="filter0_dddd"
          x="0"
          y="0"
          width="104"
          height="104"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
          <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="16" />
          <feGaussianBlur stdDeviation="12" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
          <feBlend mode="normal" in2="effect2_dropShadow" result="effect3_dropShadow" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="24" />
          <feGaussianBlur stdDeviation="16" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
          <feBlend mode="normal" in2="effect3_dropShadow" result="effect4_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect4_dropShadow" result="shape" />
        </filter>
        <radialGradient
          id="paint0_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(57.0505 -88.8889) rotate(97.0572) scale(146.891 241.258)"
        >
          <stop stopColor="#EF4636" />
          <stop offset="1" stopColor="#FF7B6F" />
        </radialGradient>
      </defs>
    </svg>
  )
}
