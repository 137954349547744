export enum AccountingActionTypes {
  SET_ACCOUNTING = 'SET_ACCOUNTING',
  SET_ACTS = 'SET_ACTS',
}

export interface IAccounting {
  readonly id: number
  readonly created_date: string
  readonly amount: string
  readonly tax: string
  readonly act_number: number
  readonly invoice_number: number
  readonly barcode: string
}

export interface IAccountingState {
  readonly acts: IActs
  readonly documents: IDocuments
}

export interface IDocuments {
  page_count: number
  count: number
  current: number
  next: string | null
  previous: string | null
  results: IAccounting[]
}

export interface IActs {
  page_count: number
  count: number
  current: number
  next: string | null
  previous: string | null
  results: IAct[]
}

export interface IAct {
  readonly id: number
  readonly quarter: string
}
