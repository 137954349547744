export enum DocumentsActionTypes {
  SET_DOCUMENTS = 'SET_DOCUMENTS',
  SET_DOCUMENT = 'SET_DOCUMENT',
  REMOVE_DOCUMENT = 'REMOVE_DOCUMENT',
}

export interface IDocument {
  id: number
  document_type: string
  file: string
}

export interface IUploadDocument {
  file: File
  document_type: string
}
