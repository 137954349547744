import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import selector from './selector'
import {
  PopupButton,
  PopupButtonWrapper,
  PopupDivider,
  PopupEmail,
  PopupFooter,
  PopupFooterButton,
  PopupHeader,
  PopupIcon,
  PopupMenuWrapper,
} from './styled'

import { Mixpanel } from '@/analytics'
import { AttentionIcon, UserIcon } from '@/components/Icons'
import { SuggestCompanyTypes } from '@/const'
import { RedBase } from '@/const/colors'
import { Routes } from '@/const/routes'

const ARCHIVED_DOCUMENT = 'http://files.aitarget.com/smb/archived_documents_101.pdf'

const PopupMenu = (props: { email: string; onClick: any; hasProfile: boolean }) => {
  const { profile } = useSelector(selector)
  const { inn, name, profile_type } = profile

  const showIndividualInfoWorning = profile_type === SuggestCompanyTypes.SE && (!inn || !name)

  const onOfertaClick = () => {
    Mixpanel.track('GoToOferta')
  }

  const onInviteFriendClick = () => {
    Mixpanel.track('ReferralVisit', { place: 'client' })

    props.onClick()
  }

  return (
    <PopupMenuWrapper>
      <PopupHeader>
        <PopupIcon>
          <UserIcon style={{ width: 40, height: 40 }} />
        </PopupIcon>
        <PopupEmail>{props.email}</PopupEmail>
        <PopupDivider />
      </PopupHeader>
      {props.hasProfile && (
        <>
          <PopupButtonWrapper>
            <PopupButton bType="link" theme="default" to={Routes.SETTINGS} onClick={props.onClick}>
              <FormattedMessage id="menu.settings" />
            </PopupButton>
            {showIndividualInfoWorning && <AttentionIcon fillBg={RedBase} style={{ width: 16, height: 16 }} />}
          </PopupButtonWrapper>
          <PopupButtonWrapper>
            <PopupButton bType="link" theme="default" to={Routes.NOTIFICATIONS} onClick={props.onClick}>
              <FormattedMessage id="menu.notifications" />
            </PopupButton>
          </PopupButtonWrapper>
          <PopupButtonWrapper>
            <PopupButton bType="link" theme="default" to={Routes.REFERRAL} onClick={onInviteFriendClick}>
              <FormattedMessage id="menu.inviteFriend" />
            </PopupButton>
          </PopupButtonWrapper>
        </>
      )}
      <PopupButtonWrapper>
        <PopupButton
          bType="external_link"
          theme="default"
          href={ARCHIVED_DOCUMENT}
          target="_blank"
          onClick={onOfertaClick}
        >
          <FormattedMessage id="menu.oferta" />
        </PopupButton>
      </PopupButtonWrapper>
      {props.hasProfile && (
        <PopupButtonWrapper>
          <PopupButton bType="link" theme="default" to={Routes.CHANGE_PASSWORD} onClick={props.onClick}>
            <FormattedMessage id="menu.changePassword" />
          </PopupButton>
        </PopupButtonWrapper>
      )}
      <PopupFooter>
        <PopupFooterButton bType="link" theme="default" to={Routes.LOGOUT} onClick={props.onClick}>
          <FormattedMessage id="menu.exit" />
        </PopupFooterButton>
      </PopupFooter>
    </PopupMenuWrapper>
  )
}

export default PopupMenu
