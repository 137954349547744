import styled from 'styled-components'

import Button from '../../components/Button'
import Logo from '../../components/Logo'
import { headerClrDark, headerClrLight, White } from '../../const/colors'
import { WidthBPs } from '../../const/commonStyles'

export const StyledHeader = styled.header`
  background: linear-gradient(218.47deg, ${headerClrLight} -74.13%, ${headerClrDark} 106.5%);
  width: 100%;
  height: 50px;
`

export const LogoWrapper = styled.div`
  padding-left: 24px;
`

export const StyledLogo = styled(Logo)`
  display: grid;
  align-items: center;

  width: 108px;
  height: 26px;

  margin: 0;
  font-size: 0;

  & figure {
    width: 108px;
    height: auto;

    margin: 0;
  }

  & svg {
    width: 83px;
  }
`

export const Container = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 173px 1fr;
  grid-template-areas: 'logo profile';

  width: 100%;
  height: 100%;

  margin: 0 auto;
  padding: 0 23px 0 0;

  @media (min-width: ${WidthBPs.MEDIUM}) {
    grid-template-columns: 239px minmax(auto, 50%) 1fr;
  }
`

export const ClientNameBlock = styled.div`
  padding: 0 17px;
  font-size: 13px;
  line-height: 16px;
  color: ${White};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`
export const ClientLabel = styled.span`
  margin-right: 10px;
  opacity: 0.6;
`
export const ClientName = styled.span`
  font-weight: 500;
`

export const ButtonBlock = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
`

export const NavBlock = styled.div`
  display: flex;
  align-items: center;
`

export const Wrapper = styled.div``

export const HeaderButton = styled(Button)`
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  color: ${White};
  font-size: 12px;
  flex-shrink: 0;
  width: 93px;
  margin-right: 14px;

  &:hover:not(:disabled) {
    background: rgba(255, 255, 255, 0.14);
    color: ${White};
  }

  &:focus:not(:disabled) {
    background: rgba(255, 255, 255, 0.18);
    color: ${White};
  }

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    transform: none;
  }

  &:last-child {
    margin-right: 0;
  }
`

export const HeaderIconButton = styled(Button)`
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  flex-shrink: 0;
  width: 42px;
  margin-right: 14px;
  stroke: none;

  &:hover:not(:disabled) {
    background: rgba(255, 255, 255, 0.14);
    stroke: none;
  }

  &:focus:not(:disabled) {
    background: rgba(255, 255, 255, 0.18);
    stroke: none;
  }

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    transform: none;
  }

  &:last-child {
    margin-right: 0;
  }
`

export const IconButton = styled(Button)`
  position: relative;
  height: 28px;
  width: 28px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  flex-shrink: 0;
  margin-left: 24px;
  fill: none;
  stroke: none;

  & svg {
    flex-shrink: 0;
    background-color: transparent;
  }

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    transform: none;
    fill: none;
    stroke: none;
  }

  @media (min-width: ${WidthBPs.MEDIUM}) {
    margin-left: 20px;
  }
`

export const AttentionIconWrapper = styled.div`
  position: absolute;
  right: -6px;
  top: -2px;
`

export const BurgerButtonWrapper = styled.div`
  width: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`

export const BurgerButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;

  & svg {
    flex-shrink: 0;
  }
`
