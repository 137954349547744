import React from 'react'

import { IIcons } from './types'

export default (props: IIcons) => {
  const { style } = props
  const defaultStyle = { width: 40, height: 40 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
        fill="#FC3F1D"
      />
      <path
        d="M26.5 30.0608H22.8332V11.8486H21.197C18.1973 11.8486 16.6215 13.3633 16.6215 15.6061C16.6215 18.151 17.7123 19.3332 19.9544 20.8486L21.8029 22.0912L16.4697 30.0608H12.5L17.2878 22.9396C14.5305 20.9695 12.985 19.0605 12.985 15.8183C12.985 11.7573 15.8034 9 21.1668 9H26.5V30.0608Z"
        fill="white"
      />
    </svg>
  )
}
