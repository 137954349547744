import React from 'react'

import { GreyLighten47 } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = GreyLighten47 } = props
  const defaultStyle = { width: 22, height: 22 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.0004 1C5.47918 1 1 5.47335 1 10.9947C1 16.5214 5.47918 21 11.0004 21C16.5246 21 21 16.5214 21 10.9947C21 5.47335 16.5246 1 11.0004 1ZM11.713 16.559C11.4808 16.7657 11.2116 16.8696 10.9067 16.8696C10.5912 16.8696 10.316 16.7676 10.0811 16.5631C9.84582 16.3591 9.72795 16.0734 9.72795 15.7063C9.72795 15.3807 9.84202 15.1066 10.0694 14.8845C10.2968 14.6624 10.5758 14.5514 10.9067 14.5514C11.2323 14.5514 11.5064 14.6624 11.7289 14.8845C11.951 15.1066 12.0624 15.3807 12.0624 15.7063C12.062 16.0681 11.9457 16.3524 11.713 16.559ZM14.6092 9.37939C14.4307 9.71029 14.2188 9.99561 13.973 10.2362C13.7279 10.4767 13.2871 10.881 12.6508 11.4495C12.4754 11.6099 12.3342 11.7506 12.2284 11.8718C12.1226 11.9935 12.0436 12.1045 11.992 12.2054C11.94 12.3062 11.9002 12.4072 11.8719 12.508C11.8436 12.6085 11.8011 12.7859 11.7435 13.0392C11.6457 13.5768 11.3381 13.8455 10.8212 13.8455C10.5524 13.8455 10.3265 13.7578 10.1424 13.582C9.95905 13.4062 9.86758 13.1453 9.86758 12.799C9.86758 12.365 9.93497 11.9889 10.0694 11.6708C10.203 11.3527 10.3819 11.0738 10.604 10.8332C10.8265 10.5927 11.1261 10.3073 11.5037 9.97639C11.8346 9.6869 12.0737 9.46858 12.2209 9.32136C12.3684 9.17381 12.4923 9.00968 12.5928 8.82897C12.6941 8.64789 12.7438 8.45176 12.7438 8.23984C12.7438 7.82613 12.5906 7.47755 12.2827 7.19333C11.9751 6.90911 11.5783 6.7668 11.0923 6.7668C10.5234 6.7668 10.1048 6.91022 9.836 7.19708C9.56719 7.48394 9.34021 7.9063 9.15384 8.46459C8.97767 9.04886 8.64412 9.34095 8.15356 9.34095C7.86406 9.34095 7.61975 9.23892 7.4206 9.03487C7.22182 8.83082 7.12243 8.60987 7.12243 8.37197C7.12243 7.88106 7.28018 7.38339 7.5953 6.87933C7.91079 6.37528 8.37082 5.95778 8.97577 5.62725C9.58035 5.29635 10.2863 5.13069 11.0923 5.13069C11.8418 5.13069 12.5033 5.26921 13.077 5.54592C13.6507 5.82222 14.0942 6.19832 14.407 6.67413C14.7195 7.14956 14.8761 7.66645 14.8761 8.22474C14.8768 8.66336 14.7876 9.04849 14.6092 9.37939Z"
        fill={fill}
      />
    </svg>
  )
}
