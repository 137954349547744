import React from 'react'

import { GreyBase } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = GreyBase } = props
  const defaultStyle = { width: 18, height: 18 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.8401 10.712L9.38591 6.16283C9.28299 6.05771 9.14575 6 8.99624 6C8.84974 6 8.71242 6.05771 8.60966 6.16283L4.15959 10.7077C4.05667 10.8129 4 10.9532 4 11.1028C4 11.2524 4.05659 11.3928 4.15959 11.4979L4.48732 11.8326C4.59024 11.9377 4.72764 11.9958 4.87414 11.9958C5.02065 11.9958 5.15796 11.9377 5.26097 11.8326L8.99778 8.01603L12.7387 11.837C12.8417 11.942 12.979 12 13.1256 12C13.2721 12 13.4093 11.942 13.5123 11.837L13.8401 11.5021C14.0533 11.2843 14.0533 10.9298 13.8401 10.712Z"
        fill={fill}
      />
    </svg>
  )
}
