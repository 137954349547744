export enum MyCashbackActionTypes {
  SET_SPENDS = 'SET_SPENDS',
  SET_PROGRESS = 'SET_PROGRESS',
  SET_HISTORY = 'SET_HISTORY',
  SET_PLATFORMS = 'SET_PLATFORMS',
}

export interface IMyCashbackSpends {
  total_current_spends: string
  percent: string
  total_current_cashback: string
  facebook_current_cashback: string
  vk_current_cashback: string
  mytarget_current_cashback: string
  total_all_time_cashback: string
  avito_current_cashback: string
  vkads_current_cashback: string
}

export interface IMyCashbackProgress {
  conditions: IMyCashbackCondition[]
  current_spend: string
  current_cashback: string
  current_percent: string
  current_condition_id: number | null
  to_new_condition: string | null
}

export interface IMyCashbackCondition {
  id: number
  start_range: number
  end_range: number | null
  value: number
}

export interface IMyCashbackHistory {
  count: number
  current: number
  next: number | null
  page_count: number
  previous: number | null
  results: IMyCashbackTransaction[] | IMyCashbackYandexTransaction[]
}

export interface IMyCashbackTransaction {
  advertising_period: string
  payout_period: string
  total_cashback: string
  total_percent: number | null
  total_spends: number | null
  is_yandex_award?: string
  platform?: 'FACEBOOK' | 'YANDEX' | 'MYTARGET' | 'VK' | 'AVITO' | 'YANDEX_PROMO_PAGE' | 'VK_ADSMARKET'
}

export interface IMyCashbackYandexAward {
  cashback: string
  name: string
  percent: number
  spend: string
}

export interface IMyCashbackYandexPeriod {
  ad_network_type: string
  cashback: string
  percent: number
  sum_of_spends: string
}

export interface IMyCashbackYandexTransaction {
  award_cashback?: string
  award_season?: string
  awards?: IMyCashbackYandexAward[]
  is_yandex_award?: string
  period_advertising_date: string
  period_payout_date: string
  period_total_cashback: number | null
  period_total_percent: number
  period_total_spend: number | null
  periods?: IMyCashbackYandexPeriod[]
}

export interface IMyCashbackPlatforms {
  cashback_condition_fb: boolean
  cashback_condition_mytarget: boolean
  cashback_condition_vk: boolean
  cashback_condition_yandex: boolean
  cashback_condition_yandex_promo: boolean
  cashback_condition_avito: boolean
  cashback_condition_vkads: boolean
  cashback_condition_vk_adsmarket: boolean
}

export type MyCashbackPlatformTypes =
  | 'cashback_condition_fb'
  | 'cashback_condition_mytarget'
  | 'cashback_condition_vk'
  | 'cashback_condition_yandex'
  | 'cashback_condition_avito'
  | 'cashback_condition_vkads'
  | 'cashback_condition_yandex_promo'

export interface IMyCashbackState {
  spends: IMyCashbackSpends
  progress: IMyCashbackProgress
  history: IMyCashbackHistory
  platforms: IMyCashbackPlatforms
}

export type MyCashbackProgressType = 'facebook' | 'vk' | 'mytarget' | 'avito'
