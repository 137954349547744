import React from 'react'

import { SlateGreyLighten22 } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = SlateGreyLighten22 } = props
  const defaultStyle = { width: 15, height: 15 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.0625 4.875H10.9375" stroke={fill} strokeWidth="0.9375" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M3.75 13V11.75C3.75 10.7554 4.14509 9.80161 4.84835 9.09835C5.55161 8.39509 6.50544 8 7.5 8C8.49456 8 9.44839 8.39509 10.1517 9.09835C10.8549 9.80161 11.25 10.7554 11.25 11.75V13C11.25 13.1658 11.1842 13.3247 11.0669 13.4419C10.9497 13.5592 10.7908 13.625 10.625 13.625H4.375C4.20924 13.625 4.05027 13.5592 3.93306 13.4419C3.81585 13.3247 3.75 13.1658 3.75 13Z"
        stroke={fill}
        strokeWidth="0.9375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.75 3V4.25C3.75 5.24456 4.14509 6.19839 4.84835 6.90165C5.55161 7.60491 6.50544 8 7.5 8C8.49456 8 9.44839 7.60491 10.1517 6.90165C10.8549 6.19839 11.25 5.24456 11.25 4.25V3C11.25 2.83424 11.1842 2.67527 11.0669 2.55806C10.9497 2.44085 10.7908 2.375 10.625 2.375H4.375C4.20924 2.375 4.05027 2.44085 3.93306 2.55806C3.81585 2.67527 3.75 2.83424 3.75 3Z"
        stroke={fill}
        strokeWidth="0.9375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
