import React from 'react'

import { GreyBase } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = GreyBase } = props
  const defaultStyle = { width: 18, height: 18 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.712 13.8401L6.16283 9.38591C6.05771 9.28299 6 9.14575 6 8.99624C6 8.84974 6.05771 8.71242 6.16283 8.60966L10.7077 4.15959C10.8129 4.05667 10.9532 4 11.1028 4C11.2524 4 11.3928 4.05659 11.4979 4.15959L11.8326 4.48732C11.9377 4.59024 11.9958 4.72764 11.9958 4.87414C11.9958 5.02065 11.9377 5.15796 11.8326 5.26097L8.01603 8.99778L11.837 12.7387C11.942 12.8417 12 12.979 12 13.1256C12 13.2721 11.942 13.4093 11.837 13.5123L11.5021 13.8401C11.2843 14.0533 10.9298 14.0533 10.712 13.8401Z"
        fill={fill}
      />
    </svg>
  )
}
