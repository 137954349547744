import React from 'react'

import { SlateGreyLighten22 } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = SlateGreyLighten22 } = props
  const defaultStyle = { width: 16, height: 16 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.3333 6.05469H7.33333C6.59695 6.05469 6 6.65164 6 7.38802V13.388C6 14.1244 6.59695 14.7214 7.33333 14.7214H13.3333C14.0697 14.7214 14.6667 14.1244 14.6667 13.388V7.38802C14.6667 6.65164 14.0697 6.05469 13.3333 6.05469Z"
        stroke={fill}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.33301 10.0553H2.66634C2.31272 10.0553 1.97358 9.91486 1.72353 9.66481C1.47348 9.41477 1.33301 9.07563 1.33301 8.722V2.72201C1.33301 2.36838 1.47348 2.02924 1.72353 1.7792C1.97358 1.52915 2.31272 1.38867 2.66634 1.38867H8.66634C9.01996 1.38867 9.3591 1.52915 9.60915 1.7792C9.8592 2.02924 9.99967 2.36838 9.99967 2.72201V3.38867"
        stroke={fill}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
