import React from 'react'

import { IIcons } from './types'

export default (props: IIcons) => {
  const { style } = props
  const defaultStyle = { width: 25, height: 25 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="24" height="24" rx="0.5" stroke="#C4C4C4" />
      <rect x="8.25781" y="16.0355" width="11" height="1" transform="rotate(-45 8.25781 16.0355)" fill="#2E2E2E" />
      <rect x="8.96484" y="8.25732" width="11" height="1" transform="rotate(45 8.96484 8.25732)" fill="#2E2E2E" />
    </svg>
  )
}
