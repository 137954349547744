import { createSelector } from 'reselect'

import { getBudget, getClients, getProfile } from '@/common/selectors'

export default createSelector([getProfile, getBudget, getClients], (profile, budget, clients) => ({
  name: profile.name,
  inn: profile.inn,
  personalPhone: profile.personal_phone,
  clientProfile: clients?.clientAccounts?.clientProfile,
}))
