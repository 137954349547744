import React, { CSSProperties, ReactNode } from 'react'

import { SlateGreyLighten13, White } from '../../const/colors'
import { InfoFullIcon } from '../Icons'
import useStyles from './styles'

interface IAlertProps {
  text: ReactNode
  styles?: CSSProperties
}

const Alert = (props: IAlertProps) => {
  const { text, styles } = props
  const classes = useStyles()

  return (
    <div className={classes.container} style={styles}>
      <InfoFullIcon style={{ width: 16, height: 16 }} fill={White} fillBg={SlateGreyLighten13} />
      <div className={classes.text}>{text}</div>
    </div>
  )
}

export default Alert
