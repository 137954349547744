import { createUseStyles } from 'react-jss'

import { RedDarken16, TextGreyBase, TextSlateGreyDarken12 } from '@/const/colors'
import { FontBody1, FontCaption } from '@/const/fontStyles'

export default createUseStyles(
  {
    form: {
      display: 'grid',
      gap: 20,
    },
    label: {
      ...FontCaption,
      color: TextSlateGreyDarken12,
      display: 'flex',
      alignItems: 'center',
      marginBottom: 4,
    },
    errorText: {
      ...FontCaption,
      color: RedDarken16,
      marginTop: 4,
    },
    footer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      gap: 10,
    },
    balance_info: {
      ...FontBody1,
      color: TextGreyBase,
      margin: '16px 0 40px',
    },
  },
  { name: 'IndividualInfoModal' }
)
