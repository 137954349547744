import React from 'react'

import { GreyBase } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = GreyBase, fillBg = GreyBase } = props
  const defaultStyle = { width: 18, height: 18 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.00039 13.5C9.22582 13.5 9.44203 13.4104 9.60143 13.251C9.76084 13.0916 9.85039 12.8754 9.85039 12.65V8.52712C9.85039 8.30168 9.76084 8.08548 9.60143 7.92608C9.44203 7.76667 9.22582 7.67712 9.00039 7.67712C8.77496 7.67712 8.55876 7.76667 8.39935 7.92608C8.23994 8.08548 8.15039 8.30168 8.15039 8.52712V12.65C8.15039 12.8754 8.23994 13.0916 8.39935 13.251C8.55876 13.4104 8.77496 13.5 9.00039 13.5Z"
        fill={fill}
      />
      <path
        d="M9 4.5C9.26522 4.5 9.51957 4.60536 9.70711 4.79289C9.89464 4.98043 10 5.23478 10 5.5C10 5.76522 9.89464 6.01957 9.70711 6.20711C9.51957 6.39464 9.26522 6.5 9 6.5C8.73478 6.5 8.48043 6.39464 8.29289 6.20711C8.10536 6.01957 8 5.76522 8 5.5C8 5.23478 8.10536 4.98043 8.29289 4.79289C8.48043 4.60536 8.73478 4.5 9 4.5Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 2.4C10.7504 2.4 12.4292 3.09535 13.6669 4.33309C14.9046 5.57083 15.6 7.24957 15.6 9C15.6 10.7504 14.9046 12.4292 13.6669 13.6669C12.4292 14.9046 10.7504 15.6 9 15.6C7.24957 15.6 5.57084 14.9046 4.3331 13.6669C3.09536 12.4292 2.4 10.7504 2.4 9C2.4 7.24957 3.09536 5.57083 4.3331 4.33309C5.57084 3.09535 7.24957 2.4 9 2.4ZM14.6569 3.34314C13.1566 1.84285 11.1217 1 9 1C6.87827 1 4.84344 1.84285 3.34315 3.34314C1.84286 4.84343 1 6.87827 1 9C1 11.1217 1.84286 13.1566 3.34315 14.6569C4.84344 16.1571 6.87827 17 9 17C11.1217 17 13.1566 16.1571 14.6569 14.6569C16.1571 13.1566 17 11.1217 17 9C17 6.87827 16.1571 4.84343 14.6569 3.34314Z"
        fill={fillBg}
      />
    </svg>
  )
}
