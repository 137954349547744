import React from 'react'

import { IIcons } from './types'

export default (props: IIcons) => {
  const { style } = props
  const defaultStyle = { width: 8, height: 9 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.36168 4.28566C2.59486 5.51306 3.80045 6.71321 4.99621 7.90318C4.75561 8.14242 4.52673 8.37027 4.30597 8.58984C2.88548 7.1753 1.44464 5.7404 -2.71832e-07 4.30187C0.0184544 4.28221 0.036909 4.26117 0.0565709 4.2415C1.44791 2.84834 2.83943 1.45519 4.23077 0.0620257C4.31356 -0.0209395 4.31338 -0.0205943 4.39686 0.0621983C4.59848 0.262453 4.80028 0.462535 5 0.660375C3.78596 1.87001 2.57985 3.07188 1.36168 4.28566Z" />
      <path d="M4.36168 4.28566C5.59486 5.51306 6.80045 6.71321 7.99621 7.90318C7.75561 8.14242 7.52673 8.37027 7.30597 8.58984C5.88548 7.1753 4.44464 5.7404 3 4.30187C3.01845 4.28221 3.03691 4.26117 3.05657 4.2415C4.44791 2.84834 5.83943 1.45519 7.23077 0.0620257C7.31356 -0.0209395 7.31338 -0.0205943 7.39686 0.0621983C7.59848 0.262453 7.80028 0.462535 8 0.660375C6.78596 1.87001 5.57985 3.07188 4.36168 4.28566Z" />
    </svg>
  )
}
