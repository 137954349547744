import { Reducer } from 'redux'

import { ImportVKActionTypes } from './actions'
import { IErrorProducts, IImportRule, IMPORT_VK_MODE, IVkCategories, IVkFeed, IVkGroups, IVkUser } from './types'

export interface IImportRulesError {
  vk_group?: string[]
}

export interface IImportVKState {
  step: number
  mode: IMPORT_VK_MODE | null
  user: null | IVkUser
  feeds: IVkFeed[]
  selectedFeedLink: string
  selectedIdFeedLink: string
  feedLinkResponse: null | IVkFeed
  vkCategories: [] | IVkCategories[]
  selectedIdVkCategory: string
  importRules: [] | IImportRule[]
  importRulesError: null | IImportRulesError
  vkGroups: [] | IVkGroups[]
  selectedIdVkGroup: string
  isLoading: boolean
  errorProducts: {
    [key: number]: IErrorProducts
  }
}

export const initialState: IImportVKState = {
  step: 1,
  mode: null,
  user: null,
  feeds: [],
  selectedFeedLink: '',
  selectedIdFeedLink: '',
  feedLinkResponse: null,
  importRules: [],
  importRulesError: null,
  vkCategories: [],
  selectedIdVkCategory: '',
  vkGroups: [],
  selectedIdVkGroup: '',
  isLoading: false,
  errorProducts: {},
}

const analyticsReducer: Reducer<IImportVKState> = (state = initialState, action: any) => {
  switch (action.type) {
    case ImportVKActionTypes.SET_STEP: {
      return {
        ...state,
        step: action.data,
      }
    }
    case ImportVKActionTypes.SET_MODE: {
      return {
        ...state,
        mode: action.data,
      }
    }
    case ImportVKActionTypes.SET_FEED_LINK: {
      return {
        ...state,
        selectedFeedLink: action.data,
      }
    }
    case ImportVKActionTypes.SEND_FEED_LINK: {
      return {
        ...state,
        feedLinkResponse: action.data,
        selectedIdFeedLink: action.data.id,
      }
    }
    case ImportVKActionTypes.RESET_FEED_LINK_RESPONSE: {
      return {
        ...state,
        feedLinkResponse: null,
      }
    }
    case ImportVKActionTypes.SET_IMPORT_RULES: {
      return {
        ...state,
        importRulesError: null,
        importRules: action.data,
      }
    }
    case ImportVKActionTypes.SET_IMPORT_RULES_ERROR: {
      return {
        ...state,
        importRulesError: action.data,
      }
    }
    case ImportVKActionTypes.SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.data,
      }
    }
    case ImportVKActionTypes.SET_USER: {
      return {
        ...state,
        user: action.data,
      }
    }
    case ImportVKActionTypes.SET_GROUPS: {
      return {
        ...state,
        vkGroups: action.data,
      }
    }
    case ImportVKActionTypes.SET_ID_GROUP: {
      return {
        ...state,
        selectedIdVkGroup: action.data,
      }
    }
    case ImportVKActionTypes.SET_CATEGORIES: {
      return {
        ...state,
        vkCategories: action.data,
      }
    }
    case ImportVKActionTypes.SET_ID_CATEGORY: {
      return {
        ...state,
        selectedIdVkCategory: action.data,
      }
    }
    case ImportVKActionTypes.SET_ERROR_PRODUCT: {
      return {
        ...state,
        errorProducts: {
          ...state.errorProducts,
          [action.data.id]: {
            ...action.data.item,
            results: state.errorProducts[action.data.id]?.results
              ? [...state.errorProducts[action.data.id].results, ...action.data.item.results]
              : action.data.item.results,
          },
        },
      }
    }
    default: {
      return state
    }
  }
}

export default analyticsReducer
