import React from 'react'

import Carousel from '../Carousel'
import useStyles from './styles'

const Aside = () => {
  const classes = useStyles()
  return (
    <div className={classes.wrapper}>
      <div className={classes.asideBlock}>
        <div className={classes.carousel}>
          <Carousel />
        </div>
      </div>
    </div>
  )
}

export default Aside
