import { AxiosError, AxiosPromise, AxiosResponse } from 'axios'
import { AnyAction, Dispatch } from 'redux'

import { IDropdownItem } from '../../components/Dropdown/types'
import { API_URL } from '../../const'
import { ISelectItem, ISelectItem as ISelectItemType } from '../../types'
import { customAxios } from '../../utils'
import { ThunkResult } from '../store'
import {
  AudiencesActionTypes,
  IErrorModalObject,
  IIndustry,
  IProposalTargetingsBody,
  IReportAdset,
  IReportBodyRequest,
  IReportCampaign,
  IReportIndustryInfo,
  IReportSlugName,
  IReportStatus,
  IReportTargeting,
  IReportTargetingObject,
  ITargetingBody,
  ModalModeTypes,
  TargetingFilterTypes,
} from './types'

export function getIndustries(): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/smart_target/industries/`, 'GET', null, true)
      .then((response: AxiosResponse<IIndustry[]>) => {
        dispatch({ type: AudiencesActionTypes.SET_INDUSTRIES, data: response.data })
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function createReport(body: IReportBodyRequest): AxiosPromise<any> {
  return customAxios(`${API_URL}/smart_target/reports/`, 'POST', body, true)
    .then((response: AxiosResponse<IReportBodyRequest>): any => {
      if (response.status === 201) {
        return response.data
      }

      return Promise.reject(response)
    })
    .catch((error: AxiosError) => Promise.reject(error))
}

export function getReportIndustryInfo(uuid: string): AxiosPromise<any> {
  //TODO Igor Trushlyakov нужно отдавать с бэка id индустрии что бы можно было заполнить quiz
  return customAxios(`${API_URL}/smart_target/reports/${uuid}/industry_info/`, 'GET', null, true)
    .then((response: AxiosResponse<IReportIndustryInfo>): any => {
      if (response.status === 200) {
        return response.data
      }

      return Promise.reject(response)
    })
    .catch((error: AxiosError) => Promise.reject(error))
}

export function getReportSlugNames(uuid: string): AxiosPromise<any> {
  return customAxios(`${API_URL}/smart_target/reports/${uuid}/targetings/`, 'GET', null, true)
    .then((response: AxiosResponse<IReportSlugName[]>): any => {
      if (response.status === 200) {
        return response.data.sort((a: IReportSlugName, b: IReportSlugName) => b.score - a.score)
      }

      return Promise.reject(response)
    })
    .catch((error: AxiosError) => Promise.reject(error))
}

export function getReportTargetings(
  uuid: string,
  slugName: string,
  page: number,
  filter?: TargetingFilterTypes
): AxiosPromise<any> {
  const url = filter
    ? `smart_target/reports/${uuid}/targetings/${slugName}/?filter=${filter}&page=${page}`
    : `smart_target/reports/${uuid}/targetings/${slugName}/?page=${page}`

  return customAxios(`${API_URL}/${url}`, 'GET', null, true)
    .then((response: AxiosResponse<IReportTargetingObject>): any => {
      if (response.status === 200) {
        return response.data
      }

      return Promise.reject(response)
    })
    .catch((error: AxiosError) => Promise.reject(error))
}

export function getReportStatus(uuid: string): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/smart_target/reports/${uuid}/`, 'GET', null, true)
      .then((response: AxiosResponse<IReportStatus>) => {
        dispatch({ type: AudiencesActionTypes.SET_REPORT_STATUS, data: response.data })
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function getCampaigns(accountId: string): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/smart_target/${accountId}/get_campaigns/`, 'GET', null, true)
      .then((response: AxiosResponse<IReportCampaign[]>) => {
        dispatch({
          type: AudiencesActionTypes.SET_CAMPAIGNS,
          data: response.data,
        })
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function saveTargeting(
  adsetId: string,
  body: ITargetingBody,
  modalCopyMode: ModalModeTypes,
  modalActivateMode: ModalModeTypes
): AxiosPromise<any> {
  let url = `${API_URL}/smart_target/adset/${adsetId}/targeting/`

  if (modalCopyMode) {
    url = `${url}?copy_mode=${modalCopyMode.toLowerCase()}`
  }

  if (modalActivateMode) {
    url = `${url}&is_active=${modalActivateMode.toLowerCase()}`
  }

  return customAxios(url, 'POST', body, true)
    .then((response: AxiosResponse<ITargetingBody>): any => {
      if (response.status === 200) {
        return response.data
      }

      return Promise.reject(response)
    })
    .catch((error: AxiosError) => Promise.reject(error))
}

export function setCampaignFilter(filterItem: ISelectItem): AnyAction {
  return { type: AudiencesActionTypes.SET_CAMPAIGN_FILTER, data: filterItem }
}

export function setCampaignAdset(item: IReportAdset): AnyAction {
  return { type: AudiencesActionTypes.SET_CAMPAIGN_ADSET, data: item }
}

export function setCurrentStep(step: number): AnyAction {
  return { type: AudiencesActionTypes.SET_CURRENT_STEP, data: step }
}

export function setSelectedAccount(selectedAccount: IDropdownItem): AnyAction {
  return { type: AudiencesActionTypes.SET_SELECTED_ACCOUNT, data: selectedAccount }
}

export function setSelectedIndustry(selectedIndustry: ISelectItemType): AnyAction {
  return { type: AudiencesActionTypes.SET_SELECTED_INDUSTRY, data: selectedIndustry }
}

export function getForecast(body: IProposalTargetingsBody): ThunkResult<Promise<any>> {
  return (dispatch: Dispatch<AnyAction>) => {
    return customAxios(`${API_URL}/smart_target/adset/forecast/`, 'POST', body, true)
      .then((response: AxiosResponse<any>) => {
        dispatch({
          type: AudiencesActionTypes.SET_FORECAST,
          data: response.data,
        })
        return response
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export function addToSelectedTargeting(selectedTargeting: IReportTargeting): AnyAction {
  return { type: AudiencesActionTypes.ADD_SELECTED_TARGETING, data: selectedTargeting }
}

export function addUsedSelectedTargeting(): AnyAction {
  return { type: AudiencesActionTypes.ADD_USED_SELECTED_TARGETING }
}

export function removeFromSelectedTargeting(selectedTargetingId: string): AnyAction {
  return { type: AudiencesActionTypes.REMOVE_SELECTED_TARGETING, data: selectedTargetingId }
}

export function clearSelectedTargeting(): AnyAction {
  return { type: AudiencesActionTypes.CLEAR_SELECTED_TARGETING }
}

export function setConfirmModal(value: boolean): AnyAction {
  return { type: AudiencesActionTypes.SET_CONFIRM_MODAL, data: value }
}

export function setErrorModal(value: boolean): AnyAction {
  return { type: AudiencesActionTypes.SET_ERROR_MODAL, data: value }
}

export function setSuccessModal(value: boolean): AnyAction {
  return { type: AudiencesActionTypes.SET_SUCCESS_MODAL, data: value }
}

export function setErrorModalObjects(value: IErrorModalObject[]): AnyAction {
  return { type: AudiencesActionTypes.SET_ERROR_MODAL_OBJECTS, data: value }
}

export function setModalCopyMode(value: ModalModeTypes): AnyAction {
  return { type: AudiencesActionTypes.SET_MODAL_COPY_MODE, data: value }
}

export function setModalActivateMode(value: ModalModeTypes): AnyAction {
  return { type: AudiencesActionTypes.SET_MODAL_ACTIVATE_MODE, data: value }
}

export function setModalLoader(value: boolean): AnyAction {
  return { type: AudiencesActionTypes.SET_MODAL_LOADER, data: value }
}

export function setModalNullModes(): AnyAction {
  return { type: AudiencesActionTypes.SET_MODAL_NULL_MODES }
}

export function resetAudiencesState(): AnyAction {
  return { type: AudiencesActionTypes.RESET_AUDIENCES_STATE }
}

export function setHeaderNotificationVisibility(value: boolean): AnyAction {
  window.localStorage.setItem('isHiddenHeaderNotification', 'true')

  return { type: AudiencesActionTypes.SET_HEADER_NOTIFICATION_VISIBILITY, data: value }
}

export function setDidUseNotificationVisibility(value: boolean): AnyAction {
  window.localStorage.setItem('isHiddenDidUseNotification', 'true')

  return { type: AudiencesActionTypes.SET_DID_USE_NOTIFICATION_VISIBILITY, data: value }
}
