import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import * as Sentry from '@sentry/browser'

import ErrorBoundary from './components/ErrorBoundary'
import Router from './containers/Router'
import store from './store/store'

if (process.env.NODE_ENV === 'production') {
  // @ts-ignore
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENV,
    normalizeDepth: 5,
  })
}

ReactDOM.render(
  <Provider store={store}>
    <ErrorBoundary>
      <Router />
    </ErrorBoundary>
  </Provider>,
  document.getElementById('root')
)
