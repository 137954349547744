import React from 'react'

import { PurpleLighten25, PurpleLighten46 } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = PurpleLighten25, fillBg = PurpleLighten46 } = props
  const defaultStyle = { width: 67, height: 66 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 67 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="33.4024" cy="33" rx="33.4024" ry="33" fill={fillBg} />
      <path d="M25 23H41" stroke={fill} strokeWidth="2" strokeLinecap="round" />
      <path
        d="M27.668 23V26.7003C27.668 27.5295 28.0112 28.3217 28.6162 28.8889L37.3865 37.111C37.9914 37.6781 38.3346 38.4704 38.3346 39.2996V42.9999"
        stroke={fill}
        strokeWidth="2"
      />
      <path
        d="M38.334 23V26.7003C38.334 27.5295 37.9908 28.3217 37.3858 28.8889L28.6155 37.111C28.0105 37.6781 27.6673 38.4704 27.6673 39.2996V42.9999"
        stroke={fill}
        strokeWidth="2"
      />
      <path d="M25 43H41" stroke={fill} strokeWidth="2" strokeLinecap="round" />
    </svg>
  )
}
