import { Reducer } from 'redux'

import { DocumentsActionTypes, IDocument } from './types'

export const initialState: IDocument[] = []

const reducer: Reducer<IDocument[]> = (state = initialState, action: any) => {
  switch (action.type) {
    case DocumentsActionTypes.SET_DOCUMENTS: {
      return [...action.data]
    }
    case DocumentsActionTypes.SET_DOCUMENT: {
      const id = state.findIndex((document: IDocument) => document.document_type === action.data.document_type)

      const newState = state.slice()
      newState.splice(id, 1, action.data)

      return id >= 0 ? newState : [...state, action.data]
    }
    case DocumentsActionTypes.REMOVE_DOCUMENT: {
      return state.slice().filter((doc: IDocument) => doc.id !== action.data)
    }
    default: {
      return state
    }
  }
}

export { reducer as documentsReducer }
