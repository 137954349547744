import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'

import { IAuthticationProps, IPrivateRouteProps } from './types'

import { AGENCY_CLIENT_ALLOWED_ROUTES, Routes } from '@/const/routes'
import IApplicationState from '@/types/state'

const checkAgencyClientRoute = (route: Routes) => {
  return (
    AGENCY_CLIENT_ALLOWED_ROUTES.includes(route) ||
    route.includes(Routes.REPORTS) ||
    route.includes(Routes.CAMPAIGN_EDITOR) ||
    route.includes(Routes.ADS_MARKING)
  )
}

export const PrivateRoute = (properties: IPrivateRouteProps) => {
  class Authentication extends Component<IAuthticationProps> {
    constructor(props: any) {
      super(props)

      this.handleRender = this.handleRender.bind(this)
    }

    public render() {
      return <Route {...properties} component={this.handleRender} />
    }

    private handleRender(props: any) {
      const ComposedComponent = properties.component

      const { authorized, has_profile, isAgentClient } = this.props

      if (!authorized) {
        return <Redirect to={{ pathname: Routes.SIGNIN, state: { from: props.location } }} />
      } else {
        if (isAgentClient) {
          const isRouteAllowed = checkAgencyClientRoute(props.location.pathname)

          if (!isRouteAllowed) {
            return <Redirect to={{ pathname: Routes.ACCESS_DENIED, state: { from: props.location } }} />
          }
        }

        if (has_profile || props.location.pathname === Routes.PROFILE) {
          return <ComposedComponent {...props} />
        }

        return <Redirect to={{ pathname: Routes.PROFILE, state: { from: props.location } }} />
      }
    }
  }

  const mapStateToProps = (state: IApplicationState) => ({
    authorized: state.user.isAuthorized,
    has_profile: state.user.has_profile,
    router: state.router,
    isAgentClient: state.user.profile.agent_client,
  })

  const AuthenticationContainer = connect(mapStateToProps)(Authentication)

  return <AuthenticationContainer />
}
