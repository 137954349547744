import { ChangeEvent, FC, ReactNode } from 'react'

export interface ICheckboxGroupSMBProps {
  name?: string
  value: any[]
  onChange: (val: any[]) => any
  onEventCall?: (checkboxValue?: any) => any
  children: (Checkbox: FC<ICheckboxSMBProps>) => JSX.Element
}

export enum CHECKBOX_SIZE {
  size18 = 'size18',
  size15 = 'size15',
}

export interface ICheckboxSMBProps {
  name?: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => unknown
  description?: string | ReactNode
  children?: ReactNode
  checked?: boolean
  value?: string | number
  disabled?: boolean
  fullWidth?: boolean
  className?: string
  position?: CHECKBOX_POSITION
  labelWidth?: number
  hasError?: boolean
  colorBase?: string
  [prop: string]: any
  withTooltip?: ReactNode
  size?: CHECKBOX_SIZE
}

export enum CHECKBOX_POSITION {
  left = 'left',
  right = 'right',
}
