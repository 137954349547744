import React from 'react'

import { IIcons } from './types'

export default (props: IIcons) => {
  const { style } = props
  const defaultStyle = { width: 24, height: 28 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.5996 17.8155C21.7969 19.8626 20.1108 22.4783 18.5374 24.0133C16.8099 25.6659 14.6601 26.9981 12.1478 27.9721C12.1002 27.9907 12.0499 28 11.9995 28C11.9492 28 11.8989 27.9907 11.8508 27.9721C9.33845 26.9976 7.18869 25.6659 5.46111 24.0133C3.88777 22.4783 2.20167 19.8626 1.39892 17.8155C0.126888 14.4353 0.0087628 11.0124 0.133997 8.73578C0.269622 6.27047 0.70056 4.62055 0.718607 4.55164C0.773294 4.34602 0.975638 4.21641 1.18454 4.25195C4.53142 4.82398 7.30353 4.32469 9.42486 2.76773C11.0059 1.60781 11.6178 0.257578 11.6238 0.243906C11.69 0.0957031 11.8371 0 11.999 0H12.0006C12.1631 0.000546875 12.3096 0.09625 12.3747 0.245C12.3807 0.257578 12.9927 1.60781 14.5737 2.76773C16.695 4.32469 19.4677 4.82398 22.814 4.25195C23.0235 4.21641 23.2253 4.34602 23.2799 4.55164C23.298 4.62055 23.7289 6.27047 23.8645 8.73578C23.9898 11.0124 23.8717 14.4353 22.5996 17.8155Z"
        fill="#E3D8FC"
      />
      <path
        d="M22.5996 17.8155C21.8027 19.8477 20.1024 22.4865 18.5374 24.0133C16.8098 25.6659 14.6601 26.9981 12.1477 27.9721C12.1001 27.9907 12.0498 28 11.9995 28C16.412 26.1874 20.1675 22.1615 21.6491 17.6712C23.3958 12.3777 22.7483 7.40906 22.4087 5.76625C22.3442 5.45344 22.0473 5.24781 21.7312 5.29156C14.2428 6.33008 12.0006 0 12.0006 0C12.163 0.000546875 12.3096 0.09625 12.3747 0.245C12.3807 0.257578 12.9926 1.60781 14.5737 2.76773C16.695 4.32469 19.4676 4.82398 22.814 4.25195C23.0234 4.21641 23.2252 4.34602 23.2799 4.55164C23.298 4.62055 23.7289 6.27047 23.8645 8.73578C23.9898 11.0124 23.8716 14.4353 22.5996 17.8155Z"
        fill="#7C4DE5"
      />
      <path
        d="M21.7202 13.6491C21.4889 15.0945 21.1088 16.4551 20.5904 17.6927C19.7544 19.7183 17.45 22.6243 15.6805 23.9112C14.6382 24.6747 13.4542 25.3429 12.1614 25.8986C12.1094 25.921 12.0542 25.9319 11.9995 25.9319C11.9443 25.9319 11.889 25.921 11.8371 25.8986C10.5448 25.3429 9.36083 24.6747 8.31848 23.9112C6.54898 22.6243 4.2446 19.7183 3.40864 17.6927C2.90442 16.4896 2.5309 15.17 2.29793 13.7705C1.83145 10.9683 2.01957 8.38324 2.2602 6.70816C2.29028 6.49598 2.47895 6.34285 2.69278 6.35707C3.03293 6.37949 3.37309 6.39043 3.7034 6.39043C7.05411 6.39043 9.30505 5.24145 10.6033 4.27785C10.9949 3.98691 11.3668 3.6659 11.7086 3.32301C11.7857 3.2459 11.8901 3.20215 11.9995 3.20215C12.1083 3.20215 12.2128 3.2459 12.2899 3.32301L12.2904 3.32355C12.6322 3.66645 13.0036 3.98746 13.3951 4.27785C14.6934 5.24199 16.9443 6.39043 20.295 6.39043C20.627 6.39043 20.9683 6.37895 21.3095 6.35707C21.5222 6.3423 21.7115 6.49543 21.7421 6.70762C21.9778 8.34004 22.1654 10.8693 21.7202 13.6491Z"
        fill="#603AC7"
      />
      <path
        d="M21.7202 13.6488C21.4889 15.0942 21.1088 16.4548 20.5904 17.6924C19.7544 19.718 17.45 22.624 15.6805 23.9109C14.6382 24.6743 13.4542 25.3426 12.1614 25.8982C21.6918 20.4388 21.0525 10.0394 20.796 7.81309C20.7681 7.57301 20.5696 7.38816 20.3279 7.38105C14.4391 7.20934 12.3233 3.3834 12.2904 3.32324C12.6322 3.66613 13.0036 3.98715 13.3951 4.27754C14.6934 5.24168 16.9443 6.39012 20.295 6.39012C20.627 6.39012 20.9683 6.37863 21.3095 6.35676C21.5222 6.34199 21.7115 6.49512 21.7421 6.7073C21.9778 8.33973 22.1654 10.869 21.7202 13.6488Z"
        fill="#452798"
      />
      <path
        d="M5.06885 15.2939C5.29537 15.2939 5.479 15.1103 5.479 14.8838C5.479 14.6573 5.29537 14.4736 5.06885 14.4736C4.84232 14.4736 4.65869 14.6573 4.65869 14.8838C4.65869 15.1103 4.84232 15.2939 5.06885 15.2939Z"
        fill="#B598F8"
      />
      <path
        d="M4.63086 13.2705C4.85738 13.2705 5.04101 13.0869 5.04101 12.8604C5.04101 12.6338 4.85738 12.4502 4.63086 12.4502C4.40434 12.4502 4.2207 12.6338 4.2207 12.8604C4.2207 13.0869 4.40434 13.2705 4.63086 13.2705Z"
        fill="#B598F8"
      />
      <path
        d="M4.51221 11.1807C4.73873 11.1807 4.92236 10.997 4.92236 10.7705C4.92236 10.544 4.73873 10.3604 4.51221 10.3604C4.28568 10.3604 4.10205 10.544 4.10205 10.7705C4.10205 10.997 4.28568 11.1807 4.51221 11.1807Z"
        fill="#B598F8"
      />
      <path
        d="M19.0093 15.2939C19.2358 15.2939 19.4194 15.1103 19.4194 14.8838C19.4194 14.6573 19.2358 14.4736 19.0093 14.4736C18.7828 14.4736 18.5991 14.6573 18.5991 14.8838C18.5991 15.1103 18.7828 15.2939 19.0093 15.2939Z"
        fill="#B598F8"
      />
      <path
        d="M18.0105 17.6201C18.237 17.6201 18.4207 17.4365 18.4207 17.21C18.4207 16.9834 18.237 16.7998 18.0105 16.7998C17.784 16.7998 17.6003 16.9834 17.6003 17.21C17.6003 17.4365 17.784 17.6201 18.0105 17.6201Z"
        fill="#B598F8"
      />
      <path
        d="M6.25049 17.6201C6.47701 17.6201 6.66064 17.4365 6.66064 17.21C6.66064 16.9834 6.47701 16.7998 6.25049 16.7998C6.02397 16.7998 5.84033 16.9834 5.84033 17.21C5.84033 17.4365 6.02397 17.6201 6.25049 17.6201Z"
        fill="#B598F8"
      />
      <path
        d="M16.8904 19.8594C17.1169 19.8594 17.3005 19.6757 17.3005 19.4492C17.3005 19.2227 17.1169 19.0391 16.8904 19.0391C16.6639 19.0391 16.4802 19.2227 16.4802 19.4492C16.4802 19.6757 16.6639 19.8594 16.8904 19.8594Z"
        fill="#B598F8"
      />
      <path
        d="M15.2105 22.0996C15.437 22.0996 15.6206 21.916 15.6206 21.6895C15.6206 21.4629 15.437 21.2793 15.2105 21.2793C14.9839 21.2793 14.8003 21.4629 14.8003 21.6895C14.8003 21.916 14.9839 22.0996 15.2105 22.0996Z"
        fill="#B598F8"
      />
      <path
        d="M7.93042 19.8594C8.15694 19.8594 8.34058 19.6757 8.34058 19.4492C8.34058 19.2227 8.15694 19.0391 7.93042 19.0391C7.7039 19.0391 7.52026 19.2227 7.52026 19.4492C7.52026 19.6757 7.7039 19.8594 7.93042 19.8594Z"
        fill="#B598F8"
      />
      <path
        d="M9.61035 22.0996C9.83687 22.0996 10.0205 21.916 10.0205 21.6895C10.0205 21.4629 9.83687 21.2793 9.61035 21.2793C9.38383 21.2793 9.2002 21.4629 9.2002 21.6895C9.2002 21.916 9.38383 22.0996 9.61035 22.0996Z"
        fill="#B598F8"
      />
      <path
        d="M19.4473 13.2705C19.6738 13.2705 19.8574 13.0869 19.8574 12.8604C19.8574 12.6338 19.6738 12.4502 19.4473 12.4502C19.2207 12.4502 19.0371 12.6338 19.0371 12.8604C19.0371 13.0869 19.2207 13.2705 19.4473 13.2705Z"
        fill="#B598F8"
      />
      <path
        d="M19.5662 11.1807C19.7927 11.1807 19.9763 10.997 19.9763 10.7705C19.9763 10.544 19.7927 10.3604 19.5662 10.3604C19.3396 10.3604 19.156 10.544 19.156 10.7705C19.156 10.997 19.3396 11.1807 19.5662 11.1807Z"
        fill="#B598F8"
      />
      <path
        d="M15.9435 12.7004C15.8937 12.5448 15.7565 12.4337 15.5942 12.4175C15.5564 12.4137 14.7079 12.3302 13.4247 12.285C12.864 10.825 12.3758 9.76809 12.3706 9.75699C12.3035 9.61217 12.1586 9.51953 11.9993 9.51953C11.84 9.51953 11.6952 9.61217 11.628 9.75699C11.6229 9.76809 11.1363 10.8213 10.577 12.2772C9.81424 12.3018 9.08438 12.3489 8.40396 12.4175C8.24181 12.4339 8.10482 12.5451 8.05516 12.7006C8.00545 12.8561 8.05259 13.0264 8.17509 13.134C8.63796 13.5407 9.1867 13.9805 9.80893 14.4436C9.51559 15.3533 9.28448 16.1966 9.12102 16.9541C9.08673 17.1131 9.1494 17.2773 9.28082 17.3728C9.35218 17.4247 9.43657 17.4509 9.52122 17.4509C9.59232 17.4509 9.66363 17.4324 9.72767 17.3949C9.73817 17.3888 10.7329 16.8053 11.9993 15.971C13.2657 16.8053 14.2605 17.3888 14.271 17.3949C14.4112 17.4769 14.5865 17.4683 14.7179 17.3728C14.8493 17.2773 14.912 17.113 14.8776 16.9541C14.7142 16.1967 14.4831 15.3533 14.1898 14.4436C14.8118 13.9806 15.3605 13.5409 15.8236 13.134C15.9462 13.0263 15.9933 12.8559 15.9435 12.7004Z"
        fill="#E3D8FC"
      />
    </svg>
  )
}
