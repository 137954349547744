import React, { ChangeEvent, useCallback, useState } from 'react'
import PhoneInput, { CountryData } from 'react-phone-input-2'

import { Countries } from './consts'
import useStyles from './styles'
import { IInputPhoneProps } from './types'

import 'react-phone-input-2/lib/material.css'

const InputPhone = (props: IInputPhoneProps) => {
  const { label = '', defaultCountry, onChange, onBlur, value, inputStyle, disabled } = props
  const [phoneValue, setPhoneValue] = useState(value)
  const [enableLongNumbers, setEnableLongNumbers] = useState<number | boolean>(20)
  const classes = useStyles()

  const blurHandler = (event: React.FocusEvent<HTMLInputElement>, countryObject: CountryData) => {
    if (onBlur) {
      onBlur(event, countryObject)
    }
  }

  const handlePhoneChange = useCallback(
    (valueData: string, countryData: CountryData, e: ChangeEvent<HTMLInputElement>, formattedValue: string) => {
      onChange(valueData, countryData)
      setPhoneValue(formattedValue)

      const isEnableLongNumbers =
        countryData.countryCode === Countries.RUSSIA ? false : countryData.format.replace(/[^.]/g, '').length
      setEnableLongNumbers(isEnableLongNumbers)
    },
    []
  )

  return (
    <PhoneInput
      country={defaultCountry}
      enableLongNumbers={enableLongNumbers}
      value={phoneValue}
      inputClass={classes.input}
      containerClass={classes.container}
      buttonClass={classes.button}
      dropdownClass={classes.dropdown}
      specialLabel={label}
      onChange={handlePhoneChange}
      onBlur={blurHandler}
      disableCountryGuess
      disabled={disabled}
      inputStyle={inputStyle}
    />
  )
}

export { InputPhone }
