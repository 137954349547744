import React from 'react'
import { DefaultTheme } from 'react-jss'
import cn from 'classnames'

import useStyles from './styles'

import LogoHeader from '@/components/LogoHeader'

export interface IContentBlockProps {
  hasProfile: boolean
  isAuthorized: boolean
  isWhite: boolean
  isSmallContainerPadding: boolean
  children: React.ReactNode
}

const ContentBlock: React.FC<IContentBlockProps> = (props: IContentBlockProps & { theme?: DefaultTheme }) => {
  const { children, isAuthorized } = props

  const classes = useStyles(props)

  return (
    <main className={cn(classes.contentBlock, { [classes.contentBlockDesktop]: !isAuthorized })}>
      {!isAuthorized && (
        <div className={classes.mobileLogo}>
          <LogoHeader />
        </div>
      )}
      <div
        className={cn({
          [classes.container]: !isAuthorized,
        })}
      >
        {children}
      </div>
    </main>
  )
}

export default ContentBlock
