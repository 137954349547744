import React from 'react'

import { IIcons } from './types'

export default (props: IIcons) => {
  const { style } = props
  const defaultStyle = { width: 64, height: 52 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 64 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.5001 23.0063C45.6341 26.8723 39.3659 26.8723 35.4999 23.0063C31.6338 19.1402 31.6338 12.8721 35.4999 9.00599C39.3659 5.13993 45.6341 5.13993 49.5001 9.00599C53.3662 12.8721 53.3662 19.1402 49.5001 23.0063Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.4997 23.0081C39.3658 26.8741 45.6339 26.8741 49.5 23.0081C53.3661 19.142 53.3661 12.8739 49.5 9.00781C51.8334 11.3412 51.0327 16.8086 47.1666 20.6747C43.3006 24.5408 37.8331 25.3415 35.4997 23.0081Z"
        fill="#EFEEF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.4999 23.0064C39.3659 26.8724 45.6341 26.8724 49.5001 23.0064C53.3662 19.1403 53.3662 12.8722 49.5001 9.00612C45.6341 5.14006 39.3659 5.14006 35.4999 9.00612C31.6338 12.8722 31.6338 19.1403 35.4999 23.0064ZM31.9998 26.5064C37.7989 32.3055 47.2011 32.3055 53.0002 26.5064C58.7993 20.7073 58.7993 11.3052 53.0002 5.50606C47.2011 -0.293043 37.7989 -0.293043 31.9998 5.50606C26.2007 11.3052 26.2007 20.7073 31.9998 26.5064Z"
        fill="url(#paint0_linear_20271_224632)"
      />
      <path d="M30.834 25.3398L33.1674 27.6732L29.6673 31.1733L27.3339 28.8399L30.834 25.3398Z" fill="#E4E9ED" />
      <path
        d="M25.035 28.2967C26.3486 27.275 28.2175 27.3914 29.3943 28.5681L29.9413 29.1152C31.1181 30.2919 31.2345 32.1608 30.2127 33.4745L17.9625 49.2248C16.7439 50.7915 14.4279 50.9357 13.0243 49.5322L8.97725 45.4851C7.57373 44.0816 7.71792 41.7656 9.28469 40.547L25.035 28.2967Z"
        fill="url(#paint1_linear_20271_224632)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.9996 26.5063C37.7987 32.3053 47.2009 32.3053 53 26.5063C58.7991 20.7072 58.7991 11.305 53 5.50586C57.0834 9.58927 57.0491 18.9571 51.25 24.7562C45.4509 30.5553 36.083 30.5897 31.9996 26.5063Z"
        fill="#C9CED1"
      />
      <defs>
        <linearGradient
          id="paint0_linear_20271_224632"
          x1="57.6669"
          y1="17.1729"
          x2="27.9164"
          y2="16.5896"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D4DBDF" />
          <stop offset="1" stopColor="#E6EBEF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_20271_224632"
          x1="30.8341"
          y1="31.1746"
          x2="8.66701"
          y2="42.8415"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B9ABDD" />
          <stop offset="1" stopColor="#DBD0F9" />
        </linearGradient>
      </defs>
    </svg>
  )
}
