import React from 'react'

import { BackgroundWhite } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = BackgroundWhite } = props
  const defaultStyle = { width: 40, height: 40 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20ZM18.0817 16.8551C17.8081 16.8105 17.5318 16.7861 17.2548 16.7821C14.5732 16.7762 12.199 18.5611 11.3938 21.1882C10.5885 23.8154 11.4709 26.6258 13.6722 28.1988C15.3882 30.0944 18.126 30.7678 20.4716 29.8211C22.8172 28.8745 24.3598 26.55 24.3603 23.9616V17.0076C25.7127 18.0029 27.3353 18.5368 28.999 18.5338V15.1165C28.6733 15.1154 28.3493 15.0799 28.0307 15.0104V14.2473C27.132 14.2476 26.2532 13.9752 25.5046 13.4643C24.7673 12.6019 24.3602 11.4932 24.3605 10.3455H23.4689C23.4199 10.0609 23.3965 9.77204 23.3991 9.483H20.059V23.1986C20.0034 24.7448 18.7681 25.9697 17.2616 25.9722C16.8114 25.9682 16.3685 25.8545 15.9695 25.6404L15.9638 25.7068C15.3469 24.8321 15.2598 23.6759 15.7382 22.7141C16.2166 21.7523 17.1798 21.1474 18.2314 21.1482C18.5118 21.1518 18.7902 21.1965 19.0583 21.2809V17.7906C18.7348 17.7408 18.4097 17.7243 18.0828 17.7243L18.0817 16.8551Z"
        fill={fill}
      />
    </svg>
  )
}
