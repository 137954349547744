import { Reducer } from 'redux'

import { AddTelegramActionTypes } from './actions'
import { reducerName } from './modals/actions'
import { IAddTelegramState } from './types'

export const initialState: IAddTelegramState = {
  subjects: [],
  error: null,
  isLoading: false,
  modals: null,
  redirect: '',
}

const addTelegramReducer: Reducer<IAddTelegramState> = (state = initialState, action: any) => {
  switch (action.type) {
    case AddTelegramActionTypes.SET_TELEGRAM_SUBJECTS: {
      return { ...state, subjects: action.data }
    }
    case AddTelegramActionTypes.SET_TELEGRAM_LOADING: {
      return { ...state, isLoading: action.data }
    }
    case AddTelegramActionTypes.SET_TELEGRAM_ERROR: {
      return { ...state, error: action.data }
    }
    case AddTelegramActionTypes.SET_TELEGRAM_REDIRECT: {
      return { ...state, redirect: action.data }
    }
    case AddTelegramActionTypes.UPDATE_TELEGRAM_ERROR: {
      return {
        ...state,
        error: {
          ...state.error,
          ...action.data,
        },
      }
    }
    case `${reducerName}.OPEN_MODAL`: {
      return {
        ...state,
        modals: action.payload,
      }
    }
    case `${reducerName}.CLOSE_MODAL`: {
      return {
        ...state,
        modals: null,
      }
    }
    default: {
      return state
    }
  }
}

export default addTelegramReducer
