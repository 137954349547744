import { createUseStyles } from 'react-jss'

export default createUseStyles(
  {
    contentWrapper: {
      display: 'flex',
      height: (props: { isAuthorized: boolean }) => (props.isAuthorized ? 'calc(100vh - 50px)' : '100vh'),
      position: 'relative',
      top: (props: { isAuthorized: boolean }) => (props.isAuthorized ? '50px' : '0'),
      overflow: 'hidden',
    },
  },
  { name: 'ContentWrapper' }
)
